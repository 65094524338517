import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import DateField from "../../field/DateField/DateField";
import useIcoService from "../../../services/icoService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import { isEmptyObject } from "../../../helpers/methods";

const ChangePaidTransferDateDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();


  const {
    formValue,
    setFormValue,
    onChangeDate
  } = useForm({ id: props.transferId, transfer_date_paid: undefined });

  const {
    getTransferShortDetails,
    updateTransfer
  } = useIcoService();

  const updateTransferFn = useAsyncFn(updateTransfer)

  const transferShortData = useAsync(
    () => {
      if (props.transferId) {
        return getTransferShortDetails(props.transferId)
      }
      return Promise.resolve(formValue)
    },
    [props.transferId]
  );


  useEffect(() => {
    if (transferShortData.loading) {
      return;
    }
    if (!isEmptyObject(transferShortData.value)) {
      setFormValue(transferShortData.value);
    }
  }, [transferShortData.loading]);

  const onSubmit = useCallback(() => {
    updateTransferFn
      .execute(props.transferId, formValue)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.transfer_updated")
        );
        props.onClose();
        props.onRefetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_transfer_updating")
        );
      });

  }, [formValue]);



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.change_paid_transfer_date_dialog.change_date")}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <DateField
            name={"transfer_date_paid"}
            onChange={onChangeDate}
            value={formValue.transfer_date_paid}
            label={t("dialog.change_paid_transfer_date_dialog.transfer_date_paid")}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={updateTransferFn.loading}
            onClick={onSubmit}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>

    </BasicDialog>
  );
};

ChangePaidTransferDateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  trasferId: PropTypes.string,
  onRefetch: PropTypes.func
};

ChangePaidTransferDateDialog.defaultProps = {
  open: false,
};

export default ChangePaidTransferDateDialog;
