import React from "react";
import {
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";


const ValidationTypography = React.memo(({label}) => {

  return(
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {label}
        </Typography>)
});

ValidationTypography.propTypes = {
  label: PropTypes.string,
};

ValidationTypography.defaultProps = {
};

export default ValidationTypography;
