import { Grid, IconButton, Typography } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import PropTypes from "prop-types";
import DateField from "../../field/DateField/DateField";
import { useCallback, useState } from "react";
import useItemService from "../../../services/itemService";
import { useForm } from "../../../hooks/useForm";
import {
  MATERIAL_MAIN_BRANCH_NAME,
  DEVICE_MAIN_BRANCH_NAME,
  COUNTER_BRANCH_NAME,
} from "../../../helpers/constants";
import useDialog from "../../../hooks/useDialog";
import SelectPartDialog from "../../dialog/SelectPartDialog";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useOfferService from "../../../services/offerService";
import { useAsyncFn } from "../../../hooks/useAsync";
import MiniButton from "../../base/MiniButton/MiniButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useEquipmentService from "../../../services/equipmentService";

const TransactionInMaterialRecord = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { prepareItemDataFromBackendForItemRecordComponent } = useItemService();

  const { onChangeAutocompleteFieldWithObjectOptions, onChange, onChangeDate } =
    useForm();

  const { getEquipmentItemRecordById } = useEquipmentService();

  const { getPartItemRecordById } = useOfferService();

  const getPartItemRecordByIdFn = useAsyncFn(getPartItemRecordById);
  const getEquipmentItemRecordByIdFn = useAsyncFn(getEquipmentItemRecordById);

  const [
    openSelectPartDialog,
    onOpenSelectPartDialog,
    onCloseSelectPartDialog,
  ] = useDialog();

  const onChangeLevelItem = (item) => {
    props.onChangeByIndex({
      name: "item",
      value: item,
      index: props.materialIndex,
    });
    props.onCleanPart(props.materialIndex);
  };

  const setItemBranchWithMaterial = useCallback(
    (selectedPartId) => {
      const getItemRecordFn =
        props.itemType === "equipment"
          ? getEquipmentItemRecordByIdFn
          : getPartItemRecordByIdFn;

      getItemRecordFn
        .execute(selectedPartId)
        .then((result) => {
          props.onChangeByIndex({
            name: "item",
            value: prepareItemDataFromBackendForItemRecordComponent(
              result.item
            ),
            index: props.materialIndex,
          });
          if (props.itemType === "equipment") {
            props.onChangeByIndex({
              name: "equipment",
              value: result.id,
              index: props.materialIndex,
            });
            props.onSetEquipmentData(result, props.materialIndex);
          } else {
            props.onChangeByIndex({
              name: "part",
              value: result.id,
              index: props.materialIndex,
            });
            props.onSetPartData(result, props.materialIndex);
          }
          onCloseSelectPartDialog();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_get_item_data")
          );
        });
    },
    [props.materialIndex]
  );

  const onChangeAutocompleteLocal = useCallback(
    (e, value_object, value_key, state_value_name) => {
      props.onChangeByIndex({
        ...onChangeAutocompleteFieldWithObjectOptions(
          e,
          value_object,
          value_key,
          state_value_name
        ),
        index: props.materialIndex,
      });
      if (state_value_name === "part") {
        props.onSetPartData(value_object, props.materialIndex);
      }
      if (state_value_name === "equipment") {
        props.onSetEquipmentData(value_object, props.materialIndex);
      }
    },
    [props.materialIndex]
  );

  const onChangeLocal = useCallback(
    (e) => {
      props.onChangeByIndex({ ...onChange(e), index: props.materialIndex });
    },
    [props.materialIndex]
  );

  const onChangeDateLocal = useCallback(
    (e) => {
      props.onChangeByIndex({ ...onChangeDate(e), index: props.materialIndex });
    },
    [props.materialIndex]
  );

  const getItemLabel = () =>
    props.itemType === "equipment"
      ? t("form.transaction_form.equipment")
      : t("form.transaction_form.part");

  const getButtonLabel = () =>
    props.itemType === "equipment"
      ? t("form.transaction_form.add_equipment")
      : t("form.transaction_form.add_part");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
            <Typography variant="subtitle2" className="capitalize-first-letter">
              {getItemLabel()}
              {` ${props.materialIndex + 1}`}
            </Typography>

            {props.showButtonDeleteMaterial && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
                onClick={() => {
                  props.onDeleteLocation(props.materialIndex);
                }}
              >
                <RemoveCircleIcon fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          <Grid item sm={6} xs={6} textAlign={"end"}>
            {props.showButtonAddMaterial && (
              <MiniButton
                variant="contained"
                size="small"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={props.onAddMaterial}
              >
                {getButtonLabel()}
              </MiniButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      {props.showItemRecord ? (
        <Grid item xs={12}>
          <ItemRecord
            itemLevels={props.material["item"]}
            onChangeItem={onChangeLevelItem}
            onChangeAutocompleteFieldWithObjectOptions={
              onChangeAutocompleteLocal
            }
            partValue={props.material["part"]}
            equipmentValue={props.material["equipment"]}
            onCleanPart={props.onCleanPart}
            showMaterialLabel={props.itemType === "part"}
            showEquipmentLabel={props.itemType === "equipment"}
            required={false}
            onClickSearchItem={onOpenSelectPartDialog}
            defaultFirstLevelItemName={
              props.itemType === "equipment"
                ? DEVICE_MAIN_BRANCH_NAME
                : MATERIAL_MAIN_BRANCH_NAME
            }
            defaultSecondLevelItemName={
              props.itemType === "equipment" ? COUNTER_BRANCH_NAME : null
            }
          />
        </Grid>
      ) : props.itemType === "equipment" ? (
        <Grid item xs={3}>
          <AutocompleteField
            name={"equipment"}
            label={t("form.transaction_form.equipment")}
            value={props.material[`equipment`]}
            options={props.poPartList?.value}
            isObjectOption={true}
            optionLabelKey={"equipment_nr"}
            onChange={onChangeAutocompleteLocal}
            addNewValue={false}
            multiple={false}
            required={true}
          />
        </Grid>
      ) : (
        <Grid item xs={3}>
          <AutocompleteField
            name={"part"}
            label={t("form.transaction_form.part")}
            value={props.material[`part`]}
            options={props.poPartList?.value}
            isObjectOption={true}
            optionLabelKey={"part_name"}
            onChange={onChangeAutocompleteLocal}
            addNewValue={false}
            multiple={false}
            required={true}
          />
        </Grid>
      )}
      <Grid item xs={1.5}>
        <FloatField
          name="transaction_amount"
          readOnly={props.readOnly || props.itemType === "equipment"}
          value={props.material[`transaction_amount`]}
          label={t("form.transaction_form.transaction_amount")}
          onChange={onChangeLocal}
          required
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextFieldFormControl
          disabled={true}
          value={
            props.material.part_unit
              ? t(`other.units.${props.material.part_unit}`)
              : undefined
          }
          label={t("form.transaction_form.part_unit")}
        />
      </Grid>

      <Grid item xs={2}>
        <FloatField
          name="transaction_price"
          readOnly={props.readOnly}
          decimalPlaces={2}
          value={props.material[`transaction_price`]}
          label={t("form.transaction_form.transaction_price")}
          onChange={onChangeLocal}
          required
          suffix={"zł"}
        />
      </Grid>

      <Grid item xs={2}>
        <FloatField
          name="transaction_value"
          disabled={true}
          value={props.material[`transaction_value`]}
          label={t("form.transaction_form.transaction_value")}
          onChange={onChangeLocal}
          suffix={"zł"}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldFormControl
          name="transaction_part_production_number"
          value={props.material[`transaction_part_production_number`]}
          label={t("form.transaction_form.production_number")}
          onChange={onChangeLocal}
        />
      </Grid>

      <Grid item xs={2}>
        <DateField
          name={"transaction_part_production_date"}
          value={props.material["transaction_part_production_date"]}
          onChange={onChangeDateLocal}
          label={t("form.transaction_form.production_date")}
          showClearIcon={false}
        />
      </Grid>
      {openSelectPartDialog && (
        <SelectPartDialog
          open={openSelectPartDialog}
          onClose={onCloseSelectPartDialog}
          onSubmit={setItemBranchWithMaterial}
          itemType={props.itemType}
        />
      )}
    </Grid>
  );
};

TransactionInMaterialRecord.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  onChangeLevelItem: PropTypes.func,
  onChangeDate: PropTypes.func,
  readOnly: PropTypes.bool,
  showItemRecord: PropTypes.bool,
  showButtonAddMaterial: PropTypes.bool,
  onAddMaterial: PropTypes.func,
  materialIndex: PropTypes.number,
  showButtonDeleteMaterial: PropTypes.bool,
  showSearchItemButton: PropTypes.bool,
  itemType: PropTypes.string,
};

TransactionInMaterialRecord.defaultProps = {
  readOnly: false,
  showItemRecord: true,
  showButtonAddMaterial: false,
  materialIndex: 0,
  showButtonDeleteMaterial: false,
  itemType: "part",
  showSearchItemButton: true,
};

export default TransactionInMaterialRecord;
