import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import useWarehouseService from "../../../../services/warehouseService";
import { useAsync, useAsyncFn } from "../../../../hooks/useAsync";
import { getTableConfigByItemType } from "./TableConfig";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import TransactionTable from "../../../table/TransactionTable";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import TransactionDialog from "../../../dialog/TransactionDialog";
import ReservationForm from "../../../form/ReservationForm/ReservationForm";
import { RESERVATION_KIND } from "../../../../helpers/constants";
import DateService from "../../../../services/dateService";
import useTicketService from "../../../../services/ticketService";
import { useSnackbarAlert } from "../../../../context/snackbarAlert";
import { reservationExportDataUrl } from "../../../../helpers/apiUrls";
import { isEmptyObject } from "../../../../helpers/methods";

const WarehouseReservationsTab = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => {
    return getTableConfigByItemType(props.itemType);
  }, [props.itemType]);

  const { getTicketSelectList } = useTicketService();

  const { cancelReservation } = useWarehouseService();
  const snackbarAlert = useSnackbarAlert();

  const ticketSelectList = useAsync(
    () => getTicketSelectList({ is_open: true }),
    []
  );
  const cancelReservationFn = useAsyncFn(cancelReservation);

  const getDefaultDate = () => {
    var date = new Date();
    date.setDate(date.getDate() + 14);
    return DateService.convertDateToFormatYYYYMMDD(date);
  };

  const defaultReservationClosedDate = getDefaultDate();

  const onCancelReservation = (e, reservationId) => {
    e.stopPropagation();
    cancelReservationFn
      .execute(reservationId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reservation_canceled")
        );
        reservationList.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_reservation_canceling")
        );
      });
  };

  const {
    page: reservationPage,
    pageSize: reservationPageSize,
    handleChangePageWithSearchParams: handleChangeReservationDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getReservationData } = useWarehouseService();

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (props.itemType === "equipment") {
      newSearchParams.set('equipment__isnull', false);
      setSearchParams(newSearchParams)
    } else {
      newSearchParams.set('part__isnull', false);
    }
    setSearchParams(newSearchParams)
  }, [searchParams, props.itemType]);

  const reservationList = useAsync(() => {
    const urlParams = new URLSearchParams(searchParams);
    const params = Object.fromEntries(urlParams.entries());
    if (!isEmptyObject(params)) {
      return getReservationData(searchParams);
    }
    return Promise.resolve([])
  }, [searchParams]);

  useImperativeHandle(ref, () => ({
    onRefetchData() {
      reservationList.refetch();
    },
  }));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={
            <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
          }
          onClick={props.onOpenTransactionDialog}
        >
          {t("tabs.warehouse_transaction_tabs.new_reservation")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TransactionTable
          transactionFilteringData={props.transactionFilteringData}
          tableConfig={tableConfig}
          transactionData={reservationList}
          page={reservationPage}
          pageSize={reservationPageSize}
          handleChangePageWithSearchParams={handleChangeReservationDataPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          onCancelReservation={onCancelReservation}
          exportToFileUrl={reservationExportDataUrl}
          exportToFileSearchParams={searchParams}
        />
      </Grid>
      {props.openTransactionDialog && (
        <TransactionDialog
          open={props.openTransactionDialog}
          title={t("tabs.warehouse_transaction_tabs.new_reservation")}
          onClose={props.onCloseTransactionDialog}
          transactionKind={RESERVATION_KIND}
          extraRequiredFields={["reservation_closed_date"]}
          onSubmit={props.onSubmit}
          isDataSaving={props.isSaving}
          reservationClosedDate={defaultReservationClosedDate}
          transactionForm={
            <ReservationForm
              ticketSelectList={ticketSelectList}
              itemType={props.itemType}
            />
          }
          itemType={props.itemType}
        />
      )}
    </Grid>
  );
});

WarehouseReservationsTab.propTypes = {
  readOnly: PropTypes.bool,
  itemType: PropTypes.string,
};

WarehouseReservationsTab.defaultProps = {
  itemType: "part",
};

export default WarehouseReservationsTab;
