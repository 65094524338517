import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import { useForm } from "../../../hooks/useForm";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import EditableTable from "../../table/EditableTable/EditableTable";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import { NEXT_TRANSFERS_EDITABLE_TABLE_FIELDS_CONFIG } from "./DialogTablesConfig";
import TableService from "../../../services/tableService";
import { Button, Grid } from "@mui/material";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import { convertUndefinedValueToZero, getErrorMsg, isEmptyArray, isEmptyValue } from "../../../helpers/methods";
import CreateTransferForm from "../../form/CreateTransferForm/CreateTransferForm";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import ValidationForm from "../../form/ValidationForm";
import useIcoService from "../../../services/icoService";


const CreateTransferDialog = ({ open, onClose, onRefetch, payments }) => {
  const { t } = useTranslation();
  const textLimit = 140;

  const [localPayments, setLocalPayments] = useState(payments.map((p) => ({ ...p, editable: true, alocation_value: 0 })))

  const [paymentIds, setPaymentIds] = useState([]);

  const requiredFields = [
    "transfer_payer_iban",
    "transfer_receiver_iban",
    "transfer_title"
  ];

  const [isAllocatedValueValid, setIsAllocatedValueValid] = useState(true)
  const helperErrorText = t("dialog.create_transfer_dialog.exceeded_maz_chars_limit")
  

  const [helperTextStyle, setHelperTextStyle] = useState({ alignSelf: "end" });
  const [isTransferTitleCorrect, setIsTransferTitleCorrect] = useState(true)

  const [areAllValuesGreaterThanZero, setAreAllValuesGreaterThanZero] = useState(true)

  const { formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({ transfer_amount: 0 });

  const {
    getIbanShortData
  } = useUserService();

  const {
    getInitTransferTitle,
    createTransfer
  } =useIcoService();

  const createTransferFn = useAsyncFn(createTransfer);

  const { emptyArrayMemo } = useEmptyArrayMemo()

  const getAlocatedValuesSum = (payments) =>{
    let sum = 0;
    for (let p of payments){
      sum = sum + parseFloat(convertUndefinedValueToZero(p?.alocation_value))
    }
    return sum
  }

  useEffect(() => {
    if (!isEmptyArray(payments)) {
      setFormValue((prev) => ({
        ...prev,
        transfer_payer: payments[0].icopayment_payer_id,
        transfer_receiver: payments[0].icopayment_receiver_id,
        transfer_payer_name: payments[0].icopayment_payer,
        transfer_receiver_name: payments[0].icopayment_receiver,
        currency: payments[0].currency_id,
        currency_name: payments[0].currency
      }
      ))
      setPaymentIds(payments.map((p) => p.id))
    }
  }, [payments]);


  useEffect(() => {
    setIsAllocatedValueValid(localPayments.every((payment) => parseFloat(payment.alocation_value) <= parseFloat(payment.icopayment_current_amount_original)))
    setAreAllValuesGreaterThanZero(localPayments.every((payment) => parseFloat(payment.alocation_value) > 0))
  
  }, [localPayments]);


  const payerBankAccounts = useAsync(
    () => {
      if (formValue?.transfer_payer && formValue?.currency) {
        return getIbanShortData({ owner: formValue.transfer_payer, currency: formValue.currency })
      }
      return Promise.resolve([])
    },
    [formValue?.transfer_payer, formValue?.currency]);

    const getPaymentTitle = useAsync(
      () => {
        if (!isEmptyArray(paymentIds)) {
          return getInitTransferTitle({payments : paymentIds})
        }
        return Promise.resolve({})
      },
      [paymentIds]);


      useEffect(() => {
        if (getPaymentTitle.loading){
          return;
        }

        setFormValue((prev) => ({...prev, transfer_title : getPaymentTitle.value}))
        
      }, [getPaymentTitle.loading]);
  
    
  const receiverBankAccounts = useAsync(
    () => {
      if (formValue?.transfer_receiver && formValue?.currency) {
        return getIbanShortData({ owner: formValue.transfer_receiver, currency: formValue.currency })
      }
      return Promise.resolve([])
    },
    [formValue?.transfer_receiver, formValue?.currency]);

  const snackbarAlert = useSnackbarAlert();

  const onPrepareData = () => {
    let dataToSend = formValue
    dataToSend.transfer_payments_data = localPayments.map((payment) => ({payment_id : payment.id, alocation_value : parseFloat(payment.alocation_value)}))
    return dataToSend
  }

  const onSubmit = () => {
    createTransferFn
        .execute(onPrepareData())
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.transfer_created")
          );
          onRefetch()
          onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(
              error.data,
              t("snackbar_alert.occurred_error_during_transfer_creating")
            )
          );
        });
  };

  const onEditRowByKind = useCallback((name, value, index) => {
    setLocalPayments((prev) => {
      let oldState = [...prev];
      let paymentRow = oldState[index]
      paymentRow[name] = value
      paymentRow.editDate = Date.now()
      oldState[index] = { ...paymentRow }
      let newLocalPayments = [...oldState]
      setFormValue((prev) =>({
        ...prev,
        transfer_amount : getAlocatedValuesSum(newLocalPayments)
    }))
      return newLocalPayments;
    })
  }, [])

  const tableData = React.useMemo(() => {
    return TableService.getFieldsConfigForEditableTable(
      NEXT_TRANSFERS_EDITABLE_TABLE_FIELDS_CONFIG,
      localPayments,
      emptyArrayMemo)
  }, [localPayments, NEXT_TRANSFERS_EDITABLE_TABLE_FIELDS_CONFIG])

  const isValid = formValue
    ? requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    ) && isAllocatedValueValid && areAllValuesGreaterThanZero  && isTransferTitleCorrect: false;



    useEffect(()=>{
      if (formValue?.transfer_title){
        checkInputLengthAndChangeStyleAfterCharsLimit(formValue.transfer_title)
      }
    },[formValue?.transfer_title])

    const checkInputLengthAndChangeStyleAfterCharsLimit = (textMessage) => {
      if (textMessage.length <= textLimit) {
        setHelperTextStyle({ alignSelf: "end" });
        setIsTransferTitleCorrect(true)
      }else{
        let tempHelperTextStyle = { ...helperTextStyle };
        tempHelperTextStyle["color"] = "red";
        setHelperTextStyle(tempHelperTextStyle);
        setIsTransferTitleCorrect(false)
      }
    };


  return (
    <BasicDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"xl"}
      titleAlign="center"
      title={t("dialog.create_transfer_dialog.transfer_data")}
    >

      <LoaderWrapper
        showLoader={payerBankAccounts.loading || receiverBankAccounts.loading}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("dialog.create_transfer_dialog.next_transfers")}
              component={"div"}
            >
              <EditableTable
                data={tableData}
                readOnly={false}
                editable={true}
                showCheckbox={false}
                onDataChange={onEditRowByKind}
                filteringData={emptyArrayMemo}
                tableConfig={NEXT_TRANSFERS_EDITABLE_TABLE_FIELDS_CONFIG}
                showContextMenu={false}
              />
              <ValidationForm
                isAllocatedValueValid= {isAllocatedValueValid}
                areAllValuesGreaterThanZero = {areAllValuesGreaterThanZero}
              />
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12} >
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("dialog.create_transfer_dialog.transfer")}
              component={"div"}
            >
              <CreateTransferForm
                transferData={formValue}
                receiverIbans={receiverBankAccounts?.value ? receiverBankAccounts.value : []}
                payerIbans={payerBankAccounts?.value ? payerBankAccounts.value : []}
                onChange={onChange}
                onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
                isInvalid={!isTransferTitleCorrect}
                helperTextStyle={helperTextStyle}
                errorText = {isTransferTitleCorrect ? undefined : helperErrorText}

              />
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              disabled={!isValid}
              fullWidth
            >
              {t("dialog.create_transfer_dialog.make_transfer")}
            </Button>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

CreateTransferDialog.propTypes = {
  readOnly: PropTypes.bool,
  icoId: PropTypes.string,
  onChangeSchedule: PropTypes.func,
  payments: PropTypes.array,
  isScheduleUpdated: PropTypes.bool,
  isScheduleFormValid: PropTypes.bool
};

CreateTransferDialog.defaultProps = {
  readOnly: false,
  payments: [],
  isScheduleUpdated: true,
  isScheduleFormValid: false
};

export default CreateTransferDialog;
