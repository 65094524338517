import i18n from "../../../i18n";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import DateService from "../../../services/dateService";
import SelectFieldService from "../../../services/selectFieldService";

export const EQUIPMENT_INSTALATION_TENANT_SET_TABLE_CONFIG = [
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.counter_repository.tenant"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eits_start_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eits_start_settlement
      ),
    label: i18n.t("table.counter_repository.eits_start_settlement"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eits_end_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eits_end_settlement
      ),
    label: i18n.t("table.counter_repository.eits_end_settlement"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eits_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.eits_active),
    label: i18n.t("table.counter_repository.eits_active"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickMultipleLocationAlert, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
