import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useUserService from "../../../services/userService";
import IbanDetailsForm from "../../form/IbanDetailsForm";
import useIcoService from "../../../services/icoService";


const IbanDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [isIbanNrFormatValid, setIsIbanNrFormatValid] = useState(true);
  const ibanNrFormatRegex = /^[a-zA-Z]{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}$/


  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({ owner: props.ownerId });

  const {
    getIbanDetails,
    createIban,
    updateIban,
  } = useUserService();

  const {
    getCurrencySelectList
  } = useIcoService();

  const updateIbanFn = useAsyncFn(updateIban)
  const createIbanFn = useAsyncFn(createIban)

  const ibanDetailsData = useAsync(
    () => getIbanDetails(props.ibanId),
    [props.ibanId]
  );

  const currencySelectListgData = useAsync(
    () => getCurrencySelectList(),
    []
  );

  const validateLocationNumber = (ibanNr) => {
    return ibanNrFormatRegex.test(ibanNr);
  };


  useEffect(() => {
      setIsIbanNrFormatValid(validateLocationNumber(formValue?.iban_nr));
  }, [formValue.iban_nr]);

  useEffect(() => {
    if (ibanDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(ibanDetailsData.value)) {
      setFormValue(ibanDetailsData.value);
    }
  }, [ibanDetailsData.loading]);



  const onSubmit = useCallback(() => {
    if (props.ibanId) {
      updateIbanFn
        .execute(props.ibanId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.iban_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_iban_updating")
          );
        });
    } else {
      if (props.ownerId) {
        createIbanFn
          .execute(formValue)
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.iban_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_iban_creating")
            );
          });
      } else {
        props.onAddIbanForNewOwner(formValue)
        props.onClose();
      }
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.currency) && !isEmptyValue(formValue.iban_nr) && !isEmptyValue(formValue.iban_bank) && isIbanNrFormatValid



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.ibanId ? t(`dialog.iban_details_dialog.iban_details`) : t(`dialog.iban_details_dialog.add_iban`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {ibanDetailsData.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <IbanDetailsForm
                ibanData={formValue}
                onChange={onChange}
                onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
                currencies={currencySelectListgData?.value ? currencySelectListgData?.value : []}
              isIbanNrFormatValid= {isIbanNrFormatValid|| ibanDetailsData.loading}

              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateIbanFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.ibanId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

IbanDialog.propTypes = {
  ownerId: PropTypes.string,
  ibanId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

IbanDialog.defaultProps = {
  open: false,
};

export default IbanDialog;
