import { Button } from "@mui/material"
import PropTypes from "prop-types";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from "react-i18next";
import { useMemo } from "react";



function AddEnclosureButton(props) {

    const { t } = useTranslation();

    const isMiniSize = useMemo(() => {
        return props.size === "mini"
    }, [props.size])

    const getAddEnclosureButtonName = () => {
        switch (props.fileType) {
            case "docs":
                return t("dialog.enclosures_dialog.add_docs")
            case "certs":
                return t("dialog.enclosures_dialog.add_certificates")
            case "draft":
                return t("dialog.enclosures_dialog.add_draft")
            default:
                return t("dialog.enclosures_dialog.add_attachment")
        }
    }

    return (
        <Button
            size={isMiniSize ? "small" : props.size}
            variant={props.variant}
            color={props.color}
            onClick={props.onClick}
            sx={props.sx}
            startIcon={<FileUploadIcon />}
            className={isMiniSize ? "mini-button" : undefined}
        >

            {getAddEnclosureButtonName()}
        </Button>
    )

}

AddEnclosureButton.propTypes = {
    sx: PropTypes.object,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    fileType: PropTypes.string
};

AddEnclosureButton.defaultProps = {
    sx: {},
    fileType: "enclosure"

};


export default AddEnclosureButton