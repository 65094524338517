import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { pl } from "date-fns/locale";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DateService from "../../../services/dateService";
import FormHelperText from "@mui/material/FormHelperText";

export default function DateField(props) {
  const { value, onChange, disabled, name, required, index } = props;

  const getInputFormatForView = () => {
    if (props.view === "day") return "dd/MM/yyyy";
    if (props.view === "month") return "MM/yyyy";
    if (props.view === "year") return "yyyy";
  };

  const getViewsPropsForView = () => {
    if (props.view === "day") return ["year", "month", "day"];
    if (props.view === "month") return ["year", "month"];
    if (props.view === "year") return ["year"];
  };

  return (
    <LocalizationProvider adapterLocale={pl} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        views={getViewsPropsForView()}
        openTo={props.view}
        minDate={props.minDate}
        maxDate={props.maxDate}
        label={props.label.charAt(0).toUpperCase() + props.label.slice(1)}
        inputFormat={getInputFormatForView()}
        disabled={disabled || props.readOnly}
        value={value || null}
        readOnly={props.readOnly}
        open={props.open}
        onClose={props.onClose}
        onChange={(newValue) => {
          if (DateService.isValidDate(newValue)) {
            onChange({ target: { value: newValue, name: name } });
          }
        }}
        renderInput={(params) => (
          <div style={{ position: "relative" }}>
            <TextField
              {...params}
              variant={props.variant}
              size="small"
              style={props.style}
              fullWidth
              required={required}
              error={props.error}
              onClick={props.onClickTextInput}
              sx={{ ...props.textFieldSx }}
            />
            {value && !props.readOnly && props.showClearIcon && (
              <IconButton
                style={{
                  position: "absolute",
                  top: props.variant === "standard" ? ".5rem" : "0rem",
                  margin: "auto",
                  right: "2rem",
                }}
                onClick={(e) => {
                  e.target.value = props.setEmptyString ? "" : null;
                  e.target.name = name;
                  onChange(e);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
        )}
      />
      {props.helperText.length > 0 ? (
        <FormHelperText
          id="component-helper-text"
          error={props.error}
          style={props.helperTextStyle}
        >
          {props.helperText}
        </FormHelperText>
      ) : null}
    </LocalizationProvider>
  );
  // }
}

DateField.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  textFieldSx: PropTypes.object,
  onClickTextInput: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  showClearIcon: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  view: PropTypes.oneOf(["day", "month", "year"]),
  setEmptyString: PropTypes.bool,
};

DateField.defaultProps = {
  readOnly: false,
  open: null,
  onClose: null,
  variant: "outlined",
  label: "",
  showClearIcon: true,
  view: "day",
  setEmptyString: false,
  helperText: "",
};
