import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useIcoService from "../../../services/icoService";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";


const InterestDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange
  } = useForm({});


  const {
    getInterestDetails,
    createInterest,
    updateInterest,
  } = useIcoService();


  const createInterestFn = useAsyncFn(createInterest)
  const updateInterestFn = useAsyncFn(updateInterest)

  const interestData = useAsync(
    () => {
      if (props.interestId) {
        return getInterestDetails(props.interestId)
      }
      return Promise.resolve(formValue)
    },
    [props.interestId]
  );

  const getPreparedData = (formValue) =>{
    return {
      interest_active : formValue?.interest_active,
      interest_name : formValue?.interest_name
    }
  }


  useEffect(() => {
    if (interestData.loading) {
      return;
    }
    if (!isEmptyObject(interestData.value)) {
      setFormValue(interestData.value);
    }
  }, [interestData.loading]);



  const onSubmit = useCallback(() => {
    if (props.interestId) {
      updateInterestFn
        .execute(props.interestId, getPreparedData(formValue))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.interest_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_interest_updating")
          );
        });
    } else {
      createInterestFn
        .execute(getPreparedData(formValue))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.interest_created")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_interest_creating")
          );
        });
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.interest_name)


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.interestId ? t(`dialog.ico_interest_dialog.interest_details`) : t(`dialog.ico_interest_dialog.add_interest`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {interestData.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>

            <Grid item xs={12}>
              <TextFieldFormControl
                name="interest_name"
                label={t("dialog.ico_interest_dialog.interest_name")}
                value={formValue?.interest_name}
                maxNumberOfCharacters = {16}
                required
                onChange={onChange}
              />
            </Grid>
            {props.interestId &&
              <Grid item xs={12}>
                <TrueFalseSelectField
                  name={"interest_active"}
                  label={t("dialog.ico_interest_dialog.interest_active")}
                  value={formValue?.interest_active}
                  onChange={onChange}
                  addEmptyOptions={false}
                  valuesAsBool
                />
              </Grid>}
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={createInterestFn.loading || updateInterestFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.interestId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

InterestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
  interestId: PropTypes.string
};

InterestDialog.defaultProps = {
  open: false,
};

export default InterestDialog
