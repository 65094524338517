import PropTypes from "prop-types";
import { Grid, IconButton } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import AutocompleteField from "../../field/AutocompleteField";
import { getTranslatedList } from "../../../helpers/methods";

const DraftForm = ({
  draftForm,
  onChange,
  draftId,
  languages,
  onChangeAutocomplete }
) => {


  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={8}>
        <TextFieldFormControl
          name="draft_nr"
          label={t("form.draft_form.draft_nr")}
          value={draftForm?.draft_nr}
          required
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteField
          name="draft_language"
          label={t("form.draft_form.draft_language")}
          value={draftForm?.draft_language}
          options={getTranslatedList(languages, "form.draft_form.")}
          isObjectOption={true}
          optionLabelKey={"name"}
          addNewValue={false}
          onChange={onChangeAutocomplete}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="draft_name"
          label={t("form.draft_form.draft_name")}
          value={draftForm?.draft_name}
          required
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="draft_description"
          label={t("form.draft_form.draft_description")}
          value={draftForm?.draft_description}
          onChange={onChange}
          resize={"vertical"}
          multiline
          rows={3}
        />
      </Grid>
      {draftId &&
        <Grid item xs={12}>
          <TrueFalseSelectField
            name="draft_active"
            label={t("form.draft_form.draft_active")}
            value={draftForm?.draft_active}
            valuesAsBool
            addEmptyOptions={false}
            onChange={onChange}
          />
        </Grid>
      }
    </Grid>
  );
};

DraftForm.propTypes = {
  draftForm: PropTypes.object,
  onChange: PropTypes.func,
};

DraftForm.defaultProps = {
  draftForm: {}
};

export default DraftForm;
