import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import FileUploadList from "../../other/FileUploadList";
import { useForm } from "../../../hooks/useForm";
import DraftForm from "../../form/DraftForm/DraftForm";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useIcoService from "../../../services/icoService";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyValue } from "../../../helpers/methods";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

function DraftDialog(props) {

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [isSavingData, setSavingData] = useState(false);

  const requiredFields = [
    "draft_nr",
    "draft_name"
  ];

  const {
    saveDraft,
    getDraftDetails,
    updateDraft,
    getDraftFilteringData
  } = useIcoService();


  const updateDraftFn = useAsyncFn(updateDraft)

  const filteringData = useAsync(getDraftFilteringData)

  const [
    memoryDraftDocs,
    onAddMemoryDraftDocs,
    onUpdateMemoryDraftDocs,
    onDeleteMemoryDraftDocs,
    onDownloadOnMemoryFile
  ] = useFileOnMemoryData("docs");

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm(props.draftId ? { id: props.draftId } : { ...props.initFormValue })

  const draftDetails = useAsync(
    () => {
      if (props.draftId){
        return getDraftDetails(props.draftId)
        .then((data) => {
          setFormValue(data)
        })
        .catch(() => {
        });
      }
      return Promise.resolve({...formValue})},
    [props.draftId]
  );


  const onPrepareDraftToSend = () => {
    let dataToSend = { ...formValue }
    dataToSend.file = memoryDraftDocs[0].file
    return dataToSend
  }


  const onSaveDraft = useCallback(() => {
    setSavingData(true)
    if (props.draftId) {
      updateDraftFn        
      .execute(props.draftId, formValue)
      .then(() => {
        setSavingData(false)
        props.onDataRefetch()
        props.onClose()
        snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.saved_draft"));
      })
      .catch(() => {
        setSavingData(false)
        props.onClose()
        snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_saving_draft"));
      });
    }
    else {
      saveDraft(onPrepareDraftToSend())
        .then(() => {
          setSavingData(false)
          props.onDataRefetch()
          props.onClose()
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.saved_draft"));
        })
        .catch(() => {
          setSavingData(false)
          props.onClose()
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_saving_draft"));
        });
    }

  }, [formValue, memoryDraftDocs])

  const isValid = formValue ? requiredFields.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) && (props.draftId ? true : memoryDraftDocs.length > 0 ): false

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={props.draftId ? t("dialog.draft_dialog.edit_draft") : t("dialog.draft_dialog.add_draft")}
      maxWidth="sm"
      showTopActionButton={false}
      showBottomActionButton={false}
      bottomActionStyle={{ padding: "10px" }}
      showCustomFooter={true}
      showDialogActions={false}
    >
      <LoaderWrapper showLoader={filteringData.loading || draftDetails.loading}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <DraftForm
            draftForm={formValue}
            onChange={onChange}
            draftId = {props.draftId}
            languages = {filteringData?.value?.languages? filteringData?.value?.languages: []}
            onChangeAutocomplete = {onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        {!props.draftId &&
          <Grid item xs={12}>
            <FileUploadList
              addEnclosureButtonProps={{ size: "mini" }}
              enclosures={memoryDraftDocs}
              fileType={"draft"}
              onDownloadEnclosure={onDownloadOnMemoryFile}
              onAddFile={onAddMemoryDraftDocs}
              onDeleteEnclosure={onDeleteMemoryDraftDocs}
              onUpdateEnclosure={onUpdateMemoryDraftDocs}
              canRemoveEnclosures={false}
              showDetailsButton={false}
              draft={props.draftId}
              multiple={false}
              accept={".docx"}
              hideAddButton={memoryDraftDocs.length > 0}
            />
          </Grid>}
        <Grid item xs={12}>
          <LoadingButton
            disabled={!isValid}
            variant="contained"
            onClick={onSaveDraft}
            component="label"
            loading={isSavingData}
            fullWidth
          >
            {t("dialog.draft_dialog.save")}
          </LoadingButton>
        </Grid>
      </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
}

DraftDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initFormValue: PropTypes.object,
  draftId: PropTypes.string,
  onDataRefetch: PropTypes.func
};

DraftDialog.defaultProps = {
  open: false,
  initFormValue: {}

};

export default DraftDialog;
