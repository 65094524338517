import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useForm } from "../../../../../hooks/useForm";
import useDialog from "../../../../../hooks/useDialog";
import { useTranslation } from "react-i18next";
import OwnerForm from "../../UniversalDetailsDrawerForms/OwnerForm";
import LoaderWrapper from "../../../../wrapper/LoaderWrapper";
import SelectDeactivateReasonDialog from "../../../../dialog/SelectDeactivateReasonDialog/SelectDeactivateReasonDialog";
import AddressDialog from "../../../../dialog/AddressDialog/AddressDialog";
import PhoneDialog from "../../../../dialog/PhoneDialog/PhoneDialog";
import MailDialog from "../../../../dialog/MailDialog";
import BoardMemberDialog from "../../../../dialog/BoardMemberDialog/BoardMemberDialog";
import useOwnerFormsService from "../../../../../hooks/useOwnerFormsService";
import BoardFormBox from "../../../../box/BoardFormBox";
import AddressFormBox from "../../../../box/AddressFormBox";
import PhoneFormBox from "../../../../box/PhoneFormBox/PhoneFormBox";
import MailFormBox from "../../../../box/MailFormBox/MailFormBox";
import OwnerHistoryLogDialog from "../../../../dialog/OwnerHistoryLogDialog/OwnerHistoryLogDialog";
import UserFormBox from "../../../../box/UserFormBox";
import useUserService from "../../../../../services/userService";
import { useSnackbarAlert } from "../../../../../context/snackbarAlert";
import { useAsyncFn } from "../../../../../hooks/useAsync";
import { getErrorMsg } from "../../../../../helpers/methods";
import SelectUserDialog from "../../../../dialog/SelectUserDialog/SelectUserDialog";
import IbanFormBox from "../../../../box/IbanFormBox";
import IbanDialog from "../../../../dialog/IbanDialog";
import OwnerAccountDialog from "../../../../dialog/OwnerAccountDialog";
import OwnerAccountFormBox from "../../../../box/OwnerAccountFormBox/OwnerAccountFormBox";
import OwnerBookkeepingFormBox from "../../../../box/OwnerBookkeepingFormBox";
import OwnerBookkeepingDialog from "../../../../dialog/OwnerBookkeepingDialog";

const OwnerDrawerContent = (props) => {

  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
  } = useForm(props.ownerData);
  const snackbarAlert = useSnackbarAlert();

  const [isLoading, setIsLoading] = useState(false);

  const [
    openLogDialog,
    onOpenLogDialog,
    handleCloseLogDialog,
  ] = useDialog();


  const [
    openDeactivateReasonsDialog,
    onOpenDeactivateReasonsDialog,
    onCloseDeactivateReasonsDialog,
  ] = useDialog();

  const onRefetchAllData = () => {
    props.onRefetchData()
    props.onRefetchTableData()
  }

  const {
    openAddressDialog,
    onOpenAddressDialog,
    onEditAddress,
    onRemoveAddress,
    onCloseAddressDialog,

    openBoardDialog,
    onOpenBoardDialog,
    onEditBoard,
    onRemoveBoard,
    onCloseBoardDialog,

    openMailDialog,
    onOpenMailDialog,
    onEditMail,
    onRemoveMail,
    onCloseMailDialog,

    openPhoneDialog,
    onOpenPhoneDialog,
    onEditPhone,
    onRemovePhone,
    onClosePhoneDialog,

    openUserDialog,
    onOpenUserDialog,
    onAddUserForNewInstance,
    onRemoveUser,
    onCloseUserDialog,

    selectedAddressId,
    selectedBoardId,
    selectedMailId,
    selectedPhoneId,


    openIbanDialog,
    onOpenIbanDialog,
    selectedIbanId,
    onAddIbanForNewOwner,
    onEditIban,
    onRemoveIban,
    onCloseIbanDialog,

    openAccountDialog,
    onOpenAccountDialog,
    selectedAccountId,
    onAddAccountForNewOwner,
    onEditAccount,
    onRemoveAccount,
    onCloseAccountDialog,

    openBookkDialog,
    onOpenBookkDialog,
    selectedOwnBookkId,
    onAddBookForNewOwner,
    onEditBookk,
    onRemoveBookk,
    onCloseBookDialog

  } = useOwnerFormsService(onRefetchAllData,
    formValue,
    setFormValue,
    props.ownerId
  )
  const {
    createUserOwnwer
  } = useUserService();


  const createUserOwnwerFn = useAsyncFn(createUserOwnwer);



  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise({ [name]: value })
      .then(() => {
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
        onCloseDeactivateReasonsDialog()
      });
  };

  const onChangeOwnerActivate = (name, value) => {
    if (value) {
      handleChangeFieldValue(name, value)
    } else {
      onOpenDeactivateReasonsDialog()
    }
  }

  const onSubmitDeactivateReason = useCallback((reason) => {
    props
      .onUpdateDataPromise({
        "owner_deactivated_reason": reason,
        "owner_active": false
      })
  }, [])


  const onAddNewOwnerUser = useCallback(((user) => {
    createUserOwnwerFn
      .execute({ 'user': user.id, 'owner': props.ownerId })
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.owner_updated")
        );
        props.onRefetchData()
      })
      .catch((err) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(err.data),
          t("snackbar_alert.occurred_error_during_owner_updating")
        );
      });
    onCloseUserDialog()
  }
  ), [props.ownerId, formValue])

  if (isLoading) {
    return <LoaderWrapper isLoading={isLoading} />
  }

  const isReadOnly = !formValue?.owner_active || props.readOnly

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <OwnerForm
          ownerData={formValue}
          onChangeTextField={onChangeWithCallback(handleChangeFieldValue)}
          onChangeOwnerActivate={onChangeWithCallback(onChangeOwnerActivate)}
          onChangeSelectField={onChangeWithCallback(handleChangeFieldValue)}
          deactivateReasonsList={props.filteringData?.value?.deactivation_reasons}
          ownerKinds={props.filteringData?.value?.owner_kinds}
          readOnly={isReadOnly}
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <BoardFormBox
          boards={formValue?.boards}
          onEditBoard={onEditBoard}
          onAddBoard={onOpenBoardDialog}
          onRemoveBoard={onRemoveBoard}
          readOnly={isReadOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressFormBox
          addresses={formValue?.addresses}
          onEditAddress={onEditAddress}
          onAddAddress={onOpenAddressDialog}
          onRemoveAddress={onRemoveAddress}
          readOnly={isReadOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneFormBox
          phones={formValue?.phones}
          onEditPhone={onEditPhone}
          onAddPhone={onOpenPhoneDialog}
          onRemovePhone={onRemovePhone}
          readOnly={isReadOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <MailFormBox
          mails={formValue?.mails}
          onEditMail={onEditMail}
          onAddMail={onOpenMailDialog}
          onRemoveMail={onRemoveMail}
          readOnly={isReadOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <UserFormBox
          users={formValue?.users}
          onAddUser={onOpenUserDialog}
          onRemoveUser={onRemoveUser}
          readOnly={isReadOnly}
          ownerId={props.ownerId}
        />
      </Grid>

      <Grid item xs={12}>
        <IbanFormBox
          ibans={formValue?.ibans}
          onAddIban={onOpenIbanDialog}
          onEditIban={onEditIban}
          onRemoveIban={onRemoveIban}
          readOnly={isReadOnly}
          ownerId={props.ownerId}
        />
      </Grid>
      <Grid item xs={12}>
        <OwnerAccountFormBox
          accounts={formValue?.accounts}
          onAddAccount={onOpenAccountDialog}
          onEditAccount={onEditAccount}
          onRemoveAccount={onRemoveAccount}
          readOnly={isReadOnly}
          ownerId={props.ownerId}
        />
      </Grid>
      <Grid item xs={12}>
        <OwnerBookkeepingFormBox
          owner_bookks={formValue?.owner_bookks}
          onEditBookk={onEditBookk}
          onAddBookk={onOpenBookkDialog}
          onRemoveBookk={onRemoveBookk}
          readOnly={isReadOnly}
          ownerId={props.ownerId}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onOpenLogDialog}
        >
          {t(`dialog.owner_details.show_changes_history_table`)}
        </Button>
      </Grid>
      {onOpenDeactivateReasonsDialog &&
        <SelectDeactivateReasonDialog
          open={openDeactivateReasonsDialog}
          onClose={onCloseDeactivateReasonsDialog}
          deactivateReasonsList={props.filteringData?.value?.deactivation_reasons}
          onSubmit={onSubmitDeactivateReason}
        />
      }
      {openAddressDialog &&
        <AddressDialog
          addressId={selectedAddressId.current}
          open={openAddressDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onCloseAddressDialog}
        />
      }
      {openPhoneDialog &&
        <PhoneDialog
          phoneId={selectedPhoneId.current}
          open={openPhoneDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onClosePhoneDialog}
        />
      }
      {openMailDialog &&
        <MailDialog
          mailId={selectedMailId.current}
          open={openMailDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onCloseMailDialog}
        />
      }
      {openBoardDialog &&
        <BoardMemberDialog
          boardId={selectedBoardId.current}
          open={openBoardDialog}
          ownerId={props.ownerId}
          onRefetch={onRefetchAllData}
          onClose={onCloseBoardDialog}
        />
      }
      {openLogDialog &&
        <OwnerHistoryLogDialog
          ownerId={props.ownerId}
          open={openLogDialog}
          onClose={handleCloseLogDialog}
        />
      }
      {openUserDialog &&
        <SelectUserDialog
          open={openUserDialog}
          onClose={onCloseUserDialog}
          searchParams={{ 'is_active': true, 'is_external': false }}
          returnObject={true}
          onSubmit={onAddNewOwnerUser}
        />
      }
      {openIbanDialog &&
        <IbanDialog
          ibanId={selectedIbanId.current}
          open={openIbanDialog}
          ownerId={props.ownerId}
          onRefetch={onRefetchAllData}
          onClose={onCloseIbanDialog}
          onAddIbanForNewOwner={onAddIbanForNewOwner}
        />
      }
      {openAccountDialog &&
        <OwnerAccountDialog
          accountId={selectedAccountId.current}
          open={openAccountDialog}
          ownerId={props.ownerId}
          onRefetch={onRefetchAllData}
          onClose={onCloseAccountDialog}
          onAddAccountForNewOwner={onAddAccountForNewOwner}
        />
      }
      {openBookkDialog &&
        <OwnerBookkeepingDialog
          ownBookkId={selectedOwnBookkId.current}
          open={openBookkDialog}
          ownerId={props.ownerId}
          onRefetch={onRefetchAllData}
          onClose={onCloseBookDialog}
          onAddBookForNewOwner={onAddBookForNewOwner}
        />
      }
    </Grid>
  );
};

OwnerDrawerContent.propTypes = {
  ownerData: PropTypes.object,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  onRefetchTableData: PropTypes.func,
};

OwnerDrawerContent.defaultProps = {
  isLoading: false,
  readOnly: true,
};

export default OwnerDrawerContent;
