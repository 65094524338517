import { useMemo, useState, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync } from "../../hooks/useAsync";
import useEquipmentService from "../../services/equipmentService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useDialogWithId from "../../hooks/useDialogWithId";

import { isSearchParamsForFilteringEmpty } from "../../helpers/methods";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import { useMeterInstalationConfig } from "./TableConfig";
import EquipmentTable from "../../components/table/EquipmentTable/EquipmentTable";

import TableService from "../../services/tableService";
import { exportEquipmentForMetersToFileUrl } from "../../helpers/apiUrls";
import MeterPassportDialog from "../../components/dialog/MeterPassportDialog";

import CounterAssignDataDialog from "../../components/dialog/CounterAssignDataDialog";

import useDialog from "../../hooks/useDialog";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog";

export default function CountersRepositoryPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getEquipmentForMeters, getEquipmentForMetersFilteringData } =
    useEquipmentService();

  const { getTableProps, fetchingFromBackend } = useMeterInstalationConfig();

  const tableProps = useMemo(() => {
    if (fetchingFromBackend) {
      return;
    }
    return getTableProps();
  }, [fetchingFromBackend]);

  const equipmentFilteringData = useAsync(getEquipmentForMetersFilteringData);

  const equipmentData = useAsync(
    () => getEquipmentForMeters(searchParams),
    [searchParams]
  );

  useEffect(() => {
    if (equipmentData.loading) {
      return;
    }
    setDataLocaly(equipmentData.value.results);
    setCountRecords(equipmentData.value.count);
  }, [equipmentData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    equipmentData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const [
    openMeterPassportDialog,
    equipmentId,
    onOpenMeterPassportDialog,
    onCloseMeterPassportDialog,
  ] = useDialogWithId();

  const [
    openAssignDataMeterDialog,
    equipmentIdForAssignData,
    onOpenMeterAssignDataDialog,
    onCloseMeterAssignDataDialog,
  ] = useDialogWithId();

  const handleEquipmentChanges = (existsChanges) => {
    if (existsChanges) {
      if (!isSearchParamsForFilteringEmpty(searchParams)) {
        setSearchParams({});
      } else {
        equipmentData.refetch();
      }
    }

    onCloseMeterPassportDialog();
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, equipmentId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = dataLocaly.find(
        (equipment) => equipment.id === equipmentId
      ).measuring_location;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, dataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      tableProps?.tableConfig,
      hiddenColumnsForTables?.[tableProps?.tableName],
      columnsOrdersForTables?.[tableProps?.tableName]
    );
  }, [
    fetchingFromBackend,
    tableProps?.tableConfig,
    hiddenColumnsForTables?.[tableProps?.tableName],
    columnsOrdersForTables?.[tableProps?.tableName],
  ]);

  const isLoading =
    tableProps === undefined ||
    fetchingFromBackend ||
    dataLocaly === undefined ||
    equipmentFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"counter_repository"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={tableProps.configForUserPreferenceDialog}
              filteringData={equipmentFilteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={false}
              createButtonLabel={t("dialog.equipment.add_counter")}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <EquipmentTable
                  data={dataLocaly}
                  showCheckbox={false}
                  showCleanFilterIcon={false}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={equipmentFilteringData}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={tableProps.tableConfig}
                  hiddenColumns={hiddenColumnsForTables[tableProps.tableName]}
                  columnsOrders={columnsOrdersForTables[tableProps.tableName]}
                  onClickMultipleLocationAlert={
                    handleOpenMultipleLocalizationDialog
                  }
                  onClickAssignData={onOpenMeterAssignDataDialog}
                  showExportToFileButton={true}
                  exportToFileUrl={exportEquipmentForMetersToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t(
                    "headers.counter_repository"
                  )}.xlsx`}
                />
              )}
              {openMeterPassportDialog && (
                <MeterPassportDialog
                  open={openMeterPassportDialog}
                  onClose={handleEquipmentChanges}
                  equipmentId={equipmentId}
                />
              )}

              {openAssignDataMeterDialog && (
                <CounterAssignDataDialog
                  open={openAssignDataMeterDialog}
                  onClose={onCloseMeterAssignDataDialog}
                  onSubmitCallback={handleEquipmentChanges}
                  equipmentId={equipmentIdForAssignData}
                  isEquiipmentMounted={true}
                />
              )}
              {openMultipleLocalizationDialog &&
                clickedOrderMultipleLocalization.current && (
                  <MultipleLocalizationDialog
                    open={openMultipleLocalizationDialog}
                    onClose={handleCloseMultipleLocalizationDialog}
                    localizationData={clickedOrderMultipleLocalization.current}
                    isLevelObjectData
                    itemType={"scheme"}
                  />
                )}
            </LoaderWrapper>
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
