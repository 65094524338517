import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useDocsData from "../../../hooks/useDocsData";
import FileUploadList from "../../other/FileUploadList";

function DocsFileUploadDialog(props) {

  const { t } = useTranslation();
  const {
    docs,
    isDocsLoading,
    updateDocs,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    refetchDocs,
  } = useDocsData(props.icoId, "ico");

 
  if (isDocsLoading) return <LoaderWrapper showLoader={true} />

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.docs_file_upload_dialog.docs")}
      maxWidth="xl"
      showTopActionButton={false}
      showBottomActionButton={false}
      bottomActionStyle={{ padding: "10px" }}
      showCustomFooter={true}
      showDialogActions={false}
    >
      <FileUploadList
        addEnclosureButtonProps={{ size: "mini" }}
        enclosures={docs}
        fileType={"docs"}
        onPreviewEnclosure={onPreviewDoc}
        onDownloadEnclosure={onDownloadDoc}
        onDeleteEnclosure={hideDocs}
        canRemoveEnclosures={true}
        readOnly={isDocsLoading || !props.hasEditPermission}
        onUpdateEnclosure={updateDocs}
        ico={props.icoId}
        refetchDocs={refetchDocs}
        availableDocsRestrictedTypes={props.avaibleRestrictedAreas}
        multiple={true}
      />
    </BasicDialog>
  );
}

DocsFileUploadDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  data: PropTypes.array,
  onHandleSettlementAddEmptyRow: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.func,
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

DocsFileUploadDialog.defaultProps = {
  open: false,
  readOnly: true,
  data: [],
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  onDataChange: PropTypes.func,
  showContextMenu: false,
  contextMenuActions: [],
  showCheckbox: true
};

export default DocsFileUploadDialog;
