import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import useGuaranteeService from "../../services/guaranteeService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import useDialog from "../../hooks/useDialog";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog";
import GuaranteeTable from "../../components/table/GuaranteeTable";
import GuaranteeScopeTable from "../../components/table/GuaranteeScopeTable";
import {
  GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE,
  GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FIELDS_CONFIG,
  GUARANTEE_TABLE,
  GUARANTEE_TABLE_FIELDS_CONFIG,
  GUARANTEE_TABLE_FILTER_PREFIX,
  GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FILTER_PREFIX,
} from "./PageTablesConfig";

import GuaranteeDialog from "../../components/dialog/GuaranteeDialog/GuaranteeDialog";
import GuaranteeScopeDialog from "../../components/dialog/GuaranteeScopeDialog";
import LoadingDialog from "../../components/dialog/LoadingDialog/LoadingDialog";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import { guaranteeExportDataUrl } from "../../helpers/apiUrls";
import TableService from "../../services/tableService";

function GuaranteeGuaranteeScopeSubpage(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [searchParams, setSearchParams] = useSearchParams();

  const [guaranteeDataLocaly, setGuaranteeDataLocaly] = useState();
  const [countGuaranteeRecords, setCountGuaranteeRecords] = useState();

  const [guaranteeScopeDataLocaly, setGuaranteScopeDataLocaly] = useState();
  const [countGuaranteeScopeRecords, setCountGuaranteeScopeRecords] =
    useState();

  const [guaranteeScopeTableTitle, setGuaranteeScopeTableTitle] =
    useState(undefined);
  const [guaranteeTableSearchParams, setGuaranteeTableSearchParams] = useState(
    {}
  );
  const [guaranteeScopeTableSearchParams, setGuaranteeScopeTableSearchParams] =
    useState({});

  const openedGuaranteeIdRef = useRef();

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      GUARANTEE_TABLE_FILTER_PREFIX,
      guaranteeTableSearchParams,
      setGuaranteeTableSearchParams
    );

    setNewItemSearchParamsIfAreChanged(
      GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FILTER_PREFIX,
      guaranteeScopeTableSearchParams,
      setGuaranteeScopeTableSearchParams
    );
  }, [searchParams]);

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const [openGuaranteeDialog, onOpenGuaranteeDialog, onCloseGuaranteeDialog] =
    useDialog();

  const [
    openGuaranteeScopeDialog,
    onOpenGuaranteeScopeDialog,
    onCloseGuaranteeScopeDialog,
  ] = useDialog();

  const [
    openSendingReminderLoadingDialog,
    onOpenSendingReminderLoadingDialog,
    onCloseSendingReminderLoadingDialog,
  ] = useDialog();

  const {
    page: guaranteeDataPage,
    pageSize: guaranteeDataPageSize,
    handleChangePageWithSearchParams: handleChangeGuaranteeDataPage,
    handleChangePageSizeWithSearchParams: handleChangeGuaranteeDataPageSize,
    resetPageNumber: resetGuaranteePageNumber,
  } = usePaginationWithSearchParams(GUARANTEE_TABLE_FILTER_PREFIX);

  const {
    page: guaranteeScopeDataPage,
    pageSize: guaranteeScopeDataPageSize,
    handleChangePageWithSearchParams: handleChangeGuaranteeScopeDataPage,
    handleChangePageSizeWithSearchParams:
    handleChangeGuaranteeScopeDataPageSize,
    resetGuaranteeScopePageNumber,
  } = usePaginationWithSearchParams(
    GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FILTER_PREFIX
  );

  const {
    getGuaranteeData,
    getGuaranteeFilteringData,
    getGuaranteeScopeFilteringData,
    getGuaranteeScopeDataForGuarantee,
    sendGuaranteeScopeManualReminder,
  } = useGuaranteeService();

  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  const guaranteeData = useAsync(
    () => getGuaranteeData(guaranteeTableSearchParams),
    [guaranteeTableSearchParams]
  );
  const guaranteeFilteringData = useAsync(getGuaranteeFilteringData);
  const guaranteeScopeFilteringData = useAsync(getGuaranteeScopeFilteringData);

  const guaranteeScopesByGuaranteeFn = useAsyncFn(
    getGuaranteeScopeDataForGuarantee
  );

  useEffect(() => {
    if (guaranteeData.loading) {
      return;
    }
    setGuaranteeDataLocaly(guaranteeData.value.results);
    setCountGuaranteeRecords(guaranteeData.value.count);
  }, [guaranteeData.loading]);

  useEffect(() => {
    populateLocalGuaranteeScopesDataWhenGuaranteeRowIsClicked();
  }, [guaranteeScopeTableSearchParams]);

  const handleChangeGuaranteeRowsPerPage = (e) => {
    handleChangeGuaranteeDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleChangeGuaranteeScopeRowsPerPage = (e) => {
    handleChangeGuaranteeScopeDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleClickGuaranteeRow = (guaranteeId) => {
    let selectedGuarantee = guaranteeDataLocaly.find(
      (guarantee) => guarantee.id === guaranteeId
    );

    if (!guaranteeId || guaranteeId === openedGuaranteeIdRef.current) {
      openedGuaranteeIdRef.current = null;
      setGuaranteeScopeTableTitle(undefined);
      setGuaranteScopeDataLocaly([]);
      setCountGuaranteeScopeRecords(0);
    } else {
      openedGuaranteeIdRef.current = guaranteeId;
      setGuaranteeScopeTableTitle(
        `${t("page.guarantee_page.guarantee_scopes_for_guarantee_nr")} ${selectedGuarantee.guarantee_nr
        }`
      );
      guaranteeScopesByGuaranteeFn
        .execute(guaranteeId, guaranteeScopeTableSearchParams)
        .then((data) => {
          setGuaranteScopeDataLocaly(data.results);
          setCountGuaranteeScopeRecords(data.count);
        });
    }
  };

  const clickedGuaranteeMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, guaranteeId) => {
      e.stopPropagation();
      clickedGuaranteeMultipleLocalization.current = guaranteeDataLocaly.find(
        (guarantee) => guarantee.id === guaranteeId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, guaranteeDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedGuaranteeMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const clickedGuaranteeToEdit = useRef();
  const guaranteeDialogIsReadOnly = useRef(false);
  const handleClickEditGuarantee = useCallback(
    (e, guaranteeId, isReadOnly) => {
      e.stopPropagation();
      clickedGuaranteeToEdit.current = guaranteeDataLocaly.find(
        (guarantee) => guarantee.id === guaranteeId
      ).id;
      guaranteeDialogIsReadOnly.current = isReadOnly;
      onOpenGuaranteeDialog();
    },
    [guaranteeDataLocaly]
  );

  const populateLocalGuaranteeScopesDataWhenGuaranteeRowIsClicked = () => {
    if (!openedGuaranteeIdRef.current) return;

    guaranteeScopesByGuaranteeFn
      .execute(openedGuaranteeIdRef.current, guaranteeScopeTableSearchParams)
      .then((data) => {
        setGuaranteScopeDataLocaly(data.results);
        setCountGuaranteeScopeRecords(data.count);
      });
  };

  const clickedGuaranteeScopeToEdit = useRef();
  const handleClickEditGuaranteeScope = useCallback(
    (e, guaranteeId) => {
      clickedGuaranteeScopeToEdit.current = guaranteeScopeDataLocaly.find(
        (guarantee) => guarantee.id === guaranteeId
      ).id;
      onOpenGuaranteeScopeDialog();
    },
    [guaranteeScopeDataLocaly]
  );

  const sendGuaranteeScopeManualReminderFn = useAsyncFn(
    sendGuaranteeScopeManualReminder
  );

  const handleSendGuaranteeScopeManualReminder = (guaranteeScopeId) => {
    onOpenSendingReminderLoadingDialog();
    sendGuaranteeScopeManualReminderFn
      .execute(guaranteeScopeId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reminder_sended")
        );
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_sending_reminder")
        );
      })
      .finally(() => {
        onCloseSendingReminderLoadingDialog();
      });
  };

  const guaranteeExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      GUARANTEE_TABLE_FIELDS_CONFIG,
        props.hiddenColumnsForTables
          ? props.hiddenColumnsForTables[GUARANTEE_TABLE]
          : [],
    
        props.columnsOrdersForTables
          ? props.columnsOrdersForTables[GUARANTEE_TABLE]
          : undefined
    );
  }, [
    GUARANTEE_TABLE_FIELDS_CONFIG,
    props.hiddenColumnsForTables,
    props.columnsOrdersForTables
  ]);

  const isGuaranteeTableLoading =
    guaranteeFilteringData.loading ||
    guaranteeDataLocaly === undefined ||
    countGuaranteeRecords === undefined;

  const isGuaranteeScopeTableLoading =
    guaranteeScopeFilteringData.loading ||
    guaranteeScopeDataLocaly === undefined ||
    countGuaranteeScopeRecords === undefined;

  const fullRefreshTables = () => {
    guaranteeData.refetch();
    populateLocalGuaranteeScopesDataWhenGuaranteeRowIsClicked();
  };

  const getGuaranteeScopeTable = () => {
    return (
      <GuaranteeScopeTable
        data={guaranteeScopeDataLocaly ? guaranteeScopeDataLocaly : []}
        showCheckbox={false}
        countRecords={countGuaranteeScopeRecords}
        page={guaranteeScopeDataPage}
        onPageChange={handleChangeGuaranteeScopeDataPage}
        rowsPerPage={guaranteeScopeDataPageSize}
        onRowsPerPageChange={handleChangeGuaranteeScopeRowsPerPage}
        onClickEdit={handleClickEditGuaranteeScope}
        onClickSendReminderManualy={handleSendGuaranteeScopeManualReminder}
        resetPageNumber={resetGuaranteeScopePageNumber}
        filteringData={guaranteeScopeFilteringData}
        hiddenColumns={
          props.hiddenColumnsForTables
            ? props.hiddenColumnsForTables[GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE]
            : []
        }
        columnsOrders={
          props.columnsOrdersForTables[GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE]
        }
        showTitle={true}
        title={guaranteeScopeTableTitle}
        style={{ maxHeight: "40vh" }}
        tableConfig={GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FIELDS_CONFIG}
        filterPrefix={GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FILTER_PREFIX}
        showContextMenu={false}
      />
    );
  };

  const getGuaranteeTable = () => {
    return (
      <GuaranteeTable
        data={guaranteeDataLocaly}
        onClickRow={handleClickGuaranteeRow}
        onClickMultipleLocationAlert={handleOpenMultipleLocalizationDialog}
        onClickEdit={handleClickEditGuarantee}
        countRecords={countGuaranteeRecords}
        page={guaranteeDataPage}
        onPageChange={handleChangeGuaranteeDataPage}
        rowsPerPage={guaranteeDataPageSize}
        onRowsPerPageChange={handleChangeGuaranteeRowsPerPage}
        resetPageNumber={resetGuaranteePageNumber}
        filteringData={guaranteeFilteringData}
        hiddenColumns={
          props.hiddenColumnsForTables
            ? props.hiddenColumnsForTables[GUARANTEE_TABLE]
            : []
        }
        columnsOrders={
          props.columnsOrdersForTables
            ? props.columnsOrdersForTables[GUARANTEE_TABLE]
            : undefined
        }
        selectedGuaranteeId={openedGuaranteeIdRef.current}
        style={{ maxHeight: "40vh" }}
        tableConfig={GUARANTEE_TABLE_FIELDS_CONFIG}
        filterPrefix={GUARANTEE_TABLE_FILTER_PREFIX}
        showCleanFilterIcon={true}
        showContextMenu={false}
        showExportToFileButton={true}
        exportToFileUrl={guaranteeExportDataUrl}
        exportToFileSearchParams={guaranteeTableSearchParams}
        exportToFileHeaders={guaranteeExportHeaders}
        exportToFileFileName={`${t("page.guarantee_page.guarantee").replace(" ", "_")}.xlsx`}
      />
    );
  };

  const getPageContent = () => {
    return (
      <LoaderWrapper showLoader={isGuaranteeTableLoading}>
        {getGuaranteeTable()}
        {openedGuaranteeIdRef.current ? (
          <LoaderWrapper showLoader={isGuaranteeScopeTableLoading}>
            {getGuaranteeScopeTable()}
          </LoaderWrapper>
        ) : (
          <Typography
            textAlign="center"
            variant="overline"
            display="block"
            gutterBottom
            style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
          >
            {t("page.guarantee_page.choose_guarantee_to_show_guarantee_scopes")}
          </Typography>
        )}
      </LoaderWrapper>
    );
  };

  return (
    <>
      {getPageContent()}
      {openMultipleLocalizationDialog &&
        clickedGuaranteeMultipleLocalization.current && (
          <MultipleLocalizationDialog
            open={openMultipleLocalizationDialog}
            onClose={handleCloseMultipleLocalizationDialog}
            localizationData={clickedGuaranteeMultipleLocalization.current}
            itemType={"guarantee"}
          />
        )}
      {openGuaranteeDialog && (
        <GuaranteeDialog
          guaranteeId={clickedGuaranteeToEdit.current}
          open={openGuaranteeDialog}
          onClose={onCloseGuaranteeDialog}
          onSubmit={fullRefreshTables}
          readOnly={guaranteeDialogIsReadOnly.current}
        />
      )}
      {openGuaranteeScopeDialog && (
        <GuaranteeScopeDialog
          guaranteeScopeId={clickedGuaranteeScopeToEdit.current}
          open={openGuaranteeScopeDialog}
          onClose={onCloseGuaranteeScopeDialog}
          onSubmit={populateLocalGuaranteeScopesDataWhenGuaranteeRowIsClicked}
        />
      )}
      {openSendingReminderLoadingDialog && (
        <LoadingDialog
          dialogTitle={t("page.guarantee_page.reminder_is_sending")}
          open={openSendingReminderLoadingDialog}
        />
      )}
    </>
  );
}

GuaranteeGuaranteeScopeSubpage.propTypes = {
  hiddenColumnsForTables: PropTypes.object,
  columnsOrdersForTables: PropTypes.object,
};

export default GuaranteeGuaranteeScopeSubpage;
