import { useMemo } from "react";
import { Grid } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import {
  NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  NAVIGATION_DRAWER_TYPE_METERS,
} from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import WarehouseTransactionsTab from "../../components/tabs/WarehouseTransactionsTab";

export default function WarehouseTransactionPage(props) {
  const { pageName } = props;
  const titleKey = useMemo(() => {
    if (props.itemType == "equipment")
      return "warehouses_equipment_transaction";

    return "warehouses_transaction";
  }, [props.itemType]);

  const navigationDrawerType = useMemo(() => {
    if (props.itemType == "equipment") return NAVIGATION_DRAWER_TYPE_METERS;

    return NAVIGATION_DRAWER_TYPE_WAREHOUSES;
  }, [props.itemType]);

  return (
    <NavigationDrawer pageName={pageName} drawerType={navigationDrawerType}>
      <DefaultPageWrapper titleKey={titleKey}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <WarehouseTransactionsTab itemType={props.itemType} />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
