import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import useLocationService from "../../../services/locationService";
import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";

import CounterMeasuringLocationForm from "../../form/CounterMeasuringLocationForm";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";

import { getErrorMsg } from "../../../helpers/methods";

const emptyFactorObject = [{ eio_factor: 0 }];

const EquipmentInstalationObjectSetDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const locationRef = useRef();

  const { formValue, setFormValue, onChangeDate } = useForm();

  const {
    formValue: locationFactors,
    setFormValue: setLocationFactors,
    onChangeInArrayForm: onChangeLocationFactors,
  } = useForm();

  const [location, setLocation] = useState();

  const {
    getEquipmentInstalationObjectSetDetailsData,
    updateEquipmentInstalationObjectSet,
  } = useEquipmentService();
  const {
    prepareLocationDataFromBackendForLocationRecordsComponent,
    prepareLocationDataToSend,
  } = useLocationService();

  const equipmentInstalationObjectSet = useAsync(() =>
    getEquipmentInstalationObjectSetDetailsData(
      props.equipmentInstalationObjectSetId
    )
  );

  const updateEquipmentInstalationObjectSetFn = useAsyncFn(
    updateEquipmentInstalationObjectSet
  );

  useEffect(() => {
    if (equipmentInstalationObjectSet.loading) return;

    const { location, eios_factors, ...rest } =
      equipmentInstalationObjectSet.value;

    setLocation(
      prepareLocationDataFromBackendForLocationRecordsComponent(location)
    );

    setLocationFactors(eios_factors);

    setFormValue(rest);
  }, [equipmentInstalationObjectSet.loading]);

  const prepareLocationsToSend = (locations, factors) => {
    const preparedLocations = [];
    for (const [index, location] of prepareLocationDataToSend(
      locations
    ).entries()) {
      preparedLocations.push({
        object: location,
        eio_factor: factors[index].eio_factor.toString(),
      });
    }
    return preparedLocations;
  };

  const getPreparedDataToSend = () => {
    const dataToSend = { ...formValue };

    dataToSend.location = prepareLocationsToSend(location, locationFactors);

    return dataToSend;
  };

  const isLocationFactorsValid = () => {
    return (
      locationFactors.reduce((sum, equipmentInstalationObject) => {
        return sum + parseFloat(equipmentInstalationObject.eio_factor);
      }, 0) === 1
    );
  };

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);
  const checkIfRequiredFieldsAreFill = () => {
    let isFormValid = true;
    let unfillFieldsTemp = [];

    const unfillFieldsLocation =
      locationRef.current.checkIfRequiredFieldsAreFill();

    if (unfillFieldsLocation.length > 0) {
      isFormValid = false;
      unfillFieldsTemp = [...unfillFieldsTemp, ...unfillFieldsLocation];
    }
    if (!isLocationFactorsValid()) {
      isFormValid = false;
      unfillFieldsTemp = [...unfillFieldsTemp, "location_factors"];
    }

    setUnfillRequiredFields(unfillFieldsTemp);

    return isFormValid;
  };

  const handleSubmit = () => {
    if (!checkIfRequiredFieldsAreFill()) {
      return;
    }

    updateEquipmentInstalationObjectSetFn
      .execute(props.equipmentInstalationObjectSetId, getPreparedDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_instalation_object_set_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isLoading = location === undefined || formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <CounterMeasuringLocationForm
            locations={location}
            setLocations={setLocation}
            locationFactors={locationFactors}
            onChangeLocationFactors={onChangeLocationFactors}
            setMeasuringLocationFactors={setLocationFactors}
            emptyFactorObject={emptyFactorObject}
            startSettlement={formValue.eios_start_settlement}
            endSettlement={formValue.eios_end_settlement}
            showEndSettlement={!formValue.eios_active}
            onChangeDate={onChangeDate}
            unfillRequiredFields={unfillRequiredFields}
            factorsAreInvalid={unfillRequiredFields.includes(
              "location_factors"
            )}
            factorsInvalidHelperText={t("sum_needs_to_be_equal_one")}
            measuringLocationRef={locationRef}
            inBox={false}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: 1 }}
            variant="contained"
            color="primary"
            fullWidth
            loading={updateEquipmentInstalationObjectSet.loading}
            onClick={handleSubmit}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={t("dialog.equipment.edit_equipment_instalation_object_set")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentInstalationObjectSetDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  equipmentInstalationObjectSetId: PropTypes.string,
};

EquipmentInstalationObjectSetDialog.defaultProps = {
  readOnly: false,
};

export default EquipmentInstalationObjectSetDialog;
