import { useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import { useAsync } from "../../../hooks/useAsync";

import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";

import EquipmentTable from "../../table/EquipmentTable/EquipmentTable";
import { EQUIPMENT_INSTALATION_TENANT_SET_TABLE_CONFIG } from "./TableConfig";

import EquipmentInstalationTenantSetDialog from "../EquipmentInstalationTenantSetDialog/EquipmentInstalationTenantSetDialog";

import useDialogWithId from "../../../hooks/useDialogWithId";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

const EquipmentInstalationTenantSetHistoryDialog = (props) => {
  const { t } = useTranslation();

  const [existChanges, setExistChanges] = useState(false);

  const [
    openEquipmentInstalationTenantSetDialog,
    equipmentInstalationTenantSetId,
    onOpenEquipmentInstalationTenantSetDialog,
    onCloseEquipmentInstalationTenantSetDialog,
  ] = useDialogWithId();

  const { getEquipmentInstalationTenantSetsForEquipmentData } =
    useEquipmentService();

  const equipmentInstalationTenantSetHistory = useAsync(
    () => getEquipmentInstalationTenantSetsForEquipmentData(props.equipmentId),
    []
  );

  const onSumbitCallbackEquipmentInstalationTenantSetDialog = (
    existChanges
  ) => {
    if (existChanges) {
      setExistChanges(true);
      equipmentInstalationTenantSetHistory.refetch();
    }
  };

  const handleClose = () => {
    if (existChanges && props.onSubmit) {
      props.onSubmit();
    }
    props.onClose();
  };

  const getDialogContent = () => {
    if (equipmentInstalationTenantSetHistory.loading)
      return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <EquipmentTable
            data={equipmentInstalationTenantSetHistory.value}
            showCheckbox={false}
            showCleanFilterIcon={false}
            countRecords={equipmentInstalationTenantSetHistory.value.length}
            style={{ maxHeight: "75vh" }}
            tableConfig={EQUIPMENT_INSTALATION_TENANT_SET_TABLE_CONFIG}
            hiddenColumns={[]}
            columnsOrders={[]}
            showExportToFileButton={false}
            withPagination={false}
            showFilters={false}
            onClickAssignData={onOpenEquipmentInstalationTenantSetDialog}
          />
          {openEquipmentInstalationTenantSetDialog && (
            <EquipmentInstalationTenantSetDialog
              open={openEquipmentInstalationTenantSetDialog}
              onClose={onCloseEquipmentInstalationTenantSetDialog}
              equipmentInstalationTenantSetId={equipmentInstalationTenantSetId}
              onSubmitCallback={
                onSumbitCallbackEquipmentInstalationTenantSetDialog
              }
            />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={handleClose}
      titleAlign="center"
      title={t("dialog.equipment.equipment_instalation_tenant_set_history")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentInstalationTenantSetHistoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  equipmentId: PropTypes.string,
};

EquipmentInstalationTenantSetHistoryDialog.defaultProps = {};

export default EquipmentInstalationTenantSetHistoryDialog;
