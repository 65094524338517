import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import useWarehouseService from "../../../../services/warehouseService";
import { useAsync } from "../../../../hooks/useAsync";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import TransactionTable from "../../../table/TransactionTable";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import TransactionDialog from "../../../dialog/TransactionDialog";
import { TRANSACTION_KIND_OUT } from "../../../../helpers/constants";
import TransactionOutForm from "../../../form/TransactionOutForm";
import useOrderService from "../../../../services/orderService";
import { transactionOutExportDataUrl } from "../../../../helpers/apiUrls";
import { getTableConfigByItemType } from "./TableConfig";
import { isEmptyObject } from "../../../../helpers/methods";

const WarehouseTransactionOutTab = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => {
    return getTableConfigByItemType(props.itemType);
  }, [props.itemType]);

  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);

  const {
    page: transactionOutPage,
    pageSize: transactionOutPageSize,
    handleChangePageWithSearchParams: handleChangeTransactionOutDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getTransactionOutData } = useWarehouseService();

  const { getOrderSelectList } = useOrderService();


  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (props.itemType === "equipment") {
      newSearchParams.set('equipment__isnull', false);
      setSearchParams(newSearchParams)
    } else {
      newSearchParams.set('part__isnull', false);
    }
    setSearchParams(newSearchParams)
  }, [searchParams, props.itemType]);

  const transactionOutList = useAsync(() => {
    const urlParams = new URLSearchParams(searchParams);
    const params = Object.fromEntries(urlParams.entries());
    if (!isEmptyObject(params)) {
      return getTransactionOutData(searchParams);
    }
    return Promise.resolve([])

  }, [searchParams]);

  const orderSelectList = useAsync(getOrderSelectList);

  useImperativeHandle(ref, () => ({
    onRefetchData() {
      transactionOutList.refetch();
    },
  }));

  return (
    <Grid container spacing={1}>
      {hasPermissionToEdit && props.itemType !== "equipment" && (
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={props.onOpenTransactionDialog}
          >
            {t("tabs.warehouse_transaction_tabs.new_transaction_out")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <TransactionTable
          onChangeParams={setSearchParams}
          transactionFilteringData={props.transactionFilteringData}
          tableConfig={tableConfig}
          transactionData={transactionOutList}
          page={transactionOutPage}
          pageSize={transactionOutPageSize}
          handleChangePageWithSearchParams={handleChangeTransactionOutDataPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          exportToFileUrl={transactionOutExportDataUrl}
          exportToFileSearchParams={searchParams}
        />
      </Grid>
      {props.openTransactionDialog && (
        <TransactionDialog
          open={props.openTransactionDialog}
          title={t("tabs.warehouse_transaction_tabs.new_transaction_out")}
          onClose={props.onCloseTransactionDialog}
          transactionKind={TRANSACTION_KIND_OUT}
          extraRequiredFields={["transaction_wz", "transaction_order"]}
          onSubmit={props.onSubmit}
          isDataSaving={props.isSaving}
          transactionForm={
            <TransactionOutForm
              orders={orderSelectList.value ? orderSelectList.value : []}
              itemType={props.itemType}
            />
          }
          itemType={props.itemType}
        />
      )}
    </Grid>
  );
});

WarehouseTransactionOutTab.propTypes = {
  readOnly: PropTypes.bool,
  itemType: PropTypes.string,
};

WarehouseTransactionOutTab.defaultProps = {
  itemType: "part",
};

export default WarehouseTransactionOutTab;
