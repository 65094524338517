import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import { returnEmptyStringWhenIsUndefined } from "../../helpers/methods";

export const formatRackShellData = (rackShellDict) => {
  if (rackShellDict) {
    return `${i18n.t(
      "table.stock_warehouse_table.rack"
    )}: ${returnEmptyStringWhenIsUndefined(
      rackShellDict?.warloc_rack_nr
    )} ${returnEmptyStringWhenIsUndefined(rackShellDict?.warloc_rack_name)} 
    ${i18n.t(
      "table.stock_warehouse_table.shell"
    )}: ${returnEmptyStringWhenIsUndefined(
      rackShellDict?.warloc_shell_nr
    )} ${returnEmptyStringWhenIsUndefined(rackShellDict?.warloc_shell_name)}`;
  }
};

export const STOCK_WAREHOUSE_TABLE_NAME = "stock_warehouse";
export const PART_STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG = [
  {
    name: "part_name",
    getValueCallback: (rowData) => rowData.part_name,
    label: i18n.t("table.stock_warehouse_table.part_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_identifactor",
    getValueCallback: (rowData) => rowData.item_identifactor,
    label: i18n.t("table.stock_warehouse_table.item_identifactor"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_suplier",
    getValueCallback: (rowData) => rowData.part_suplier,
    label: i18n.t("table.stock_warehouse_table.part_suplier_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.part_supliers,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "part_type",
    getValueCallback: (rowData) => rowData.part_type,
    label: i18n.t("table.stock_warehouse_table.part_type"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_number",
    getValueCallback: (rowData) => rowData.part_number,
    label: i18n.t("table.stock_warehouse_table.part_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_ean",
    getValueCallback: (rowData) => rowData.part_ean,
    label: i18n.t("table.stock_warehouse_table.ean"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_price",
    getValueCallback: (rowData) => rowData.stock_price,
    label: i18n.t("table.stock_warehouse_table.part_price"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.stock_warehouse_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "warehouse_parent",
    getValueCallback: (rowData) => rowData.warehouse_parent,
    label: i18n.t("table.stock_warehouse_table.warehouse_parent"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "rack_shell",
    getValueCallback: (rowData) =>
      formatRackShellData(rowData.warehouse_location),
    label: i18n.t("table.stock_warehouse_table.rack_shell"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "warehouse_responsible",
    getValueCallback: (rowData) => rowData.warehouse_responsible,
    label: i18n.t("table.stock_warehouse_table.warehouse_responsible_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.responsiblers,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "part_unit",
    getValueCallback: (rowData) => i18n.t(`other.units.${rowData.part_unit}`),
    label: i18n.t("table.stock_warehouse_table.part_unit"),
    filterType: null,
  },
  {
    name: "stock_amount",
    getValueCallback: (rowData) => rowData.stock_amount,
    label: i18n.t("table.stock_warehouse_table.stock_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_reservation",
    getValueCallback: (rowData) => rowData.stock_reservation,
    label: i18n.t("table.stock_warehouse_table.stock_reservation"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_availability",
    getValueCallback: (rowData) => rowData.stock_availability,
    label: i18n.t("table.stock_warehouse_table.stock_availability"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

export const EQUIPMENT_STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG = [
  {
    name: "equipment_nr",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.stock_warehouse_table.equipment_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_identifactor",
    getValueCallback: (rowData) => rowData.item_identifactor,
    label: i18n.t("table.stock_warehouse_table.item_identifactor"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "equipment_supplier",
    getValueCallback: (rowData) => rowData.supplier,
    label: i18n.t("table.stock_warehouse_table.part_suplier_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.part_supliers,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
    other_export_field_name: "supplier",
  },
  {
    name: "equipment_ean",
    getValueCallback: (rowData) => rowData.equipment_ean,
    label: i18n.t("table.stock_warehouse_table.ean"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_price",
    getValueCallback: (rowData) => rowData.stock_price,
    label: i18n.t("table.stock_warehouse_table.part_price"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.stock_warehouse_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "warehouse_parent",
    getValueCallback: (rowData) => rowData.warehouse_parent,
    label: i18n.t("table.stock_warehouse_table.warehouse_parent"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "rack_shell",
    getValueCallback: (rowData) =>
      formatRackShellData(rowData.warehouse_location),
    label: i18n.t("table.stock_warehouse_table.rack_shell"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "warehouse_responsible",
    getValueCallback: (rowData) => rowData.warehouse_responsible,
    label: i18n.t("table.stock_warehouse_table.warehouse_responsible_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.responsiblers,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "stock_amount",
    getValueCallback: (rowData) => rowData.stock_amount,
    label: i18n.t("table.stock_warehouse_table.stock_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_reservation",
    getValueCallback: (rowData) => rowData.stock_reservation,
    label: i18n.t("table.stock_warehouse_table.stock_reservation"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "stock_availability",
    getValueCallback: (rowData) => rowData.stock_availability,
    label: i18n.t("table.stock_warehouse_table.stock_availability"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

export const getTableConfigByItemType = (itemType) => {
  if (itemType === "equipment") {
    return EQUIPMENT_STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG;
  }
  return PART_STOCK_WAREHOUSE_TABLE_FIELDS_CONFIG;
};
