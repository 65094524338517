import { AUTOCOMPLETE_FIELD_TYPE, DATE_RANGE_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";


export const INTEREST_RATE_TABLE_NAME  = "interest_rate_table";
export const INTEREST_RATE_TABLE_CONFIG = [
  {
    name: "interest",
    getValueCallback: (rowData) => rowData.interest,
    label: i18n.t("table.interest_table.interest_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.interests,
      isObjectOption: true,
      optionLabelKey: "interest_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "intrat_value",
    getValueCallback: (rowData) => rowData.intrat_value,
    label: i18n.t("table.interest_table.intrat_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined
  },
  {
    name: "intrat_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.intrat_date),
    label: i18n.t("table.interest_table.intrat_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },

    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    excludedFromExport: true,
  }
];
