import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import AccountDetailsForm from "../../form/AccountDetailsForm";
import useOfferService from "../../../services/offerService";


const OwnerAccountDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange
  } = useForm({ owner: props.ownerId, account_owner_default : false, account_for_ico : false});


  const {
    getAccountDetails,
    createAccount,
    updateAccount,
  } = useOfferService();


  const updateAccountFn = useAsyncFn(updateAccount)
  const createAccountFn = useAsyncFn(createAccount)

  const ownerAccountDetailsData = useAsync(
    () => {
      if (props.accountId){
        return  getAccountDetails(props.accountId)
      }
      return Promise.resolve(formValue)}, 
    [props.accountId]
  );


  useEffect(() => {
    if (ownerAccountDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(ownerAccountDetailsData.value)) {
      setFormValue(ownerAccountDetailsData.value);
    }
  }, [ownerAccountDetailsData.loading]);



  const onSubmit = useCallback(() => {
    if (props.accountId) {
      updateAccountFn
        .execute(props.accountId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.account_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_account_updating")
          );
        });
    } else {
      if (props.ownerId) {
        createAccountFn
          .execute(formValue)
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.account_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_account_creating")
            );
          });
      } else {
        props.onAddAccountForNewOwner(formValue)
        props.onClose();
      }
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.account_name) && !isEmptyValue(formValue.account_nr)


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.accountId ? t(`dialog.owner_account_dialog.account_details`) : t(`dialog.owner_account_dialog.add_account`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {ownerAccountDetailsData.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <AccountDetailsForm
                account={formValue}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={createAccountFn.loading || updateAccountFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.accountId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

OwnerAccountDialog.propTypes = {
  ownerId: PropTypes.string,
  ownerAccId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddAccountForNewOwner : PropTypes.func,
  onRefetch : PropTypes.func
};

OwnerAccountDialog.defaultProps = {
  open: false,
};

export default OwnerAccountDialog;
