import React from "react";
import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import TextFieldFormControl from "../../field/TextFieldFormControl";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";

const MeterAtributesForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.attributes")}
      boxStyle={{ height: "100%" }}
      style={{
        height: "100%",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={3}>
          <TextFieldFormControl
            name={"equipment_parent"}
            label={t("form.meter_passport.equipment_parent_nr")}
            value={props.formValue.equipment_parent}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFormControl
            name={"equipment_category"}
            label={t("form.meter_passport.equipment_category")}
            value={props.formValue.equipment_category}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFormControl
            name={"equipment_media_type"}
            label={t("form.meter_passport.equipment_media_type")}
            value={props.formValue.equipment_media_type}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFormControl
            name={"equipment_status"}
            label={t("form.meter_passport.equipment_status")}
            value={props.formValue.equipment_status}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_in_bms"}
            label={t("form.meter_passport.equstate_is_in_bms")}
            value={t(`${props.formValue.equstate_is_in_bms}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_in_scheme"}
            label={t("form.meter_passport.equstate_is_in_scheme")}
            value={t(`${props.formValue.equstate_is_in_scheme}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_temporary"}
            label={t("form.meter_passport.equstate_is_temporary")}
            value={t(`${props.formValue.equstate_is_temporary}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_server_room"}
            label={t("form.meter_passport.equstate_is_server_room")}
            value={t(`${props.formValue.equstate_is_server_room}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_building"}
            label={t("form.meter_passport.equstate_is_building")}
            value={t(`${props.formValue.equstate_is_building}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_floor"}
            label={t("form.meter_passport.equstate_is_floor")}
            value={t(`${props.formValue.equstate_is_floor}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_in_settlement"}
            label={t("form.meter_passport.equstate_is_in_settlement")}
            value={t(`${props.formValue.equstate_is_in_settlement}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_damaged"}
            label={t("form.meter_passport.equstate_is_damaged")}
            value={t(`${props.formValue.equstate_is_damaged}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_subcounter"}
            label={t("form.meter_passport.equstate_is_subcounter")}
            value={t(`${props.formValue.equstate_is_subcounter}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldFormControl
            name={"equstate_is_empty_location"}
            label={t("form.meter_passport.equstate_is_empty_location")}
            value={t(`${props.formValue.equstate_is_empty_location}`)}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
      </Grid>
    </BoxWithLabel>
  );
};

MeterAtributesForm.propTypes = {
  formValue: PropTypes.object,
};

MeterAtributesForm.defaultProps = {};

export default MeterAtributesForm;
