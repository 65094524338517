import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Grid, IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import FloatField from "../../base/FloatField";
import AutocompleteField from "../../field/AutocompleteField";

import MiniButton from "../../base/MiniButton/MiniButton";

import useEquipmentService from "../../../services/equipmentService";

const EquipmentTenantRecord = React.memo((props) => {
  const { t } = useTranslation();

  const { getEquipmentTenantFieldId } = useEquipmentService();

  const onChange = (e) => {
    props.onChange(props.index, e);
  };

  const onChangeAutocompleteFieldWithObjectOptions = (...inputProps) =>
    props.onChangeAutocompleteFieldWithObjectOptions(
      props.index,
      ...inputProps
    );

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
            <Typography variant="subtitle2" className="capitalize-first-letter">
              {t("other.equipment_instalation_tenant_record.tenant")}
              {props.showRelationNumber ? ` ${props.index + 1}` : ""}
            </Typography>
            {props.showButtonDeleteRelation && !props.readOnly && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
                onClick={() => {
                  props.onDeleteRelation(props.index);
                }}
              >
                <RemoveCircleIcon fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          <Grid item sm={6} xs={6} textAlign={"end"}>
            {props.showButtonAddRelation && (
              <MiniButton
                variant="contained"
                size="small"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={props.onAddRelation}
              >
                {t("other.equipment_instalation_tenant_record.add_tenant")}
              </MiniButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <AutocompleteField
              name="tenant"
              label={t("other.equipment_instalation_tenant_record.tenant")}
              value={props.formValue?.tenant}
              options={props.tenantSelectList}
              isObjectOption={true}
              optionLabelKey={"tenant_short_name"}
              addNewValue={false}
              multiple={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              readOnly={props.readOnly}
              required
              error={
                props.unfillRequiredFields.includes(
                  getEquipmentTenantFieldId(props.index)
                ) || props.duplicatedTenants
              }
              helperText={
                props.unfillRequiredFields.includes(
                  getEquipmentTenantFieldId(props.index)
                )
                  ? t("field_required")
                  : props.duplicatedTenants
                  ? t("values_must_be_unique")
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FloatField
              name="eit_factor"
              onChange={onChange}
              value={props.formValue?.eit_factor}
              label={t("other.equipment_instalation_tenant_record.eit_factor")}
              readOnly={props.readOnly}
              decimalPlaces={2}
              max={1}
              required
              isInvalid={
                props.unfillRequiredFields.includes(
                  getEquipmentTenantFieldId(props.index, "eit_factor")
                ) || props.invalidTenantFactors
              }
              helperText={
                props.unfillRequiredFields.includes(
                  getEquipmentTenantFieldId(props.index, "eit_factor")
                )
                  ? t("field_required")
                  : props.invalidTenantFactors
                  ? t("sum_needs_to_be_equal_one")
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

EquipmentTenantRecord.propTypes = {
  index: PropTypes.number,
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  showRelationNumber: PropTypes.bool,
  showButtonDeleteRelation: PropTypes.bool,
  onDeleteRelation: PropTypes.func,
  showButtonAddRelation: PropTypes.bool,
  onAddRelation: PropTypes.func,
  tenantSelectList: PropTypes.array,
  readOnly: PropTypes.bool,
  unfillRequiredFields: PropTypes.array,
  invalidTenantFactors: PropTypes.bool,
  duplicatedTenants: PropTypes.bool,
};

EquipmentTenantRecord.defaultProps = {
  showRelationNumber: true,
  showButtonDeleteRelation: true,
  showButtonAddRelation: true,
  readOnly: false,
  unfillRequiredFields: [],
};

export default EquipmentTenantRecord;
