import React from "react";

import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import CellTowerIcon from "@mui/icons-material/CellTower";

const MeterReadingHistoryForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.reading_history")}
      boxStyle={{ height: "100%" }}
      style={{ height: "100%", display: "flex", justifyContent: "center" }}
    >
      <IconButtonWithTooltip
        title={t("form.meter_passport.reading_history")}
        fontSize={"500%"}
      >
        <CellTowerIcon fontSize="inherit" color="primary" />
      </IconButtonWithTooltip>
    </BoxWithLabel>
  );
};

export default MeterReadingHistoryForm;
