import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import OwnerDetailsForm from "../../form/OwnerDetailsForm";
import { getErrorMsg, isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import AddressDialog from "../AddressDialog/AddressDialog";
import PhoneDialog from "../PhoneDialog/PhoneDialog";
import MailDialog from "../MailDialog";
import BoardMemberDialog from "../BoardMemberDialog/BoardMemberDialog";
import BoardFormBox from "../../box/BoardFormBox";
import AddressFormBox from "../../box/AddressFormBox";
import PhoneFormBox from "../../box/PhoneFormBox/PhoneFormBox";
import MailFormBox from "../../box/MailFormBox/MailFormBox";
import useOwnerFormsService from "../../../hooks/useOwnerFormsService";
import OwnerHistoryLogDialog from "../OwnerHistoryLogDialog/OwnerHistoryLogDialog";
import useDialog from "../../../hooks/useDialog";
import SelectUserDialog from "../SelectUserDialog/SelectUserDialog";
import UserFormBox from "../../box/UserFormBox";
import IbanFormBox from "../../box/IbanFormBox";
import IbanDialog from "../IbanDialog";
import { tr } from "date-fns/locale";
import OwnerAccountFormBox from "../../box/OwnerAccountFormBox/OwnerAccountFormBox";
import OwnerAccountDialog from "../OwnerAccountDialog";
import OwnerBookkeepingFormBox from "../../box/OwnerBookkeepingFormBox";
import OwnerBookkeepingDialog from "../OwnerBookkeepingDialog";


const OwnerDetailsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange,
  } = useForm({ owner_active: true });


  const ownerDetailsData = useAsync(
    () => getOwnerDetails(props.ownerId),
    [props.ownerId]
  );

  const ownerFilteringData = useAsync(
    () => getOwnersFilteringData(),
    []
  );


  useEffect(() => {
    if (ownerDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(ownerDetailsData.value)) {
      setFormValue(ownerDetailsData.value);
    }

  }, [ownerDetailsData.loading]);


  const {
    openAddressDialog,
    onOpenAddressDialog,
    onAddAddresForNewOwner,
    onEditAddress,
    onRemoveAddress,
    onCloseAddressDialog,

    openBoardDialog,
    onOpenBoardDialog,
    onAddBoardForNewOwner,
    onEditBoard,
    onRemoveBoard,
    onCloseBoardDialog,

    openMailDialog,
    onOpenMailDialog,
    onAddMailForNewOwner,
    onEditMail,
    onRemoveMail,
    onCloseMailDialog,

    openPhoneDialog,
    onOpenPhoneDialog,
    onAddPhoneForNewOwner,
    onEditPhone,
    onRemovePhone,
    onClosePhoneDialog,

    openUserDialog,
    onOpenUserDialog,
    onAddUserForNewInstance,
    onRemoveUser,
    onCloseUserDialog,

    selectedAddressId,
    selectedBoardId,
    selectedMailId,
    selectedPhoneId,

    openIbanDialog,
    onOpenIbanDialog,
    selectedIbanId,
    onAddIbanForNewOwner,
    onEditIban,
    onRemoveIban,
    onCloseIbanDialog,

    openAccountDialog,
    onOpenAccountDialog,
    selectedAccountId,
    onAddAccountForNewOwner,
    onEditAccount,
    onRemoveAccount,
    onCloseAccountDialog,

    openBookkDialog,
    onOpenBookkDialog,
    selectedOwnBookkId,
    onAddBookForNewOwner,
    onEditBookk,
    onRemoveBookk,
    onCloseBookDialog

  } =
    useOwnerFormsService(ownerDetailsData.refetch,
      formValue,
      setFormValue,
      props.ownerId)


  const {
    getOwnerDetails,
    updateOwnerDetails,
    createOwner,
    getOwnersFilteringData,
    createUserOwnwer } = useUserService();

  const updateOwnerDetailsFn = useAsyncFn(updateOwnerDetails)
  const createUserOwnwerFn = useAsyncFn(createUserOwnwer);
  const createOwnerFn = useAsyncFn(createOwner)

  const [
    openLogDialog,
    onOpenLogDialog,
    handleCloseLogDialog,
  ] = useDialog();


  const onSubmit = useCallback(() => {
    if (props.ownerId) {
      updateOwnerDetailsFn
        .execute(props.ownerId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.owner_updated")
          );
          props.onClose();
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_owner_updating")
          );
        });
    } else {

      createOwnerFn
        .execute(formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.owner_created")
          );
          props.onClose();
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_owner_creating")
          );
        });
    }

  }, [formValue]);


  const onAddNewOwnerUser = useCallback(((user) => {
    if (props.ownerId) {
      createUserOwnwerFn
        .execute({ 'user': user.id, 'owner': props.ownerId })
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.owner_updated")
          );

          ownerDetailsData.refetch()
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_owner_updating")
          );
        });
    } else {
      onAddUserForNewInstance(user)
    }
    onCloseUserDialog()
  }), [props.ownerId, formValue])

  const isReadOnly = !formValue?.owner_active || props.readOnly

  const isValid = !isEmptyValue(formValue.owner_full_name) && !isEmptyValue(formValue.owner_short_name) && !isEmptyValue(formValue.owner_nip) && formValue.owner_active !== false ? true : !isEmptyValue(formValue.owner_deactivated_reason)

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.ownerId ? t(`dialog.owner_details.owner_details`) : t(`dialog.owner_details.create_owner`)}
      maxWidth="xl"
      showDialogActions
    >

      <LoaderWrapper showLoader={ownerDetailsData.loading || ownerFilteringData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <OwnerDetailsForm
              readOnly={isReadOnly}
              ownerData={formValue}
              onChange={onChange}
              deactivationReasons={ownerFilteringData?.value?.deactivation_reasons}
              ownerKinds={ownerFilteringData?.value?.owner_kinds}
            />
          </Grid>
          <Grid item xs={4}>
            <BoardFormBox
              readOnly={isReadOnly}
              boards={formValue?.boards}
              onEditBoard={onEditBoard}
              onAddBoard={onOpenBoardDialog}
              onRemoveBoard={onRemoveBoard}
            />
          </Grid>
          <Grid item xs={4}>
            <AddressFormBox
              addresses={formValue?.addresses}
              onEditAddress={onEditAddress}
              onAddAddress={onOpenAddressDialog}
              onRemoveAddress={onRemoveAddress}
              readOnly={isReadOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <PhoneFormBox
              phones={formValue?.phones}
              onEditPhone={onEditPhone}
              onAddPhone={onOpenPhoneDialog}
              onRemovePhone={onRemovePhone}
              readOnly={isReadOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <MailFormBox
              mails={formValue?.mails}
              onEditMail={onEditMail}
              onAddMail={onOpenMailDialog}
              onRemoveMail={onRemoveMail}
              readOnly={isReadOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <UserFormBox
              users={formValue?.users}
              onAddUser={onOpenUserDialog}
              onRemoveUser={onRemoveUser}
              readOnly={isReadOnly}
              ownerId={props.ownerId}
            />
          </Grid>
          <Grid item xs={4}>
            <IbanFormBox
              ibans={formValue?.ibans}
              onAddIban={onOpenIbanDialog}
              onEditIban={onEditIban}
              onRemoveIban={onRemoveIban}
              readOnly={isReadOnly}
              ownerId={props.ownerId}
            />
          </Grid>
          <Grid item xs={4}>
            <OwnerAccountFormBox
              accounts={formValue?.accounts}
              onAddAccount={onOpenAccountDialog}
              onEditAccount={onEditAccount}
              onRemoveAccount={onRemoveAccount}
              readOnly={isReadOnly}
              ownerId={props.ownerId}
            />
          </Grid>
          <Grid item xs={4}>
            <OwnerBookkeepingFormBox
              owner_bookks={formValue?.owner_bookks}
              onEditBookk={onEditBookk}
              onAddBookk={onOpenBookkDialog}
              onRemoveBookk={onRemoveBookk}
              readOnly={isReadOnly}
              ownerId={props.ownerId}
            />
          </Grid>
          <Grid item xs={4} />



          {props.ownerId &&
            <Grid item xs={formValue?.owner_active && !props.readOnly ? 6 : 12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onOpenLogDialog}
              >
                {t(`dialog.owner_details.show_changes_history_table`)}
              </Button>
            </Grid>
          }
          {(formValue?.owner_active && !props.readOnly) &&
            <Grid item xs={formValue?.owner_active ? props.ownerId ? 6 : 12 : 12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateOwnerDetailsFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.ownerId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          }
        </Grid>

      </LoaderWrapper>
      {!props.readOnly && openAddressDialog &&
        <AddressDialog
          addressId={selectedAddressId.current}
          open={openAddressDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseAddressDialog}
          onAddAddresForNewOwner={onAddAddresForNewOwner}
        />
      }
      {!props.readOnly && openPhoneDialog &&
        <PhoneDialog
          phoneId={selectedPhoneId.current}
          open={openPhoneDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onClosePhoneDialog}
          onAddPhoneForNewOwner={onAddPhoneForNewOwner}
        />
      }
      {!props.readOnly && openMailDialog &&
        <MailDialog
          mailId={selectedMailId.current}
          open={openMailDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseMailDialog}
          onAddMailForNewOwner={onAddMailForNewOwner}
        />
      }
      {!props.readOnly && openBoardDialog &&
        <BoardMemberDialog
          boardId={selectedBoardId.current}
          open={openBoardDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseBoardDialog}
          onAddBoardForNewOwner={onAddBoardForNewOwner}
        />
      }
      {!props.readOnly && openIbanDialog &&
        <IbanDialog
          ibanId={selectedIbanId.current}
          open={openIbanDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseIbanDialog}
          onAddIbanForNewOwner={onAddIbanForNewOwner}
        />
      }
      {!props.readOnly && openAccountDialog &&
        <OwnerAccountDialog
          accountId={selectedAccountId.current}
          open={openAccountDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseAccountDialog}
          onAddAccountForNewOwner={onAddAccountForNewOwner}
        />
      }
      {!props.readOnly && openIbanDialog &&
        <IbanDialog
          ibanId={selectedIbanId.current}
          open={openIbanDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseIbanDialog}
          onAddIbanForNewOwner={onAddIbanForNewOwner}
        />
      }
      {openLogDialog &&
        <OwnerHistoryLogDialog
          ownerId={props.ownerId}
          open={openLogDialog}
          onClose={handleCloseLogDialog}
        />
      }
      {!props.readOnly && openBookkDialog &&
        <OwnerBookkeepingDialog
          ownBookkId={selectedOwnBookkId.current}
          open={openBookkDialog}
          ownerId={props.ownerId}
          onRefetch={ownerDetailsData.refetch}
          onClose={onCloseBookDialog}
          onAddBookForNewOwner={onAddBookForNewOwner}
        />
      }
      {openUserDialog &&
        <SelectUserDialog
          open={openUserDialog}
          onClose={onCloseUserDialog}
          searchParams={{ 'is_active': true, 'is_external': false }}
          returnObject={true}
          onSubmit={onAddNewOwnerUser}
        />
      }
    </BasicDialog>
  );
};

OwnerDetailsDialog.propTypes = {
  ownerId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

OwnerDetailsDialog.defaultProps = {
  open: false,
  readOnly: true
};

export default OwnerDetailsDialog;
