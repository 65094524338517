import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import useIcoService from "../../../../services/icoService";
import { INTEREST_RATE_TABLE_CONFIG } from "./TableConfig";
import { interestRateExportUrl } from "../../../../helpers/apiUrls";
import InterestRateDialog from "../../../dialog/InterestRateDialog";


const InterestRateTab = (props) => {
  const { t } = useTranslation();
  const [interestRatesLocal, setInterestRatesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams] = useSearchParams();

  const { emptyArrayMemo } = useEmptyArrayMemo()

  const { getInterestRateList } = useIcoService();



  const [
    openInterestRateDialog,
    interestRateChangeId,
    onOpenInterestRateDialog,
    onCloseInterestRateDialog
  ] = useDialogWithId();



  const [openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams();

  const interestRateData = useAsync(
    () => getInterestRateList(searchParams),
    [searchParams]
  );


  useEffect(() => {
    if (interestRateData.loading) {
      return;
    }
    setInterestRatesLocal(interestRateData.value.results);
    setCountRecords(interestRateData.value.count);
  }, [interestRateData.loading]);

  const isLoading = interestRatesLocal === undefined || props.filteringData.loading


  const handleOpenInterestDialog = useCallback(
    (e, interestId) => {
      e.stopPropagation();
      onOpenInterestRateDialog(interestId);
    },
    []
  );

  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        INTEREST_RATE_TABLE_CONFIG,
        interestRatesLocal ? interestRatesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenInterestDialog
      ),

    [interestRatesLocal, INTEREST_RATE_TABLE_CONFIG]
  );

  const onCleanFlitersInFilterDialog = () => {
    setInterestRatesLocal(undefined);
    setCountRecords(undefined);
  };

  const interestRateExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      INTEREST_RATE_TABLE_CONFIG,
      emptyArrayMemo,
      emptyArrayMemo
    );
  }, [
    INTEREST_RATE_TABLE_CONFIG,
  ]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenInterestRateDialog(undefined)}
        >
          {t("tabs.ico_admin_tabs.add_interest_rate")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={INTEREST_RATE_TABLE_CONFIG}
          showContextMenu={false}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            INTEREST_RATE_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
          showExportToFileButton={true}
          exportToFileUrl={interestRateExportUrl}
          exportToFileSearchParams={searchParams}
          exportToFileHeaders={interestRateExportHeaders}
          exportToFileFileName={`${t("tabs.ico_admin_tabs.interest_rates").replace(" ", "_")}.xlsx`}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={INTEREST_RATE_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openInterestRateDialog &&
        <InterestRateDialog
          interestRateId={interestRateChangeId}
          open={openInterestRateDialog}
          onClose={onCloseInterestRateDialog}
          onRefetch={interestRateData.refetch}
          filteringData={props.filteringData || emptyArrayMemo}

        />

      }

    </Grid>
  );
};

InterestRateTab.propTypes = {
  filteringData: PropTypes.object,
};

InterestRateTab.defaultProps = {};

export default InterestRateTab;
