import PropTypes from "prop-types";

import i18n from "../../../i18n";

import { Grid } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import {
  DATE_RANGE_FIELD_TYPE,
  DATE_TIME_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE,
  DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE,
  DATE_TO_DATETIME_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
} from "../../../helpers/constants";

import FilterFieldForm from "../../field/FilterFieldForm";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

import HasChangesDotFilterForm from "../../other/HasChangesDotFilterForm";

import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export default function UniversalFilterForm(props) {
  const { getFilterFieldKey } = usePaginationWithSearchParams(
    props.filterPrefix
  );

  if (props.filteringData.loading) return <LoaderWrapper showLoader={true} />;

  const setFieldName = (name) => {
    return getFilterFieldKey(name);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={props.style}
      spacing={1}
    >
      {props.filtersConfig.map((filterConfig) =>
        filterConfig.filterType === DATE_RANGE_FIELD_TYPE ||
        filterConfig.filterType === DATE_TIME_RANGE_FIELD_TYPE ||
        filterConfig.filterType === DATETIME_TO_DATE_RANGE_FIELD_TYPE ? (
          <>
            <Grid item xs={12} sm={6} key={`${filterConfig.name}_start`}>
              <FilterFieldForm
                formValue={props.formValue}
                name={setFieldName(`${filterConfig.name}_start`)}
                label={`${filterConfig.label} (${i18n.t("from")})`}
                type={filterConfig.filterType}
                fieldConfig={filterConfig.getFilterFieldConfigCallback(
                  props.filteringData
                )}
                readOnly={props.readOnly}
                onChange={props.onChange}
                onChangeDate={props.onChangeDate}
                onChangeDatetimeToDate={(e) =>
                  props.onChangeDatetimeToDate(
                    e,
                    DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE
                  )
                }
                onChangeAutocompleteFieldWithObjectOptions={
                  props.onChangeAutocompleteFieldWithObjectOptions
                }
                onChangeDateConvertedToDateTimeWithZeroHourOfDate={
                  props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} key={`${filterConfig.name}_end`}>
              <FilterFieldForm
                formValue={props.formValue}
                name={setFieldName(`${filterConfig.name}_end`)}
                label={`${filterConfig.label} (${i18n.t("to")})`}
                type={filterConfig.filterType}
                fieldConfig={filterConfig.getFilterFieldConfigCallback(
                  props.filteringData
                )}
                readOnly={props.readOnly}
                onChange={props.onChange}
                onChangeDate={props.onChangeDate}
                onChangeDatetimeToDate={(e) =>
                  props.onChangeDatetimeToDate(
                    e,
                    DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE
                  )
                }
                onChangeAutocompleteFieldWithObjectOptions={
                  props.onChangeAutocompleteFieldWithObjectOptions
                }
                onChangeDateConvertedToDateTimeWithZeroHourOfDate={
                  props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
                }
              />
            </Grid>
          </>
        ) : filterConfig.filterType === NUMERIC_RANGE_FIELD_TYPE ? (
          <>
            <Grid item xs={12} sm={6} key={`${filterConfig.name}_from`}>
              <FilterFieldForm
                formValue={props.formValue}
                name={setFieldName(`${filterConfig.name}_from`)}
                label={`${filterConfig.label} (${i18n.t("from")})`}
                type={NUMERIC_FIELD_TYPE}
                fieldConfig={filterConfig.getFilterFieldConfigCallback(
                  props.filteringData
                )}
                readOnly={props.readOnly}
                onChange={props.onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} key={`${filterConfig.name}_to`}>
              <FilterFieldForm
                formValue={props.formValue}
                name={setFieldName(`${filterConfig.name}_to`)}
                label={`${filterConfig.label} (${i18n.t("to")})`}
                type={NUMERIC_FIELD_TYPE}
                fieldConfig={filterConfig.getFilterFieldConfigCallback(
                  props.filteringData
                )}
                readOnly={props.readOnly}
                onChange={props.onChange}
              />
            </Grid>
          </>
        ) : filterConfig.filterType ? (
          <>
            <Grid item xs={12} key={filterConfig.name}>
              <FilterFieldForm
                formValue={props.formValue}
                name={setFieldName(filterConfig.name)}
                label={filterConfig.label}
                type={filterConfig.filterType}
                fieldConfig={filterConfig.getFilterFieldConfigCallback(
                  props.filteringData
                )}
                onChange={props.onChange}
                onChangeDate={props.onChangeDate}
                onChangeAutocompleteFieldWithObjectOptions={
                  props.onChangeAutocompleteFieldWithObjectOptions
                }
                onChangeDateConvertedToDateTimeWithZeroHourOfDate={
                  props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
                }
              />
            </Grid>
            {filterConfig.includeExcludedFilter && (
              <Grid
                item
                container
                xs={12}
                key={`${filterConfig.name}_excluded`}
              >
                <Grid item xs={1}>
                  <IconButtonWithTooltip title={i18n.t("excluded")}>
                    <PriorityHighIcon
                      sx={{ color: "var(--alert)" }}
                      fontSize="small"
                    />
                  </IconButtonWithTooltip>
                </Grid>
                <Grid item xs={11}>
                  <FilterFieldForm
                    formValue={props.formValue}
                    name={setFieldName(`${filterConfig.name}_excluded`)}
                    label={filterConfig.label}
                    type={filterConfig.filterType}
                    fieldConfig={filterConfig.getFilterFieldConfigCallback(
                      props.filteringData
                    )}
                    onChange={props.onChange}
                    onChangeDate={props.onChangeDate}
                    onChangeAutocompleteFieldWithObjectOptions={
                      props.onChangeAutocompleteFieldWithObjectOptions
                    }
                    onChangeDateConvertedToDateTimeWithZeroHourOfDate={
                      props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
                    }
                  />
                </Grid>
              </Grid>
            )}
          </>
        ) : null
      )}
      {props.includeOpenCloseFilter && (
        <Grid
          item
          xs={12}
          key={props.openCloseFilterKey}
          sx={{ display: "flex" }}
        >
          {props.includeHasChangesDotFilter && (
            <HasChangesDotFilterForm
              name={setFieldName(props.hasChangesDotFilterKey)}
              onChange={props.onChange}
              readOnly={props.readOnly}
              value={
                props.formValue[setFieldName(props.hasChangesDotFilterKey)]
              }
            />
          )}
          <FilterFieldForm
            formValue={props.formValue}
            name={setFieldName(props.openCloseFilterKey)}
            label={props.openCloseFilterName}
            type={BOOLEAN_FIELD_TYPE}
            fieldConfig={{
              valuesAsBool: true,
              trueOptionLabel: props.trueOptionLabel,
              falseOptionLabel: props.falseOptionLabel,
              emptyOptionsLabel: props.emptyOptionsLabel,
            }}
            onChange={props.onChange}
            onChangeDate={props.onChangeDate}
            onChangeAutocompleteFieldWithObjectOptions={
              props.onChangeAutocompleteFieldWithObjectOptions
            }
            onChangeDateConvertedToDateTimeWithZeroHourOfDate={
              props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
            }
          />
        </Grid>
      )}
      {props.includeDateFilter && (
        <Grid item xs={12} key={props.dateFilterKey}>
          <FilterFieldForm
            formValue={props.formValue}
            name={props.dateFilterKey}
            label={props.dateFilterName}
            type={DATE_TO_DATETIME_FIELD_TYPE}
            onChange={props.onChange}
            onChangeDate={props.onChangeDate}
            onChangeAutocompleteFieldWithObjectOptions={
              props.onChangeAutocompleteFieldWithObjectOptions
            }
            onChangeDateConvertedToDateTimeWithZeroHourOfDate={
              props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
            }
          />
        </Grid>
      )}
      {props.includeExtraFilter && (
        <Grid item xs={12} key={props.extraFilterKey}>
          <FilterFieldForm
            formValue={props.formValue}
            name={setFieldName(props.extraFilterKey)}
            label={props.extraFilterName}
            type={props.extraFilterType}
            fieldConfig={props.extraFilterFieldConfig}
            onChange={props.onChange}
            onChangeDate={props.onChangeDate}
            onChangeAutocompleteFieldWithObjectOptions={
              props.onChangeAutocompleteFieldWithObjectOptions
            }
            onChangeDateConvertedToDateTimeWithZeroHourOfDate={
              props.onChangeDateConvertedToDateTimeWithZeroHourOfDate
            }
          />
        </Grid>
      )}
    </Grid>
  );
}

UniversalFilterForm.propTypes = {
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeDateConvertedToDateTimeWithZeroHourOfDate: PropTypes.func,
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      getFilterFieldConfigCallback: PropTypes.func,
    })
  ),
  isLoading: PropTypes.bool,
  includeOpenCloseFilter: PropTypes.bool,
  openCloseFilterName: PropTypes.string,
  openCloseFilterKey: PropTypes.string,
  includeHasChangesDotFilter: PropTypes.bool,
  hasChangesDotFilterKey: PropTypes.string,
  includeDateFilter: PropTypes.bool,
  dateFilterName: PropTypes.string,
  dateFilterKey: PropTypes.string,
  filterPrefix: PropTypes.string,
  includeExtraFilter: PropTypes.bool,
  extraFilterKey: PropTypes.string,
  extraFilterName: PropTypes.string,
  extraFilterType: PropTypes.string,
  extraFilterFieldConfig: PropTypes.object,
  style: PropTypes.object,
};

UniversalFilterForm.defaultProps = {
  formValue: {},
  readOnly: false,
  includeOpenCloseFilter: false,
  openCloseFilterName: i18n.t("button.open_close_filter_button.open"),
  openCloseFilterKey: "is_open",
  includeHasChangesDotFilter: false,
  hasChangesDotFilterKey: "has_changes",
  dateFilterName: i18n.t("date"),
  dateFilterKey: "date_start",
  isLoading: false,
};
