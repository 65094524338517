import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const IcoProductDetailsForm = ({
  product,
  onChange,
  icoProductId }
) => {

  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={12}>
        <TextFieldFormControl
          name="icoprod_name"
          label={t("form.ico_product_details_form.icoprod_name")}
          value={product?.icoprod_name}
          required
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="icoprod_name_eng"
          label={t("form.ico_product_details_form.icoprod_name_eng")}
          value={product?.icoprod_name_eng}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TrueFalseSelectField
          name={"icoprod_is_default"}
          label={t("form.ico_product_details_form.icoprod_is_default")}
          value={product?.icoprod_is_default}
          onChange={onChange}
          addEmptyOptions={false}
          valuesAsBool
        />
      </Grid>
    { icoProductId &&
      <Grid item xs={12}>
        <TrueFalseSelectField
          name={"icoprod_active"}
          label={t("form.ico_product_details_form.icoprod_active")}
          value={product?.icoprod_active}
          onChange={onChange}
          addEmptyOptions={false}
          valuesAsBool
        />
      </Grid>}
    </Grid>
  );
};

IcoProductDetailsForm.propTypes = {
  product: PropTypes.object,
  onChange: PropTypes.func,
  icoProductId : PropTypes.string
};

IcoProductDetailsForm.defaultProps = {
};

export default IcoProductDetailsForm;
