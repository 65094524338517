import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import useUserService from "../../../services/userService";
import { useAsync } from "../../../hooks/useAsync";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import DateTimeField from "../../field/DateTimeField";

const UserDetailsDialog = (props) => {
  const { t } = useTranslation();
  const { getUserDetails } = useUserService();
  const userDetailsData = useAsync(
    () => getUserDetails(props.userId),
    [props.userId]
  );

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t(`dialog.user_details.${props.title}`)}
      maxWidth="xs"
      rowGap={2}
      showDialogActions
    >
      <LoaderWrapper showLoader={userDetailsData.loading}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowGap={1}
        >
          <Grid item xs={12}>
            <TextFieldFormControl
              readOnly
              value={userDetailsData?.value?.full_name}
              label={t("dialog.user_details.full_name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              readOnly
              value={userDetailsData?.value?.company}
              label={t("dialog.user_details.company")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              readOnly
              value={userDetailsData?.value?.phone}
              label={t("dialog.user_details.phone")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              readOnly
              value={userDetailsData?.value?.mail}
              label={t("dialog.user_details.email")}
            />
          </Grid>
          
          <Grid item xs={12}>
            <DateTimeField
              readOnly = {true}
              disabled
              value={userDetailsData?.value?.last_login}
              label={t("dialog.user_details.last_login")}
            />
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

UserDetailsDialog.propTypes = {
  userId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

UserDetailsDialog.defaultProps = {
  open: false,
  title: "applicant",
};

export default UserDetailsDialog;
