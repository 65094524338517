import {
  CommunicationFiltersTypes,
  EXTERNAL_TYPE,
  INTERNAL_TYPE,
} from "../helpers/constants";
import useFileService from "../services/fileService";
import { useAsync, useAsyncFn } from "./useAsync";
import { useState, useEffect } from "react";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/auth";
import usePermissionService from "../services/permissionService";
import { getRandomString } from "../helpers/methods";

const useEnclosuresData = (
  itemId,
  defaultFilters = [],
  itemType = "ticket"
) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const snackbarAlert = useSnackbarAlert();

  const {
    defaultEnclosureType,
    showExtendedAddEnclosureDialog,
    showFilters,
    showEnclosureType,
    showSubcontractorSelect,
    canHideEnclosures,
    showPublishButton,
  } = usePermissionService();

  const [localEnclosures, setLocalEnclosures] = useState([]);
  const [loadingEnclosures, setLoadingEnclosures] = useState(true);

  const [chosenEnclosuresFilterGroup, setChosenEnclosuresFilterChosenGroup] =
    useState(defaultFilters);

  const [searchParams, setSearchParams] = useState({});

  const {
    getEnclosuresForItemData,
    updateEnclosureTOData,
    updateEnclosureOfferData,
    handleDownloadEnclosure,
    handlePreviewEnclosure,
    copyAttachment,
    addEnclosureToFavorite,
    saveManyAttachments,
    deleteOfferEnclosureFromDB,
    updateEnclosurePartData,
    updateEnclosureEquipmentData,
  } = useFileService();

  const enclosuresData = useAsync(
    () => getEnclosuresForItemData(itemId, searchParams, itemType),
    [itemId, searchParams, itemType]
  );

  const saveEnclosureFn = useAsyncFn(saveManyAttachments);
  const copyEnclosureFn = useAsyncFn(copyAttachment);
  const updateEnclosureFn = useAsyncFn(updateEnclosureTOData);
  const updateOfferEnclosureFn = useAsyncFn(updateEnclosureOfferData);
  const updatePartEnclosureFn = useAsyncFn(updateEnclosurePartData);
  const updateEnclosureEquipmentDataFn = useAsyncFn(
    updateEnclosureEquipmentData
  );
  const addEnclosureToFavoriteFn = useAsyncFn(addEnclosureToFavorite);
  const removeOfferEnclosureFn = useAsyncFn(deleteOfferEnclosureFromDB);

  useEffect(() => {
    if (!enclosuresData.loading) {
      setLocalEnclosures(enclosuresData.value);
    }
    setLoadingEnclosures(enclosuresData.loading);
  }, [enclosuresData.loading]);

  const prepareEnclosureToSend = (enclosure, isNew = true) => {
    if (isNew) {
      enclosure["id"] = undefined;
      enclosure["user"] = user.user_id;
      if (itemType === "ticket" || itemType === "ticket_order") {
        enclosure["ticket"] = itemId;
      } else if (itemType === "order") {
        enclosure["order"] = itemId;
      } else if (itemType === "part") {
        enclosure["part"] = itemId;
      } else if (itemType === "equipment") {
        enclosure["equipment"] = itemId;
      } else {
        enclosure["offer"] = itemId;
      }
    }
    if (itemType === "part") {
      enclosure["enpa_note"] = enclosure.enclosure_note;
    }
    if (itemType === "equipment") {
      enclosure["enequ_note"] = enclosure.enclosure_note;
    }
    enclosure["enclosure_external"] =
      enclosure.enclosure_type === EXTERNAL_TYPE ? true : false;
    return enclosure;
  };

  const setCommonDataBeforeSendFiles = (commonData) => {
    return prepareEnclosureToSend(commonData, true);
  };

  const prepareEnclosureToCopy = (enclosure) => {
    enclosure["id"] = undefined;
    enclosure["file"] = undefined;
    enclosure["enclosure"] = enclosure.enclosure_id;
    enclosure["ento_user"] = user.user_id;
    if (itemType === "ticket" || itemType === "ticket_order") {
      enclosure["ticket"] = itemId;
    } else {
      enclosure["order"] = itemId;
    }

    enclosure["enclosure_external"] =
      enclosure.enclosure_type === EXTERNAL_TYPE ? true : false;
    return enclosure;
  };

  const prepareManyEnclosuresToCopy = (enclosures, commonData) => {
    let dataToSend = [];
    for (let enc of enclosures) {
      let newEnc = { ...enc, ...commonData };
      dataToSend.push(prepareEnclosureToCopy(newEnc));
    }

    return dataToSend;
  };

  const prepareEnclosureToHide = (enclosureTOId) => {
    if (itemType === "part") {
      return {
        id: enclosureTOId,
        enpa_active: false,
      };
    }
    if (itemType === "equipment") {
      return {
        id: enclosureTOId,
        enequ_active: false,
      };
    }
    return {
      id: enclosureTOId,
      ento_active: false,
    };
  };

  const chooseEnclosuresFilterGroup = (groupName) => {
    setChosenEnclosuresFilterChosenGroup((oldState) => {
      var newState = [...oldState];
      if (newState.includes(groupName)) {
        newState.splice(newState.indexOf(groupName), 1);
      } else {
        newState.push(groupName);
      }
      return newState;
    });
  };

  useEffect(() => {
    let filterArray = defaultFilters.filter(
      (el) => !chosenEnclosuresFilterGroup.includes(el)
    );
    let filters = {};
    for (let [key, value] of Object.entries(CommunicationFiltersTypes)) {
      if (filterArray.includes(value.key)) {
        let object = {};
        let key = value.filterParam;
        object[key] = true;
        filters = Object.assign({}, filters, object);
      }
    }
    setSearchParams({ ...filters });
  }, [chosenEnclosuresFilterGroup]);

  const saveEnclosures = async (newEnclosures, commonData) => {
    let copiedEnclosures = newEnclosures.filter(
      (enclosure) => enclosure.copied
    );
    let newEnclosureData = newEnclosures.filter(
      (enclosure) => enclosure.id === undefined
    );
    setLoadingEnclosures(true);
    if (copiedEnclosures.length > 0) {
      await copyEnclosureFn
        .execute(prepareManyEnclosuresToCopy(copiedEnclosures, commonData))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.enclosure_saved")
          );
          setLoadingEnclosures(false);
          enclosuresData.refetch();
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_enclosure_saving")
          );
          setLoadingEnclosures(false);
        });
    }
    if (newEnclosureData.length > 0) {
      await saveEnclosureFn
        .execute(
          prepareDataToSend(newEnclosureData),
          setCommonDataBeforeSendFiles(commonData),
          searchParams
        )
        .then((res) => {
          setLoadingEnclosures(false);
          setLocalEnclosures(res);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_enclosure_saving")
          );
          setLoadingEnclosures(false);
        });
    }
  };

  const updateEnclosure = (enclosure) => {
    updateEnclosureFn
      .execute(enclosure.id, prepareEnclosureToSend(enclosure, false))
      .then((res) => {
        setChosenEnclosuresFilterChosenGroup([...defaultFilters]);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_updated")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_updating")
        );
      });
  };

  const updateOfferEnclosure = (enclosure) => {
    updateOfferEnclosureFn
      .execute(enclosure.id, prepareEnclosureToSend(enclosure, false))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_updated")
        );
        enclosuresData.refetch();
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_updating")
        );
      });
  };

  const updatePartEnclosure = (enclosure) => {
    updatePartEnclosureFn
      .execute(enclosure.id, prepareEnclosureToSend(enclosure, false))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_updated")
        );
        enclosuresData.refetch();
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_updating")
        );
      });
  };

  const updateEquipmentEnclosure = (enclosure) => {
    updateEnclosureEquipmentDataFn
      .execute(enclosure.id, prepareEnclosureToSend(enclosure, false))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_updated")
        );
        enclosuresData.refetch();
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_updating")
        );
      });
  };

  const deleteEnclosureForItem = (enclosureTOId) => {
    updateEnclosureFn
      .execute(enclosureTOId, prepareEnclosureToHide(enclosureTOId))
      .then((res) => {
        setChosenEnclosuresFilterChosenGroup([...defaultFilters]);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_deleted")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_deletiing")
        );
      });
  };

  const deleteEnclosureForPart = (enclosurePartId) => {
    updatePartEnclosureFn
      .execute(enclosurePartId, prepareEnclosureToHide(enclosurePartId))
      .then((res) => {
        setChosenEnclosuresFilterChosenGroup([...defaultFilters]);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_deleted")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_deletiing")
        );
      });
  };

  const deleteEnclosureForEquipment = (enclosureEquipmentId) => {
    updateEnclosureEquipmentDataFn
      .execute(
        enclosureEquipmentId,
        prepareEnclosureToHide(enclosureEquipmentId)
      )
      .then((res) => {
        setChosenEnclosuresFilterChosenGroup([...defaultFilters]);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_deleted")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_deletiing")
        );
      });
  };

  const prepareDataToSend = (enclosures) => {
    for (let enclosure of enclosures) {
      enclosure["token_data"] = {
        file_ori_name: enclosure["enclosure_name"],
        unique_index: getRandomString(),
        content_type: enclosure["file"].type,
      };
    }
    return enclosures;
  };
  const onDownloadEnclosure = (enclosureId, enclosureName) => {
    handleDownloadEnclosure(enclosureId, enclosureName);
  };

  const onPreviewEnclosure = (enclosureId, index) => {
    if (enclosureId) {
      handlePreviewEnclosure(enclosureId);
    }
  };

  const onAddEnclosureToFavorite = (enclosureId) => {
    addEnclosureToFavoriteFn
      .execute(enclosureId)
      .then((res) => {
        setChosenEnclosuresFilterChosenGroup([...defaultFilters]);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_added_to_favority")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_enclosure_adding_to_favority")
        );
      });
  };

  const onDeletePermanentlyOfferEnclosure = (enclosureId) => {
    removeOfferEnclosureFn
      .execute(enclosureId)
      .then((res) => {
        enclosuresData.refetch();
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.enclosure_is_removed_successed")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_removing_enclosure")
        );
      });
  };

  return {
    enclosures: localEnclosures,
    setEnclosures: setLocalEnclosures,
    saveEnclosures,
    refetchEnclosures: enclosuresData.refetch,
    updateEnclosure,
    deleteEnclosureForItem,
    chooseEnclosuresFilterGroup,
    chosenEnclosuresFilterGroup,
    isEnclosuresLoading: loadingEnclosures,
    onDownloadEnclosure,
    onPreviewEnclosure,
    onAddEnclosureToFavorite,
    defaultEnclosureType,
    showExtendedAddEnclosureDialog,
    showFilters,
    showEnclosureType,
    showSubcontractorSelect,
    canHideEnclosures,
    onDeletePermanentlyOfferEnclosure,
    updateOfferEnclosure,
    showPublishButton,
    updatePartEnclosure,
    deleteEnclosureForPart,
    updateEquipmentEnclosure,
    deleteEnclosureForEquipment,
  };
};

export default useEnclosuresData;
