import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useUserService from "../../../services/userService";
import { useForm } from "../../../hooks/useForm";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import useIcoService from "../../../services/icoService";
import DateField from "../../field/DateField/DateField";
import { ICO_GENERATE_ACTION } from "../../../helpers/constants";
import { useSnackbarAlert } from "../../../context/snackbarAlert";



const IcoGenerateDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [isSavingData, setIsSavingData] = useState(false);

  const {
    getIcoAgreementGenerateDetails,
    getDraftSelectList,
    updateIco
  } = useIcoService();

  const draftsList = useAsync(getDraftSelectList);
  const updateIcoFn = useAsyncFn(updateIco)

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({
    ico: props.icoId,

  })

  const requiredFields = [
    "icopart_person",
    "draft",
    "ico_date_agreement"
  ];

  const icoGenerateData = useAsync(
    () => getIcoAgreementGenerateDetails(props.icoId),
    [props.icoId]);

  useEffect(() => {
    if (icoGenerateData.loading) {
      return;
    }
    setFormValue(icoGenerateData.value)

  }, [icoGenerateData.loading]);

  const isValid = formValue
    ? requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    ) : false;


  const { getUserSelectList } = useUserService();

  const userSelectList = useAsync(
    () => getUserSelectList(props.searchParams));

  const isLoading = userSelectList.loading || draftsList.loading

  const onPrepareDataToSave = () => {
    let dataToSend = {};
    dataToSend.ico = formValue
    dataToSend.ico_participiants = [{
    icopart_is_default_participant: false,
    icopart_role_acceptor : false,
    icopart_role_doer : true,
    icopart_note : formValue?.icopart_note,
    icopart_person : formValue?.icopart_person
    }]
    dataToSend.action = ICO_GENERATE_ACTION;
    return dataToSend;
  };

  const onUpdateIco = () => {
    setIsSavingData(true);
    updateIcoFn
      .execute(props.icoId, onPrepareDataToSave())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.update_request")
        );
        setIsSavingData(false);
        props.onClose()
        props.onDataRefetch()
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_updating_request")
        );
      });
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.ico_generate_dialog.generate_agreement")}
    >
      <LoaderWrapper showLoader={isLoading || isSavingData} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <AutocompleteField
              name="icopart_person"
              label={t("dialog.ico_generate_dialog.dpr_user")}
              value={formValue?.icopart_person}
              options={userSelectList ? userSelectList.value : []}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldFormControl
              name="icopart_note"
              multiline
              rows={3}
              resize={"vertical"}
              value={formValue?.icopart_note}
              label={t("dialog.ico_generate_dialog.note")}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              name="ico_date_agreement"
              label={t("form.ico_main_data_form.ico_date_agreement")}
              value={formValue?.ico_date_agreement}
              onChange={onChangeDate}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteField
              name={"draft"}
              label={t("dialog.ico_generate_dialog.draft")}
              value={formValue?.draft}
              options={draftsList?.value}
              isObjectOption={true}
              optionLabelKey={"draft_name"}
              addNewValue={false}
              multiple={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onUpdateIco()}
              disabled={!isValid}
            >
              {t("generate")}
            </Button>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

IcoGenerateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

};

IcoGenerateDialog.defaultProps = {
  open: false
};

export default IcoGenerateDialog;
