import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import SelectField from "../../field/SelectField";

const OwnerBookkDetailsForm = (props) => {
  const { t } = useTranslation();


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >

      <Grid item xs={12}>
        <AutocompleteField
          name="ownboo_person"
          value={props.ownBookData?.ownboo_person}
          label={t("form.owner_bookk_details_form.ownboo_person")}
          onChange={props.onChangeAutocomplete}
          required
          options={props.users}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          addNewValue={false}
          multiple={false}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          options={props.filteringData?.ownboo_functions}
          name={"ownboo_function"}
          value={props.ownBookData?.ownboo_function}
          onChange={props.onChange}
          isObjectOption = {false}
          label={t("form.owner_bookk_details_form.function")}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldFormControl
          name="ownboo_note"
          value={props.ownBookData?.ownboo_note}
          label={t("form.owner_bookk_details_form.ownboo_note")}
          onChange={props.onChange}
          multiline
          rows={3}
          resize="vertical"
        />
      </Grid>

    </Grid>
  );
};

OwnerBookkDetailsForm.propTypes = {
  ibanData: PropTypes.object,
  currencies: PropTypes.array,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func
};

OwnerBookkDetailsForm.defaultProps = {
  users: []

};

export default OwnerBookkDetailsForm;
