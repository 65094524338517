import { Navigate } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import hasUserPermission, {
  IS_K,
  IS_KT,
  IS_OM,
  IS_R,
  IS_T,
  IS_TB,
  IS_ADMIN,
  IS_POST,
  IS_RR,
  IS_LEGAL,
  IS_WAREHOUSE,
  IS_FINANCE,
  IS_ACCOUNT,
  IS_WAREHOUSE_ADMIN,
  IS_SCM,
  IS_ICO_ADMIN,
} from "../../../helpers/userPermissions";

const RedirectRoute = () => {
  const { user } = useAuth();
  let redirectLink = "/login/";

  if (hasUserPermission([IS_K], user)) redirectLink = "/ticket_orders/";
  else if (hasUserPermission([IS_KT], user)) redirectLink = "/orders/";
  else if (hasUserPermission([IS_OM], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_R], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_T, IS_TB], user)) redirectLink = "/my_tasks/";
  else if (hasUserPermission([IS_ADMIN], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_RR], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_LEGAL], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_WAREHOUSE], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_WAREHOUSE_ADMIN], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_FINANCE], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_ACCOUNT], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_SCM], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_POST], user)) redirectLink = "/my_tickets/";
  else if (hasUserPermission([IS_ICO_ADMIN], user)) redirectLink = "/my_tickets/";

  return <Navigate to={redirectLink} />;
};

export default RedirectRoute;
