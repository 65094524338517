import { DATE_FIELD_TYPE, NUMERIC_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import i18n from "../../../i18n";
import DateService from "../../../services/dateService";


export const NEXT_TRANSFERS_EDITABLE_TABLE_FIELDS_CONFIG = [
  {
    name: "ico_nr",
    getValueCallback: (rowData) => rowData.ico_nr,
    label: i18n.t("table.icos_table.agreement_number"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "ico_name",
    getValueCallback: (rowData) => rowData.ico_name,
    label: i18n.t("table.icos_table.agreement_name"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "icopayment_payer",
    getValueCallback: (rowData) => rowData.icopayment_payer,
    label: i18n.t("table.icos_table.payer"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "icopayment_receiver",
    getValueCallback: (rowData) => rowData.icopayment_receiver,
    label: i18n.t("table.icos_table.receiver"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "icopayment_kind",
    getValueCallback: (rowData) => i18n.t(`table.icos_table.${rowData.icopayment_kind}_rate`),
    label: i18n.t("table.icos_table.payment_kind"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  }, 
  {
    name: "icopayment_due_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icopayment_due_date),
    label: i18n.t("table.icos_table.due_date"),
    dataType: DATE_FIELD_TYPE,
    editable: false
  },
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.icos_table.currency"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "icopayment_current_amount_original",
    getValueCallback: (rowData) => rowData.icopayment_current_amount_original_formatted,
    label: i18n.t("table.icos_table.total_payment_to_pay"),
    dataType: TEXT_FIELD_TYPE,
    editable: false
  },
  {
    name: "alocation_value",
    getValueCallback: (rowData) => rowData?.alocation_value,
    label: i18n.t("table.icos_table.alocation_value"),
    dataType: NUMERIC_FIELD_TYPE,
    editable: true
  },
];

