import React, { useCallback, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import DateField from "../../field/DateField/DateField";

import EquipmentTenantRecord from "../../other/EquipmentTenantRecord/EquipmentTenantRecord";

import { isEmptyValue, isEmptyArray } from "../../../helpers/methods";

import useEquipmentService from "../../../services/equipmentService";

const CounterTenantForm = React.memo(
  React.forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { getEquipmentTenantFieldId } = useEquipmentService();

    const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);
    const [duplicatedTenants, setDuplicatedTenants] = useState(false);
    const [invalidTenantFactors, setInvalidTenantFactors] = useState(false);

    useImperativeHandle(ref, () => ({
      checkIfRequiredFieldsAreFill, // Wartość, którą chcemy udostępnić
    }));

    const checkIfRequiredFieldsAreFill = () => {
      if (props.readOnly) return [];

      setDuplicatedTenants(false);
      setInvalidTenantFactors(false);

      let unfillFieldsTemp = [];
      let index = 0;
      for (const equipmentTenant of props.formValue) {
        if (isEmptyValue(equipmentTenant.tenant)) {
          unfillFieldsTemp.push(getEquipmentTenantFieldId(index));
        }
        if (isEmptyValue(equipmentTenant.eit_factor)) {
          unfillFieldsTemp.push(getEquipmentTenantFieldId(index, "eit_factor"));
        }
        index += 1;
      }
      setUnfillRequiredFields(unfillFieldsTemp);

      if (!isEmptyArray(unfillFieldsTemp)) {
        return ["eit_factors"];
      }

      if (
        props.formValue.reduce((sum, equipmentTenant) => {
          return sum + parseFloat(equipmentTenant.eit_factor);
        }, 0) !== 1
      ) {
        setInvalidTenantFactors(true);
        return ["eit_factors"];
      }

      if (
        props.formValue.map((equipmentTenant) => equipmentTenant.tenant)
          .length !==
        new Set(
          props.formValue.map((equipmentTenant) => equipmentTenant.tenant)
        ).size
      ) {
        setDuplicatedTenants(true);
        return ["tenants"];
      }
      return unfillFieldsTemp;
    };

    const handleDeleteTenant = useCallback((index) => {
      props.setFormValue((prev) => {
        let newFormValue = [...prev];
        if (newFormValue.length !== 1) {
          newFormValue.splice(index, 1);
        } else {
          newFormValue.splice(index, 1, {});
        }

        return newFormValue;
      });
    }, []);

    const handleAddTenant = useCallback(() => {
      props.setFormValue((prev) => [...prev, {}]);
    }, []);

    const handleChangeInArrayForm = useCallback((...inputProps) => {
      props.onChangeInArrayForm(...inputProps);
    }, []);

    const handleChangeAutocompleteFieldWithObjectOptionsInArrayForm =
      useCallback((...inputProps) => {
        props.onChangeAutocompleteFieldWithObjectOptionsInArrayForm(
          ...inputProps
        );
      }, []);

    const formContent = (
      <Grid
        container
        rowSpacing={2}
        columnSpacing={1}
        justifyContent={"flex-end"}
      >
        <Grid item xs={12} container spacing={0.5}>
          {props.formValue.map((equipmentTenant, index) => (
            <EquipmentTenantRecord
              key={index}
              formValue={equipmentTenant}
              readOnly={props.readOnly}
              index={index}
              tenantSelectList={props.filteringData.tenant}
              showButtonAddRelation={!props.readOnly && index === 0}
              showButtonDeleteRelation={index !== 0}
              onAddRelation={handleAddTenant}
              onDeleteRelation={handleDeleteTenant}
              onChange={handleChangeInArrayForm}
              onChangeAutocompleteFieldWithObjectOptions={
                handleChangeAutocompleteFieldWithObjectOptionsInArrayForm
              }
              unfillRequiredFields={unfillRequiredFields}
              invalidTenantFactors={invalidTenantFactors}
              duplicatedTenants={duplicatedTenants}
            />
          ))}
        </Grid>
        {props.showStartSettlement && (
          <Grid item xs={6}>
            <DateField
              name={"eits_start_settlement"}
              label={t("form.meter_passport.eits_start_settlement")}
              value={props.startSettlement}
              onChange={props.onChangeDate}
              showClearIcon={false}
              maxDate={props.endSettlement ? props.endSettlement : null}
              readOnly={
                props.readOnly ||
                props.readOnlyFields.includes("eits_start_settlement")
              }
              required
              helperText={
                props.unfillRequiredFields.includes("eits_start_settlement") &&
                t("field_required")
              }
              error={props.unfillRequiredFields.includes(
                "eits_start_settlement"
              )}
            />
          </Grid>
        )}
        {props.showEndSettlement && (
          <Grid item xs={6}>
            <DateField
              name={"eits_end_settlement"}
              label={t("form.meter_passport.eits_end_settlement")}
              value={props.endSettlement}
              onChange={props.onChangeDate}
              minDate={props.startSettlement ? props.startSettlement : null}
              showClearIcon={false}
              readOnly={
                props.readOnly ||
                props.readOnlyFields.includes("eits_end_settlement")
              }
              required
              helperText={
                props.unfillRequiredFields.includes("eits_end_settlement") &&
                t("field_required")
              }
              error={props.unfillRequiredFields.includes("eits_end_settlement")}
            />
          </Grid>
        )}
      </Grid>
    );

    return props.inBox ? (
      <BoxWithLabel label={t("form.meter_passport.tenant")}>
        {formContent}
      </BoxWithLabel>
    ) : (
      formContent
    );
  })
);

CounterTenantForm.propTypes = {
  formValue: PropTypes.object,
  setFormValue: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeInArrayForm: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptionsInArrayForm: PropTypes.func,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  readOnlyFields: PropTypes.bool,
  unfillRequiredFields: PropTypes.array,
  showStartSettlement: PropTypes.bool,
  showEndSettlement: PropTypes.bool,
  inBox: PropTypes.bool,
};

CounterTenantForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
  showStartSettlement: true,
  showEndSettlement: false,
  inBox: true,
};

export default CounterTenantForm;
