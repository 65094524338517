import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";
import WarningAlertIcon from "../../components/other/WarningAlertIcon";
import DraftsIcon from "@mui/icons-material/Drafts";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";

export const GUARANTEE_TABLE = "guarantee";

export const GUARANTEE_TABLE_FIELDS_CONFIG = [
  {
    name: "guarantee_nr",
    getValueCallback: (rowData) => rowData.guarantee_nr,
    label: i18n.t("table.guarantee.guarantee_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "guarantee_kind",
    getValueCallback: (rowData) => rowData.guarantee_kind,
    label: i18n.t("table.guarantee.guarantee_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.guarantee_kind,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "guarantee_sector",
    getValueCallback: (rowData) => rowData.guarantee_sector,
    label: i18n.t("table.guarantee.guarantee_sector"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.guarantee_sector,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "owner",
    getValueCallback: (rowData) => rowData.owner,
    label: i18n.t("table.guarantee.owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.owner,
      isObjectOption: true,
      optionLabelKey: "owner_full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "subcontractor",
    getValueCallback: (rowData) => rowData.subcontractor,
    label: i18n.t("table.guarantee.subcontractor"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.subcontractor,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      const content = (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.locations[0]?.level_1}
        </PrefixSuffixFieldTableWrapper>
      );
      return rowData.is_multiple_locations
        ? content
        : rowData.locations[0]?.level_1;
    },
    label: i18n.t("table.guarantee.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.locations[0]?.level_2,
    label: i18n.t("table.guarantee.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.locations[0]?.level_3,
    label: i18n.t("table.guarantee.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.locations[0]?.level_4,
    label: i18n.t("table.guarantee.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.locations[0]?.level_5,
    label: i18n.t("table.guarantee.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "guarantee_value",
    getValueCallback: (rowData) => rowData.guarantee_value,
    label: i18n.t("table.guarantee.guarantee_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "guarantee_date_contract",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.guarantee_date_contract),
    label: i18n.t("table.guarantee.guarantee_date_contract"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "guarantee_date_protocol",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.guarantee_date_protocol),
    label: i18n.t("table.guarantee.guarantee_date_protocol"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "guarantee_response_time",
    getValueCallback: (rowData) => rowData.guarantee_response_time,
    label: i18n.t("table.guarantee.guarantee_response_time"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "guarantee_realisation_time",
    getValueCallback: (rowData) => rowData.guarantee_realisation_time,
    label: i18n.t("table.guarantee.guarantee_realisation_time"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "guarantee_scope_installation",
    getValueCallback: (rowData) => rowData.guarantee_scope_installation,
    label: i18n.t("table.guarantee.guarantee_scope_installation"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    cellStyle: { whiteSpace: "nowrap" },
  },
  {
    name: "guarantee_warranty",
    getValueCallback: (rowData) => rowData.guarantee_warranty,
    label: i18n.t("table.guarantee.guarantee_warranty"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    cellStyle: { whiteSpace: "nowrap" },
  },
  {
    name: "guarantee_pledge",
    getValueCallback: (rowData) => rowData.guarantee_name,
    label: i18n.t("table.guarantee.guarantee_pledge"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    cellStyle: { whiteSpace: "nowrap" },
  },
  {
    name: "guarantee_note",
    getValueCallback: (rowData) => rowData.guarantee_note,
    label: i18n.t("table.guarantee.guarantee_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    cellStyle: { whiteSpace: "nowrap" },
  },
  {
    name: "preview",
    getValueCallback: (rowData, onClickMultipleLocationAlert, onClickEdit) => (
      <InfoIcon onClick={(e) => onClickEdit(e, rowData.id, true)} />
    ),
    label: i18n.t("table.guarantee.preview"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    excludedFromExport: true,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickMultipleLocationAlert, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(e, rowData.id)} />
    ),
    label: i18n.t("table.guarantee.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    excludedFromExport: true,
  },
];

export const GUARANTEE_SCOPE_TABLE = "guarantee_scope";

export const GUARANTEE_SCOPE_TABLE_FIELDS_CONFIG = [
  {
    name: "guarantee_nr",
    getValueCallback: (rowData) => rowData.guarantee_nr,
    label: i18n.t("table.guarantee_scope.guarantee_nr"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.guarantee_nr,
      isObjectOption: true,
      optionLabelKey: "guarantee_nr",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "guascope_kind",
    getValueCallback: (rowData) => rowData.guascope_kind,
    label: i18n.t("table.guarantee_scope.guascope_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.guascope_kind,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "guascope_date_close",
    getValueCallback: (rowData) =>
      DateService.convertDateToFormatYYYYMMDD(rowData.guascope_date_close),
    label: i18n.t("table.guarantee_scope.guascope_date_close"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },

  {
    name: "guascope_desc",
    getValueCallback: (rowData) => rowData.guascope_desc,
    label: i18n.t("table.guarantee_scope.guascope_desc"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "guascope_responsible",
    getValueCallback: (rowData) => rowData.guascope_responsible,
    label: i18n.t("table.guarantee_scope.guascope_responsible"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.guascope_responsible,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "send_reminder_manualy",
    getValueCallback: (rowData, onClickEdit, onCLickSendReminderManualy) => (
      <Tooltip title={i18n.t("table.guarantee_scope.send_reminder_manualy")}>
        <DraftsIcon onClick={(e) => onCLickSendReminderManualy(rowData.id)} />
      </Tooltip>
    ),
    label: i18n.t("table.guarantee_scope.send_reminder_manualy"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    excludedFromExport: true,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(e, rowData.id)} />
    ),
    label: i18n.t("table.guarantee_scope.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    excludedFromExport: true,
  },
];

export const GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE =
  "guarantee_scope_for_guarantee";

export const GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FIELDS_CONFIG =
  GUARANTEE_SCOPE_TABLE_FIELDS_CONFIG.filter(
    (field) => field.name !== "guarantee_nr"
  );

export const GUARANTEE_GUARANTEE_SCOPE_TABLE_CONFIGS = [
  { name: GUARANTEE_TABLE, config: GUARANTEE_TABLE_FIELDS_CONFIG },
  {
    name: GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE,
    config: GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FIELDS_CONFIG,
  },
];

export const GUARANTEE_SCOPE_TABLE_CONFIGS = [
  { name: GUARANTEE_SCOPE_TABLE, config: GUARANTEE_SCOPE_TABLE_FIELDS_CONFIG },
];

export const GUARANTEE_TABLE_FILTER_PREFIX = "guarantee";
export const GUARANTEE_SCOPE_FOR_GUARANTEE_TABLE_FILTER_PREFIX = "guarscope";
export const GUARANTEE_SCOPE_TABLE_FILTER_PREFIX = "scope";
