import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../context/auth";
import { useSnackbarAlert } from "../context/snackbarAlert";
import useUserService from "../services/userService"
import useAddressService from "../services/addressService";
import usePhoneService from "../services/phoneService";
import useMailService from "../services/mailService";
import { useAsyncFn } from "./useAsync";
import useDialog from "./useDialog";
import useOfferService from "../services/offerService";

const useOwnerFormsService = (
  onDataRefetch,
  formValue,
  setFormValue,
  ownerId,
  subcontractorId
) => {
  const { t } = useTranslation();

  const selectedAddressId = useRef();
  const selectedPhoneId = useRef();
  const selectedMailId = useRef();
  const selectedBoardId = useRef();
  const selectedUserId = useRef();
  const selectedIbanId = useRef();
  const selectedAccountId = useRef();
  const selectedOwnBookkId = useRef();

  const snackbarAlert = useSnackbarAlert();

  const [
    openAddressDialog,
    onOpenAddressDialog,
    handleCloseAddressDialog,
  ] = useDialog();

  const [
    openPhoneDialog,
    onOpenPhoneDialog,
    handleClosePhoneDialog,
  ] = useDialog();

  const [
    openMailDialog,
    onOpenMailDialog,
    handleCloseMailDialog,
  ] = useDialog();


  const [
    openBoardDialog,
    onOpenBoardDialog,
    handleCloseBoardDialog,
  ] = useDialog();

  const [
    openUserDialog,
    onOpenUserDialog,
    handleCloseUserDialog,
  ] = useDialog();

  const [
    openIbanDialog,
    onOpenIbanDialog,
    handleCloseIbanDialog,
  ] = useDialog();

  const [
    openAccountDialog,
    onOpenAccountDialog,
    handleCloseAccountDialog,
  ] = useDialog();

  const [
    openBookkDialog,
    onOpenBookkDialog,
    handleCloseBookkDialog,
  ] = useDialog();

  const {
    removeBoardOwner,
    removeUserSubcontractor,
    removeUserOwner,
    removeIbanOwner,
    removeOwnerBookk } = useUserService();

  const {
    removeAccount
  } = useOfferService();

  const {
    removeAddressOwner,
    removeAddressSubcontractor
  } = useAddressService();

  const {
    removePhoneOwner,
    removePhoneSubcontractor
  } = usePhoneService();

  const {
    removeMailOwner,
    removeMailSubcontractor
  } = useMailService();


  const removeAddressOwnerFn = useAsyncFn(removeAddressOwner)
  const removeAddressSubcontractorFn = useAsyncFn(removeAddressSubcontractor)
  const removePhoneOwnerFn = useAsyncFn(removePhoneOwner)
  const removePhoneSubcontractorFn = useAsyncFn(removePhoneSubcontractor)
  const removeMailOwnerFn = useAsyncFn(removeMailOwner)
  const removeMailSubcontractorFn = useAsyncFn(removeMailSubcontractor)
  const removeBoardOwnerFn = useAsyncFn(removeBoardOwner)
  const removeUserSubcontractorFn = useAsyncFn(removeUserSubcontractor)
  const removeUserOwnerFn = useAsyncFn(removeUserOwner)
  const removeIbanOwnerFn = useAsyncFn(removeIbanOwner)
  const removeAccountOwnerFn = useAsyncFn(removeAccount)
  const removeOwnerBookkFn = useAsyncFn(removeOwnerBookk)

  const onRemoveAddressFromDb = useCallback((modelAddressId) => {
    if (subcontractorId) {
      removeAddressSubcontractorFn.execute(modelAddressId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.address_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_address_removing")
          );
        });
    } else {
      removeAddressOwnerFn.execute(modelAddressId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.address_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_address_removing")
          );
        });
    }
  }, [])

  const onRemovePhoneFromDb = useCallback((modelPhoneId) => {
    if (subcontractorId) {
      removePhoneSubcontractorFn.execute(modelPhoneId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.phone_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_phone_removing")
          );
        });
    } else {
      removePhoneOwnerFn.execute(modelPhoneId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.phone_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_phone_removing")
          );
        });
    }
  }, [])

  const onRemoveMailFromDb = useCallback((modelMailId) => {
    if (subcontractorId) {
      removeMailSubcontractorFn.execute(modelMailId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.mail_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_mail_removing")
          );
        });
    } else {
      removeMailOwnerFn.execute(modelMailId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.mail_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_mail_removing")
          );
        });
    }
  }, [])

  const onRemoveUserFromDb = useCallback((modelUserId) => {
    if (subcontractorId) {
      removeUserSubcontractorFn.execute(modelUserId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.user_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_user_removing")
          );
        });
    } else {
      removeUserOwnerFn.execute(modelUserId)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.user_removed")
          );
          onDataRefetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_user_removing")
          );
        });
    }
  }, [])



  const onRemoveBoardFromDb = useCallback((boardId) => {
    removeBoardOwnerFn.execute(boardId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.board_member_removed")
        );
        onDataRefetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_board_member_removing")
        );
      });
  }, [])


  const onRemoveIbanFromDb = useCallback((ibanId) => {
    removeIbanOwnerFn.execute(ibanId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.iban_removed")
        );
        onDataRefetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_iban_removing")
        );
      });
  }, [])

  const onRemoveAccountFromDb = useCallback((accountId) => {
    removeAccountOwnerFn.execute(accountId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.account_removed")
        );
        onDataRefetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_account_removing")
        );
      });
  }, [])

  const onRemoveOwnBookFromDb= useCallback((ownBookId) => {
    removeOwnerBookkFn.execute(ownBookId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.owner_bookkeeping_removed")
        );
        onDataRefetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_owner_bookkeeping_removing")
        );
      });
  }, [])


  const onCloseAddressDialog = useCallback(() => {
    selectedAddressId.current = undefined
    handleCloseAddressDialog()
  }, [])

  const onEditAddress = useCallback((addressId) => {
    // if (ownerId){
    //   selectedAddressId.current = addressId
    //   onOpenAddressDialog()
    // }
  }, [ownerId])

  const onClosePhoneDialog = useCallback(() => {
    selectedPhoneId.current = undefined
    handleClosePhoneDialog()
  }, [])

  const onEditPhone = useCallback((phoneId) => {
    if (ownerId || subcontractorId) {
      selectedPhoneId.current = phoneId
      onOpenPhoneDialog()
    }
  }, [ownerId, subcontractorId])


  const onCloseMailDialog = useCallback(() => {
    selectedMailId.current = undefined
    handleCloseMailDialog()
  }, [])

  const onCloseUserDialog = useCallback(() => {
    selectedUserId.current = undefined
    handleCloseUserDialog()
  }, [])

  const onCloseIbanDialog = useCallback(() => {
    selectedIbanId.current = undefined
    handleCloseIbanDialog()
  }, [])

  const onCloseAccountDialog = useCallback(() => {
    selectedAccountId.current = undefined
    handleCloseAccountDialog()
  }, [])

  const onCloseBookDialog = useCallback(() => {
    selectedOwnBookkId.current = undefined
    handleCloseBookkDialog()
  }, [])

  const onEditMail = useCallback((mailId) => {
    if (ownerId || subcontractorId) {
      selectedMailId.current = mailId
      onOpenMailDialog()
    }
  }, [ownerId, subcontractorId])

  const onCloseBoardDialog = useCallback(() => {
    selectedBoardId.current = undefined
    handleCloseBoardDialog()
  }, [])

  const onEditBoard = useCallback((boardId) => {
    if (ownerId) {
      selectedBoardId.current = boardId
      onOpenBoardDialog()
    }
  }, [ownerId])

  const onAddMailForNewOwner = useCallback((mailData) => {
    let tempMails = formValue.mails !== undefined ? formValue.mails : []
    let updatedMails = [...tempMails, mailData]
    setFormValue((formValue) => ({
      ...formValue,
      ['mails']: [...updatedMails],
    }));
  }, [formValue])

  const onRemoveMail = useCallback((modelMailId, index) => {
    if (modelMailId) {
      onRemoveMailFromDb(modelMailId)
    } else {
      setFormValue((formValue) => {
        let tempMails = [...formValue.mails];
        tempMails.splice(index, 1);
        formValue['mails'] = tempMails
        return { ...formValue };

      });
    }
  }, [formValue])

  const onAddAddresForNewOwner = useCallback((addressData) => {
    let tempAdresses = formValue.addresses !== undefined ? formValue.addresses : []
    addressData.full_address = `${addressData.address_zip} ${addressData.address_city}, ${addressData.address_street} ${addressData.address_building}`
    let updatedAddresses = [...tempAdresses, addressData]
    setFormValue((formValue) => ({
      ...formValue,
      ['addresses']: [...updatedAddresses],
    }));
  }, [formValue])

  const onRemoveAddress = useCallback((modelAddressId, index) => {
    if (modelAddressId) {
      onRemoveAddressFromDb(modelAddressId)
    } else {
      setFormValue((formValue) => {
        let tempAddresses = [...formValue.addresses];
        tempAddresses.splice(index, 1);
        formValue['addresses'] = tempAddresses
        return { ...formValue };

      });
    }
  }, [formValue])

  const onAddPhoneForNewOwner = useCallback((phoneData) => {
    let tempPhones = formValue.phones !== undefined ? formValue.phones : []
    let updatedPhones = [...tempPhones, phoneData]
    setFormValue((formValue) => ({
      ...formValue,
      ['phones']: [...updatedPhones],
    }));
  }, [formValue])



  const onAddIbanForNewOwner = useCallback((ibanData) => {
    let tempIbans = formValue.ibans !== undefined ? formValue.ibans : []
    let updatedIbans = [...tempIbans, ibanData]
    setFormValue((formValue) => ({
      ...formValue,
      ['ibans']: [...updatedIbans],
    }));
  }, [formValue])

  const onAddAccountForNewOwner = useCallback((acountData) => {
    let tempAccounts = formValue.accounts !== undefined ? formValue.accounts : []
    let updatedAccounts = [...tempAccounts, acountData]
    setFormValue((formValue) => ({
      ...formValue,
      ['accounts']: [...updatedAccounts],
    }));
  }, [formValue])

  const onAddBookForNewOwner = useCallback((ownBookkData) => {
    let tempOwnBookks = formValue.owner_bookks !== undefined ? formValue.owner_bookks : []
    let updatedOwnBookks = [...tempOwnBookks, ownBookkData]
    setFormValue((formValue) => ({
      ...formValue,
      ['owner_bookks']: [...updatedOwnBookks],
    }));
  }, [formValue])

  const onRemovePhone = useCallback((modelPhoneId, index) => {
    if (modelPhoneId) {
      onRemovePhoneFromDb(modelPhoneId)
    } else {
      setFormValue((formValue) => {
        let tempPhones = [...formValue.phones];
        tempPhones.splice(index, 1);
        formValue['phones'] = tempPhones
        return { ...formValue };

      });
    }
  }, [formValue])

  const onAddUserForNewInstance = useCallback((userData) => {
    userData = { 'user': userData.id, 'full_name': userData.full_name }
    let tempUsers = formValue.users !== undefined ? formValue.users : []
    let updatedUsers = [...tempUsers, userData]

    setFormValue((formValue) => ({
      ...formValue,
      ['users']: [...updatedUsers],
    }));
  }, [formValue])

  const onRemoveUser = useCallback((modelUserId, index) => {
    if (modelUserId) {
      onRemoveUserFromDb(modelUserId)
    } else {
      setFormValue((formValue) => {
        let tempUsers = [...formValue.users];
        tempUsers.splice(index, 1);
        formValue['users'] = tempUsers
        return { ...formValue };

      });
    }
  }, [formValue])


  const onEditIban = useCallback((ibanId) => {
    if (ownerId || ibanId) {
      selectedIbanId.current = ibanId
      onOpenIbanDialog()
    }
  }, [ownerId])

  const onRemoveIban = useCallback((ibanId, index) => {
    if (ibanId) {
      onRemoveIbanFromDb(ibanId)
    } else {
      setFormValue((formValue) => {
        let tempIbans = [...formValue.ibans];
        tempIbans.splice(index, 1);
        formValue['ibans'] = tempIbans
        return { ...formValue };

      });
    }
  }, [formValue])


  const onEditAccount = useCallback((accountId) => {
    if (ownerId || accountId) {
      selectedAccountId.current = accountId
      onOpenAccountDialog()
    }
  }, [ownerId])

  const onEditBookk = useCallback((ownBookId) => {
    if (ownerId || ownBookId) {
      selectedOwnBookkId.current = ownBookId
      onOpenBookkDialog()
    }
  }, [ownerId])

  const onRemoveAccount = useCallback((accountId, index) => {
    if (accountId) {
      onRemoveAccountFromDb(accountId)
    } else {
      setFormValue((formValue) => {
        let tempAccounts = [...formValue.accounts];
        tempAccounts.splice(index, 1);
        formValue['accounts'] = tempAccounts
        return { ...formValue };

      });
    }
  }, [formValue])

  const onRemoveBookk = useCallback((ownBookId, index) => {
    if (ownBookId) {
      onRemoveOwnBookFromDb(ownBookId)
    } else {
      setFormValue((formValue) => {
        let tempOwnBooks = [...formValue.owner_bookks];
        tempOwnBooks.splice(index, 1);
        formValue['owner_bookks'] = tempOwnBooks
        return { ...formValue };

      });
    }
  }, [formValue])

  const onAddBoardForNewOwner = useCallback((boardData) => {
    let tempBoards = formValue.boards !== undefined ? formValue.boards : []
    boardData.full_name = boardData.board_member ? boardData.full_name : boardData.board_company
    boardData.board_member = boardData.board_member
    let updatedBoards = [...tempBoards, boardData]
    setFormValue((formValue) => ({
      ...formValue,
      ['boards']: [...updatedBoards],
    }));
  }, [formValue])

  const onRemoveBoard = useCallback((boardId, index) => {
    if (boardId) {
      onRemoveBoardFromDb(boardId)
    } else {
      setFormValue((formValue) => {
        let tempBoards = [...formValue.boards];
        tempBoards.splice(index, 1);
        formValue['boards'] = tempBoards
        return { ...formValue };

      });
    }
  }, [formValue])

  return {
    openAddressDialog,
    onOpenAddressDialog,
    onAddAddresForNewOwner,
    onEditAddress,
    onRemoveAddress,
    onCloseAddressDialog,

    openBoardDialog,
    onOpenBoardDialog,
    onAddBoardForNewOwner,
    onEditBoard,
    onRemoveBoard,
    onCloseBoardDialog,

    openMailDialog,
    onOpenMailDialog,
    onAddMailForNewOwner,
    onEditMail,
    onRemoveMail,
    onCloseMailDialog,

    openPhoneDialog,
    onOpenPhoneDialog,
    onAddPhoneForNewOwner,
    onEditPhone,
    onRemovePhone,
    onClosePhoneDialog,

    openUserDialog,
    onOpenUserDialog,
    onAddUserForNewInstance,
    onRemoveUser,
    onCloseUserDialog,

    selectedAddressId,
    selectedBoardId,
    selectedMailId,
    selectedPhoneId,

    openIbanDialog,
    onOpenIbanDialog,
    selectedIbanId,
    onAddIbanForNewOwner,
    onEditIban,
    onRemoveIban,
    onCloseIbanDialog,

    openAccountDialog,
    onOpenAccountDialog,
    selectedAccountId,
    onAddAccountForNewOwner,
    onEditAccount,
    onRemoveAccount,
    onCloseAccountDialog,

    openBookkDialog,
    onOpenBookkDialog,
    selectedOwnBookkId,
    onAddBookForNewOwner,
    onEditBookk,
    onRemoveBookk,
    onCloseBookDialog

  };
};

export default useOwnerFormsService;
