import { useRef, useState } from "react";
import useFieldValidation from "./useFieldValidation";

const useItemFormValidation = (itemType, formValue) => {
  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);
  const locationRecordsRef = useRef();

  const { getUnfillRequiredFields } = useFieldValidation();

  const checkIfRequiredFieldsAreFill = () => {
    const unfillFieldsTemp = getUnfillRequiredFieldsLocal();
    setUnfillRequiredFields(unfillFieldsTemp);
    if (unfillFieldsTemp.length > 0) {
      return false;
    }
    return true;
  };

  const getUnfillRequiredFieldsLocal = () => {
    let requiredFields = [];
    if (itemType === "equipment") {
      requiredFields = [
        `${itemType}_nr`,
        // `${itemType}_name`,
        `${itemType}_price_net`,
        "equipment_media_type",
        "supplier",
      ];
    } else {
      requiredFields = [
        `${itemType}_name`,
        `${itemType}_price`,
        `${itemType}_suplier`,
        `${itemType}_unit`,
        "account",
      ];
    }

    let unfillFieldsTemp = [];
    requiredFields.push("item");
    if (locationRecordsRef?.current?.checkIfRequiredFieldsAreFill) {
      unfillFieldsTemp = [
        ...unfillFieldsTemp,
        ...locationRecordsRef.current.checkIfRequiredFieldsAreFill(),
      ];
    }
    unfillFieldsTemp = [
      ...unfillFieldsTemp,
      ...getUnfillRequiredFields(requiredFields, formValue),
    ];
    setUnfillRequiredFields(unfillFieldsTemp);
    return unfillFieldsTemp;
  };

  return {
    unfillRequiredFields,
    locationRecordsRef,
    checkIfRequiredFieldsAreFill,
  };
};

export default useItemFormValidation;
