import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import AccountBoxForm from "../../form/AccountBoxForm";


export default function OwnerAccountFormBox({ accounts, onEditAccount, onAddAccount, onRemoveAccount, readOnly }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t(`other.owner_account_form_box.owner_accounts`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {accounts?.map((account, index) => {
                    return (
                        <AccountBoxForm
                            account={account}
                            index={index}
                            onEditAccount={onEditAccount}
                            onRemoveAccount={onRemoveAccount}
                            readOnly ={readOnly}
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddAccount}
                    >
                        {t("other.owner_account_form_box.add_account")}
                    </Button>
                    }
                </Grid>
            </Grid>
        </BaseBox>
    );
}

OwnerAccountFormBox.propTypes = {
    accounts : PropTypes.array,
    onEditAccount : PropTypes.func,
    onAddAccount : PropTypes.func,
    onRemoveAccount : PropTypes.func,
    readOnly : PropTypes.bool
}


OwnerAccountFormBox.defaultProps = {
    accounts : [],
    readOnly : true
}
