import PropTypes from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

import {
  EQUIPMENT_STATE_TABLE_NAME,
  EQUIPMENT_INSTALATION_TABLE_NAME,
  EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME,
  EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME,
} from "../../../page/CountersSearchPage/TableConfig";

function SelectEquipmentFilterTypeButton(props) {
  const filterOptions = [
    {
      label: `${i18n.t("search")} ${i18n.t(
        "button.select_equipemnt_filter_type.by_attributes"
      )}`,
      key: EQUIPMENT_STATE_TABLE_NAME,
      callback: () => props.onChangeFilterValue(EQUIPMENT_STATE_TABLE_NAME),
    },
    {
      label: `${i18n.t("search")} ${i18n.t(
        "button.select_equipemnt_filter_type.by_instalation_location"
      )}`,
      key: EQUIPMENT_INSTALATION_TABLE_NAME,
      callback: () =>
        props.onChangeFilterValue(EQUIPMENT_INSTALATION_TABLE_NAME),
    },
    {
      label: `${i18n.t("search")} ${i18n.t(
        "button.select_equipemnt_filter_type.by_tenant"
      )}`,
      key: EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME,
      callback: () =>
        props.onChangeFilterValue(EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME),
    },
    {
      label: `${i18n.t("search")} ${i18n.t(
        "button.select_equipemnt_filter_type.by_measuring_location"
      )}`,
      key: EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME,
      callback: () =>
        props.onChangeFilterValue(EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME),
    },
  ];

  return (
    <SplitButton
      selectedItemKey={props.selectedFilterKey}
      withChangeSelectedItem={true}
      color={props.color}
      options={filterOptions}
    />
  );
}

SelectEquipmentFilterTypeButton.propTypes = {
  onChangeFilterValue: PropTypes.func,
  color: PropTypes.string,
};

SelectEquipmentFilterTypeButton.defaultProps = {
  color: "primary",
};

export default SelectEquipmentFilterTypeButton;
