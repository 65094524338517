import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DrawIcon from '@mui/icons-material/Draw';
import HomeIcon from '@mui/icons-material/Home';
import { Tooltip } from "@mui/material";
import SelectFieldService from "../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import DownloadIcon from '@mui/icons-material/Download';

export const ICO_DOCS_TABLE_NAME = "ico_docs_table";

export const ICO_DOCS_TABLE_FIELDS_CONFIG = [
  {
    name: "ico_nr",
    getValueCallback: (rowData) => rowData.ico_nr,
    label: i18n.t("table.icos_table.agreement_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true
  },
  {
    name: "ico_name",
    getValueCallback: (rowData) => rowData.ico_name,
    label: i18n.t("table.icos_table.agreement_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true
  },
  {
    name: "lender",
    getValueCallback: (rowData) => rowData.lender,
    label: i18n.t("table.icos_table.lender"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "borrower",
    getValueCallback: (rowData) => rowData.borrower,
    label: i18n.t("table.icos_table.borrower"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "ico_status",
    getValueCallback: (rowData) => rowData.ico_status,
    label: i18n.t("table.icos_table.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.statuses,
      isObjectOption: true,
      optionLabelKey: "ico_status_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "ico_date_input",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_input),
    label: i18n.t("table.ico_docs_table.request"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_date_acceptance",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_acceptance),
    label: i18n.t("table.ico_docs_table.acceptance"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_date_creation",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_creation),
    label: i18n.t("table.ico_docs_table.creation"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_date_agreement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_agreement),
    label: i18n.t("table.ico_docs_table.agreement_sign"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_date_close",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_close),
    label: i18n.t("table.ico_docs_table.close"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "accept",
    getValueCallback: (rowData, onClickEdit, hasPermission, onClickAccept) => {
      return (
        <Tooltip title={i18n.t("table.ico_docs_table.show_accepts")}>
          <CheckCircleSharpIcon
            onClick={(e) => onClickAccept(e, rowData)}
            color={rowData?.allow_accepts && hasPermission ? "primary" : "disabled"}
          />
        </Tooltip>
      );
    },
    label: undefined,
    filterType: null,
  },
  {
    name: "generate",
    getValueCallback: (rowData, onClickEdit, hasPermission, onClickAccept, onClickGenerate) => {
      return (
        <Tooltip title={i18n.t("table.ico_docs_table.generate_doc")}>
          <PictureAsPdfSharpIcon
            onClick={(e) => onClickGenerate(e, rowData)}
            color={rowData?.allow_generate && hasPermission ? "primary" : "disabled"}
          />
        </Tooltip>
      );
    },
    label: undefined,
    filterType: null,
  },
  {
    name: "sign",
    getValueCallback: (rowData, onClickEdit, hasPermission, onClickAccept, onClickGenerate, onClickSignDoc) => {
      return (
        <Tooltip title={i18n.t("table.ico_docs_table.sign_doc")}>
          <DrawIcon
            onClick={(e) => onClickSignDoc(e, rowData)}
            color={rowData?.allow_sign && hasPermission ? "primary" : "disabled"}
          />
        </Tooltip>
      );
    },
    label: undefined,
    filterType: null,
  },
  {
    name: "archive",
    getValueCallback: (rowData, onClickEdit, hasPermission, onClickAccept, onClickGenerate, onClickSignDoc, onClickArchive) => {
      return (
        <Tooltip title={i18n.t("table.ico_docs_table.archive")}>
          <HomeIcon
            onClick={(e) => onClickArchive(e, rowData)}
            color={rowData?.allow_archive && hasPermission ? "primary" : "disabled"}
          />
        </Tooltip>
      );
    },
    label: undefined,
    filterType: null,
  },
  {
    name: "upload",
    getValueCallback: (rowData, onClickEdit, hasPermission, onClickAccept, onClickGenerate, onClickSignDoc, onClickArchive, onClickUppload) => {
      return (
        <Tooltip title={i18n.t("table.ico_docs_table.attach_scans")}>
          <FileUploadIcon
            onClick={(e) => onClickUppload(e, rowData.id)}
            color={hasPermission ? "primary" : "disabled"}
          />
        </Tooltip>
      );
    },
    label: undefined,
    filterType: null,
  }
];


export const DRAFTS_TABLE_FIELDS_CONFIG = [

  {
    name: "draft_nr",
    getValueCallback: (rowData) => rowData.draft_nr,
    label: i18n.t("table.drafts_table.draft_nr"),
  },
  {
    name: "draft_name",
    getValueCallback: (rowData) => rowData.draft_name,
    label: i18n.t("table.drafts_table.draft_name"),
  },
  {
    name: "draft_language",
    getValueCallback: (rowData) => i18n.t(`table.drafts_table.${rowData.draft_language}`),
    label: i18n.t("table.drafts_table.draft_language"),
  },
  {
    name: "draft_description",
    getValueCallback: (rowData) => rowData.draft_description,
    label: i18n.t("table.drafts_table.draft_description"),
  },
  {
    name: "draft_content_name",
    getValueCallback: (rowData) => rowData.draft_content_name,
    label: i18n.t("table.drafts_table.draft_content_name"),
  },
  {
    name: "draft_active",
    getValueCallback: (rowData) => SelectFieldService.getLabelOptionsTrueOrFalse(rowData.draft_active),
    label: i18n.t("table.drafts_table.draft_active"),
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit, hasPermission) => {
      const content = (
        <Tooltip title={i18n.t("table.drafts_table.edit")}>
          <ModeEditOutlineOutlined
            onClick={(e) => onClickEdit(e, rowData.id)}
          />
        </Tooltip>
      );
      return rowData.draft_active && hasPermission ? content : "";
    },
    label: undefined,
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "download",
    getValueCallback: (rowData, onClickEdit, hasPermission, onClickDownload) => {
      return (<Tooltip title={i18n.t("table.drafts_table.download")}>
        <DownloadIcon
          onClick={(e) => onClickDownload(e, rowData)}
        />
      </Tooltip>);
    },
    label: undefined,
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
]