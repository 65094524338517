import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import {
  userBehalfCreateTicketSelectListUrl,
  userHasSkillUrl,
  userSelectListUrl,
  userDetailsUrl,
  tenantUrl,
  tenantDetailsUrl,
  tenantAddressUrl,
  ownerDetailsUrl,
  subcontractorSelectListUrl,
  subcontractorUrl,
  employeeSkillUrl,
  payersDetailsUrl,
  allPayersDetailsUrl,
  ownersUrl,
  ownersFilteringDataUrl,
  boardDetailsUrl,
  createBoardUrl,
  boardFilteringDataUrl,
  ownerLogsUrl,
  userDefaultLocationUrl,
  userDefaultTicketOmLocationUrl,
  tenantSelectListUrl,
  tenantRRListUrl,
  tenantRRDetailsUrl,
  tenantRRDetailsListUrl,
  tenantFilteringDataUrl,
  userEmailNotificationsUrl,
  userEmailNotificationsFilteringDataUrl,
  userTenantListUrl,
  userTenantDetailsUrl,
  userTenantFilteringDataUrl,
  userTenantInitialDataUrl as newUserTenantInitialDataUrl,
  tenantShortDetailsDataUrl,
  internalPayersDetailsUrl,
  userPermissionUrl,
  usersBySubcontractorUrl,
  otherWarehousePermissionUrl,
  transactionsWarehousePermissionUrl,
  usersPermissionUrl,
  checkUsersPermissionUrl,
  nonWarehousePermissionUrl,
  subcontractorDetailsUrl,
  subcontractorFilteringDataUrl,
  userSubcontractorUrl,
  userSubcontractorDetailsUrl,
  userOwnerUrl,
  userOwnerDetailsUrl,
  activeWarehouseForTranscationUrl,
  ibanShortDataUrl,
  ibanDetailsUrl,
  ibanUrl,
  ownerBookDetailsUrl,
  ownBookkFilteringDataUrl,
  ownerBookUrl,
} from "../helpers/apiUrls";
import { prepareUrlWithQueryParams } from "../helpers/methods";

export default function useUserService() {
  const axiosPrivate = useAxiosPrivate();

  const getUserSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userSelectListUrl, searchParams)
    );
  };

  const getUserBehalfCreateTicketSelectList = () => {
    return makeRequest(axiosPrivate, userBehalfCreateTicketSelectListUrl);
  };

  const getUserHasSkill = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userHasSkillUrl, searchParams)
    );
  };

  const getUserDetails = (userId) => {
    return makeRequest(axiosPrivate, userDetailsUrl(userId));
  };

  const getTenantDetails = (tenantId, searchParams) => {
    if (!tenantId) return Promise.resolve({});

    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(tenantDetailsUrl(tenantId), searchParams)
    );
  };

  const getAllTenantSettlementsData = () => {
    return makeRequest(axiosPrivate, allPayersDetailsUrl);
  };

  const getInternalTenantSettlementsData = () => {
    return makeRequest(axiosPrivate, internalPayersDetailsUrl);
  };

  const getTenantSettlementsData = (offerId) => {
    if (offerId) {
      return makeRequest(axiosPrivate, payersDetailsUrl(offerId));
    }
    return Promise.resolve([]);
  };

  const getOwnerDetails = (ownerId) => {
    if (ownerId) {
      return makeRequest(axiosPrivate, ownerDetailsUrl(ownerId));
    }
    return Promise.resolve({});
  };

  const updateOwnerDetails = (ownerId, data) => {
    return makeRequest(axiosPrivate, ownerDetailsUrl(ownerId), {
      method: "PATCH",
      data: data,
    });
  };

  const createOwner = (ownerData) => {
    return makeRequest(axiosPrivate, ownersUrl, {
      method: "POST",
      data: ownerData,
    });
  };

  const getOwnersFilteringData = () => {
    return makeRequest(axiosPrivate, ownersFilteringDataUrl);
  };

  const getOwners = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ownersUrl, searchParams)
    );
  };

  const getSubconstractorsList = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(subcontractorUrl, searchParams)
    );
  };

  const getSubconstractorsFilteringData = () => {
    return makeRequest(axiosPrivate, subcontractorFilteringDataUrl);
  };

  const getSubcontractorDetails = (subId) => {
    if (subId) {
      return makeRequest(axiosPrivate, subcontractorDetailsUrl(subId));
    }
    return Promise.resolve({});
  };

  const updateSubcontractorDetails = (subId, data) => {
    return makeRequest(axiosPrivate, subcontractorDetailsUrl(subId), {
      method: "PATCH",
      data: data,
    });
  };

  const getUsersBySubcontractor = (subcontractorId) => {
    return makeRequest(axiosPrivate, usersBySubcontractorUrl(subcontractorId));
  };

  const createNewSubcontractor = (subcontractorData) => {
    return makeRequest(axiosPrivate, subcontractorUrl, {
      method: "POST",
      data: subcontractorData,
    });
  };

  const getEmployeeSkill = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(employeeSkillUrl, searchParams)
    );
  };

  const getEmployeesSelectList = () => {
    return getUserSelectList("roles=role_is_t");
  };

  const assignSkillsToUser = (dataToSend) => {
    return makeRequest(axiosPrivate, employeeSkillUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const removeBoardOwner = (boardId) => {
    return makeRequest(axiosPrivate, boardDetailsUrl(boardId), {
      method: "PATCH",
      data: { board_member_is_deleted: true },
    });
  };

  const getBoardDetails = (boardId) => {
    if (boardId) {
      return makeRequest(axiosPrivate, boardDetailsUrl(boardId));
    }
    return Promise.resolve({});
  };

  const createBoard = (boardData) => {
    return makeRequest(axiosPrivate, createBoardUrl, {
      method: "POST",
      data: boardData,
    });
  };

  const updateBoard = (boardId, boardData) => {
    return makeRequest(axiosPrivate, boardDetailsUrl(boardId), {
      method: "PATCH",
      data: boardData,
    });
  };

  const getBoardFilteringData = () => {
    return makeRequest(axiosPrivate, boardFilteringDataUrl);
  };

  const getOwnerLogs = (ownerId) => {
    return makeRequest(axiosPrivate, ownerLogsUrl(ownerId));
  };

  const getUserDefaultLocation = () => {
    return makeRequest(axiosPrivate, userDefaultLocationUrl);
  };

  const getUserDefaultLocationForTicketOM = (ticketOmId) => {
    return makeRequest(
      axiosPrivate,
      userDefaultTicketOmLocationUrl(ticketOmId)
    );
  };

  const getTenantList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(tenantSelectListUrl, searchParams)
    );
  };

  const getTenantRRList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(tenantRRListUrl, searchParams)
    );
  };

  const getTenantRRDetails = (tenantId) => {
    return makeRequest(axiosPrivate, tenantRRDetailsUrl(tenantId));
  };
  const getTenantAddress = (tenantId) => {
    return makeRequest(axiosPrivate, tenantAddressUrl(tenantId));
  };

  const getTenantRRDetailsList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(tenantRRDetailsListUrl, searchParams)
    );
  };

  const getUserEmailNotifications = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userEmailNotificationsUrl, searchParams)
    );
  };

  const getUserEmailNotificationsFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        userEmailNotificationsFilteringDataUrl,
        searchParams
      )
    );
  };

  const updateUserEmailNotifications = (dataToSend) => {
    return makeRequest(axiosPrivate, userEmailNotificationsUrl, {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getTenantFilteringData = () => {
    return makeRequest(axiosPrivate, tenantFilteringDataUrl);
  };

  const updateTenant = (tenantId, dataToSend) => {
    return makeRequest(axiosPrivate, tenantDetailsUrl(tenantId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const createTenant = (dataToSend) => {
    return makeRequest(axiosPrivate, tenantUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getUserTenantList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userTenantListUrl, searchParams)
    );
  };

  const getUserTenantDetails = (userTenantId) => {
    return makeRequest(axiosPrivate, userTenantDetailsUrl(userTenantId));
  };

  const getUserTenantFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userTenantFilteringDataUrl, searchParams)
    );
  };

  const getNewUserTenantInitialData = () => {
    return makeRequest(axiosPrivate, newUserTenantInitialDataUrl);
  };

  const addUserTenant = (dataToSend) => {
    return makeRequest(axiosPrivate, userTenantListUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateUserTenant = (dataToSend, userTenantId) => {
    return makeRequest(axiosPrivate, userTenantDetailsUrl(userTenantId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getTenantShortDetailsDataList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(tenantShortDetailsDataUrl, searchParams)
    );
  };

  const checkUserPermission = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(checkUsersPermissionUrl, searchParams)
    );
  };

  const updateUserPermission = (dataToSend, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(usersPermissionUrl, searchParams),
      {
        method: "POST",
        data: dataToSend,
      }
    );
  };

  const getAllowedWarehouseByTransactionKind = (transactionKind) => {
    return makeRequest(axiosPrivate, activeWarehouseForTranscationUrl, {
      method: "POST",
      data: transactionKind,
    });
  };

  const getTransactionWarehousePermissionList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        transactionsWarehousePermissionUrl,
        searchParams
      )
    );
  };

  const getOtherWarehousePermissionList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(otherWarehousePermissionUrl, searchParams)
    );
  };

  const getNonWarehousePermissionList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(nonWarehousePermissionUrl, searchParams)
    );
  };

  const removeUserSubcontractor = (userSubId) => {
    return makeRequest(axiosPrivate, userSubcontractorDetailsUrl(userSubId), {
      method: "PATCH",
      data: { ussu_active: false },
    });
  };

  const createUserSubcontractor = (data) => {
    return makeRequest(axiosPrivate, userSubcontractorUrl, {
      method: "POST",
      data: data,
    });
  };

  const createUserOwnwer = (data) => {
    return makeRequest(axiosPrivate, userOwnerUrl, {
      method: "POST",
      data: data,
    });
  };

  const removeUserOwner = (userOwnId) => {
    return makeRequest(axiosPrivate, userOwnerDetailsUrl(userOwnId), {
      method: "PATCH",
      data: { usow_active: false },
    });
  };

  const getIbanShortData = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ibanShortDataUrl, searchParams)
    );
  };

  const removeIbanOwner = (ibanId) => {
    return makeRequest(axiosPrivate, ibanDetailsUrl(ibanId), {
      method: "PATCH",
      data: { iban_active: false },
    });
  };

  const getIbanDetails = (ibanId) => {
    if (ibanId) {
      return makeRequest(axiosPrivate, ibanDetailsUrl(ibanId));
    }
    return Promise.resolve({});
  };

  const createIban = (ibanData) => {
    return makeRequest(axiosPrivate, ibanUrl, {
      method: "POST",
      data: ibanData,
    });
  };

  const updateIban = (ibanId, ibanData) => {
    return makeRequest(axiosPrivate, ibanDetailsUrl(ibanId), {
      method: "PATCH",
      data: ibanData,
    });
  };

  const removeOwnerBookk = (ownBookkId) => {
    return makeRequest(axiosPrivate, ownerBookDetailsUrl(ownBookkId), {
      method: "PATCH",
      data: { ownboo_active: false },
    });
  };

  const getOwnerBookkDetails = (ownBookkId) => {
    if (ownBookkId) {
      return makeRequest(axiosPrivate, ownerBookDetailsUrl(ownBookkId));
    }
    return Promise.resolve({});
  };

  const createOwnerBookk = (ownBookkData) => {
    return makeRequest(axiosPrivate, ownerBookUrl, {
      method: "POST",
      data: ownBookkData,
    });
  };

  const updateOwnerBookk = (ownBookkId, ownBookkData) => {
    return makeRequest(axiosPrivate, ownerBookDetailsUrl(ownBookkId), {
      method: "PATCH",
      data: ownBookkData,
    });
  };
  
  const getOwnerBookkFilteringData = () => {
    return makeRequest(axiosPrivate, ownBookkFilteringDataUrl);
  }

  return {
    getUserSelectList,
    getUserBehalfCreateTicketSelectList,
    getUserHasSkill,
    getUserDetails,
    getTenantDetails,
    getOwnerDetails,
    getUsersBySubcontractor,
    getSubconstractorsList,
    getSubcontractorDetails,
    getSubconstractorsFilteringData,
    createNewSubcontractor,
    updateSubcontractorDetails,
    getEmployeeSkill,
    getEmployeesSelectList,
    assignSkillsToUser,
    getTenantSettlementsData,
    getAllTenantSettlementsData,
    getOwners,
    updateOwnerDetails,
    createOwner,
    getOwnersFilteringData,
    removeBoardOwner,
    getBoardDetails,
    createBoard,
    updateBoard,
    getBoardFilteringData,
    getOwnerLogs,
    getUserDefaultLocation,
    getUserDefaultLocationForTicketOM,
    getTenantList,
    getTenantRRList,
    getTenantRRDetails,
    getUserEmailNotifications,
    getUserEmailNotificationsFilteringData,
    updateUserEmailNotifications,
    getTenantRRDetailsList,
    getTenantFilteringData,
    createTenant,
    updateTenant,
    getUserTenantList,
    getUserTenantDetails,
    getUserTenantFilteringData,
    getNewUserTenantInitialData,
    updateUserTenant,
    addUserTenant,
    getTenantShortDetailsDataList,
    getInternalTenantSettlementsData,
    checkUserPermission,
    updateUserPermission,
    getTransactionWarehousePermissionList,
    getOtherWarehousePermissionList,
    getNonWarehousePermissionList,
    removeUserSubcontractor,
    createUserSubcontractor,
    getTenantAddress,
    createUserOwnwer,
    removeUserOwner,
    getAllowedWarehouseByTransactionKind,
    getIbanShortData,
    getIbanDetails,
    removeIbanOwner,
    createIban,
    updateIban,
    removeOwnerBookk,
    getOwnerBookkDetails,
    createOwnerBookk,
    updateOwnerBookk,
    getOwnerBookkFilteringData
  };
}
