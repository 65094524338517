import React, { useCallback, useMemo } from "react";
import AddButton from "../../button/AddButton";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import BaseTable from "../../base/BaseTable/BaseTable";
import { getParamsTableFieldConfigByItemType } from "./TableParamsConfig";

const PartParamsTable = React.memo(
  ({
    partsParamsData,
    readOnly,
    onHandleAddNewParameter,
    showParametersContextMenu,
    parametersContextMenuActions,
    itemType,
  }) => {
    const getAddParameterButton = useCallback(() => {
      if (!readOnly) {
        return <AddButton onClick={onHandleAddNewParameter} />;
      }
    }, [readOnly, onHandleAddNewParameter]);

    const tableConfig = useMemo(
      () => getParamsTableFieldConfigByItemType(itemType),
      [itemType]
    );

    return (
      <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
        {getAddParameterButton()}
        <BaseTable
          showContextMenu={showParametersContextMenu}
          contextMenuActions={parametersContextMenuActions}
          showNoRecordsPaper={false}
          headers={TableService.getHeaderLables(tableConfig)}
          rows={TableService.getPreparedDataForBaseTable(
            tableConfig,
            partsParamsData
          )}
        />
      </div>
    );
  }
);

PartParamsTable.propTypes = {
  partsParamsData: PropTypes.array,
  readOnly: PropTypes.bool,
  onHandleAddNewParameter: PropTypes.func,
};

PartParamsTable.defaultProps = {
  readOnly: true,
  itemType: "part",
};

export default PartParamsTable;
