import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";

function IconButtonWithTooltip(props) {
  return (
    <Tooltip title={props.title}>
      <IconButton style={{ fontSize: props.fontSize }} onClick={props.onClick}>
        {props.children}
      </IconButton>
    </Tooltip>
  );
}

IconButtonWithTooltip.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  fontSize: PropTypes.string,
};

export default IconButtonWithTooltip;
