import { useAsync, useAsyncFn } from "./useAsync";
import { useState, useEffect } from "react";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/auth";
import { getRandomString } from "../helpers/methods";
import useDocsService from "../services/docsService";

const useDocsData = (itemId, itemType = "agreement") => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [localDocs, setLocalDocs] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [isSavedCorrectly, setIsSaveCorrectly] = useState(false);
  const [purchaseUploadedFile, setPurchaseUploadedFile] = useState();

  const [searchParams, setSearchParams] = useState({});

  const {
    getDocsForItemData,
    saveManyDocs,
    updateDocsData,
    handleDownloadDoc,
    handlePreviewDoc,
  } = useDocsService();

  const docsData = useAsync(
    () => getDocsForItemData(itemId, searchParams, itemType),
    [itemId, searchParams, itemType]
  );

  const saveDocsFn = useAsyncFn(saveManyDocs);

  const updateDocsFn = useAsyncFn(updateDocsData);

  useEffect(() => {
    if (!docsData.loading) {
      setLocalDocs(docsData.value);
    }
    setLoadingDocs(docsData.loading);
  }, [docsData.loading]);

  const prepareDocsToSend = (docs) => {
    if (itemType === "agreement") {
      docs["agreement"] = itemId;
    } else if (itemType === "post") {
      docs["post"] = itemId;
    } else if (itemType === "purchase") {
      docs["po"] = itemId;
    } else if (itemType === "equipment") {
      docs["equipment"] = itemId;
    } else if (itemType === "ico") {
      docs["ico"] = itemId;
    }

    return docs;
  };

  const setCommonDataBeforeSendFiles = (commonData) => {
    return prepareDocsToSend(commonData);
  };

  const prepareDocsToHide = (docsId) => {
    return {
      id: docsId,
      docs_active: false,
    };
  };

  const saveDocs = async (newDocs, commonData) => {
    setLoadingDocs(true);

    await saveDocsFn
      .execute(
        prepareDataToSend(newDocs),
        setCommonDataBeforeSendFiles(commonData),
        searchParams
      )
      .then((res) => {
        setLoadingDocs(false);
        setIsSaveCorrectly(true);
        if (itemType === "purchase") {
          setPurchaseUploadedFile(res);
        } else {
          setLocalDocs(res);
        }
      })
      .catch((err) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_docs_saving")
        );
        setLoadingDocs(false);
      });
  };

  const updateDocs = (docs) => {
    updateDocsFn
      .execute(docs.id, prepareDocsToSend(docs))
      .then((res) => {
        setIsSaveCorrectly(true);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.docs_updated")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_docs_updating")
        );
      });
  };

  const hideDocs = (docsId) => {
    updateDocsFn
      .execute(docsId, prepareDocsToHide(docsId))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.docs_deleted")
        );
        docsData.refetch();
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_docs_deleting")
        );
      });
  };

  const prepareDataToSend = (docs) => {
    for (let doc of docs) {
      doc["token_data"] = {
        file_ori_name: doc["docs_name"],
        unique_index: getRandomString(),
        content_type: doc["file"].type,
      };
    }
    return docs;
  };
  const onDownloadDoc = (docsId, docsName) => {
    handleDownloadDoc(docsId, docsName);
  };

  const onPreviewDoc = (docsId, index) => {
    if (docsId) {
      handlePreviewDoc(docsId);
    }
  };

  return {
    docs: localDocs,
    setDocs: setLocalDocs,
    isDocsLoading: loadingDocs,
    isSavedCorrectly,
    setIsSaveCorrectly,
    refetchDocs: docsData.refetch,
    updateDocs,
    saveDocs,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    purchaseUploadedFile,
  };
};

export default useDocsData;
