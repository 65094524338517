import { Button, Grid, Paper } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../field/AutocompleteField";
import {
  convertUndefinedValueToZero,
  isEmptyValue,
  roundPrice,
} from "../../../helpers/methods";
import PropTypes from "prop-types";
import useDialog from "../../../hooks/useDialog";
import PurchaseFvDialog from "../../dialog/PurchaseFvDialog/PurchaseFvDialog";
import useCheckPermission from "../../../hooks/usePermission";
import {
  CREATE_INVOCIE_PERMISSION,
  CREATE_PURCHASE_PERMISSION,
  MANAGE_PARTS_PERMISSION,
  MATERIAL_MAIN_BRANCH_NAME,
} from "../../../helpers/constants";
import { formatRackShellData } from "../../../page/StockWarehousePage/PageTablesConfig";
import CreateItemDialog from "../../dialog/CreateItemDialog";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import TransactionInMaterialRecord from "./TransactionInMaterialRecord";
import { useCallback, useEffect, useState } from "react";
import useItemService from "../../../services/itemService";

const TransactionInForm = (props) => {
  const { t } = useTranslation();
  const { getEmptyItemElement } = useItemService();
  const [hasPermissionToManage] = useCheckPermission(MANAGE_PARTS_PERMISSION);
  const [materials, setMaterials] = useState(() => {
    let tempMaterial = { item: getEmptyItemElement() };
    if (props.itemType === "equipment") {
      tempMaterial["transaction_amount"] = 1;
    }

    return [tempMaterial];
  });

  const [hasPermissionToManagePurchases] = useCheckPermission(
    CREATE_PURCHASE_PERMISSION
  );
  const [hasCreateInvoicePermission] = useCheckPermission(
    CREATE_INVOCIE_PERMISSION
  );

  const [openInvoiceDialog, handleOpenInvoiceDialog, handleCloseInvoiceDialog] =
    useDialog();

  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();

  const getObjectedLocationList = (list) => {
    let translatedList = [];
    if (list) {
      for (let permission of list) {
        translatedList.push({
          id: permission.id,
          warehouse_location: formatRackShellData(permission),
        });
      }
    }
    return translatedList;
  };

  const onChangeByIndex = useCallback((changeObj) => {
    setMaterials((prev) => {
      let tempState = [...prev];
      let material = { ...tempState[changeObj.index] };
      if (
        changeObj.name === "transaction_amount" ||
        changeObj.name === "transaction_price"
      ) {
        material[changeObj.name] = convertUndefinedValueToZero(changeObj.value);
        material["transaction_value"] = roundPrice(
          convertUndefinedValueToZero(material.transaction_amount) *
            convertUndefinedValueToZero(material.transaction_price)
        );
      } else {
        material[changeObj.name] = changeObj.value;
      }
      tempState[changeObj.index] = { ...material };
      return [...tempState];
    });
  }, []);

  const onCleanPartByIndex = useCallback((index) => {
    setMaterials((prev) => {
      let tempState = [...prev];
      let material = { ...tempState[index] };
      material["part"] = undefined;
      material["equipment"] = undefined;
      material["poli_unit"] = undefined;
      material["transaction_price"] = undefined;
      material["transaction_amount"] =
        props.itemType === "equipment" ? 1 : undefined;
      material["transaction_value"] = undefined;
      tempState[index] = { ...material };
      return [...tempState];
    });
  }, []);

  const onSetPartData = useCallback((partObject, index) => {
    setMaterials((prev) => {
      let tempState = [...prev];
      let material = { ...tempState[index] };
      material["part_unit"] = partObject["part_unit"];
      material["transaction_price"] = parseFloat(
        convertUndefinedValueToZero(partObject["part_price"])
      );
      material["transaction_amount"] = undefined;
      material["transaction_value"] = undefined;
      tempState[index] = { ...material };
      return [...tempState];
    });
  }, []);

  const onSetEquipmentData = useCallback((equipmentObject, index) => {
    setMaterials((prev) => {
      let tempState = [...prev];
      let equipment = { ...tempState[index] };
      equipment["transaction_price"] = parseFloat(
        convertUndefinedValueToZero(equipmentObject["equipment_price_net"])
      );
      equipment["transaction_amount"] = 1;
      equipment["transaction_value"] =
        equipment["transaction_price"] * equipment["transaction_amount"];
      tempState[index] = { ...equipment };
      return [...tempState];
    });
  }, []);

  const onAddMaterial = useCallback(() => {
    setMaterials((prev) => {
      let tempState = [...prev];
      let tempMaterial = { item: getEmptyItemElement() };
      if (props.itemType === "equipment") {
        tempMaterial["transaction_amount"] = 1;
      }
      tempState.push(tempMaterial);
      return [...tempState];
    });
  }, []);

  const onDeleteLocation = useCallback((index) => {
    setMaterials((prev) => {
      let tempState = [...prev];
      tempState.splice(index, 1);

      return [...tempState];
    });
  }, []);

  useEffect(() => {
    props.setFormValue((tempForm) => ({ ...tempForm, materials: materials }));
  }, [materials]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {hasPermissionToManage && (
          <>
            <Grid item xs={4} textAlign={{ xs: "center", sm: "right" }}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={
                  <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={onOpenCreateItemDialog}
              >
                {props.itemType === "equipment"
                  ? t("bar.warehouse_tool_bar.create_equipment")
                  : t("bar.warehouse_tool_bar.create_material")}
              </Button>
            </Grid>
            <Grid item xs={8} />
          </>
        )}
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
              rowSpacing={2}
            >
              <Grid item xs={4}>
                <AutocompleteField
                  name={`warehouse`}
                  label={t(`form.transaction_form.warehouse`)}
                  value={props.formValue[`warehouse`]}
                  options={props.allowedWarehouses?.value}
                  isObjectOption={true}
                  optionLabelKey={"warehouse_name"}
                  addNewValue={false}
                  onChange={props.onChangeAutocomplete}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  name={`warehouse_location`}
                  label={t(`form.transaction_form.warehouse_location`)}
                  value={props.formValue[`warehouse_location`]}
                  options={getObjectedLocationList(
                    props.warehouseLocations?.value
                  )}
                  isObjectOption={true}
                  optionLabelKey={"warehouse_location"}
                  addNewValue={false}
                  onChange={props.onChangeAutocomplete}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldFormControl
                  name="transaction_shipment_document"
                  value={props.formValue[`transaction_shipment_document`]}
                  label={t("form.transaction_form.shipment_document_number")}
                  onChange={props.onChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={2} />
        {materials?.map((material, index) => {
          return (
            <TransactionInMaterialRecord
              materialIndex={index}
              material={material}
              showItemRecord={
                isEmptyValue(props.formValue[`transaction_po`]) ||
                props.poPartList?.value?.length === 0
              }
              onChangeByIndex={onChangeByIndex}
              onCleanPart={onCleanPartByIndex}
              onSetPartData={onSetPartData}
              onSetEquipmentData={onSetEquipmentData}
              poPartList={props.poPartList}
              readOnly={props.readOnly}
              showButtonAddMaterial={index === 0}
              onAddMaterial={onAddMaterial}
              showButtonDeleteMaterial={index !== 0}
              onDeleteLocation={onDeleteLocation}
              itemType={props.itemType}
              showSearchItemButton={props.itemType !== "equipment"}
            />
          );
        })}
      </Grid>

      {openInvoiceDialog && (
        <PurchaseFvDialog
          open={openInvoiceDialog}
          onClose={handleCloseInvoiceDialog}
          onRefetchData={props.transactionSelectData.refetch}
        />
      )}

      {openCreateItemDialog && (
        <CreateItemDialog
          open={openCreateItemDialog}
          onClose={onCloseCreateItemDialog}
          itemType={props.itemType}
        />
      )}
    </>
  );
};

TransactionInForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  readOnly: PropTypes.bool,
  transactionSelectData: PropTypes.array,
  poPartList: PropTypes.array,
  onCreateNewPo: PropTypes.func,
};

TransactionInForm.defaultProps = {
  readOnly: false,
};

export default TransactionInForm;
