import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";

const PartParameterForm = (props) => {
  const { t } = useTranslation();

  const getParamFieldNameByItemType = () => {
    if (props.itemType === "equipment") {
      return "equparval_value";
    }
    return "parval_value";
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={
          props.readOnly ||
          props.readOnlyParameter ||
          !props.showAddParameterButton
            ? 12
            : 11
        }
      >
        <AutocompleteField
          name="parameter"
          label={t(`form.part_parameter_form.parameter`)}
          value={props.formValue.parameter}
          options={props.parametersList?.value}
          isObjectOption={true}
          optionLabelKey={"param_name"}
          onChange={props.onChangeParameter}
          disabled={props.readOnly || props.readOnlyParameter}
          required
        />
      </Grid>
      {!props.readOnly &&
        !props.readOnlyParameter &&
        props.showAddParameterButton && (
          <Grid item xs={1} style={{ paddingLeft: "0" }}>
            <IconButtonWithTooltip
              style={{ border: "3px solid var(--secondary)" }}
              title={t(`form.part_parameter_form.create_parameter`)}
              onClick={props.onOpenAddNewParameter}
            >
              <AddButton
                fontSize="medium"
                sx={{
                  color: "var(--secondary)",
                }}
              />
            </IconButtonWithTooltip>
          </Grid>
        )}

      <Grid item xs={12}>
        {props.formValue.param_is_text ? (
          <TextFieldFormControl
            name={getParamFieldNameByItemType()}
            label={t(`form.part_parameter_form.value`)}
            value={props.formValue?.[getParamFieldNameByItemType()]}
            onChange={props.onChange}
            disabled={props.readOnly}
            required
          />
        ) : (
          <FloatField
            name={getParamFieldNameByItemType()}
            label={t(`form.part_parameter_form.value`)}
            value={props.formValue?.[getParamFieldNameByItemType()]}
            onChange={props.onChange}
            disabled={props.readOnly}
            required
            decimalPlaces={4}
            suffix={props.selectedUnit}
          />
        )}
      </Grid>
    </Grid>
  );
};

PartParameterForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyParameter: PropTypes.bool,
  showAddParameterButton: PropTypes.bool,
  itemType: PropTypes.string,
};

PartParameterForm.defaultProps = {
  readOnly: false,
  readOnlyParameter: false,
  showAddParameterButton: false,
  itemType: "part",
};

export default PartParameterForm;
