import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import useItemCalculationService from "../../../services/itemCalculationService";
import SelectField from "../../field/SelectField";
import Tooltip from "@mui/material/Tooltip";


const ItemExtendForm = (props) => {

  const { t } = useTranslation();

  const {
    onChangeCalculatedFields,
    getVatRate
  } = useItemCalculationService()

  useEffect(() => {
    if (props.itemId === undefined) {
      let tempFormValue = props.formValue
      tempFormValue[`${props.itemType}_vat_proc`] = getVatRate()
      props.setFormValue({ ...tempFormValue })
    }
  }, [props.itemType, props.itemId])

  const getTranslatedPartList = () => {
    let translatedUnits = []
    for (let partUnit of props.partChoiceSelectData?.value.part_unit) {
      translatedUnits.push({ key: partUnit, name: t(`other.units.${partUnit}`) })
    }
    return translatedUnits
  }

  const onChangeCalculatedFieldsLocal = useCallback((e) => {
    onChangeCalculatedFields(e, props.itemType, props.formValue, props.setFormValue)
  }, [props.itemType, props.formValue, props.setFormValue]);

  const getFirstItemDataRow = () => {
    return (
      <>
        <Grid item xs={12} sm={3}>
          <TextFieldFormControl
            name={`${props.itemType}_name`}
            label={t(`form.item_form.name`)}
            value={props.formValue[`${props.itemType}_name`]}
            onChange={props.onChange}
            required
            disabled={props.readOnly}
            isInvalid={props.unfillRequiredFields.includes(`${props.itemType}_suplier`)}
            helperText={
              props.unfillRequiredFields.includes(`${props.itemType}_suplier`) &&
              t("field_required")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutocompleteField
            name={`${props.itemType}_suplier`}
            label={props.itemType === 'service' ? t("form.item_form.service_suplier") : t("form.item_form.part_producer")}
            value={props.formValue[`${props.itemType}_suplier`]}
            options={props.partChoiceSelectData?.value.part_supliers}
            isObjectOption={true}
            optionLabelKey={"subcontractor_short_name"}
            addNewValue={false}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            required
            disabled={props.readOnly}
            error={props.unfillRequiredFields.includes(`${props.itemType}_suplier`)}
            helperText={
              props.unfillRequiredFields.includes(`${props.itemType}_suplier`) &&
              t("field_required")
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutocompleteField
            name={`part_kind`}
            label={t(`form.item_form.part_kind`)}
            value={props.formValue[`part_kind`]}
            options={props.partChoiceSelectData?.value.part_kinds}
            isObjectOption={true}
            optionLabelKey={"partkind_name"}
            addNewValue={false}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            disabled={props.readOnly}

          />
        </Grid>
        <Tooltip title={<h2 >{t(`form.item_form.to_complete`)}</h2>} arrow>
          <Grid item xs={12} sm={3}>
            <AutocompleteField
              name={`account`}
              label={t(`form.item_form.account`)}
              value={props.formValue[`account`]}
              options={props.partChoiceSelectData?.value.accounts}
              isObjectOption={true}
              optionLabelKey={"account_name"}
              addNewValue={false}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              required
              disabled={props.readOnly}
              error={props.unfillRequiredFields.includes(`account`)}
              helperText={
                props.unfillRequiredFields.includes(`account`) &&
                t("field_required")
              }
            />
          </Grid>
        </Tooltip>
        <Tooltip title={<h2 >{t(`form.item_form.to_complete`)}</h2>} arrow>
          <Grid item xs={12} sm={3}>
            <AutocompleteField
              name={`gtu`}
              label={t(`form.item_form.gtu`)}
              value={props.formValue[`gtu`]}
              options={props.partChoiceSelectData?.value.gtus}
              isObjectOption={true}
              optionLabelKey={"gtu_name"}
              addNewValue={false}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              disabled={props.readOnly}
            />
          </Grid>
        </Tooltip>
      </>
    )
  }
  const getSecondItemDataRow = () => {
    return (
      <>
        <Grid item xs={12} sm={3}>
          <FloatField
            name={`${props.itemType}_price`}
            label={t(`form.item_form.unit_price`)}
            value={props.formValue[`${props.itemType}_price`]}
            onChange={onChangeCalculatedFieldsLocal}
            required
            suffix={"zł"}
            disabled={props.readOnly}
            isInvalid={props.unfillRequiredFields.includes(`${props.itemType}_price`)}
            helperText={
              props.unfillRequiredFields.includes(`${props.itemType}_price`) &&
              t("field_required")
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FloatField
            name={`${props.itemType}_margin_min_pln`}
            label={t(`form.item_form.margin_unit`)}
            value={props.formValue[`${props.itemType}_margin_min_pln`]}
            onChange={onChangeCalculatedFieldsLocal}
            suffix={"zł"}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FloatField
            name={`${props.itemType}_margin_min_proc`}
            label={t(`form.item_form.margin_unit`)}
            value={props.formValue[`${props.itemType}_margin_min_proc`]}
            onChange={onChangeCalculatedFieldsLocal}
            suffix={"%"}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={3}>

          <SelectField
            name={`${props.itemType}_unit`}
            label={t(`form.item_form.unit`)}
            value={props.formValue[`${props.itemType}_unit`]}
            options={getTranslatedPartList()}
            optionLabel={"name"}
            optionKey={"key"}
            addNewValue={false}
            onChange={props.onChange}
            required
            disabled={props.readOnly}
            error={props.unfillRequiredFields.includes(`${props.itemType}_unit`)}
            helperText={
              props.unfillRequiredFields.includes(`${props.itemType}_unit`) &&
              t("field_required")
            }
          />

        </Grid>
        <Grid item xs={12} sm={3}>
          <FloatField
            name={`${props.itemType}_vat_proc`}
            label={t(`form.item_form.vate_rate`)}
            value={props.formValue[`${props.itemType}_vat_proc`]}
            disabled={true}
          />
        </Grid>

      </>)
  }

  const getThirdItemDataRow = () => {
    return (
      <>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={`${props.itemType}_type`}
            label={t(`table.stock_warehouse_table.part_type`)}
            value={props.formValue[`${props.itemType}_type`]}
            onChange={props.onChange}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={"part_number"}
            label={t(`table.stock_warehouse_table.part_number`)}
            value={props.formValue[`part_number`]}
            onChange={props.onChange}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={"part_ean"}
            label={t(`table.stock_warehouse_table.ean`)}
            value={props.formValue[`part_ean`]}
            onChange={props.onChange}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={"part_mac_address"}
            label={t(`table.stock_warehouse_table.mac_address`)}
            value={props.formValue[`part_mac_address`]}
            onChange={props.onChange}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={`${props.itemType}_package`}
            label={t(`form.item_form.part_package`)}
            value={props.formValue[`${props.itemType}_package`]}
            onChange={props.onChange}
            disabled={props.readOnly}
          />
        </Grid>
        <Tooltip title={<h2 >{t(`form.item_form.carbon_tooltip`)}</h2>} arrow>
          <Grid item xs={12} sm={2}>
            <FloatField
              name={`${props.itemType}_carbon_footprint`}
              label={t(`form.item_form.part_carbon_footprint`)}
              value={props.formValue[`${props.itemType}_carbon_footprint`]}
              onChange={props.onChange}
              disabled={props.readOnly}
            />
          </Grid>
        </Tooltip>
        <Grid item xs={12} sm={3}>
          <FloatField
            name={`${props.itemType}_lead_time`}
            label={t(`form.item_form.part_lead_time`)}
            value={props.formValue[`${props.itemType}_lead_time`]}
            onChange={props.onChange}
            suffix={t(`form.item_form.days`)}
            disabled={props.readOnly}
          />
        </Grid>
      </>)
  }

 
  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Grid container columns={{ sm: 15, xs: 12 }} spacing={1}>
          {getFirstItemDataRow()}
          {getSecondItemDataRow()}
          {getThirdItemDataRow()}

          <Grid item xs={15} sm={7.5}>
            <TextFieldFormControl
              name={`${props.itemType}_desc`}
              label={t(`form.item_form.part_desc`)}
              value={props.formValue[`${props.itemType}_desc`]}
              onChange={props.onChange}
              disabled={props.readOnly}
              multiline
              rows={4}
              resize={"vertical"}
              inputProps={{ resize: "vertical" }}
            />
          </Grid>
          <Grid item xs={15} sm={7.5}>
            <TextFieldFormControl
              name={`${props.itemType}_note`}
              label={t(`form.item_form.part_note`)}
              value={props.formValue[`${props.itemType}_note`]}
              onChange={props.onChange}
              disabled={props.readOnly}
              multiline
              rows={4}
              resize={"vertical"}
              inputProps={{ resize: "vertical" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>


  );

};

ItemExtendForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subcontractorSelectList: PropTypes.array,
  itemType: PropTypes.oneOf(['service', 'part']),
  unfillRequiredFields: PropTypes.array
};

ItemExtendForm.defaultProps = {
  unfillRequiredFields: []

};

export default ItemExtendForm;
