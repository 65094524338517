import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import FloatField from "../../base/FloatField/FloatField";

import useItemService from "../../../services/itemService";
import useEquipmentService from "../../../services/equipmentService";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { getErrorMsg } from "../../../helpers/methods";

const EquipmentParametersDialog = (props) => {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const { getEquipmentParameterValues, updateEquipmentParameterValues } =
    useEquipmentService();
  const equipmentParametersValues = useAsync(() =>
    getEquipmentParameterValues(props.equipmentId)
  );
  const updateEquipmentParameterValuesFn = useAsyncFn(
    updateEquipmentParameterValues
  );

  const { getParametersListForMeters } = useItemService();
  const parameterList = useAsync(getParametersListForMeters);

  const [formValue, setFormValue] = useState();

  useEffect(() => {
    if (equipmentParametersValues.loading) return;

    setFormValue(equipmentParametersValues.value);
  }, [equipmentParametersValues.loading]);

  const handleChangeParameterValue = (parameterId, newValue) => {
    let changedParameter = formValue.find(
      (paramValue) => paramValue.parameter === parameterId
    );
    changedParameter.equparval_value = newValue;

    setFormValue((prev) =>
      prev.map((parameter) =>
        parameter.parameter === parameterId ? changedParameter : parameter
      )
    );
  };

  const handleSubmit = (dataToSend) => {
    updateEquipmentParameterValuesFn
      .execute(props.equipmentId, dataToSend)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_parameters_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid = formValue !== undefined;

  const generateParamtersFields = () => {
    return parameterList.value.map((parameter) => (
      <>
        <Grid item xs={6}>
          <FloatField
            label={parameter.param_name}
            onChange={(e) => {
              handleChangeParameterValue(parameter.id, e.target.value);
            }}
            value={
              formValue.find(
                (paramValue) => paramValue.parameter === parameter.id
              )?.equparval_value
            }
            decimalPlaces={4}
          />
        </Grid>
      </>
    ));
  };

  const isLoading =
    formValue === undefined ||
    parameterList.loading ||
    equipmentParametersValues.loading ||
    updateEquipmentParameterValuesFn.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        {generateParamtersFields()}
        <Grid item xs={12} marginTop={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={updateEquipmentParameterValuesFn.loading}
            disabled={!isFormValid}
            onClick={() => handleSubmit(formValue)}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={t("form.meter_passport.techincal_parameters")}
      maxWidth="sm"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentParametersDialog.propTypes = {
  equipmentId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
};

EquipmentParametersDialog.defaultProps = {
  open: false,
};

export default EquipmentParametersDialog;
