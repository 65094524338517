import { useState, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import { useAsync } from "../../../hooks/useAsync";

import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";

import EquipmentTable from "../../table/EquipmentTable/EquipmentTable";
import { EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_CONFIG } from "./TableConfig";

import EquipmentInstalationObjectSetDialog from "../EquipmentInstalationObjectSetDialog/EquipmentInstalationObjectSetDialog";

import useDialogWithId from "../../../hooks/useDialogWithId";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import MultipleLocalizationDialog from "../MutlipleLocalizationDialog";
import useDialog from "../../../hooks/useDialog";

const EquipmentInstalationObjectSetHistoryDialog = (props) => {
  const { t } = useTranslation();

  const [existChanges, setExistChanges] = useState(false);

  const [
    openEquipmentInstalationObjectSetDialog,
    equipmentInstalationObjectSetId,
    onOpenEquipmentInstalationObjectSetDialog,
    onCloseEquipmentInstalationObjectSetDialog,
  ] = useDialogWithId();

  const { getEquipmentInstalationObjectSetsForEquipmentData } =
    useEquipmentService();

  const equipmentInstalationObjectSetHistory = useAsync(
    () => getEquipmentInstalationObjectSetsForEquipmentData(props.equipmentId),
    []
  );

  const onSumbitCallbackEquipmentInstalationDialog = (existChanges) => {
    if (existChanges) {
      setExistChanges(true);
      equipmentInstalationObjectSetHistory.refetch();
    }
  };

  const handleClose = () => {
    if (existChanges && props.onSubmit) {
      props.onSubmit();
    }
    props.onClose();
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, objectSetId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current =
        equipmentInstalationObjectSetHistory.value.find(
          (objectSet) => objectSet.id === objectSetId
        ).location;
      onOpenMultipleLocalizationDialog();
    },
    [
      onOpenMultipleLocalizationDialog,
      equipmentInstalationObjectSetHistory.value,
    ]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const getDialogContent = () => {
    if (equipmentInstalationObjectSetHistory.loading)
      return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <EquipmentTable
            data={equipmentInstalationObjectSetHistory.value}
            showCheckbox={false}
            showCleanFilterIcon={false}
            countRecords={equipmentInstalationObjectSetHistory.value.length}
            style={{ maxHeight: "75vh" }}
            tableConfig={EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_CONFIG}
            hiddenColumns={[]}
            columnsOrders={[]}
            showExportToFileButton={false}
            withPagination={false}
            showFilters={false}
            onClickMultipleLocationAlert={handleOpenMultipleLocalizationDialog}
            onClickAssignData={onOpenEquipmentInstalationObjectSetDialog}
          />
        </Grid>

        {openEquipmentInstalationObjectSetDialog && (
          <EquipmentInstalationObjectSetDialog
            open={openEquipmentInstalationObjectSetDialog}
            onClose={onCloseEquipmentInstalationObjectSetDialog}
            equipmentInstalationObjectSetId={equipmentInstalationObjectSetId}
            onSubmitCallback={onSumbitCallbackEquipmentInstalationDialog}
          />
        )}
        {openMultipleLocalizationDialog &&
          clickedOrderMultipleLocalization.current && (
            <MultipleLocalizationDialog
              open={openMultipleLocalizationDialog}
              onClose={handleCloseMultipleLocalizationDialog}
              localizationData={clickedOrderMultipleLocalization.current}
              isLevelObjectData
              itemType={"scheme"}
            />
          )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={handleClose}
      titleAlign="center"
      title={t("dialog.equipment.equipment_instalation_object_set_history")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentInstalationObjectSetHistoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  equipmentId: PropTypes.string,
};

EquipmentInstalationObjectSetHistoryDialog.defaultProps = {};

export default EquipmentInstalationObjectSetHistoryDialog;
