import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

const IbanForm = ({
  index,
  iban,
  onEditIban,
  onRemoveIban,
  readOnly }
) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8} onClick={() => onEditIban(iban?.id)}>
        <TextFieldFormControl
          value={iban?.iban_nr}
          label={iban?.iban_bank}
          readOnly = {readOnly}
        />
      </Grid>

      {!readOnly &&
        <Grid item xs={0.5}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveIban(iban?.id, index)
            }}
            title={t("other.iban_form_box.remove_iban")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

IbanForm.propTypes = {
  iban: PropTypes.object,
  onBoardChange: PropTypes.func,
  onBoardRemove: PropTypes.func
};

IbanForm.defaultProps = {
};

export default IbanForm;
