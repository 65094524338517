import React from "react";
import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import TextFieldFormControl from "../../field/TextFieldFormControl";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";

const MeterDescriptionForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.comments")}
      style={{
        height: "100%",
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_note"}
            value={props.formValue.equipment_note}
            label={t("form.meter_passport.equipment_note")}
            readOnly={false}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_note_bms"}
            value={props.formValue.equipment_note_bms}
            label={t("form.meter_passport.equipment_note_bms")}
            readOnly={false}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_note_settlement"}
            value={props.formValue.equipment_note_settlement}
            label={t("form.meter_passport.equipment_note_settlement")}
            onChange={props.onChange}
            readOnly={false}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipinstal_desc"}
            value={props.formValue.equipinstal_desc}
            label={t("form.meter_passport.equipinstal_desc")}
            onChange={props.onChange}
            readOnly={false}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            multiline={true}
            rows={3}
          />
        </Grid>
      </Grid>
    </BoxWithLabel>
  );
};

MeterDescriptionForm.propTypes = {
  formValue: PropTypes.object,
};

MeterDescriptionForm.defaultProps = {};

export default MeterDescriptionForm;
