import { useTranslation } from "react-i18next";
import {
  offersDataUrl,
  offerFilteringDataUrl,
  offersByTicketDataUrl,
  offerDetailsDataUrl,
  offersAddFavorityUrl,
  offersDeleteFavorityUrl,
  serviceSelectListUrl,
  serviceExtendSelectListUrl,
  gtuSelectListUrl,
  servicesDataUrl,
  partExtendSelectListUrl,
  partsDataUrl,
  offerServicesOfferDataUrl,
  offerPartsOfferDataUrl,
  offerDrawerDetailsDataUrl,
  offersToJoinDataUrl,
  offerGenerateUrl,
  offersToRRAcceptDataUrl,
  offerSummaryCostsDataUrl,
  accountSelectListUrl,
  offerSettlementDataUrl,
  clausesSelectListUrl,
  offerClausesListUrl,
  myOffersDataUrl,
  myOffersByTicketDataUrl,
  myOfferFilteringDataUrl,
  myOfferDrawerDetailsDataUrl,
  myOfferSettlementDataUrl,
  myOfferDetailsDataUrl,
  myOfferMessageDataUrl,
  newOfferVersionUrl,
  copyOfferForUpdateUrl,
  partSelectListUrl,
  partDetailsData,
  partInfoSelectListUrl,
  offerDocumentUrl,
  grtReportUrl,
  partKindSelectListUrl,
  partFilteringDataUrl,
  partDetailsDataUrl,
  offerOWUUrl,
  copyOfferForInnerUrl,
  partItemBarnchUrl,
  serviceDetailsDataUrl,
  serviceFilteringUrl,
  offerLogsUrl,
  offerSettlementLogUrl,
  offerDistributionDataUrl,
  offerChartFilteringDataUrl,
  accountDetailsUrl,
  accountUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useFileService from "./fileService";
import { useSnackbarAlert } from "../context/snackbarAlert";

export default function useOfferService() {
  const {
    getBlobFromAzureByUrl,
    downloadFileByBlobUrl,
    downloadFile,
    uploadAttachments,
  } = useFileService();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const axiosPrivate = useAxiosPrivate();

  const getAllOffersList = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offersDataUrl, searchParams)
    );
  };

  const getMyOffersList = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(myOffersDataUrl, searchParams)
    );
  };

  const getOfferListByTicket = (ticketId, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offersByTicketDataUrl(ticketId), searchParams)
    );
  };

  const getMyOfferListByTicket = (ticketId, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(myOffersByTicketDataUrl(ticketId), searchParams)
    );
  };

  const getOfferListToJoin = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offersToJoinDataUrl, searchParams)
    );
  };

  const getOfferListToRRAccept = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offersToRRAcceptDataUrl, searchParams)
    );
  };

  const getOfferJoinPageDataByView = (isJoinView, searchParams = {}) => {
    if (isJoinView) {
      return getOfferListToJoin(searchParams);
    } else {
      return getAllOffersList(searchParams);
    }
  };

  const getOffersFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offerFilteringDataUrl, searchParams)
    );
  };

  const getMyOffersFilteringData = () => {
    return makeRequest(axiosPrivate, myOfferFilteringDataUrl);
  };

  const getOfferDrawerDetailsData = (offerId) => {
    return makeRequest(axiosPrivate, offerDrawerDetailsDataUrl(offerId));
  };

  const getMyOfferDetailsData = (offerId) => {
    return makeRequest(axiosPrivate, myOfferDrawerDetailsDataUrl(offerId));
  };

  const getOfferDetailsData = (offerId) => {
    return makeRequest(axiosPrivate, offerDetailsDataUrl(offerId));
  };

  const getOfferSummaryCostsData = (offerId) => {
    return makeRequest(axiosPrivate, offerSummaryCostsDataUrl(offerId));
  };

  const getOfferServicesData = (offerId) => {
    if (offerId) {
      return makeRequest(axiosPrivate, offerServicesOfferDataUrl(offerId));
    }
    return Promise.resolve([]);
  };

  const getOfferPartsData = (offerId) => {
    if (offerId) {
      return makeRequest(axiosPrivate, offerPartsOfferDataUrl(offerId));
    }
    return Promise.resolve([]);
  };

  const getOffeSettlementData = (offerId, searchParams = {}) => {
    if (offerId) {
      return makeRequest(
        axiosPrivate,
        prepareUrlWithQueryParams(offerSettlementDataUrl(offerId), searchParams)
      );
    }
    return Promise.resolve([]);
  };

  const getOMyffeSettlementData = (offerId) => {
    if (offerId) {
      return makeRequest(axiosPrivate, myOfferSettlementDataUrl(offerId));
    }
    return Promise.resolve([]);
  };

  const addOffersToFavority = (offers_ids) => {
    return makeRequest(axiosPrivate, offersAddFavorityUrl, {
      method: "POST",
      data: offers_ids,
    });
  };

  const deleteOffersFromFavority = (offers_ids) => {
    return makeRequest(axiosPrivate, offersDeleteFavorityUrl, {
      method: "POST",
      data: offers_ids,
    });
  };

  const createNewOffer = (offerData) => {
    return makeRequest(axiosPrivate, offersDataUrl, {
      method: "POST",
      data: offerData,
    });
  };

  const updateOfferData = (offerId, offerData) => {
    return makeRequest(axiosPrivate, offerDetailsDataUrl(offerId), {
      method: "PATCH",
      data: offerData,
    });
  };

  const updateMyOfferData = (offerId, offerData) => {
    return makeRequest(axiosPrivate, myOfferDetailsDataUrl(offerId), {
      method: "PATCH",
      data: offerData,
    });
  };

  const sendMessageToMyOffer = (offerId, message) => {
    return makeRequest(axiosPrivate, myOfferMessageDataUrl(offerId), {
      method: "POST",
      data: message,
    });
  };

  const getServicesSelectListData = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(serviceSelectListUrl, searchParams)
    );
  };

  const getExtendServicesSelectListData = () => {
    return makeRequest(axiosPrivate, serviceExtendSelectListUrl);
  };

  const getExtendPartsSelectListData = () => {
    return makeRequest(axiosPrivate, partExtendSelectListUrl);
  };
  const getPartsSelectListData = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(partSelectListUrl, searchParams)
    );
  };
  const getPartInfoSelectListData = () => {
    return makeRequest(axiosPrivate, partInfoSelectListUrl);
  };
  const getPartsListData = () => {
    return makeRequest(axiosPrivate, partsDataUrl);
  };

  const getPartDetailsData = (partId, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(partDetailsDataUrl(partId), searchParams)
    );
  };

  const getServiceInfoSelectListData = () => {
    return makeRequest(axiosPrivate, serviceSelectListUrl);
  };
  const getServiceDetailsData = (serviceId, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(serviceDetailsDataUrl(serviceId), searchParams)
    );
  };

  const getServiceFilteringData = () => {
    return makeRequest(axiosPrivate, serviceFilteringUrl);
  };

  const getAccountSelectList = () => {
    return makeRequest(axiosPrivate, accountSelectListUrl);
  };

  const getPartKindSelectList = () => {
    return makeRequest(axiosPrivate, partKindSelectListUrl);
  };
  const getGtuSelectList = () => {
    return makeRequest(axiosPrivate, gtuSelectListUrl);
  };

  const createNewService = (serviceData) => {
    return makeRequest(axiosPrivate, servicesDataUrl, {
      method: "POST",
      data: serviceData,
    });
  };

  const removeService = (serviceId) => {
    return makeRequest(axiosPrivate, serviceDetailsDataUrl(serviceId), {
      method: "DELETE",
    });
  };

  const createNewPart = async (dataToSend) => {
    const files = dataToSend.files;
    delete dataToSend.files;

    let results = await uploadAttachments(files, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];

    dataToSend.enclosures = uploadedData.filter(
      (file) => file.objectType === "enclosure"
    );
    dataToSend.certs = uploadedData.filter(
      (file) => file.objectType === "certs"
    );

    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, partsDataUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editPartData = (partId, partData) => {
    return makeRequest(axiosPrivate, partDetailsDataUrl(partId), {
      method: "PATCH",
      data: partData,
    });
  };

  const removePart = (partId) => {
    return makeRequest(axiosPrivate, partDetailsDataUrl(partId), {
      method: "DELETE",
    });
  };

  const getPartItemRecordById = (partId) => {
    return makeRequest(axiosPrivate, partItemBarnchUrl(partId));
  };
  const editServiceData = (serviceId, serviceData) => {
    return makeRequest(axiosPrivate, serviceDetailsDataUrl(serviceId), {
      method: "PATCH",
      data: serviceData,
    });
  };

  const genereteOffer = (offerId) => {
    return makeRequest(axiosPrivate, offerGenerateUrl(offerId));
  };

  const getCalusesList = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(clausesSelectListUrl, searchParams)
    );
  };

  const getOfferClausesList = (offerId) => {
    if (offerId) {
      return makeRequest(axiosPrivate, offerClausesListUrl(offerId));
    }
    return Promise.resolve([]);
  };

  const createNewOfferVersion = (offerId) => {
    return makeRequest(axiosPrivate, newOfferVersionUrl(offerId), {
      method: "POST",
    });
  };

  const createCopyOfferForUpdate = (offerId) => {
    return makeRequest(axiosPrivate, copyOfferForUpdateUrl(offerId), {
      method: "POST",
    });
  };

  const createInnerOffer = (offerId) => {
    return makeRequest(axiosPrivate, copyOfferForInnerUrl(offerId), {
      method: "POST",
    });
  };

  const getSasTokenToGetOfferDocumentFromAzure = (offerId) => {
    return makeRequest(axiosPrivate, offerDocumentUrl(offerId));
  };

  const getOfferPdfDocumentToPreview = async (offerId) => {
    let data = await getSasTokenToGetOfferDocumentFromAzure(offerId);
    let url = await getBlobFromAzureByUrl(
      data.download_link,
      data.content_type
    );
    return url;
  };

  const handleDownloadOfferDocument = async (offerId, offerName) => {
    let data = await getSasTokenToGetOfferDocumentFromAzure(offerId);
    let url = await getBlobFromAzureByUrl(
      data.download_link,
      data.content_type
    );
    downloadFileByBlobUrl(url, offerName);
  };

  const getGrtReport = (searchParams) => {
    return downloadFile(
      prepareUrlWithQueryParams(grtReportUrl, searchParams),
      "OBC_prace_dodatkowe_GRT.xlsx"
    );
  };

  const getPartFilteringData = () => {
    return makeRequest(axiosPrivate, partFilteringDataUrl);
  };

  const getOWUContent = () => {
    return makeRequest(axiosPrivate, offerOWUUrl);
  };
  
  const getOfferLogs = (offerId) => {
    return makeRequest(axiosPrivate, offerLogsUrl(offerId));
  };

  const getSettlementLogs = (offerId) => {
    return makeRequest(axiosPrivate, offerSettlementLogUrl(offerId));
  };

  const getOfferDistribution = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offerDistributionDataUrl, searchParams)
    );
  };

  const getOfferChartFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(offerChartFilteringDataUrl, searchParams)
    );
  };

  const createAccount = (accountData) => {
    return makeRequest(axiosPrivate, accountUrl, {
      method: "POST",
      data: accountData,
    });
  };

  
  const getAccountDetails = (accountId) => {
    return makeRequest(axiosPrivate, accountDetailsUrl(accountId));
  };

  const updateAccount = (accountId, data) => {
    return makeRequest(axiosPrivate, accountDetailsUrl(accountId), {
      method: "PATCH",
      data: data,
    });
  };

  const removeAccount = (accountId) => {
    return makeRequest(axiosPrivate, accountDetailsUrl(accountId), {
      method: "PATCH",
      data: { account_active : false },
    });
  };

  return {
    getAllOffersList,
    getMyOffersList,
    getOfferListByTicket,
    getMyOfferListByTicket,
    getOffersFilteringData,
    getMyOffersFilteringData,
    getOfferDetailsData,
    getOfferSummaryCostsData,
    addOffersToFavority,
    deleteOffersFromFavority,
    createNewOffer,
    updateOfferData,
    updateMyOfferData,
    getServicesSelectListData,
    getExtendServicesSelectListData,
    getPartsListData,
    getPartInfoSelectListData,
    getPartDetailsData,
    getExtendPartsSelectListData,
    getPartsSelectListData,
    getAccountSelectList,
    getGtuSelectList,
    createNewService,
    createNewPart,
    getPartItemRecordById,
    getOfferServicesData,
    getOfferPartsData,
    getOfferDrawerDetailsData,
    getOfferListToJoin,
    getOfferJoinPageDataByView,
    genereteOffer,
    getOfferListToRRAccept,
    getOffeSettlementData,
    getCalusesList,
    getOfferClausesList,
    getMyOfferDetailsData,
    getOMyffeSettlementData,
    sendMessageToMyOffer,
    createNewOfferVersion,
    createCopyOfferForUpdate,
    getOfferPdfDocumentToPreview,
    getSasTokenToGetOfferDocumentFromAzure,
    handleDownloadOfferDocument,
    getGrtReport,
    getPartKindSelectList,
    getPartFilteringData,
    editPartData,
    getOWUContent,
    createInnerOffer,
    getOfferLogs,
    getSettlementLogs,
    getServiceDetailsData,
    getServiceInfoSelectListData,
    editServiceData,
    getServiceFilteringData,
    removeService,
    removePart,
    getOfferDistribution,
    getOfferChartFilteringData,
    getAccountDetails,
    createAccount,
    updateAccount,
    removeAccount
  };
}
