import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";


export const INTEREST_TABLE_NAME  = "interest_table";
export const INTEREST_TABLE_CONFIG = [
  {
    name: "interest_name",
    getValueCallback: (rowData) => rowData.interest_name,
    label: i18n.t("table.interest_table.interest_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "interest_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.interest_active ),
    label: i18n.t("table.interest_table.interest_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.interest_table.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },

  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.interest_table.creator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },

    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
