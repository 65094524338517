import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import useWarehouseService from "../../../../services/warehouseService";
import { useAsync } from "../../../../hooks/useAsync";
import { getTableConfigByItemType } from "./TableConfig";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import TransactionTable from "../../../table/TransactionTable";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import TransactionDialog from "../../../dialog/TransactionDialog";
import { TRANSACTION_KIND_DELETE } from "../../../../helpers/constants";
import TransactionRemoveForm from "../../../form/TransactionRemoveForm";
import { transactionRemoveExportDataUrl } from "../../../../helpers/apiUrls";
import { isEmptyObject } from "../../../../helpers/methods";

const WarehouseTransactionRemoveTab = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => {
    return getTableConfigByItemType(props.itemType);
  }, [props.itemType]);

  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);

  const {
    page: transactionRemovePage,
    pageSize: transactionRemovePageSize,
    handleChangePageWithSearchParams: handleChangeTransactionRemoveDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getTransactionRemoveData } = useWarehouseService();

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (props.itemType === "equipment") {
      newSearchParams.set('equipment__isnull', false);
      setSearchParams(newSearchParams)
    } else {
      newSearchParams.set('part__isnull', false);
    }
    setSearchParams(newSearchParams)
  }, [searchParams, props.itemType]);


  const transactionOutList = useAsync(() => {
    const urlParams = new URLSearchParams(searchParams);
    const params = Object.fromEntries(urlParams.entries());
    if (!isEmptyObject(params)) {
      return getTransactionRemoveData(searchParams);
    }
    return Promise.resolve([])
  }, [searchParams]);

  useImperativeHandle(ref, () => ({
    onRefetchData() {
      transactionOutList.refetch();
    },
  }));

  return (
    <Grid container spacing={1}>
      {hasPermissionToEdit && (
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={props.onOpenTransactionDialog}
          >
            {t("tabs.warehouse_transaction_tabs.new_transaction_remove")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <TransactionTable
          transactionFilteringData={props.transactionFilteringData}
          tableConfig={tableConfig}
          transactionData={transactionOutList}
          page={transactionRemovePage}
          pageSize={transactionRemovePageSize}
          handleChangePageWithSearchParams={
            handleChangeTransactionRemoveDataPage
          }
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          exportToFileUrl={transactionRemoveExportDataUrl}
          exportToFileSearchParams={searchParams}
        />
      </Grid>
      {props.openTransactionDialog && (
        <TransactionDialog
          open={props.openTransactionDialog}
          title={t("tabs.warehouse_transaction_tabs.new_transaction_remove")}
          onClose={props.onCloseTransactionDialog}
          transactionKind={TRANSACTION_KIND_DELETE}
          extraRequiredFields={["transaction_reason"]}
          onSubmit={props.onSubmit}
          isDataSaving={props.isSaving}
          transactionForm={<TransactionRemoveForm itemType={props.itemType} />}
          itemType={props.itemType}
        />
      )}
    </Grid>
  );
});

WarehouseTransactionRemoveTab.propTypes = {
  readOnly: PropTypes.bool,
  itemType: PropTypes.string,
};

WarehouseTransactionRemoveTab.defaultProps = {
  itemType: "part",
};

export default WarehouseTransactionRemoveTab;
