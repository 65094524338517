import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import {
  DOCS_RESTRICTED_OBJECT,
  DOCS_RESTRICTED_OWNER,
  DOCS_RESTRICTED_ROLE,
  DOCS_RESTRICTED_TENANT,
  DOCS_RESTRICTED_USER,
  MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES,
  MAX_FILENAME_LENGTH_CHARS,
} from "../../../helpers/constants";
import useFileService from "../../../services/fileService";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { isEmptyValue, isEmptyArray } from "../../../helpers/methods";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import AddFileForm from "../../form/AddFileForm/AddFileForm";
import LocationRecords from "../../other/LocationRecords";
import useLocationService from "../../../services/locationService";
import useDocsService from "../../../services/docsService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";

import { getRandomString } from "../../../helpers/methods";

import BoxWithLabel from "../../box/BoxWithLabel/BoxWithLabel";
import DocsRestrictedCardTypes from "../../other/DocsRestrictedCardTypes";

const DOCS_RESTRICTED_FIELDS = [
  DOCS_RESTRICTED_USER,
  DOCS_RESTRICTED_TENANT,
  DOCS_RESTRICTED_OWNER,
  DOCS_RESTRICTED_ROLE,
  DOCS_RESTRICTED_OBJECT,
];

const DOCS_RESTRICTED_TYPES_CONFIG = [
  {
    id: DOCS_RESTRICTED_TENANT,
    label: i18n.t(`backend_choices_list.${DOCS_RESTRICTED_TENANT}`),
    icon: "Diversity3Icon",
  },

  {
    id: DOCS_RESTRICTED_OBJECT,
    label: i18n.t(`backend_choices_list.${DOCS_RESTRICTED_OBJECT}`),
    icon: "ApartmentIcon",
  },
  {
    id: DOCS_RESTRICTED_ROLE,
    label: i18n.t(`backend_choices_list.${DOCS_RESTRICTED_ROLE}`),
    icon: "EngineeringIcon",
  },
  {
    id: DOCS_RESTRICTED_OWNER,
    label: i18n.t(`backend_choices_list.${DOCS_RESTRICTED_OWNER}`),
    icon: "BusinessIcon",
  },
  {
    id: DOCS_RESTRICTED_USER,
    label: i18n.t(`backend_choices_list.${DOCS_RESTRICTED_USER}`),
    icon: "GroupsIcon",
  },
];

function AddEditDocsRestrictedDialog(props) {
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const isEditMode = props.docsId != null;

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const getDialogTitle = () => {
    if (isEditMode) {
      if (props.readOnly) {
        return t("dialog.add_new_docs_dialog.docs_details");
      }
      return t("dialog.add_new_docs_dialog.edit_docs");
    }
    return t("dialog.add_new_docs_dialog.add_docs");
  };

  const {
    showFileByBlobUrl,
    getEnclosureDataToPublishDocs,
    handlePreviewEnclosure,
  } = useFileService();

  const {
    getDocsRestrictedFilteringData,
    getDocsRestrictedDetailsData,
    saveManyDocs,
    createManyDocs,
    handlePreviewDoc,
    updateDocsData,
  } = useDocsService();

  const docsRestrictedFilteringData = useAsync(getDocsRestrictedFilteringData);
  const saveDocsFn = useAsyncFn(saveManyDocs);
  const createManyDocsFn = useAsyncFn(createManyDocs);
  const updateDocsRestrictedFn = useAsyncFn(updateDocsData);

  const docsObjectDetailsData = useAsync(() => {
    if (props.enclosureId)
      return getEnclosureDataToPublishDocs(props.enclosureId);

    if (props.docsId) return getDocsRestrictedDetailsData(props.docsId);

    return Promise.resolve({
      locations: undefined,
    });
  }, [props.docsId, props.enclosureId]);

  const getPreparedFormDataToSend = () => {
    let { docs_restricted_type, ...dataToSend } = { ...formValue };
    dataToSend.docs_restricted_object = prepareLocationDataToSend(locations);

    if (props.agreement) {
      dataToSend.agreement = props.agreement;
    } else if (props.post) {
      dataToSend.post = props.post;
    } else if (props.equipment) {
      dataToSend.equipment = props.equipment;
    } else if (props.po) {
      dataToSend.po = props.po;
    }else if (props.ico) {
      dataToSend.ico = props.ico;
    }

    if (props.enclosureId) {
      dataToSend.docs_name = docsObjectDetailsData.value.docs_name;
      dataToSend.content_type = docsObjectDetailsData.value.content_type;
    }

    return dataToSend;
  };

  const prepareFilesDataToSend = (docs) => {
    for (let doc of docs) {
      doc["token_data"] = {
        file_ori_name: doc["docs_name"],
        unique_index: getRandomString(),
        content_type: doc["file"].type,
      };
    }
    return docs;
  };

  const onSubmit = () => {
    if (props.filesOnMemory) {
      props.onSaveOnMemoryFile(
        prepareFilesDataToSend(selectedFiles),
        getPreparedFormDataToSend()
      );
      props.onClose();
    } else if (isEditMode) {
      updateDocsRestrictedFn
        .execute(props.docsId, getPreparedFormDataToSend())
        .then(() => {
          if (props.onSubmit) props.onSubmit();
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.docs_updated")
          );
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_docs_saving")
          );
        });
    } else {
      if (props.enclosureId) {
        createManyDocsFn
          .execute([getPreparedFormDataToSend()])
          .then(() => {
            if (props.onSubmit) props.onSubmit();
          })
          .catch((err) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_docs_saving")
            );
          });
      } else {
        saveDocsFn
          .execute(
            prepareFilesDataToSend(selectedFiles),
            getPreparedFormDataToSend()
          )
          .then(() => {
            if (props.onSubmit) props.onSubmit();
          })
          .catch((err) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_docs_saving")
            );
          });
      }
    }
  };

  const [locations, setLocations] = useState(undefined);

  const handleChangeDocsRestrictedType = (restrictedValue) => {
    if (!props.readOnly) {
      setFormValue((prev) => ({
        ...prev,
        docs_restricted_type: restrictedValue,
        docs_restricted_user: [],
        docs_restricted_tenant: [],
        docs_restricted_owner: [],
        docs_restricted_role: [],
      }));

      setLocations(prepareLocationDataFromBackendForLocationRecordsComponent());
    }
  };

  const {
    prepareLocationDataFromBackendForLocationRecordsComponent,
    prepareLocationDataToSend,
  } = useLocationService();

  const getInitialDocsRestrictedTypeValue = (formValue, locations) => {
    if (
      !isEmptyValue(locations) &&
      !isEmptyArray(
        prepareLocationDataToSend(
          prepareLocationDataFromBackendForLocationRecordsComponent(locations)
        )
      )
    )
      return DOCS_RESTRICTED_OBJECT;

    let fieldName = null;
    DOCS_RESTRICTED_FIELDS.forEach((field) => {
      if (
        !isEmptyValue(formValue?.[field]) &&
        !isEmptyArray(formValue?.[field])
      )
        fieldName = field;
    });

    return fieldName;
  };

  useEffect(() => {
    if (docsObjectDetailsData.loading) return;

    const {
      id,
      docs_restricted_object,
      docs_name,
      content_type,
      miniature_download_link,
      ...formValue
    } = docsObjectDetailsData.value;

    if (isEditMode || props.enclosureId) {
      setSelectedFiles([
        { docs_name, content_type, id, miniature_download_link },
      ]);
    }

    setFormValue({
      ...formValue,
      docs_restricted: props.docsRestricted,
      docs_restricted_type: getInitialDocsRestrictedTypeValue(
        formValue,
        docs_restricted_object
      ),
    });

    setLocations(
      prepareLocationDataFromBackendForLocationRecordsComponent(
        docs_restricted_object
      )
    );
  }, [props.docsId, docsObjectDetailsData.loading]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const setNotAddedNames = (namesArr) => {
    let formattedNames = "";
    for (let name of namesArr) {
      formattedNames += "\n" + name;
    }
    return (
      t(
        "snackbar_alert.max_allowed_file_size_or_name_is_exceeded_files_are_not_added"
      ) + formattedNames
    );
  };

  const onChooseFile = (e) => {
    let selectedFiles = e.target.files;
    let enclosures = [];
    let notAddedEnclosuresNames = [];
    if (selectedFiles) {
      for (let file of selectedFiles) {
        let enclosure = {
          file: file,
          docs_name: file.name,
          content_type: file.type,
        };

        if (
          file.size < MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES &&
          file.name.length < MAX_FILENAME_LENGTH_CHARS
        ) {
          enclosure.blob_url = window.URL.createObjectURL(
            new Blob([enclosure.file], { type: enclosure.file.type })
          );
          enclosures.push(enclosure);
        } else {
          notAddedEnclosuresNames.push(file.name);
        }
      }
      setSelectedFiles((enclosuresTemp) => {
        if (props.multiple) {
          enclosuresTemp = [...enclosuresTemp, ...enclosures];
        } else {
          enclosuresTemp = [...enclosures];
        }
        return enclosuresTemp;
      });
      if (notAddedEnclosuresNames.length > 0) {
        snackbarAlert.openWarningSnackbarAlert(
          setNotAddedNames(notAddedEnclosuresNames)
        );
      }
    }
  };

  const onPreviewDocs = useCallback(
    (itemId, index) => {
      if (itemId) {
        if (props.docsId) handlePreviewDoc(itemId);
        else if (props.enclosureId) handlePreviewEnclosure(itemId);
      } else {
        let enclosure = selectedFiles[index];
        showFileByBlobUrl(enclosure.blob_url);
      }
    },
    [selectedFiles]
  );

  const onDeleteSelectedDocs = (index) => {
    if (isEditMode) {
      updateDocsRestrictedFn
        .execute(props.docsId, { docs_active: false })
        .then(() => {
          if (props.onSubmit) props.onSubmit();
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.docs_updated")
          );
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_docs_saving")
          );
        });
    } else {
      setSelectedFiles((enclosuresTemp) => {
        enclosuresTemp = [...enclosuresTemp];
        enclosuresTemp.splice(index, 1);
        return enclosuresTemp;
      });
    }
  };

  const isFormValid = selectedFiles.length > 0;

  const isLoading = docsRestrictedFilteringData.loading || saveDocsFn.loading;

  return (
    <>
      <BasicDialog
        open={props.open}
        onClose={() => {
          props.onClose();
          setFormValue({});
        }}
        titleAlign="center"
        contentAlign="center"
        title={getDialogTitle()}
        maxWidth="md"
      >
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={1}
          >
            <Grid item xs={12}>
              <AddFileForm
                docs={selectedFiles}
                onChooseFile={onChooseFile}
                onDeleteEnclosure={onDeleteSelectedDocs}
                onPreview={onPreviewDocs}
                fileType={"docs"}
                multiple={props.multiple}
                showButtonWithFileRestriction={
                  !isEditMode &&
                  (props.multiple ? props.multiple : selectedFiles.length === 0)
                }
                readOnly={props.readOnly || props.enclosureId}
              />
            </Grid>
            {props.availableDocsRestrictedTypes.length > 0 && (
              <>
                <Grid item xs={12}>
                  <BoxWithLabel
                    border={"0px solid white"}
                    boxStyle={{ padding: 0, margin: -4 }}
                    label={t("table.docs_restricted.docs_restricted_type")}
                  >
                    <DocsRestrictedCardTypes
                      restrictedTypes={DOCS_RESTRICTED_TYPES_CONFIG.filter(
                        (config) =>
                          props.availableDocsRestrictedTypes.includes(config.id)
                      )}
                      onCardClick={handleChangeDocsRestrictedType}
                      selectedRestrictedTypeId={formValue?.docs_restricted_type}
                    />
                  </BoxWithLabel>
                </Grid>
                {formValue?.docs_restricted_type === DOCS_RESTRICTED_OBJECT && (
                  <Grid item xs={12}>
                    <LocationRecords
                      locations={locations}
                      onChangeLocations={setLocations}
                      readOnly={props.readOnly}
                    />
                  </Grid>
                )}
                {formValue?.docs_restricted_type === DOCS_RESTRICTED_USER && (
                  <Grid item xs={12}>
                    <AutocompleteField
                      name="docs_restricted_user"
                      multiple={true}
                      onChange={onChangeAutocompleteFieldWithObjectOptions}
                      options={
                        docsRestrictedFilteringData.value?.docs_restricted_user
                      }
                      label={t("table.docs_restricted.docs_restricted_user")}
                      isObjectOption
                      optionLabelKey={"full_name"}
                      value={formValue?.docs_restricted_user}
                      disabled={props.readOnly}
                    />
                  </Grid>
                )}
                {formValue?.docs_restricted_type === DOCS_RESTRICTED_TENANT && (
                  <Grid item xs={12}>
                    <AutocompleteField
                      name="docs_restricted_tenant"
                      multiple={true}
                      onChange={onChangeAutocompleteFieldWithObjectOptions}
                      options={
                        docsRestrictedFilteringData.value
                          ?.docs_restricted_tenant
                      }
                      label={t("table.docs_restricted.docs_restricted_tenant")}
                      isObjectOption
                      optionLabelKey={"tenant_short_name"}
                      value={formValue?.docs_restricted_tenant}
                      disabled={props.readOnly}
                    />
                  </Grid>
                )}
                {formValue?.docs_restricted_type === DOCS_RESTRICTED_OWNER && (
                  <Grid item xs={12}>
                    <AutocompleteField
                      name="docs_restricted_owner"
                      multiple={true}
                      onChange={onChangeAutocompleteFieldWithObjectOptions}
                      options={
                        docsRestrictedFilteringData.value?.docs_restricted_owner
                      }
                      label={t("table.docs_restricted.docs_restricted_owner")}
                      isObjectOption
                      optionLabelKey={"owner_short_name"}
                      value={formValue?.docs_restricted_owner}
                      disabled={props.readOnly}
                    />
                  </Grid>
                )}
                {formValue?.docs_restricted_type === DOCS_RESTRICTED_ROLE && (
                  <Grid item xs={12}>
                    <AutocompleteField
                      name="docs_restricted_role"
                      multiple={true}
                      onChange={onChangeAutocompleteFieldWithObjectOptions}
                      options={
                        docsRestrictedFilteringData.value?.docs_restricted_role
                      }
                      label={t("table.docs_restricted.docs_restricted_role")}
                      isObjectOption
                      optionLabelKey={"role_name"}
                      value={formValue?.docs_restricted_role}
                      disabled={props.readOnly}
                    />
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <TextFieldFormControl
                name="docs_note"
                label={t("table.docs_restricted.docs_note")}
                value={formValue?.docs_note}
                onChange={onChange}
                disabled={props.readOnly}
                multiline
                rows={2}
              />
            </Grid>
            {!props.readOnly && (
              <Grid item xs={12}>
                <LoadingButton
                  disabled={!isFormValid}
                  variant="contained"
                  onClick={onSubmit}
                  component="label"
                  loading={props.docsLoading}
                  fullWidth
                  startIcon={<FileUploadIcon />}
                >
                  {isEditMode
                    ? t("form.add_enclosure_form.update_docs")
                    : t("form.add_enclosure_form.add_docs")}
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </LoaderWrapper>
      </BasicDialog>
    </>
  );
}

AddEditDocsRestrictedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultEnclosureType: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  showExtendedAddEnclosureDialog: PropTypes.bool,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  docsLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  doscId: PropTypes.string,
  enclosureId: PropTypes.string,
  docsRestricted: PropTypes.bool,
  availableDocsRestrictedTypes: PropTypes.array,
  filesOnMemory: PropTypes.bool,
  readOnly: PropTypes.bool,
};
AddEditDocsRestrictedDialog.defaultProps = {
  open: false,
  docsLoading: false,
  docsRestricted: false,
  availableDocsRestrictedTypes: DOCS_RESTRICTED_FIELDS,
  filesOnMemory: false,
  readOnly: false,
};

export default AddEditDocsRestrictedDialog;
