import i18n from "../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import SelectFieldService from "../../services/selectFieldService";
import { Tooltip } from "@mui/material";

export const OBJECT_TABLE_LEVEL_1_NAME = "object_table_level_1";
export const OBJECT_TABLE_LEVEL_2_NAME = "object_table_level_2";
export const OBJECT_TABLE_LEVEL_3_NAME = "object_table_level_3";
export const OBJECT_TABLE_LEVEL_4_NAME = "object_table_level_4";
export const OBJECT_TABLE_LEVEL_5_NAME = "object_table_level_5";

export const OBJECT_TABLE_LEVEL_1_PREFIX = "object_level_1";
export const OBJECT_TABLE_LEVEL_2_PREFIX = "object_level_2";
export const OBJECT_TABLE_LEVEL_3_PREFIX = "object_level_3";
export const OBJECT_TABLE_LEVEL_4_PREFIX = "object_level_4";
export const OBJECT_TABLE_LEVEL_5_PREFIX = "object_level_5";

export const OBJECT_TABLE_LEVEL_1_CONFIG = [
  {
    name: "objects_level_1_code",
    getValueCallback: (rowData) => rowData.location.level_1?.object_code,
    label: i18n.t("table.object.level_1_object_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_code,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData) => rowData.location.level_1?.object_name,
    label: i18n.t("table.object.level_1_object_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
];

export const OBJECT_TABLE_LEVEL_2_CONFIG = [
  ...OBJECT_TABLE_LEVEL_1_CONFIG,
  {
    name: "objects_level_2_code",
    getValueCallback: (rowData) => rowData.location.level_2?.object_code,
    label: i18n.t("table.object.level_2_object_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_code,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) => rowData.location.level_2?.object_name,
    label: i18n.t("table.object.level_2_object_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
];

export const OBJECT_TABLE_LEVEL_3_CONFIG = [
  ...OBJECT_TABLE_LEVEL_2_CONFIG,

  {
    name: "objects_level_3_code",
    getValueCallback: (rowData) => rowData.location.level_3?.object_code,
    label: i18n.t("table.object.level_3_object_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_code,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) => rowData.location.level_3?.object_name,
    label: i18n.t("table.object.level_3_object_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
];

export const OBJECT_LOCATIONS_LEVEL_4_CONFIG = [
  ...OBJECT_TABLE_LEVEL_3_CONFIG,
  {
    name: "objects_level_4_code",
    getValueCallback: (rowData) => rowData.location.level_4?.object_code,
    label: i18n.t("table.object.level_4_object_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_code,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) => rowData.location.level_4?.object_name,
    label: i18n.t("table.object.level_4_object_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
];

export const OBJECT_TABLE_LEVEL_4_CONFIG = [
  ...OBJECT_LOCATIONS_LEVEL_4_CONFIG,
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.object.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "object_shared",
    getValueCallback: (rowData) => {
      return (<Tooltip title={<h2>{i18n.t("dialog.object.if_true_object_shows_all_om_if_tenant_is_selected_also_this_r_have_access")}</h2>} arrow>
        {SelectFieldService.getLabelOptionsTrueOrFalse(rowData.object_shared)}
      </Tooltip>);
    },
    label: i18n.t("table.object.object_shared"), 
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "object_public",
    getValueCallback: (rowData) => {
      return ( <Tooltip title={<h2>{i18n.t("dialog.object.if_true_object_shows_all_users_in_my_solvy")}</h2>} arrow>
        {SelectFieldService.getLabelOptionsTrueOrFalse(rowData.object_public)}
      </Tooltip>);
    },
    label: i18n.t("table.object.object_public"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

export const OBJECT_TABLE_LEVEL_5_CONFIG = [
  ...OBJECT_LOCATIONS_LEVEL_4_CONFIG,
  {
    name: "objects_level_5_code",
    getValueCallback: (rowData) => rowData.location.level_5?.object_code,
    label: i18n.t("table.object.level_5_object_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_code,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) => rowData.location.level_5?.object_name,
    label: i18n.t("table.object.level_5_object_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "object_category_code",
    getValueCallback: (rowData) => rowData.object_category?.objcat_code,
    label: i18n.t("table.object.object_category_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_category_code,
      isObjectOption: true,
      optionLabelKey: "objcat_code",
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "object_category_name",
    getValueCallback: (rowData) => rowData.object_category?.objcat_name,
    label: i18n.t("table.object.object_category_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_category_name,
      isObjectOption: true,
      optionLabelKey: "objcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "object_subcategory_code",
    getValueCallback: (rowData) => rowData.object_subcategory?.objcat_code,
    label: i18n.t("table.object.object_subcategory_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_subcategory_code,
      isObjectOption: true,
      optionLabelKey: "objcat_code",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "object_subcategory_name",
    getValueCallback: (rowData) => rowData.object_subcategory?.objcat_name,
    label: i18n.t("table.object.object_subcategory_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.object_subcategory_name,
      isObjectOption: true,
      optionLabelKey: "objcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.object.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "object_shared",
    getValueCallback: (rowData) => {
      return (<Tooltip title={<h2>{i18n.t("dialog.object.if_true_object_shows_all_om_if_tenant_is_selected_also_this_r_have_access")}</h2>} arrow>
        {SelectFieldService.getLabelOptionsTrueOrFalse(rowData.object_shared)}
      </Tooltip>);
    },
    label: i18n.t("table.object.object_shared"), 
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "object_public",
    getValueCallback: (rowData) => {
      return ( <Tooltip title={<h2>{i18n.t("dialog.object.if_true_object_shows_all_users_in_my_solvy")}</h2>} arrow>
        {SelectFieldService.getLabelOptionsTrueOrFalse(rowData.object_public)}
      </Tooltip>);
    },
    label: i18n.t("table.object.object_public"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "object_area",
    getValueCallback: (rowData) => rowData.object_area,
    label: i18n.t("table.object.object_area"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

const EDIT_FIELD_CONFIG = {
  name: "edit",
  getValueCallback: (rowData, onClickEdit) => (
    <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
  ),
  label: i18n.t("table.tenant_table.edit"),
  filterType: null,
  getFilterFieldConfigCallback: (filteringData) => undefined,
  allowChangeVisibility: false,
  excludedFromExport: true,
};

export const getObjectTablePropsByLevel = (level) => {
  switch (level) {
    case "1":
      return {
        tableName: OBJECT_TABLE_LEVEL_1_NAME,
        tableConfig: [...OBJECT_TABLE_LEVEL_1_CONFIG, EDIT_FIELD_CONFIG],
        tableFilterPrefix: OBJECT_TABLE_LEVEL_1_PREFIX,
      };
    case "2":
      return {
        tableName: OBJECT_TABLE_LEVEL_2_NAME,
        tableConfig: [...OBJECT_TABLE_LEVEL_2_CONFIG, EDIT_FIELD_CONFIG],
        tableFilterPrefix: OBJECT_TABLE_LEVEL_2_PREFIX,
      };
    case "3":
      return {
        tableName: OBJECT_TABLE_LEVEL_3_NAME,
        tableConfig: [...OBJECT_TABLE_LEVEL_3_CONFIG, EDIT_FIELD_CONFIG],
        tableFilterPrefix: OBJECT_TABLE_LEVEL_3_PREFIX,
      };
    case "4":
      return {
        tableName: OBJECT_TABLE_LEVEL_4_NAME,
        tableConfig: [...OBJECT_TABLE_LEVEL_4_CONFIG, EDIT_FIELD_CONFIG],
        tableFilterPrefix: OBJECT_TABLE_LEVEL_4_PREFIX,
      };
    case "5":
      return {
        tableName: OBJECT_TABLE_LEVEL_5_NAME,
        tableConfig: [...OBJECT_TABLE_LEVEL_5_CONFIG, EDIT_FIELD_CONFIG],
        tableFilterPrefix: OBJECT_TABLE_LEVEL_5_PREFIX,
      };

    default:
      return undefined;
  }
};
