import React from "react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import { InputAdornment } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {
  DEFAULT_TEXT_FIELD_MAX_LENGTH,
  DEFAULT_MULTILINE_TEXT_FIELD_MAX_LENGTH,
} from "../../../helpers/constants";

class TextFieldFormControl extends Component {
  getInputProps = () => {
    const maxLength = this.props.maxNumberOfCharacters
      ? this.props.maxNumberOfCharacters
      : this.props.multiline
      ? DEFAULT_MULTILINE_TEXT_FIELD_MAX_LENGTH
      : DEFAULT_TEXT_FIELD_MAX_LENGTH;

    const InputProps = {
      inputProps: { maxLength: maxLength, ...this.props.inputProps },
    };
    if (this.props.prefix) {
      InputProps["startAdornment"] = (
        <InputAdornment position="start" style={{ marginRight: 0 }}>
          {this.props.prefix}
        </InputAdornment>
      );
    }
    if (this.props.suffix) {
      InputProps["endAdornment"] = (
        <InputAdornment position="end">{this.props.suffix}</InputAdornment>
      );
    }
    if (this.props.centerText) {
      InputProps["centerAdornment"] = (
        <InputAdornment position="end">{this.props.suffix}</InputAdornment>
      );
    }
    if (this.props.centerText !== undefined) {
      InputProps.inputProps["textAlign"] = "center";
    }
    if (this.props.max !== undefined) {
      InputProps.inputProps["max"] = this.props.max;
    }
    if (this.props.min !== undefined) {
      InputProps.inputProps["min"] = this.props.min;
    }
    if (this.props.maxLength) {
      InputProps.inputProps["maxLength"] = this.props.maxLength;
    }
    if (this.props.minLength) {
      InputProps.inputProps["minLength"] = this.props.maxLength;
    }
    if (this.props.inputComponent) {
      InputProps.inputComponent = this.props.inputComponent;
    }
    if (this.props.inputComponent) {
      InputProps.inputProps["textAlign"] = this.props.textAlign;
    }
    if (this.props.inputStyle) {
      InputProps.inputProps["style"] = {
        ...this.props.inputStyle,
        resize: this.props.resize,
      };
      return InputProps;
    }
  };

  isShrink = () => {
    if (
      this.props.inputComponent &&
      !["", undefined, null].includes(this.props.value)
    ) {
      return true;
    }
    return undefined;
  };

  render() {
    return (
      <FormControl
        fullWidth={this.props.fullWidth}
        variant={this.props.variant}
      >
        <TextField
          inputRef={this.props.inputRef}
          style={this.props.style}
          sx={this.props.sx}
          type={this.props.type}
          size={"small"}
          id={this.props.id !== undefined ? this.props.id : this.props.name}
          label={
            this.props.label
              ? this.props.label.charAt(0).toUpperCase() +
                this.props.label.slice(1)
              : this.props.label
          }
          variant={this.props.variant}
          value={
            ![null, undefined].includes(this.props.value)
              ? this.props.value
              : ""
          }
          name={this.props.name}
          onChange={this.props.onChange}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled || this.props.readOnly}
          multiline={this.props.multiline}
          rows={this.props.rows}
          InputLabelProps={{
            ...this.props.InputLabelProps,
            shrink: this.isShrink(),
          }}
          InputProps={this.getInputProps()}
          required={this.props.required}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          autoFocus={this.props.autoFocus}
          onKeyDown={this.props.onKeyDown}
          error={this.props.isInvalid}
          placeholder={this.props.placeholder}
        />
        {this.props.helperText.length > 0 ? (
          <FormHelperText
            id="component-helper-text"
            error={this.props.isInvalid}
            style={this.props.helperTextStyle}
          >
            {this.props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

TextFieldFormControl.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  t: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  InputLabelProps: PropTypes.object,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  hidden: PropTypes.func,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  inputComponent: PropTypes.any,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  helperTextStyle: PropTypes.object,
  sx: PropTypes.object,
  inputRef: PropTypes.any,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  variant: PropTypes.string,
  inputStyle: PropTypes.object,
  maxNumberOfCharacters: PropTypes.number,
  resize: PropTypes.string,
};

TextFieldFormControl.defaultProps = {
  type: "text",
  disabled: false,
  multiline: false,
  rows: undefined,
  InputLabelProps: {},
  required: false,
  helperText: "",
  readOnly: false,
  isInvalid: false,
  helperTextStyle: {},
  fullWidth: true,
  variant: "outlined",
  inputStyle: {},
  inputProps: {},
};

export default withTranslation()(TextFieldFormControl);
