import { useTranslation } from "react-i18next";
import { useCallback,  useEffect,  useState } from "react";
import useOfferService from "../services/offerService";
import { useAsyncFn } from "./useAsync";
import { useSnackbarAlert } from "../context/snackbarAlert";
import useDialog from "./useDialog";
import useSettlementData from "./useSettlementData";
import { OFFER_ACCEPED, OFFER_ACCEPED_CR, OFFER_REJECTED, OFFER_REJECTED_CR } from "../helpers/constants";
import { isEmptyValue } from "../helpers/methods";



const useAcceptRejectOffers = (
    offerId,
    totalCostData,
    offersDataRefetch,
    onCloseOfferDocumentDialog,
    tableConfig,
    isOfferCR = false
) => {
    const snackbarAlert = useSnackbarAlert();
    const { t } = useTranslation();

    const [existsChanges, setExistsChanges] = useState(false);
    const [isPoRequired, setPoRequired] = useState(false)

    const [
        openRejectOfferDialog,
        onOpenRejectOfferDialog,
        handleCloseRejectOfferDialog,
    ] = useDialog();

    const [
        openSettlementOfferDialog,
        onOpenSettlementOfferDialog,
        handleCloseSettlementOfferDialog,
    ] = useDialog();

    const {
        updateMyOfferData,
        sendMessageToMyOffer, } =
        useOfferService();

    const {
        offerSettlementData,
        onChangeSettlementTableData,
        getSettlementsToSend,
    } = useSettlementData(
        offerId ? offerId : undefined,
        tableConfig,
        totalCostData ? totalCostData : undefined,
        true,
        "my-offers"
    );

    useEffect(() => {
        if(offerSettlementData && tableConfig){
          let tempOfferSettlementData = [...offerSettlementData];
          let poConfig = tableConfig.find((sc) =>sc.name ==="settlement_po")
          if( tempOfferSettlementData?.some((set) =>set.tenant_has_required_po===true)){
            poConfig["required"] = true
            setPoRequired(true)
          }else{
            poConfig["required"] = false
            setPoRequired(false)
          }
        }
        
      }, [offerSettlementData]);

    const updateOfferDataFn = useAsyncFn(updateMyOfferData);

    const sendMessageToMyOfferFn = useAsyncFn(sendMessageToMyOffer);


    const onSendMessageToOffer = useCallback(
        (formValue) => {
            sendMessageToMyOfferFn
                .execute(offerId, formValue)
                .then((res) => {
                    setExistsChanges(true)
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.message_sent")
                    );
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_message_sending")
                    );
                });
            onCloseOfferDocumentDialog();
            handleCloseRejectOfferDialog();
            handleCloseSettlementOfferDialog();
        },
        [offerId]
    );

    const handleSendAcceptOffer = useCallback(() => {
        onOfferUpdate(onPrepareDataToSave(isOfferCR ? OFFER_ACCEPED_CR :OFFER_ACCEPED));
    }, [offerId, offerSettlementData, isOfferCR]);

    const handleSendRejectOffer = useCallback(
        (data) => {
            onOfferUpdate(onPrepareDataToSave(isOfferCR ? OFFER_REJECTED_CR:OFFER_REJECTED, data));
        },
        [offerId, offerSettlementData, isOfferCR]
    );

    const onPrepareDataToSave = (action, data = {}) => {
        let dataToSend = {};
        dataToSend.offer_details = { ...data, id: offerId };
        dataToSend.action = action;
        dataToSend.settlements = getSettlementsToSend();
        return dataToSend;
    };

    const onOfferUpdate = (data) => {
        updateOfferDataFn
            .execute(offerId, data)
            .then((res) => {
                setExistsChanges(true)
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.offer_data_updated")
                );
                onCloseOfferDocumentDialog();
                handleCloseRejectOfferDialog();
                handleCloseSettlementOfferDialog();
                offersDataRefetch()
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                    t("snackbar_alert.occurred_error_during_updating_offer")
                );
            });
    };const regex = /\d/; 
    const isPoNumberNeeded = isPoRequired ?  !offerSettlementData?.some((s) => isEmptyValue(s.settlement_po ?s.settlement_po.trim(): undefined)) : true
    const isPoNumberIncludeDigit = offerSettlementData?.every((s) => (isEmptyValue(s.settlement_po) ? true : regex.test(s.settlement_po)))
    const isPoNumberValid =  isPoNumberNeeded && isPoNumberIncludeDigit
    return {
        onSendMessageToOffer,
        handleSendAcceptOffer,
        handleSendRejectOffer,
        openSettlementOfferDialog,
        onOpenSettlementOfferDialog,
        openRejectOfferDialog,
        onOpenRejectOfferDialog,
        handleCloseRejectOfferDialog,
        handleCloseSettlementOfferDialog,
        existsChanges, 
        offerSettlementData,
        onChangeSettlementTableData,
        offerDetailsLoading : updateOfferDataFn.loading,
        isPoNumberValid
    };
};

export default useAcceptRejectOffers;
