import { useState } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import BasicDialog from "../../base/BasicDialog";

import MeterPassportForm from "../../form/MeterPassportForm";

function MeterPassportDialog(props) {
  const { t } = useTranslation();

  const [existsChanges, setExistsChanges] = useState();

  return (
    <>
      <BasicDialog
        open={props.open}
        onClose={() => props.onClose(existsChanges)}
        titleAlign="center"
        title={t("headers.meter_passport")}
        maxWidth="xxl"
        showTopActionButton={false}
        showBottomActionButton={true}
        bottomActionStyle={{ padding: "10px" }}
        showCustomFooter={false}
        showDialogActions={false}
      >
        <MeterPassportForm
          equipmentId={props.equipmentId}
          setExistsChanges={setExistsChanges}
        />
      </BasicDialog>
    </>
  );
}

MeterPassportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipmentId: PropTypes.string,
};

MeterPassportDialog.defaultProps = {
  open: false,
};

export default MeterPassportDialog;
