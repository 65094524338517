import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import IcoProductsTab from "./IcoProductsTab";
import useIcoService from "../../../services/icoService";
import { useAsync } from "../../../hooks/useAsync";
import CurrencyTab from "./CurrencyTab";
import CurrencyValueTab from "./CurrencyValueTab";
import InterestTab from "./InterestTab/InterestTab";
import InterestRateTab from "./InterestRateTab/InterestRateTab";




const IcoAdminTabs = React.memo((props) => {
  const { t } = useTranslation();

  const { getIcoFilteringData } = useIcoService();


  const icoFilteringData = useAsync(getIcoFilteringData);


  return (
    <FullWidthTabs
    cleanFilters={true}
      items={[
        {
          label: t("tabs.ico_admin_tabs.finance_products"),
          content: (
            <IcoProductsTab
              filteringData = {icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.currencies"),
          content: (
            <CurrencyTab
              filteringData = {icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.currency_rates"),
          content: (
            <CurrencyValueTab
              filteringData = {icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.interests"),
          content: (
            <InterestTab
              filteringData = {icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.interest_rates"),
          content: (
            <InterestRateTab
              filteringData = {icoFilteringData}
            />
          ),
        },
      ]}
    />
  );
});

IcoAdminTabs.propTypes = {
  pageName: PropTypes.string,
};

IcoAdminTabs.defaultProps = {
};

export default IcoAdminTabs;
