import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";


export const ICO_PRODUCT_TABLE_NAME  = "ico_products_table";
export const ICO_PRODUCT_TABLE_CONFIG = [
  {
    name: "icoprod_name",
    getValueCallback: (rowData) => rowData.icoprod_name,
    label: i18n.t("table.ico_product_table.icoprod_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "icoprod_name_eng",
    getValueCallback: (rowData) => rowData.icoprod_name_eng,
    label: i18n.t("table.ico_product_table.icoprod_name_eng"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.ico_product_table.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" }, 
    }),
  },


  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.ico_product_table.creator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "icoprod_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.icoprod_active),
    label: i18n.t("table.ico_product_table.icoprod_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "icoprod_is_default",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.icoprod_is_default),
    label: i18n.t("table.ico_product_table.icoprod_is_default"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },

    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
