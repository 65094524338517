import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useIcoService from "../../../services/icoService";
import IcoProductDetailsForm from "../../form/IcoProductDetailsForm";


const IcoProductDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange
  } = useForm({ icoprod_active : true, icoprod_is_default  : false});


  const {
    getIcoProductDetails,
    createIcoProduct,
    updateIcoProduct
  } = useIcoService();


  const createIcoProductFn = useAsyncFn(createIcoProduct)
  const updateIcoProductFn = useAsyncFn(updateIcoProduct)

  const productDetailsData = useAsync(
    () => {
      if (props.icoProductId){
        return  getIcoProductDetails(props.icoProductId)
      }
      return Promise.resolve(formValue)}, 
    [props.icoProductId]
  );


  useEffect(() => {
    if (productDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(productDetailsData.value)) {
      setFormValue(productDetailsData.value);
    }
  }, [productDetailsData.loading]);



  const onSubmit = useCallback(() => {
    if (props.icoProductId) {
      updateIcoProductFn
        .execute(props.icoProductId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.ico_product_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_ico_product_updating")
          );
        });
    } else {
        createIcoProductFn 
          .execute(formValue)
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.ico_product_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_ico_product_creating")
            );
          });
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.icoprod_name) 


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.icoProductId ? t(`dialog.ico_product_dialog.product_details`) : t(`dialog.ico_product_dialog.add_product`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {productDetailsData.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <IcoProductDetailsForm
                product={formValue}
                onChange={onChange}
                icoProductId = {props.icoProductId}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={createIcoProductFn.loading || updateIcoProductFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.icoProductId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

IcoProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch : PropTypes.func,
  icoProductId : PropTypes.string
};

IcoProductDialog.defaultProps = {
  open: false,
};

export default IcoProductDialog
