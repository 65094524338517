import React from "react";
import PropTypes from "prop-types";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithLabel from "../../box/BoxWithLabel";

import useDialog from "../../../hooks/useDialog";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import EquipmentParametersDialog from "../../dialog/EquipmentParametersDialog/EquipmentParametersDialog";

import { useTranslation } from "react-i18next";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";

const MeterParametersForm = (props) => {
  const { t } = useTranslation();

  const [
    openParametersDialog,
    onOpenParametersDialog,
    onCloseParametersDialog,
  ] = useDialog();

  return (
    <BoxWithLabel label={t("form.meter_passport.techincal_parameters")}>
      <div style={{ display: "flex" }}>
        {props.parameterList.map((paramter) => (
          <TextFieldFormControl
            style={{ marginInline: "10px", ...BLACK_TEXT_FIELD_INPUT_STYLE }}
            value={
              props.parametersValues.find(
                (paramValue) => paramValue.parameter === paramter.id
              )?.equparval_value
            }
            label={paramter.param_name}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        ))}
        {props.showEditButton && (
          <IconButtonWithTooltip
            onClick={onOpenParametersDialog}
            title={t("edit")}
          >
            <ModeEditOutlineOutlined color="primary" fontSize="medium" />
          </IconButtonWithTooltip>
        )}
      </div>
      {openParametersDialog && (
        <EquipmentParametersDialog
          equipmentId={props.equipmentId}
          open={onOpenParametersDialog}
          onClose={onCloseParametersDialog}
          onSubmitCallback={props.onSubmit}
        />
      )}
    </BoxWithLabel>
  );
};

MeterParametersForm.propTypes = {
  parameterList: PropTypes.array,
  parametersValues: PropTypes.array,
  equipmentId: PropTypes.string,
  onSubmit: PropTypes.func,
  showEditButton: PropTypes.bool,
};

MeterParametersForm.defaultProps = {
  parameterList: [],
  parametersValues: [],
  showEditButton: false,
};

export default MeterParametersForm;
