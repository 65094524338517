import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import useItemCalculationService from "../../../services/itemCalculationService";
import SubcontractorDetailsDialog from "../../dialog/SubcontractorDetailsDialog/SubcontractorDetailsDialog";
import { EDIT_SUBCONTRACTORS_PERMISSION } from "../../../helpers/constants";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";
import useCheckPermission from "../../../hooks/usePermission";
import useDialog from "../../../hooks/useDialog";

const ItemEquipmentForm = (props) => {
  const { t } = useTranslation();

  const { getVatRate } = useItemCalculationService();

  const [hasPermissionToAddSubcontractor] = useCheckPermission(
    EDIT_SUBCONTRACTORS_PERMISSION
  );

  useEffect(() => {
    if (props.itemId === undefined) {
      let tempFormValue = props.formValue;
      tempFormValue[`${props.itemType}_vat_proc`] = getVatRate();
      props.setFormValue({ ...tempFormValue });
    }
  }, [props.itemType, props.itemId]);

  const [
    openSuboctractorDialog,
    onOpenSubcontractorDialog,
    onCloseSubcotractorDialog,
  ] = useDialog();

  const getFirstItemDataRow = () => {
    return (
      <>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={"equipment_nr"}
            value={props.formValue.equipment_nr}
            label={t("form.meter_passport.equipment_nr")}
            onChange={props.onChange}
            decimalPlaces={0}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("equipment_nr")
            }
            isInvalid={props.unfillRequiredFields.includes(`equipment_nr`)}
            helperText={
              props.unfillRequiredFields.includes(`equipment_nr`) &&
              t("field_required")
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldFormControl
            name={`${props.itemType}_ean`}
            label={t(`table.stock_warehouse_table.ean`)}
            value={props.formValue[`${props.itemType}_ean`]}
            onChange={props.onChange}
            disabled={
              props.readOnly ||
              props.readOnlyFields.includes(`${props.itemType}_ean`)
            }
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Grid container>
            <Grid
              item
              xs={hasPermissionToAddSubcontractor && !props.readOnly ? 11 : 12}
            >
              <AutocompleteField
                name={`supplier`}
                label={t("form.item_form.part_producer")}
                value={props.formValue[`supplier`]}
                options={props.filteringData?.value.supplier}
                isObjectOption={true}
                optionLabelKey={"subcontractor_short_name"}
                addNewValue={false}
                onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                disabled={
                  props.readOnly || props.readOnlyFields.includes(`supplier`)
                }
                error={props.unfillRequiredFields.includes(`supplier`)}
                helperText={
                  props.unfillRequiredFields.includes(`supplier`) &&
                  t("field_required")
                }
                required
              />
            </Grid>
            {hasPermissionToAddSubcontractor && !props.readOnly && (
              <Grid item xs={1} style={{ paddingLeft: "0" }}>
                <IconButtonWithTooltip
                  style={{ border: "3px solid var(--secondary)" }}
                  title={t(`form.guarantee.add_subcontractor`)}
                  onClick={onOpenSubcontractorDialog}
                >
                  <AddButton
                    fontSize="medium"
                    sx={{
                      color: "var(--secondary)",
                    }}
                  />
                </IconButtonWithTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <FloatField
            name={`${props.itemType}_price_net`}
            label={t(`form.item_form.unit_price`)}
            value={props.formValue[`${props.itemType}_price_net`]}
            onChange={props.onChange}
            required
            suffix={"zł"}
            disabled={
              props.readOnly ||
              props.readOnlyFields.includes(`${props.itemType}_price_net`)
            }
            isInvalid={props.unfillRequiredFields.includes(
              `${props.itemType}_price_net`
            )}
            helperText={
              props.unfillRequiredFields.includes(
                `${props.itemType}_price_net`
              ) && t("field_required")
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FloatField
            name={`${props.itemType}_vat_proc`}
            label={t(`form.item_form.vate_rate`)}
            value={props.formValue[`${props.itemType}_vat_proc`]}
            disabled={true}
          />
          {openSuboctractorDialog && (
            <SubcontractorDetailsDialog
              open={openSuboctractorDialog}
              onClose={onCloseSubcotractorDialog}
              readOnly={!hasPermissionToAddSubcontractor}
              onRefetchData={props.filteringData.refetch}
              deafultFormValue={{ subcontractor_is_producer: true }}
            />
          )}
        </Grid>
      </>
    );
  };
  const getSecondItemDataRow = () => {
    return (
      <>
        <Grid item xs={12} sm={2.5}>
          <DateField
            name={"equipment_date_production"}
            label={t("form.meter_passport.equipment_date_production")}
            value={props.formValue.equipment_date_production}
            onChange={props.onChangeDate}
            maxDate={new Date()}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_date_production")
            }
            view={"month"}
            required
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <DateField
            name={"equipment_date_legalization"}
            label={t("form.meter_passport.equipment_date_legalization")}
            value={props.formValue.equipment_date_legalization}
            onChange={props.onChangeDate}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_date_legalization")
            }
            required
            minDate={props.formValue.equipment_date_production}
            view={"month"}
          />
        </Grid>
        <Tooltip
          title={
            <h2 style={{ whiteSpace: "break-spaces" }}>
              {t(`form.meter_passport.equipment_media_type_tooltip`)}
            </h2>
          }
          arrow
          placement={"top"}
        >
          <Grid item xs={12} sm={2}>
            <AutocompleteField
              name={"equipment_media_type"}
              label={t("form.meter_passport.equipment_media_type")}
              value={props.formValue.equipment_media_type}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              options={props.filteringData?.value.equipment_media_type}
              readOnly={
                props.readOnly ||
                props.readOnlyFields.includes("equipment_media_type")
              }
              error={props.unfillRequiredFields.includes(
                `equipment_media_type`
              )}
              helperText={
                props.unfillRequiredFields.includes(`equipment_media_type`) &&
                t("field_required")
              }
              optionLabelKey={"equmedtyp_name"}
              multiple={false}
              isObjectOption={true}
              required
              disableClearable={true}
            />
          </Grid>
        </Tooltip>
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent={"flex-end"}>
          {getFirstItemDataRow()}
          {getSecondItemDataRow()}
        </Grid>
      </Grid>
    </Grid>
  );
};

ItemEquipmentForm.propTypes = {
  formValue: PropTypes.object,
  filteringData: PropTypes.object,
  unfillRequiredFields: PropTypes.array,
  readOnlyFields: PropTypes.array,
};

ItemEquipmentForm.defaultProps = {
  readOnlyFields: [],
  unfillRequiredFields: [],
  itemType: "equipment",
};

export default ItemEquipmentForm;
