import { Button, Grid } from "@mui/material";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import useCorrespondenceService from "../../services/correspondenceService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import { NAVIGATION_DRAWER_TYPE_CORRESPONDENCE } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CorrespondenceRelatedTablesTabs from "../../components/tabs/CorrespondenceRelatedTablesTabs/CorrespondenceRelatedTablesTabs";
import useCheckPermission from "../../hooks/usePermission";
import { EDIT_CORRESPONDENCE_PERMISSION } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../components/field/AutocompleteField";
import CorrespondencePostInTab from "../../components/tabs/CorrespondenceRelatedTablesTabs/CorrespondencePostInTab/CorrespondencePostInTab";
import { useSearchParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useState } from "react";

export default function PostAllocationPage(props) {
  const { t } = useTranslation();
  const { pageName } = props;
  const { getPostFilteringData, getPostSubcategoryList } = useCorrespondenceService();
  const [postSubcategorySelectList, setPostSubcategorySelectList] = useState([])
  const getPostSubcategoryListFn = useAsyncFn(getPostSubcategoryList)
  const postFilteringData = useAsync(getPostFilteringData);
  const [hasEditPermission] = useCheckPermission(EDIT_CORRESPONDENCE_PERMISSION)
  const [filterParams, setFilterParams] = useState(undefined);
  const {
    formValue,
    setFormValue,
  } = useForm([]);

  const handleAutocompleteChange = (e, value_object, value_key, state_value_name) => {
    setFormValue((formValue) => ({
      ...formValue,
      [state_value_name]: value_object[value_key],
      post_subcat: undefined,  
    }));

    if (value_object.id) {
     
      getPostSubcategoryListFn.execute({ post_category: value_object.id })
        .then((res) => setPostSubcategorySelectList(res));

      
      setFilterParams((prevParams) => ({
        ...prevParams, 
        post_subcat_category: value_object?.id ? [value_object.id] : [], 
        post_subcat: undefined,
      }));
    } else {
      
      setFilterParams((prevParams) => ({
        ...prevParams, 
        post_subcat_category: [],  
        post_subcat: value_object?.id || undefined,
      }));
      setPostSubcategorySelectList([]);
    }
  };

  
  const handleSubcategoryChange = (e, value_object) => {
    setFormValue((formValue) => ({
      ...formValue,
      post_subcat: value_object?.id || undefined,
    }));

    
    setFilterParams((prevParams) => ({
      ...prevParams, 
      post_subcat: value_object?.id ? [value_object.id] : [],  
    }));
  };
 
  const cleanParams = (params) => {
    const cleaned = Object.fromEntries(
      Object.entries(params || {}).filter(([_, value]) => 
        value !== undefined && (Array.isArray(value) ? value.length > 0 : true)
      )
    );
    return Object.keys(cleaned).length > 0 ? cleaned : undefined;
  };



  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_CORRESPONDENCE}
    >
      <DefaultPageWrapper titleKey={"post_allocation"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={2}
          >
            <Grid item xs={2}>
              <AutocompleteField
                name="post_subcat_category"
                label={t("table.correspondence.shipment_category")}
                value={formValue["post_subcat_category"]}
                options={postFilteringData.value?.post_subcat_category}
                isObjectOption={true}
                optionLabelKey={"post_cat_name"}
                addNewValue={false}
                onChange={handleAutocompleteChange}
                disabled={props.readOnly}
              />
            </Grid>
            <Grid item xs={2}>
              <AutocompleteField
                name="post_subcat"
                label={t("form.post_form.shipment_subcategory")}
                value={formValue["post_subcat"]}
                options={postSubcategorySelectList}
                isObjectOption={true}
                optionLabelKey={"post_subcat_name"}
                addNewValue={false}
                onChange={handleSubcategoryChange}
                disabled={props.readOnly}
              />
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={11}></Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <CorrespondencePostInTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData={postFilteringData}
              hasEditPermission={hasEditPermission}
              filterParams={cleanParams(filterParams)}

            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

