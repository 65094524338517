import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useIcoService from "../../../services/icoService";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";


const CurrencyDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange
  } = useForm({});


  const {
    getCurrencyDetails,
    createCurrency,
    updateCurrency
  } = useIcoService();


  const createCurrencyFn = useAsyncFn(createCurrency)
  const updateIcoProductFn = useAsyncFn(updateCurrency)

  const currencyData = useAsync(
    () => {
      if (props.currencyId) {
        return getCurrencyDetails(props.currencyId)
      }
      return Promise.resolve(formValue)
    },
    [props.currencyId]
  );

  const getPreparedData = (formValue) =>{
    return {
      currency_active : formValue?.currency_active,
      currency_name : formValue?.currency_name
    }
  }


  useEffect(() => {
    if (currencyData.loading) {
      return;
    }
    if (!isEmptyObject(currencyData.value)) {
      setFormValue(currencyData.value);
    }
  }, [currencyData.loading]);

  useEffect(() => {
 
    if (!isEmptyValue(formValue?.currency_name)) {
      setFormValue((prev) => ({...prev, currency_name : formValue.currency_name.toUpperCase()}));
    }
  }, [formValue]);



  const onSubmit = useCallback(() => {
    if (props.currencyId) {
      updateIcoProductFn
        .execute(props.currencyId, getPreparedData(formValue))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.currency_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_currency_updating")
          );
        });
    } else {
      createCurrencyFn
        .execute(getPreparedData(formValue))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.currency_created")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_currency_creating")
          );
        });
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.currency_name) && formValue.currency_name.length === 3


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.currencyId ? t(`dialog.ico_currency_dialog.currency_details`) : t(`dialog.ico_currency_dialog.add_currency`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {currencyData.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>

            <Grid item xs={12}>
              <TextFieldFormControl
                name="currency_name"
                label={t("dialog.ico_currency_dialog.currency_name")}
                value={formValue?.currency_name}
                maxNumberOfCharacters = {3}
                required
                onChange={onChange}
              />
            </Grid>
            {props.currencyId &&
              <Grid item xs={12}>
                <TrueFalseSelectField
                  name={"currency_active"}
                  label={t("dialog.ico_currency_dialog.currency_active")}
                  value={formValue?.currency_active}
                  onChange={onChange}
                  addEmptyOptions={false}
                  valuesAsBool
                />
              </Grid>}
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={createCurrencyFn.loading || updateIcoProductFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.currencyId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

CurrencyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
  currencyId: PropTypes.string
};

CurrencyDialog.defaultProps = {
  open: false,
};

export default CurrencyDialog 
