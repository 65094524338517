import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const AccountDetailsForm = ({
  account,
  onChange }
) => {

  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      <Grid item xs={12}>
        <TextFieldFormControl
          name="account_name"
          label={t("form.account_details_form.account_name")}
          value={account?.account_name}
          required
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="account_nr"
          label={t("form.account_details_form.account_nr")}
          value={account?.account_nr}
          required
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TrueFalseSelectField
          name={"account_for_ico"}
          label={t("form.account_details_form.account_for_ico")}
          value={account?.account_for_ico}
          onChange={onChange}
          addEmptyOptions={false}
          valuesAsBool
        />
      </Grid>
      <Grid item xs={12}>
        <TrueFalseSelectField
          name={"account_owner_default"}
          label={t("form.account_details_form.account_is_default")}
          value={account?.account_owner_default}
          onChange={onChange}
          addEmptyOptions={false}
          valuesAsBool
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="account_note"
          label={t("form.account_details_form.account_note")}
          value={account?.account_note}
          multiline
          rows={3}
          resize={"vertical"}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

AccountDetailsForm.propTypes = {
  account: PropTypes.object,
  onChange: PropTypes.func,
};

AccountDetailsForm.defaultProps = {
};

export default AccountDetailsForm;
