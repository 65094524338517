import BasicDialog from "../../base/BasicDialog";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useOfferService from "../../../services/offerService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import ItemForm from "../../form/ItemForm/ItemForm";
import useDialog from "../../../hooks/useDialog";
import SubcontractorDetailsDialog from "../SubcontractorDetailsDialog/SubcontractorDetailsDialog";
import ItemExtendForm from "../../form/ItemExtendForm/ItemExtendForm";
import useItemService from "../../../services/itemService";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import FileUploadList from "../../other/FileUploadList";
import BaseBox from "../../base/BaseBox/baseBox";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import {
  INTERNAL_TYPE,
  MATERIAL_MAIN_BRANCH_NAME,
  SERVICE_MAIN_BRANCH_NAME,
  DEVICE_MAIN_BRANCH_NAME,
  COUNTER_BRANCH_NAME,
} from "../../../helpers/constants";
import useLocationService from "../../../services/locationService";
import { useForm } from "../../../hooks/useForm";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useFileService from "../../../services/fileService";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";
import useItemFormValidation from "../../../hooks/useItemFormValidation";
import PartParameterDialog from "../PartParameterDialog";
import PartParamsTable from "../../table/PartParamsTable";
import { getErrorMsg } from "../../../helpers/methods";
import SelectItemDialog from "../SelectItemDialog";
import ItemEquipmentForm from "../../form/ItemEquipmentForm";
import useEquipmentService from "../../../services/equipmentService";

const CreateItemDialog = (props) => {
  const { t } = useTranslation();

  const {
    createNewService,
    createNewPart,
    getPartFilteringData,
    getServiceFilteringData,
  } = useOfferService();
  const { createNewEquipmentItem, getEquipmentForCreateMeterFilteringData } =
    useEquipmentService();

  const createNewServiceFn = useAsyncFn(createNewService);
  const createNewPartFn = useAsyncFn(createNewPart);
  const createEquipmentFn = useAsyncFn(createNewEquipmentItem);

  const filteringData = useAsync(() => {
    if (props.itemType === "service") {
      return getServiceFilteringData();
    }
    if (props.itemType === "equipment") {
      return getEquipmentForCreateMeterFilteringData();
    }
    return getPartFilteringData();
  }, [props.itemType]);

  const snackbarAlert = useSnackbarAlert();

  const {
    getEmptyItemElement,
    getItemRecordById,
    prepareItemDataFromBackendForItemRecordComponent,
  } = useItemService();

  const { getChosenHighestLevelObject } = useLocationService();

  const [items, setItems] = useState(getEmptyItemElement());
  const [parameters, setParameters] = useState([]);

  const getItemRecordByIdFn = useAsyncFn(getItemRecordById);

  const showLoading = filteringData.loading;

  const { downloadFileByBlobUrl } = useFileService();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});

  const {
    unfillRequiredFields,
    locationRecordsRef,
    checkIfRequiredFieldsAreFill,
  } = useItemFormValidation(props.itemType, formValue);

  const [
    enclosures,
    onAddEnclosure,
    onUpdateEnclosure,
    onDeleteEnclosure,
    prepareEnclosuresToSend,
  ] = useFileOnMemoryData();

  const [
    certificates,
    onAddCert,
    onUpdateCert,
    onDeleteCert,
    prepareCertsToSend,
  ] = useFileOnMemoryData("certs");

  const onDownloadEnclosure = (enclosureId, enclosureName, index) => {
    const enclosure = enclosures[index];
    downloadFileByBlobUrl(enclosure.blob_url, enclosureName);
  };

  const onDownloadCert = (certId, certName, index) => {
    const cert = certificates[index];
    downloadFileByBlobUrl(cert.blob_url, certName);
  };

  const [
    openAddPartParameterDialog,
    onOpenAddPartParameterDialog,
    onCloseAddPartParameterDialog,
  ] = useDialog();

  const [
    openSelectItemDialog,
    onOpenSelectItemDialog,
    onCloseSelectItemDialog,
  ] = useDialog();

  const onSubmit = (newItemData) => {
    if (props.itemType === "service") {
      createNewServiceFn
        .execute(newItemData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.service_created")
          );
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_service_data_creating")
          );
        });
    } else if (props.itemType === "equipment") {
      createEquipmentFn
        .execute(newItemData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.equipment_created")
          );
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_saving_changes")
          );
        });
    } else {
      createNewPartFn
        .execute(newItemData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.part_created")
          );
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_creating_new_part")
          );
        });
    }
  };

  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
  };

  const onAddNewParameter = useCallback(
    (parameter) => {
      let tempParameters = [...parameters];
      tempParameters.push(parameter);
      setParameters(tempParameters);
      onCloseAddPartParameterDialog();
    },
    [formValue, parameters]
  );

  const onPrepareData = () => {
    formValue["item"] = getChosenHighestLevelObject(items);
    if (props.itemType === "part" || props.itemType === "equipment") {
      formValue["files"] = prepareEnclosuresToSend(enclosures).concat(
        prepareCertsToSend(certificates)
      );
      formValue["parameters"] = parameters;
    }

    if (checkIfRequiredFieldsAreFill()) {
      onSubmit(formValue);
    }
  };

  const getItemRecord = useCallback(
    (selectedItemId) => {
      getItemRecordByIdFn
        .execute(selectedItemId)
        .then((result) => {
          setItems(
            prepareItemDataFromBackendForItemRecordComponent(result.item)
          );
          onCloseSelectItemDialog();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_get_item_data")
          );
        });
    },
    [items]
  );

  const itemProps = {
    unfillRequiredFields: unfillRequiredFields,
    itemType: props.itemType,
    readOnly: false,
    partChoiceSelectData: filteringData,
    filteringData: filteringData,
    formValue: formValue,
    setFormValue: setFormValue,
    onChange: onChange,
    onChangeDate: onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions:
      onChangeAutocompleteFieldWithObjectOptions,
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth={"xl"}
      title={t(`dialog.item_dialog.add_${props.itemType}`)}
    >
      <LoaderWrapper showLoader={showLoading}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {props.itemType === "part" ? (
              <ItemExtendForm {...itemProps} />
            ) : props.itemType === "equipment" ? (
              <ItemEquipmentForm {...itemProps} />
            ) : (
              <ItemForm {...itemProps} />
            )}
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t(`form.item_form.purchase_tree`)}
              component={"div"}
            >
              <ItemRecord
                ref={locationRecordsRef}
                itemLevels={items}
                onChangeItem={onChangeLevelItem}
                onClickSearchItem={onOpenSelectItemDialog}
                defaultFirstLevelItemName={
                  props.itemType === "part"
                    ? MATERIAL_MAIN_BRANCH_NAME
                    : props.itemType === "equipment"
                    ? DEVICE_MAIN_BRANCH_NAME
                    : SERVICE_MAIN_BRANCH_NAME
                }
                defaultSecondLevelItemName={
                  props.itemType === "equipment" ? COUNTER_BRANCH_NAME : null
                }
              />
            </BoxWithTypography>
          </Grid>
          {(props.itemType === "part" || props.itemType === "equipment") && (
            <>
              <Grid item xs={3}>
                <PartParamsTable
                  partsParamsData={parameters}
                  readOnly={false}
                  onHandleAddNewParameter={onOpenAddPartParameterDialog}
                  showParametersContextMenu={false}
                  itemType={props.itemType}
                />
              </Grid>
              <Grid item xs={4.5}>
                <BaseBox>
                  <FileUploadList
                    addEnclosureButtonProps={{ size: "mini" }}
                    defaultEnclosureType={INTERNAL_TYPE}
                    enclosures={enclosures}
                    onDownloadEnclosure={onDownloadEnclosure}
                    onAddEnclosure={onAddEnclosure}
                    onDeleteEnclosure={onDeleteEnclosure}
                    onUpdateEnclosure={onUpdateEnclosure}
                    showExtendedAddEnclosureDialog={true}
                    showSubcontractorSelect={false}
                    showEnclosureType={false}
                    showEnclosurePartKind={props.itemType === "part"}
                    allowCopyEnclosure={false}
                  />
                </BaseBox>
              </Grid>

              <Grid item xs={4.5}>
                <BaseBox>
                  <FileUploadList
                    addEnclosureButtonProps={{ size: "mini" }}
                    defaultEnclosureType={INTERNAL_TYPE}
                    enclosures={certificates}
                    onDownloadEnclosure={onDownloadCert}
                    onAddEnclosure={onAddCert}
                    onDeleteEnclosure={onDeleteCert}
                    onUpdateEnclosure={onUpdateCert}
                    fileType={"certs"}
                    showDetailsButton={false}
                  />
                </BaseBox>
              </Grid>
            </>
          )}
          <Grid item xs={15}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={props.isLoading}
              onClick={onPrepareData}
            >
              {t(`dialog.item_dialog.add_${props.itemType}`)}
            </LoadingButton>
          </Grid>
        </Grid>
        {openAddPartParameterDialog && (
          <PartParameterDialog
            open={openAddPartParameterDialog}
            onClose={onCloseAddPartParameterDialog}
            onAddParameter={onAddNewParameter}
            isLoading={false}
            itemType={props.itemType}
            restrictParamListForItem={props.restrictParamListForItem}
            itemId={getChosenHighestLevelObject(items)}
          />
        )}
        {openSelectItemDialog && (
          <SelectItemDialog
            open={openSelectItemDialog}
            onClose={onCloseSelectItemDialog}
            onSubmit={getItemRecord}
            itemType={props.itemType}
            treeIndex={props.treeIndex}
          />
        )}
      </LoaderWrapper>
    </BasicDialog>
  );
};

CreateItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  itemType: PropTypes.string,
  onRefetchData: PropTypes.bool,
  readOnly: PropTypes.bool,
  isButtonAddItemAvailable: PropTypes.bool,
  formType: PropTypes.string,
  showSearchItemButton: PropTypes.bool,
  restrictParamListForItem: PropTypes.bool,
  treeIndex: PropTypes.number,
};

CreateItemDialog.defaultProps = {
  itemType: "service",
  readOnly: true,
  isButtonAddItemAvailable: true,
  showSearchItemButton: true,
  restrictParamListForItem: true,
  treeIndex: 1,
};

export default CreateItemDialog;
