import i18n from "../i18n";

export const LANGUAGE_KEY_IN_LOCAL_STORAGE = "language";

export const CommunicationFiltersTypes = {
  logs: {
    key: "log",
    name: "logs",
    color: "secondary",
    filterParam: "exclude_logs",
    selectedDefault: false,
  },
  internal: {
    key: "internal",
    name: "internal",
    color: "alternate",
    filterParam: "exclude_internals",
    selectedDefault: true,
  },
  external: {
    key: "external",
    name: "external",
    color: "primary",
    filterParam: "exclude_externals",
    selectedDefault: true,
  },
};

export const INTERNAL_TYPE = "internal";
export const EXTERNAL_TYPE = "external";
export const LOG_TYPE = "log";

export const ORDERING_DIRECTION_ASC = "asc";
export const ORDERING_DIRECTION_DESC = "desc";
export const ERROR_TEXT_STYLE = {
  marginLeft: 0,
  marginRight: 0,
  fontWeight: "bold",
  color: "red",
  minWidth: "180px",
};

export const WARNIG_TEXT_STYLE = {
  marginLeft: 0,
  marginRight: 0,
  fontWeight: "bold",
  color: "orange",
  minWidth: "180px",
};

export const UNIQUE_ERROR_TEXT = {
  helperText: "field should be unique",
  helperTextStyle: ERROR_TEXT_STYLE,
};

export const EMPTY_WARNING_TEXT = {
  helperText: "field should be unique",
  helperTextStyle: WARNIG_TEXT_STYLE,
};

export const ERROR_BOX_STYLE = {
  borderRadius: "4px",
  outline: "2px solid red",
};

export const WARNING_BOX_STYLE = {
  borderRadius: "4px",
  outline: "2px solid orange",
};

export const PAGINATION_PAGE_SIZE = 10;
export const BIG_PAGINATION_PAGE_SIZE = 100;

export const PROC = "proc";
export const PLN = "pln";

export const SUP3 = <sup>3</sup>;
export const SUP2 = <sup>2</sup>;

export const FORMAT_NUMBER_SEPARATOR = " ";

export const IS_EDITED = "isEdited";

export const TEXT_FIELD_TYPE = "text";
export const NUMERIC_FIELD_TYPE = "numeric";
export const INTEGER_FIELD_TYPE = "integer";
export const CHECKBOX_FIELD_TYPE = "checkbox";
export const SELECT_FIELD_TYPE = "select";
export const AUTOCOMPLETE_FIELD_TYPE = "autocomplete";
export const BOOLEAN_FIELD_TYPE = "boolean";
export const DATE_FIELD_TYPE = "date";
export const DATETIME_TO_DATE_FIELD_TYPE = "datetime_to_date";
export const DATE_TO_DATETIME_FIELD_TYPE = "date_to_datetime";
export const DATE_RANGE_FIELD_TYPE = "date_range";
export const DATETIME_TO_DATE_RANGE_FIELD_TYPE = "datetime_to_date_range";
export const DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE =
  "datetime_to_date_range_start";
export const DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE =
  "datetime_to_date_range_end";
export const NUMERIC_RANGE_FIELD_TYPE = "numeric_range";
export const BUTTON_FIELD_TYPE = "button_type";
export const DATE_TIME_FIELD_TYPE = "datetime";
export const DATE_TIME_RANGE_FIELD_TYPE = "datetime_range";
export const TIME_FIELD_TYPE = "time";
export const TIME_RANGE_FIELD_TYPE = "time_range";

export const SELECTED_ROW_COLOR = "var(--selected-row)";
export const FAVORITE_ROW_COLOR = "var(--favorite)";
export const CLAIM_ROW_COLOR = "var(--red-light)";
export const CLOSED_ROW_COLOR = "var(--closed)";
export const OFFER_CR_ROW_COLOR = "var(--warning)";
export const IS_WITHOUT_RESPOSNE_COLOR = "var(--warning)";

export const FILTER_SEPARATOR = "^";

export const DETAILS_DRAWER_WIDTH = 360;

export const MINI_DETAILS_DRAWER_WIDTH = 75;

export const TICKET_ITEM_TYPE = "ticket";
export const TICKET_ORDER_ITEM_TYPE = "ticket_order";
export const ORDER_ITEM_TYPE = "order";

export const OFFER_CANCEL_ACTION = "cancel";
export const OFFER_JOIN_ACTION = "join";
export const OFFER_JOINED_ACTION = "joined";
export const OFFER_SEND_TO_RR = "send_rr";
export const OFFER_ACCEPED_BY_RR = "accept_rr";
export const OFFER_ACCEPED_INTERNAL = "accept_internal";
export const OFFER_SEND_TO_OM = "send_om";
export const OFFER_ACCEPED = "accept";
export const OFFER_REJECTED = "reject";
export const OFFER_CHANGE_REQUEST_ACTION = "change_request";
export const OFFER_REJECTED_CR = "reject_cr";
export const OFFER_ACCEPED_CR = "accept_cr";

export const ICO_CANCEL_ACTION = "cancel";
export const ICO_SEND_TO_ACCEPT_ACTION = "send_to_accept";
export const ICO_CONSOLIDATION_ACTION = "consolidation";
export const ICO_ACCEPT = "accept";
export const ICO_REJECT = "reject";
export const ICO_GENERATE_ACTION = "generate";
export const ICO_SIGN_ACTION = "sign";



export const PURCHASE_SEND_ACTION = "po_send";
export const PURCHASE_LINE_DELIVERED_STATUS = "Dostarczony";

export const TASK = "task";
export const ORDER = "order";

export const STATUS_KIND = {
  INITIAL: "initial",
  VERIFICATION: "verification",
  WAITING: "waiting",
  PENDING: "pending",
  CLOSE: "close",
  NORMAL: "normal",
  DURING: "during",
  UNFINISHED: "unfinished",
  ALLOCATED: "allocated",
  TO_ACCEPTANCE: "to_acceptance",
};

export const TIME_OPTIONS_HOURS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];

export const RCP_ACTIVITIES = {
  WORK: "work",
  VACATION: "vacation",
  DELEGATION: "delegation",
  SICK_LEAVE: "sick_leave",
};

export const RCP_SHIFTS = {
  DAY: "day",
  AFTERNOON: "afternoon",
  NIGHT: "night",
};

export const TICTIC_KIND_CHOICES = {
  ACTION: "action",
  VISIBILITY: "visibility",
  CLAIM: "claim",
};

export const DEFAULT_TEXT_FIELD_MAX_LENGTH = "256";
export const DEFAULT_MULTILINE_TEXT_FIELD_MAX_LENGTH = "1024";

export const AAD_MAIL_DOMAIN = "oliviacentre.com";
export const MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES = 52428800;
export const MAX_ALLOWED_FILE_SIZE_IN_MB =
  MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES / 1048576;

export const MAX_FILENAME_LENGTH_CHARS = 200;

export const TABLE_TEXT_FIELD_MAX_LENGTH = "64";

export const APP_STAGE = {
  LOCAL: "local",
  DEVEL: "devel",
  STAGE: "stage",
  PREPROD: "preprod",
  PROD: "prod",
};

export const APP_STAGE_BAR_HEIGHT = "22px";

export const DIALOG_EDIT_MODE = "edit_mode";
export const DIALOG_CREATE_MODE = "create_mode";
export const DIALOG_PREVIEW_MODE = "preview_mode";

export const MY_OLIVIA_LOGIN_SYSTEM = "my_olivia";
export const AAD_LOGIN_SYSTEM = "aad";

export const EDIT_PERMISSION = "edit";

export const TRANSACTION_KIND_IN = "trans_in";
export const TRANSACTION_KIND_OUT = "trans_out";
export const TRANSACTION_KIND_MOVE = "trans_move";
export const TRANSACTION_KIND_DELETE = "trans_delete";
export const RESERVATION_KIND = "reservation";

export const MANAGE_PARTS_PERMISSION = "manage_parts";
export const CREATE_PURCHASE_PERMISSION = "create_purchase";
export const CREATE_INVOCIE_PERMISSION = "create_invoice";
export const BUFFER_EDIT_PERMISSION = "buffer_edit";

export const MANAGE_TICKETS_PERMISSION = "edit_all_tickets";
export const MANAGE_ORDERS_PERMISSION = "edit_all_orders";
export const MANAGE_TASKS_PERMISSION = "edit_all_tasks";
export const MANAGE_PLANNER_PERMISSION = "manage_planner";
export const MANAGE_TEAMS_PERMISSION = "manage_teams";
export const EDIT_OWNERS_PERMISSION = "edit_owners";
export const EDIT_TENANTS_PERMISSION = "edit_tenants";
export const EDIT_AGREEMENTS_PERMISSION = "edit_agreements";
export const EDIT_SUBCONTRACTORS_PERMISSION = "edit_subcontractors";
export const EDIT_CORRESPONDENCE_PERMISSION = "edit_correspondence";
export const MANAGE_SERVICES_PERMISSION = "manage_services";
export const MANAGE_ITEMS_TREE_PERMISSION = "manage_items_tree";
export const EDIT_RR_PANEL = "edit_rr_panel";
export const MANAGE_DOCS_RESTRICTED = "manage_docs_restricted";

export const TICKET_CATEGORY_KINDS = {
  ADDITIONAL_WORK: "additional_work",
  SERVICE_REQEST: "service_request",
};

export const MATERIAL_MAIN_BRANCH_NAME = "MATERIAŁY";
export const SERVICE_MAIN_BRANCH_NAME = "USŁUGI";
export const DEVICE_MAIN_BRANCH_NAME = "URZĄDZENIA";
export const TOOL_MAIN_BRANCH_NAME = "NARZĘDZIA";

export const COUNTER_BRANCH_NAME = "Liczniki";

export const DOCS_RESTRICTED_USER = "docs_restricted_user";
export const DOCS_RESTRICTED_TENANT = "docs_restricted_tenant";
export const DOCS_RESTRICTED_OWNER = "docs_restricted_owner";
export const DOCS_RESTRICTED_ROLE = "docs_restricted_role";
export const DOCS_RESTRICTED_OBJECT = "docs_restricted_object";

export const PLANNER_ITEM_TASK = "task";
export const PLANNER_ITEM_OFF_WORK = "off_work";

export const ICO_AGREEMENT_CONTRACT = "contract";
export const ICO_AGREEMENT_APPENDIX = "appendix";
export const ICO_AGREEMENT_AGREEMENT = "agreement";
