import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import MaskedTextFieldForm from "../../base/MaskedTextFieldForm";

const IbanDetailsForm = (props) => {
  const { t } = useTranslation();


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >

      <Grid item xs={12}>
        <TextFieldFormControl
          name="iban_bank"
          value={props.ibanData?.iban_bank}
          label={t("form.iban_details_form.iban_bank")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={9}>
        <MaskedTextFieldForm
          mask={"aa 9999 9999 9999 9999 9999 9999"}
          onChange={props.onChange}
          disabled={props.readOnly}
          value={props.ibanData?.iban_nr}
          maskChar={"_"}
          name="iban_nr"
          label={t("form.iban_details_form.iban_nr")}
          showHelperText={!props.isIbanNrFormatValid}
          FormHelperTextProps={{ sx: { color: 'red' } }}
          helperText={t("form.iban_details_form.uncorrect_iban_nr")}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <AutocompleteField
          name={"currency"}
          label={t("form.iban_details_form.currency")}
          value={props.ibanData?.currency}
          options={props.currencies}
          isObjectOption={true}
          optionLabelKey={"currency_name"}
          onChange={props.onChangeAutocomplete}
          addNewValue={false}
          multiple={false}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="iban_note"
          value={props.ibanData?.iban_note}
          label={t("form.iban_details_form.iban_note")}
          onChange={props.onChange}
          multiline
          rows={3}
          resize="vertical"
        />
      </Grid>

    </Grid>
  );
};

IbanDetailsForm.propTypes = {
  ibanData: PropTypes.object,
  currencies: PropTypes.array,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func
};

IbanDetailsForm.defaultProps = {
  currencies: []
};

export default IbanDetailsForm;
