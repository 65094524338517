import React from "react";
import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import TextFieldFormControl from "../../field/TextFieldFormControl";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

const CounterDescriptionFieldsForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel label={t("form.meter_passport.comments")}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_note"}
            value={props.formValue.equipment_note}
            label={t("form.meter_passport.equipment_note")}
            onChange={props.onChange}
            readOnly={
              props.readOnly || props.readOnlyFields.includes("equipment_note")
            }
            required
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_note_bms"}
            value={props.formValue.equipment_note_bms}
            label={t("form.meter_passport.equipment_note_bms")}
            onChange={props.onChange}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_note_bms")
            }
            required
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_note_settlement"}
            value={props.formValue.equipment_note_settlement}
            label={t("form.meter_passport.equipment_note_settlement")}
            onChange={props.onChange}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_note_settlement")
            }
            required
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipinstal_desc"}
            value={props.formValue.equipinstal_desc}
            label={t("form.meter_passport.equipinstal_desc")}
            onChange={props.onChange}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipinstal_desc")
            }
            required
            multiline={true}
            rows={3}
          />
        </Grid>
      </Grid>
    </BoxWithLabel>
  );
};

CounterDescriptionFieldsForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDate: PropTypes.func,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  readOnlyFields: PropTypes.bool,
};

CounterDescriptionFieldsForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
};

export default CounterDescriptionFieldsForm;
