import React from "react";
import PropTypes from "prop-types";

import FloatField from "../../base/FloatField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

const CounterParametersForm = (props) => {
  const { t } = useTranslation();

  const handleChangeParameterValue = (parameterId, newValue) => {
    let changedParameter = props.parameters.find(
      (paramValue) => paramValue.id === parameterId
    );
    changedParameter.equparval_value = newValue;

    props.setParameters((prev) =>
      prev.map((parameter) =>
        parameter.id === parameterId ? changedParameter : parameter
      )
    );
  };

  return (
    <BoxWithLabel label={t("form.meter_passport.techincal_parameters")}>
      <div style={{ display: "flex" }}>
        {props.parameters.map((parameter) =>
          parameter.param_is_text ? (
            <TextFieldFormControl
              style={{ marginInline: "10px" }}
              label={parameter.param_name}
              value={parameter.equparval_value}
              onChange={(e) => {
                handleChangeParameterValue(parameter.id, e.target.value);
              }}
            />
          ) : (
            <FloatField
              style={{ marginInline: "10px" }}
              label={parameter.param_name}
              onChange={(e) => {
                handleChangeParameterValue(parameter.id, e.target.value);
              }}
              value={parameter.equparval_value}
              decimalPlaces={4}
            />
          )
        )}
      </div>
    </BoxWithLabel>
  );
};

CounterParametersForm.propTypes = {
  parameters: PropTypes.object,
  setParameters: PropTypes.func,
};

CounterParametersForm.defaultProps = {};

export default CounterParametersForm;
