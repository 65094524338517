import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import useLocationService from "../../../services/locationService";
import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";

import CounterInstalationLocationForm from "../../form/CounterInstalationForm/CounterInstalationLocationForm";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";

import { getErrorMsg } from "../../../helpers/methods";

const EquipmentInstalationDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const locationRef = useRef();

  const { formValue, setFormValue, onChangeDate } = useForm();

  const [location, setLocation] = useState();

  const { getEquipmentInstalationDetailsData, updateEquipmentInstalation } =
    useEquipmentService();
  const {
    prepareLocationDataFromBackendForLocationRecordsComponent,
    prepareLocationDataToSend,
  } = useLocationService();

  const equipmentInstalation = useAsync(() =>
    getEquipmentInstalationDetailsData(props.equipmentInstalationId)
  );

  const updateEquipmentInstalationFn = useAsyncFn(updateEquipmentInstalation);

  useEffect(() => {
    if (equipmentInstalation.loading) return;

    const { location, ...rest } = equipmentInstalation.value;

    setLocation(
      prepareLocationDataFromBackendForLocationRecordsComponent(location)
    );

    setFormValue(rest);
  }, [equipmentInstalation.loading]);

  const getPreparedDataToSend = () => {
    const dataToSend = { ...formValue };

    dataToSend.object = prepareLocationDataToSend(location)[0];

    return dataToSend;
  };

  const handleSubmit = () => {
    const unfillFieldsInstalationLocation =
      locationRef.current.checkIfRequiredFieldsAreFill();

    if (unfillFieldsInstalationLocation.length > 0) {
      return;
    }

    updateEquipmentInstalationFn
      .execute(props.equipmentInstalationId, getPreparedDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_instalation_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isLoading = location === undefined || formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <CounterInstalationLocationForm
            formValue={location}
            setFormValue={setLocation}
            onChangeDate={onChangeDate}
            showDeinstalationDate={!formValue.equipinstal_active}
            instalationDate={formValue.equipinstal_date_instalation}
            deinstalationDate={formValue.equipinstal_date_deinstalation}
            instalationLocationRef={locationRef}
            inBox={false}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: 1 }}
            variant="contained"
            color="primary"
            fullWidth
            loading={updateEquipmentInstalation.loading}
            onClick={handleSubmit}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={t("dialog.equipment.edit_equipment_instalation")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentInstalationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  equipmentInstalationId: PropTypes.string,
};

EquipmentInstalationDialog.defaultProps = {};

export default EquipmentInstalationDialog;
