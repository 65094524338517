import i18n from "../../../i18n";
import DateService from "../../../services/dateService";


export const ICO_PREBO_TABLE_FIELDS_CONFIG = [

  {
    name: "lender",
    getValueCallback: (rowData) => rowData.lender,
    label: i18n.t("table.icos_table.lender"),
  },
  {
    name: "borrower",
    getValueCallback: (rowData) => rowData.borrower,
    label: i18n.t("table.icos_table.borrower"),
  },
  {
    name: "icoprebo_date_activation",
    getValueCallback: (rowData) =>
    DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icoprebo_date_activation),
    label: i18n.t("table.icos_table.icoprebo_date_activation")
  },
  {
    name: "icoprebo_value_original",
    getValueCallback: (rowData) => rowData.icoprebo_value_original,
    label: i18n.t("table.icos_table.icoprebo_value_original"),
  },  
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.icos_table.currency"),
  },
  {
    name: "icoprebo_note_finance",
    getValueCallback: (rowData) => rowData.icoprebo_note_finance,
    label: i18n.t("table.icos_table.note_finance"),
  }, 
];
