import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import { NAVIGATION_DRAWER_TYPE_ADMIN } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import UserTenantTableWithToolBar from "../../components/other/UserTenantTableWithToolBar";

import {
  ADMIN_USER_TENANT_TABLE_NAME,
  ADMIN_USER_TENANT_TABLE_CONFIG,
  ADMIN_USER_TENANT_TABLE_FILTER_PREFIX,
} from "./TableConfig";

const USER_TENANT_DIALOG_FIELDS_TO_SAVE = [
  "user__id",
  "user__first_name",
  "user__last_name",
  "user__mail",
  "user__phone",
  "user__roles",
  "user__object_id",
  "user__user_external_system",
  "user__user_external_identificator",
  "uste_rr_visibility",
  "tenant",
  "band",
  "uste_band_tenant",
  "user__is_active",
  "uste_note",
  "responsibilities",
];

const USER_TENANT_DIALOG_HIDDEN_FIELDS = [
  // "band",
  // "uste_band_tenant",
  // "uste_note",
  // "responsibilities",
];

const USER_TENANT_DIALOG_READ_ONLY_FIELDS = [];

export default function AdminUsersPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_ADMIN}
    >
      <DefaultPageWrapper titleKey={"admin_users"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={12}>
            <UserTenantTableWithToolBar
              pageName={pageName}
              tableName={ADMIN_USER_TENANT_TABLE_NAME}
              tableConfig={ADMIN_USER_TENANT_TABLE_CONFIG}
              tableFilterPrefix={ADMIN_USER_TENANT_TABLE_FILTER_PREFIX}
              addUserTenantButtonTitle={t("page.admin_users.add_user")}
              exportToFileFileName={`${t("page.admin_users.users")}.xlsx`}
              userTenantDialogFieldsToSave={USER_TENANT_DIALOG_FIELDS_TO_SAVE}
              userTenantDialogHiddenFields={USER_TENANT_DIALOG_HIDDEN_FIELDS}
              userTenantDialogReadOnlyFields={
                USER_TENANT_DIALOG_READ_ONLY_FIELDS
              }
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
