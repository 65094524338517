import React from "react";
import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

const CounterAttibutesForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel label={t("form.meter_passport.attributes")}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={4}>
          <AutocompleteField
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            name={"equipment_parent"}
            label={t("form.meter_passport.equipment_parent_nr")}
            value={props.formValue.equipment_parent}
            options={props.filteringData.equipment_parent}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_parent")
            }
            optionLabelKey={"equipment_nr"}
            multiple={false}
            isObjectOption={true}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteField
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            name={"equipment_category"}
            label={t("form.meter_passport.equipment_category")}
            value={props.formValue.equipment_category}
            options={props.filteringData.equipment_category}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_category")
            }
            optionLabelKey={"equcat_name"}
            multiple={false}
            isObjectOption={true}
            disableClearable
            error={props.unfillRequiredFields.includes("equipment_category")}
            helperText={
              props.unfillRequiredFields.includes("equipment_category") &&
              t("field_required")
            }
          />
        </Grid>
        {/* <Grid item xs={3}>
          <AutocompleteField
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            name={"equipment_media_type"}
            label={t("form.meter_passport.equipment_media_type")}
            value={props.formValue.equipment_media_type}
            options={props.filteringData.equipment_media_type}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_media_type")
            }
            optionLabelKey={"equmedtyp_name"}
            multiple={false}
            isObjectOption={true}
          />
        </Grid> */}
        {/* <Grid item xs={3}>
          <AutocompleteField
            name={"equipment_responsible_user"}
            label={t("form.meter_passport.equipment_responsible_user")}
            value={props.formValue.equipment_responsible_user}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            options={props.filteringData.equipment_responsible_user}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_responsible_user")
            }
            optionLabelKey={"full_name"}
            multiple={false}
            isObjectOption={true}
          />
        </Grid> */}

        <Grid item xs={4}>
          <AutocompleteField
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            name={"equipment_status"}
            label={t("form.meter_passport.equipment_status")}
            value={props.formValue.equipment_status}
            options={props.filteringData.equipment_status}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_status")
            }
            optionLabelKey={"equstat_name"}
            multiple={false}
            disableClearable
            error={props.unfillRequiredFields.includes("equipment_status")}
            helperText={
              props.unfillRequiredFields.includes("equipment_status") &&
              t("field_required")
            }
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_in_bms"}
            label={t("form.meter_passport.equstate_is_in_bms")}
            value={props.formValue.equstate_is_in_bms}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_in_bms")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_in_scheme"}
            label={t("form.meter_passport.equstate_is_in_scheme")}
            value={props.formValue.equstate_is_in_scheme}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_in_scheme")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_temporary"}
            label={t("form.meter_passport.equstate_is_temporary")}
            value={props.formValue.equstate_is_temporary}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_temporary")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_server_room"}
            label={t("form.meter_passport.equstate_is_server_room")}
            value={props.formValue.equstate_is_server_room}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_server_room")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_building"}
            label={t("form.meter_passport.equstate_is_building")}
            value={props.formValue.equstate_is_building}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_building")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_floor"}
            label={t("form.meter_passport.equstate_is_floor")}
            value={props.formValue.equstate_is_floor}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_floor")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_in_settlement"}
            label={t("form.meter_passport.equstate_is_in_settlement")}
            value={props.formValue.equstate_is_in_settlement}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_in_settlement")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_damaged"}
            label={t("form.meter_passport.equstate_is_damaged")}
            value={props.formValue.equstate_is_damaged}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_damaged")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_subcounter"}
            label={t("form.meter_passport.equstate_is_subcounter")}
            value={props.formValue.equstate_is_subcounter}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_subcounter")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TrueFalseSelectField
            name={"equstate_is_empty_location"}
            label={t("form.meter_passport.equstate_is_empty_location")}
            value={props.formValue.equstate_is_empty_location}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equstate_is_empty_location")
            }
            valuesAsBool
            addEmptyOptions={false}
            onChange={props.onChange}
          />
        </Grid>
      </Grid>
    </BoxWithLabel>
  );
};

CounterAttibutesForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  readOnlyFields: PropTypes.bool,
};

CounterAttibutesForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
};

export default CounterAttibutesForm;
