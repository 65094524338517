import { useEffect, useState, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { useForm } from "../../../hooks/useForm";
import { useTranslation } from "react-i18next";

import BasicDialog from "../../base/BasicDialog";

import useEquipmentService from "../../../services/equipmentService";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { Grid } from "@mui/material";
import LoaderWrapper from "../../wrapper/LoaderWrapper/LoaderWrapper";
import LoadingButton from "@mui/lab/LoadingButton";

import useItemService from "../../../services/itemService";
import useLocationService from "../../../services/locationService";

import {
  getErrorMsg,
  isEmptyValue,
  isEmptyArray,
} from "../../../helpers/methods";

import CounterDataForms from "../../form/CounterDataForms/CounterDataForms";
import useFieldValidation from "../../../hooks/useFieldValidation";

const emptyObjectsList = [{}];
const emptyFactorObject = [{ eio_factor: 0 }];

const DEFAULT_REQUIRED_FIELD = [
  "equipment_category",
  "equipment_media_type",
  "equipment_status",
  "equipment_responsible_user",
  "eits_start_settlement",
  "eios_start_settlement",
];

const EQUIPMENT_STATUS_ACTIVE = "b14ca6c1-1a35-41ca-9ac0-b2a1a2a2c4cf";

function CounterAssignDataDialog(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    prepareLocationDataToSend,
    prepareLocationDataFromBackendForLocationRecordsComponent,
  } = useLocationService();

  const {
    getEquipmentDataForAssignMeter,
    getEquipmentForMetersFilteringData,
    updateEquipment,
  } = useEquipmentService();

  const equipmentFilteringData = useAsync(getEquipmentForMetersFilteringData);
  const equipmentData = useAsync(
    () => getEquipmentDataForAssignMeter(props.equipmentId),
    [props.equipmentId]
  );

  const { getParametersListForMeters } = useItemService();
  const parameterList = useAsync(() =>
    getParametersListForMeters({ equipment: props.equipmentId })
  );
  const [parameters, setParameters] = useState();

  const {
    formValue: tenants,
    setFormValue: setTenants,
    onChangeInArrayForm: onChangeTenantsInArrayForm,
    onChangeAutocompleteFieldWithObjectOptionsInArrayForm:
      onChangeTenantsAutocompleteFieldWithObjectOptionsInArrayForm,
  } = useForm();

  const {
    formValue: instalationLocation,
    setFormValue: setInstalationLocation,
  } = useForm();

  const { formValue: measuringLocation, setFormValue: setMeasuringLocation } =
    useForm();

  const {
    formValue: measuringLocationFactors,
    setFormValue: setMeasuringLocationFactors,
    onChangeInArrayForm: onChangeMeasuringLocationFactorsInArrayForm,
  } = useForm();

  const [equipmentIsMounted, setEquipmentIsMounted] = useState();
  useEffect(() => {
    if (equipmentData.loading || parameterList.loading) return;

    const {
      parameters,
      equipment_tenants,
      instalation_location,
      measuring_location,
      measuring_location_factors,
      equipment_is_mounted,
      ...rest
    } = equipmentData.value;

    setEquipmentIsMounted(equipment_is_mounted);

    setTenants(
      isEmptyArray(equipment_tenants) ? emptyObjectsList : equipment_tenants
    );

    setParameters(
      parameterList.value.map((parameter) => ({
        id: parameter.id,
        param_is_text: parameter.param_is_text,
        param_name: parameter.param_name,
        equparval_value: parameters.find((p) => p.parameter === parameter.id)
          ?.equparval_value,
      }))
    );

    setMeasuringLocationFactors(measuring_location_factors);

    setMeasuringLocation(
      prepareLocationDataFromBackendForLocationRecordsComponent(
        measuring_location
      )
    );
    setInstalationLocation(
      prepareLocationDataFromBackendForLocationRecordsComponent(
        instalation_location
      )
    );

    setFormValue(rest);
  }, [equipmentData.loading, parameterList.loading]);

  const updateEquipmentFn = useAsyncFn(updateEquipment);

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);
  const { getUnfillRequiredFields } = useFieldValidation();

  const requiredFields = useMemo(
    () => props.requiredFields || DEFAULT_REQUIRED_FIELD,
    [props.requiredFields]
  );

  const instalationLocationRecordsRef = useRef();
  const measuringLocationRef = useRef();
  const tenantSetRef = useRef();

  const checkIfRequiredFieldsAreFill = () => {
    let isFormValid = true;
    let unfillFieldsTemp = getUnfillRequiredFields(requiredFields, formValue);

    if (unfillFieldsTemp.length > 0) {
      isFormValid = false;
    }

    const unfillFieldsInstalationLocation =
      instalationLocationRecordsRef.current.checkIfRequiredFieldsAreFill();

    if (unfillFieldsInstalationLocation.length > 0) {
      isFormValid = false;
      unfillFieldsTemp = [
        ...unfillFieldsTemp,
        ...unfillFieldsInstalationLocation,
      ];
    }

    const unfillFieldsMeasuringLocation =
      measuringLocationRef.current.checkIfRequiredFieldsAreFill();

    if (unfillFieldsMeasuringLocation.length > 0) {
      isFormValid = false;
      unfillFieldsTemp = [
        ...unfillFieldsTemp,
        ...unfillFieldsMeasuringLocation,
      ];
    }

    const unfillFieldsTenantSet =
      tenantSetRef.current.checkIfRequiredFieldsAreFill();

    if (unfillFieldsTenantSet.length > 0) {
      isFormValid = false;
      unfillFieldsTemp = [...unfillFieldsTemp, ...unfillFieldsTenantSet];
    }

    if (isFormValid && !isMeasuringLocationFactorsValid()) {
      isFormValid = false;
      unfillFieldsTemp = [...unfillFieldsTemp, "location_factors"];
    }

    setUnfillRequiredFields(unfillFieldsTemp);

    return isFormValid;
  };

  const getPreparedFormDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (value !== equipmentData.value[key]) dataToSend[key] = value;
    }
    return dataToSend;
  };

  const getPreparedParametersToSend = () => {
    const preparedParameters = [];
    for (let parameter of parameters) {
      let oldParameter = equipmentData.value.parameters.find(
        (p) => p.parameter === parameter.id
      );
      if (parameter?.equparval_value !== oldParameter?.equparval_value) {
        preparedParameters.push({
          id: oldParameter?.id,
          parameter: parameter.id,
          equparval_value: parameter.equparval_value,
        });
      }
    }

    return preparedParameters;
  };

  const prepareMeasuringLocations = (locations, factors) => {
    const preparedMeasuringLocations = [];
    for (const [index, location] of prepareLocationDataToSend(
      locations
    ).entries()) {
      preparedMeasuringLocations.push({
        object: location,
        eio_factor: factors[index].eio_factor.toString(),
      });
    }
    return preparedMeasuringLocations;
  };

  const getPreparedMeasuringLocationsToSend = () => {
    const oldMeasuringLocations = prepareMeasuringLocations(
      prepareLocationDataFromBackendForLocationRecordsComponent(
        equipmentData.value.measuring_location
      ),
      equipmentData.value.measuring_location_factors
    );
    const newMeasuringLocations = prepareMeasuringLocations(
      measuringLocation,
      measuringLocationFactors
    );

    if (
      formValue.eios_start_settlement !==
      equipmentData.value.eios_start_settlement
    ) {
      return newMeasuringLocations;
    }

    if (oldMeasuringLocations.length !== newMeasuringLocations.length) {
      return newMeasuringLocations;
    }

    for (let i = 0; i < newMeasuringLocations.length; i++) {
      const newLocation = newMeasuringLocations[i];
      const oldLocation = oldMeasuringLocations[i];

      if (
        newLocation.eio_factor !== oldLocation.eio_factor ||
        newLocation.object !== oldLocation.object
      ) {
        return newMeasuringLocations;
      }
    }

    return [];
  };

  const getPreparedInstalationLocationToSend = () => {
    const newInstalationLocationId =
      prepareLocationDataToSend(instalationLocation)[0];
    const oldInstalationLocationId = prepareLocationDataToSend(
      prepareLocationDataFromBackendForLocationRecordsComponent(
        equipmentData.value.instalation_location
      )
    )[0];

    if (newInstalationLocationId !== oldInstalationLocationId) {
      return newInstalationLocationId;
    }

    return null;
  };

  const getPreparedTenantSetToSend = () => {
    const oldEquipmentTenants = isEmptyArray(
      equipmentData.value.equipment_tenants
    )
      ? emptyObjectsList
      : equipmentData.value.equipment_tenants;
    const newEquipmentTenants = [...tenants];

    if (
      formValue.eits_start_settlement !==
      equipmentData.value.eits_start_settlement
    ) {
      return newEquipmentTenants;
    }

    if (newEquipmentTenants.length !== oldEquipmentTenants.length) {
      return newEquipmentTenants;
    }

    for (let i = 0; i < newEquipmentTenants.length; i++) {
      if (
        newEquipmentTenants[i].tenant !== oldEquipmentTenants[i].tenant ||
        newEquipmentTenants[i].eit_factor !== oldEquipmentTenants[i].eit_factor
      ) {
        return newEquipmentTenants;
      }
    }
    return null;
  };

  const getPreparedDataToSend = () => {
    const dataToSend = getPreparedFormDataToSend();
    console.log("dataToSend", dataToSend);

    const preparedParameters = getPreparedParametersToSend();
    if (!isEmptyArray(preparedParameters)) {
      dataToSend.parameter_list = preparedParameters;
    }
    console.log("preparedParameters", preparedParameters);

    const preparedMeasuringLocations = getPreparedMeasuringLocationsToSend();
    if (!isEmptyArray(preparedMeasuringLocations)) {
      dataToSend.eios_start_settlement = formValue.eios_start_settlement;
      dataToSend.equipment_instalation_object_set = preparedMeasuringLocations;
    }
    console.log("preparedMeasuringLocations", preparedMeasuringLocations);

    const preparedInstalationLocation = getPreparedInstalationLocationToSend();
    if (preparedInstalationLocation) {
      if (equipmentIsMounted) {
        dataToSend.new_instalation_location = preparedInstalationLocation;
      } else {
        dataToSend.changed_instalation_location = preparedInstalationLocation;
      }
    }
    console.log("preparedInstalationLocation", preparedInstalationLocation);

    const preparedTenantSetToSend = getPreparedTenantSetToSend();
    if (preparedTenantSetToSend) {
      dataToSend.eits_start_settlement = formValue.eits_start_settlement;
      dataToSend.equipment_instalation_tenant_set = preparedTenantSetToSend;
    }
    console.log("preparedTenantSetToSend", preparedTenantSetToSend);

    console.log("Final dataToSend", dataToSend);

    if (equipmentIsMounted) {
      dataToSend["equipment_status"] = EQUIPMENT_STATUS_ACTIVE;
    }

    return dataToSend;
  };

  const handleSubmit = () => {
    if (!checkIfRequiredFieldsAreFill()) {
      return;
    }
    updateEquipmentFn
      .execute(props.equipmentId, getPreparedDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isParametersValid = () => {
    return parameters.every(
      (parameter) => !isEmptyValue(parameter.equparval_value)
    );
  };

  const isTenantSetValid = () => {
    return (
      tenants.every(
        (equipmentTenant) =>
          !isEmptyValue(equipmentTenant.tenant) &&
          !isEmptyValue(equipmentTenant.eit_factor)
      ) &&
      tenants.reduce((sum, equipmentTenant) => {
        return sum + parseFloat(equipmentTenant.eit_factor);
      }, 0) === 1 &&
      tenants.map((equipmentTenant) => equipmentTenant.tenant).length ===
        new Set(tenants.map((equipmentTenant) => equipmentTenant.tenant))
          .size &&
      !isEmptyValue(formValue.eits_start_settlement)
    );
  };

  const isMeasuringLocationFactorsValid = () => {
    return (
      measuringLocationFactors.reduce((sum, equipmentInstalationObject) => {
        return sum + parseFloat(equipmentInstalationObject.eio_factor);
      }, 0) === 1 && !isEmptyValue(formValue.eios_start_settlement)
    );
  };

  const isFormValueValid = () => {
    const formValueRequiredField = [
      "equipment_nr",
      "equipment_date_production",
      "equipment_date_legalization",
      "equipment_media_type",
      "equipment_category",
      "equipment_responsible_user",
    ];

    return formValueRequiredField.every((field) =>
      Array.isArray(formValue[field])
        ? !isEmptyArray(formValue[field])
        : !isEmptyValue(formValue[field])
    );
  };

  const isFormValid = () =>
    isFormValueValid() &&
    // isParametersValid() &&
    isTenantSetValid() &&
    isMeasuringLocationFactorsValid();

  const readOnlyFields = useMemo(() => {
    let tempReadOnlyFields = ["equipment_nr"];
    if (equipmentIsMounted) {
      tempReadOnlyFields.push("equipment_status");
    }
    return tempReadOnlyFields;
  }, [equipmentIsMounted]);

  const isLoading =
    equipmentData.loading ||
    equipmentFilteringData.loading ||
    formValue === undefined ||
    parameters === undefined ||
    tenants === undefined ||
    measuringLocation === undefined ||
    instalationLocation === undefined ||
    measuringLocationFactors === undefined ||
    equipmentIsMounted === undefined ||
    parameterList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container>
        <Grid item xs={12}>
          <CounterDataForms
            formValue={formValue}
            parameters={parameters}
            setParameters={setParameters}
            tenants={tenants}
            setTenants={setTenants}
            instalationLocation={instalationLocation}
            setInstalationLocation={setInstalationLocation}
            measuringLocation={measuringLocation}
            setMeasuringLocation={setMeasuringLocation}
            measuringLocationFactors={measuringLocationFactors}
            onChangeMeasuringLocationFactor={
              onChangeMeasuringLocationFactorsInArrayForm
            }
            setMeasuringLocationFactors={setMeasuringLocationFactors}
            onChange={onChange}
            onChangeAutocompleteFieldWithObjectOptions={
              onChangeAutocompleteFieldWithObjectOptions
            }
            onChangeAutocompleteTenantFieldWithObjectOptions={
              onChangeTenantsAutocompleteFieldWithObjectOptionsInArrayForm
            }
            onChangeTenantInArrayForm={onChangeTenantsInArrayForm}
            onChangeDate={onChangeDate}
            filteringData={equipmentFilteringData.value}
            readOnlyFields={readOnlyFields}
            emptyFactorObject={emptyFactorObject}
            unfillRequiredFields={unfillRequiredFields}
            instalationLocationRef={instalationLocationRecordsRef}
            measuringLocationRef={measuringLocationRef}
            tenantSetRef={tenantSetRef}
            factorsAreInvalid={unfillRequiredFields.includes(
              "location_factors"
            )}
            factorsInvalidHelperText={t("sum_needs_to_be_equal_one")}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: 1 }}
            variant="contained"
            color="primary"
            fullWidth
            loading={updateEquipmentFn.loading}
            // disabled={!isFormValid()}
            onClick={handleSubmit}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={props.dialogTitle || t("dialog.equipment.assign_counter")}
      maxWidth="xxl"
      showTopActionButton={false}
      showBottomActionButton={true}
      bottomActionStyle={{ padding: "10px" }}
      showCustomFooter={false}
      showDialogActions={false}
    >
      {getDialogContent()}
    </BasicDialog>
  );
}

CounterAssignDataDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  dialogTitle: PropTypes.string,
};

CounterAssignDataDialog.defaultProps = {
  open: false,
};

export default CounterAssignDataDialog;
