import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import BoardForm from "../../form/BoardForm";
import IbanForm from "../../form/IbanForm/IbanForm";

export default function IbanFormBox({ ibans, onEditIban, onAddIban, onRemoveIban, readOnly }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t(`other.iban_form_box.owner_ibans`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {ibans?.map((iban, index) => {
                    return (
                        <IbanForm
                            iban={iban}
                            index={index}
                            onEditIban={onEditIban}
                            onRemoveIban={onRemoveIban}
                            readOnly ={readOnly}
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddIban}
                    >
                        {t("other.iban_form_box.add_iban")}
                    </Button>
                    }
                </Grid>
            </Grid>
        </BaseBox>
    );
}

IbanFormBox.propTypes = {
    ibans : PropTypes.array,
    onEditIban : PropTypes.func,
    onAddIban : PropTypes.func,
    onRemoveIban : PropTypes.func,
    readOnly : PropTypes.bool
}


IbanFormBox.defaultProps = {
    ibans : [],
    readOnly : true
}
