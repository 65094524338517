import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import SelectFieldService from "../../services/selectFieldService";
import InfoIcon from "@mui/icons-material/Info";

export const OWNER_TABLE_NAME = "owner_table";

export const OWNER_TABLE_FIELDS_CONFIG = [
  {
    name: "preview",
    getValueCallback: (rowData, onClickEdit) => {
      return (
      <InfoIcon onClick={(e) => onClickEdit(e, rowData.id)} />
      );      
    },
    label: i18n.t("table.owner_table.preview"),
    filterType: null,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "owner_full_name",
    getValueCallback: (rowData) => rowData.owner_full_name,
    label: i18n.t("table.owner_table.owner_full_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
    allowChangeVisibility: false,
  },
  {
    name: "owner_short_name",
    getValueCallback: (rowData) => rowData.owner_short_name,
    label: i18n.t("table.owner_table.owner_short_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "owner_kind",
    getValueCallback: (rowData) => rowData.owner_kind,
    label: i18n.t("table.owner_table.owner_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owner_kinds,
      isObjectOption: false,
      addNewValue: false,
      multiple: false,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "owner_krs",
    getValueCallback: (rowData) => rowData.owner_krs,
    label: i18n.t("table.owner_table.krs"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "board",
    getValueCallback: (rowData) => rowData.board,
    label: i18n.t("table.owner_table.board"),
    filterType: TEXT_FIELD_TYPE,
    style: { minWidth: "200px" },
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "200px" },
    }),
  },
  {
    name: "owner_legal",
    getValueCallback: (rowData) => rowData.owner_legal,
    label: i18n.t("table.owner_table.owner_legal"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "owner_court",
    getValueCallback: (rowData) => rowData.owner_court,
    label: i18n.t("table.owner_table.owner_court"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "owner_nip",
    getValueCallback: (rowData) => rowData.owner_nip,
    label: i18n.t("table.owner_table.nip"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "owner_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.owner_active),
    label: i18n.t("table.owner_table.owner_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
