import React from "react";
import PropTypes from "prop-types";

import BoxWithLabel from "../../box/BoxWithLabel";
import MessagesWindow from "../../window/MessagesWindow";

import { useTranslation } from "react-i18next";

const MeterLogsForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.logs")}
      boxStyle={{ height: "100%" }}
    >
      <MessagesWindow
        messages={props.logs}
        messageWindowHeight={{
          maxHeight: "300px",
          padding: "0px",
          paddingRight: "20px",
        }}
        fullWidthMessages={true}
        canHideMessages={false}
      />
    </BoxWithLabel>
  );
};

MeterLogsForm.propTypes = {
  logs: PropTypes.array,
};

MeterLogsForm.defaultProps = {};

export default MeterLogsForm;
