import React from "react";
import PropTypes from "prop-types";

import { Grid, Tooltip, IconButton } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";

import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import EquipmentInstalationObjectSetHistoryDialog from "../../dialog/EquipmentInstalationObjectSetHistoryDialog";
import useDialog from "../../../hooks/useDialog";

const MeterMeasuringLocationForm = (props) => {
  const { t } = useTranslation();

  const [
    openEquipmentInstalationObjectSetHistoryDialog,
    onOpenEquipmentInstalationObjectSetHistoryDialog,
    onCloseEquipmentInstalationHistoryDialog,
  ] = useDialog();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.measuring_locations")}
      boxStyle={{ height: "100%" }}
      style={{
        height: "100%",
      }}
    >
      <Grid container justifyContent={"flex-end"} columnSpacing={1}>
        {props.locations.map((location, idx) => (
          <Grid
            item
            xs={12}
            container
            spacing={2}
            marginBottom={idx === props.locations.length - 1 ? 0 : 1}
          >
            {Array.from({ length: 5 }, (x, i) => i + 1).map((level) => (
              <Grid item xs={2.2}>
                <TextFieldFormControl
                  value={location[`level_${level}`]?.[`object_name`] || " "}
                  label={t(`table.object.level_${level}_object_name`)}
                  readOnly={true}
                  inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
                  style={BLACK_TEXT_FIELD_INPUT_STYLE}
                />
              </Grid>
            ))}
            <Grid item xs={1}>
              <TextFieldFormControl
                value={props.factors[idx].eio_factor}
                label={t("form.meter_passport.eio_factor")}
                readOnly={true}
                inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
                style={BLACK_TEXT_FIELD_INPUT_STYLE}
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={2} marginTop={1}>
          <TextFieldFormControl
            name={"eios_start_settlement"}
            label={t("form.meter_passport.eios_start_settlement")}
            value={props.startSettlement}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
      </Grid>

      {props.showEquipmentInstalationObjectSetHistoryButton && (
        <Grid item xs={12} container justifyContent={"flex-end"}>
          <Tooltip
            title={t(
              "dialog.equipment.equipment_instalation_object_set_history"
            )}
          >
            <IconButton
              onClick={onOpenEquipmentInstalationObjectSetHistoryDialog}
            >
              <ManageHistoryIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {openEquipmentInstalationObjectSetHistoryDialog && (
        <EquipmentInstalationObjectSetHistoryDialog
          open={openEquipmentInstalationObjectSetHistoryDialog}
          onClose={onCloseEquipmentInstalationHistoryDialog}
          equipmentId={props.equipmentId}
          onSubmit={props.onSubmit}
        />
      )}
    </BoxWithLabel>
  );
};

MeterMeasuringLocationForm.propTypes = {
  locations: PropTypes.array,
  factors: PropTypes.array,
  showEquipmentInstalationObjectSetHistoryButton: PropTypes.bool,
};

MeterMeasuringLocationForm.defaultProps = {
  showEquipmentInstalationObjectSetHistoryButton: true,
};

export default MeterMeasuringLocationForm;
