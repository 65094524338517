import { useMemo, useState } from "react";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import {
  useMeterSearchConfig,
  EQUIPMENT_STATE_TABLE_NAME,
} from "./TableConfig";

import EquipmentTableWithToolBar from "./EquipmentTableWithToolBar";

export default function CountersSearchPage(props) {
  const { pageName } = props;

  const [selectedTable, setSelectedTable] = useState(
    EQUIPMENT_STATE_TABLE_NAME
  );

  const { getTableProps, fetchingFromBackend } = useMeterSearchConfig();

  const tableProps = useMemo(() => {
    if (fetchingFromBackend) {
      return;
    }
    return getTableProps(selectedTable);
  }, [fetchingFromBackend, selectedTable]);

  const isLoading = tableProps === undefined || fetchingFromBackend;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"meter_search"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <EquipmentTableWithToolBar
              pageName={props.pageName}
              setSelectedTable={setSelectedTable}
              tableName={tableProps.tableName}
              tableConfig={tableProps.tableConfig}
              tableFilterPrefix={tableProps.tableFilterPrefix}
              tableConfigForUserPreferencesDialog={
                tableProps.configForUserPreferenceDialog
              }
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
