import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useIcoService from "../../../../services/icoService";
import { CURRENCY_VALUE_TABLE_CONFIG } from "./TableConfig";
import { currencyRateExportUrl } from "../../../../helpers/apiUrls";


const CurrencyValueTab = (props) => {
  const { t } = useTranslation();
  const [currencyValuesLocal, setCurrencyValuesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams] = useSearchParams();

  const { emptyArrayMemo } = useEmptyArrayMemo()

  const { getCurrencyRateList } = useIcoService();



  const [openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams();

  const currencyRateData = useAsync(
    () => getCurrencyRateList(searchParams),
    [searchParams]
  );


  useEffect(() => {
    if (currencyRateData.loading) {
      return;
    }
    setCurrencyValuesLocal(currencyRateData.value.results);
    setCountRecords(currencyRateData.value.count);
  }, [currencyRateData.loading]);

  const isLoading = currencyValuesLocal === undefined || props.filteringData.loading




  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        CURRENCY_VALUE_TABLE_CONFIG,
        currencyValuesLocal ? currencyValuesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo
      ),

    [currencyValuesLocal, CURRENCY_VALUE_TABLE_CONFIG]
  );

  const onCleanFlitersInFilterDialog = () => {
    setCurrencyValuesLocal(undefined);
    setCountRecords(undefined);
  };

  const curencyRateExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      CURRENCY_VALUE_TABLE_CONFIG,
      emptyArrayMemo,
      emptyArrayMemo
    );
  }, [
    CURRENCY_VALUE_TABLE_CONFIG,
  ]);


  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >

      <Grid item sm={11} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={CURRENCY_VALUE_TABLE_CONFIG}
          showContextMenu={false}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            CURRENCY_VALUE_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
          showExportToFileButton={true}
          exportToFileUrl={currencyRateExportUrl}
          exportToFileSearchParams={searchParams}
          exportToFileHeaders={curencyRateExportHeaders}
          exportToFileFileName={`${t("tabs.ico_admin_tabs.currency_rates").replace(" ", "_")}.xlsx`}

        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={CURRENCY_VALUE_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
    </Grid>
  );
};

CurrencyValueTab.propTypes = {
  filteringData: PropTypes.object,
};

CurrencyValueTab.defaultProps = {};

export default CurrencyValueTab;
