import React from "react";
import PropTypes from "prop-types";

import { Grid, IconButton, Tooltip } from "@mui/material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";
import DateService from "../../../services/dateService";

import EquipmentInstalationHistoryDialog from "../../dialog/EquipmentInstalationHistoryDialog";

import useDialog from "../../../hooks/useDialog";

const MeterLocationForm = (props) => {
  const { t } = useTranslation();

  const [
    openEquipmentInstalationHistoryDialog,
    onOpenEquipmentInstalationHistoryDialog,
    onCloseEquipmentInstalationHistoryDialog,
  ] = useDialog();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.instalation_location")}
      // boxStyle={{ height: "100%" }}
      // style={{
      //   height: "100%",
      // }}
    >
      <Grid
        container
        columnSpacing={2}
        rowSpacing={1}
        justifyContent={"flex-end"}
      >
        {Array.from({ length: 5 }, (x, i) => i + 1).map((level) => (
          <Grid item xs={2.4}>
            <TextFieldFormControl
              value={props.location[`level_${level}`]?.[`object_name`] || " "}
              label={t(`table.object.level_${level}_object_name`)}
              inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
              style={BLACK_TEXT_FIELD_INPUT_STYLE}
              readOnly={true}
            />
          </Grid>
        ))}
        {props.lastInstalation && (
          <Grid item xs={2}>
            <TextFieldFormControl
              name={"equipment_date_instalation"}
              label={t("form.meter_passport.equipment_date_instalation")}
              value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
                props.lastInstalation
              )}
              readOnly={true}
              inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
              style={BLACK_TEXT_FIELD_INPUT_STYLE}
            />
          </Grid>
        )}
        {props.lastDeinstalation && (
          <Grid item xs={2}>
            <TextFieldFormControl
              name={"equipment_date_deinstalation"}
              label={t("form.meter_passport.equipment_date_deinstalation")}
              value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
                props.lastDeinstalation
              )}
              readOnly={true}
              inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
              style={BLACK_TEXT_FIELD_INPUT_STYLE}
            />
          </Grid>
        )}

        {props.showInstalationHistoryButton && (
          <Grid item xs={12} container justifyContent={"flex-end"}>
            <Tooltip
              title={t("dialog.equipment.equipment_instalation_history")}
            >
              <IconButton onClick={onOpenEquipmentInstalationHistoryDialog}>
                <ManageHistoryIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {openEquipmentInstalationHistoryDialog && (
          <EquipmentInstalationHistoryDialog
            open={openEquipmentInstalationHistoryDialog}
            onClose={onCloseEquipmentInstalationHistoryDialog}
            equipmentId={props.equipmentId}
            onSubmit={props.onSubmit}
          />
        )}
      </Grid>
    </BoxWithLabel>
  );
};

MeterLocationForm.propTypes = {
  locations: PropTypes.array,
  equipmentId: PropTypes.string,
  onSubmit: PropTypes.func,
  showInstalationHistoryButton: PropTypes.bool,
};

MeterLocationForm.defaultProps = {
  showInstalationHistoryButton: true,
};

export default MeterLocationForm;
