import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useAsync } from "../../../../hooks/useAsync";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../../field/AutocompleteField";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import useItemService from "../../../../services/itemService";
import useOfferService from "../../../../services/offerService";
import { sortedIemByNumers } from "../../../../helpers/methods";
import useEquipmentService from "../../../../services/equipmentService";

const ItemLevel = React.memo((props) => {
  const { t } = useTranslation();

  const { getItemsSelectList } = useItemService();

  const { getPartsSelectListData, getServicesSelectListData } =
    useOfferService();

  const { getEquipmentSelectListData } = useEquipmentService();

  const items = useAsync(() => {
    var searchParams = {};
    if (props.itemParent === null && props.defaultFirstLevelItemName) {
      searchParams.item_name = props.defaultFirstLevelItemName;
    } else if (props.level === 2 && props.defaultSecondLevelItemName) {
      searchParams.item_name = props.defaultSecondLevelItemName;
    } else if (props.itemParent === null) {
      searchParams.item_parent__isnull = true;
    } else {
      searchParams.item_parent = props.itemParent;
    }
    return getItemsSelectList(searchParams);
  }, [props.itemParent]);

  const displayLevel = useMemo(() => {
    if (props.readOnly && !props.value) {
      props.locationLevel["visible"] = false;
      return false;
    }
    if (props.level === 1 || (items.value && items.value.length > 0)) {
      props.locationLevel["visible"] = true;

      return true;
    }

    props.locationLevel["visible"] = false;
    return false;
  }, [
    items.value,
    props.level,
    props.value,
    props.readOnly,
    items.loading,
    props.locationLevel,
  ]);

  const partList = useAsync(() => {
    if (!displayLevel && !items.loading && props.showMaterialLabel) {
      return getPartsSelectListData({ item: props.itemParent });
    }
    return Promise.resolve([]);
  }, [displayLevel, items.loading, props.partValue]);

  const serviceList = useAsync(() => {
    if (!displayLevel && !items.loading && props.showServiceLabel) {
      return getServicesSelectListData({ item: props.itemParent });
    }
    return Promise.resolve([]);
  }, [displayLevel, items.loading, props.partValue]);

  const equipmentList = useAsync(() => {
    if (!displayLevel && !items.loading && props.showEquipmentLabel) {
      return getEquipmentSelectListData({ item: props.itemParent });
    }
    return Promise.resolve([]);
  }, [displayLevel, items.loading, props.partValue]);

  return (
    <>
      {displayLevel ? (
        <Grid item sm={4} xs={12}>
          {items.loading ? (
            <LoaderWrapper showLoader={true} />
          ) : (
            <AutocompleteField
              fullWidth
              readOnly={props.readOnly}
              options={sortedIemByNumers(items.value)}
              isObjectOption={true}
              optionLabelKey={"indexed_item_name"}
              variant={"outlined"}
              label={`${t("other.item_select.level")} ${props.level}`}
              value={props.value}
              required={props.required}
              onChange={(value_object, value_key) =>
                props.onChange(value_key, props.level)
              }
              error={props.errorField}
              helperText={props.helperTextField}
              multiple={false}
              addNewValue={false}
              disableClearable={true}
            />
          )}
        </Grid>
      ) : (
        <>
          {props.showMaterialLabel ? (
            <Grid item sm={4} xs={12}>
              {partList.loading ? (
                <LoaderWrapper showLoader={true} />
              ) : (
                <AutocompleteField
                  fullWidth
                  name={"part"}
                  readOnly={props.readOnly}
                  options={partList.value}
                  isObjectOption={true}
                  optionLabelKey={"part_info"}
                  variant={"outlined"}
                  label={`${t("other.item_select.part")}`}
                  value={props.partValue}
                  required={true}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                  multiple={false}
                  addNewValue={false}
                  disableClearable={true}
                />
              )}
            </Grid>
          ) : null}
          {props.showEquipmentLabel ? (
            <Grid item sm={4} xs={12}>
              {equipmentList.loading ? (
                <LoaderWrapper showLoader={true} />
              ) : (
                <AutocompleteField
                  fullWidth
                  name={"equipment"}
                  readOnly={props.readOnly}
                  options={equipmentList.value}
                  isObjectOption={true}
                  optionLabelKey={"equipment_info"}
                  variant={"outlined"}
                  label={`${t("other.item_select.equipment")}`}
                  value={props.equipmentValue}
                  required={true}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                  multiple={false}
                  addNewValue={false}
                  disableClearable={true}
                />
              )}
            </Grid>
          ) : null}
          {props.showServiceLabel ? (
            <Grid item sm={6} xs={12}>
              {serviceList.loading ? (
                <LoaderWrapper showLoader={true} />
              ) : (
                <AutocompleteField
                  fullWidth
                  name={"service"}
                  readOnly={props.readOnly}
                  options={serviceList.value}
                  isObjectOption={true}
                  optionLabelKey={"service_name"}
                  variant={"outlined"}
                  label={`${t("other.item_select.service")}`}
                  value={props.serviceValue}
                  required={true}
                  onChange={props.onChangeAutocompleteFieldWithObjectOptions}
                  multiple={false}
                  addNewValue={false}
                  disableClearable={true}
                />
              )}
            </Grid>
          ) : null}
        </>
      )}
    </>
  );
});

ItemLevel.propTypes = {
  level: PropTypes.number,
  itemParent: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorField: PropTypes.bool,
  readOnly: PropTypes.bool,
  showMaterialLabel: PropTypes.bool,
  defaultFirstLevelItemName: PropTypes.string,
  defaultSecondLevelItemName: PropTypes.string,
  showServiceLabel: PropTypes.bool,
  showEquipmentLabel: PropTypes.bool,
};

ItemLevel.defaultProps = {
  itemParent: null,
  readOnly: false,
  showMaterialLabel: false,
  required: true,
  showServiceLabel: false,
  showEquipmentLabel: false,
};

export default ItemLevel;
