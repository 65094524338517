import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import BoxWithLabel from "../../box/BoxWithLabel";
import LocationRecords from "../../other/LocationRecords";
import DateField from "../../field/DateField/DateField";

import { useTranslation } from "react-i18next";

const CounterInstalationLocationForm = (props) => {
  const { t } = useTranslation();

  const formContent = (
    <Grid
      container
      rowSpacing={2}
      justifyContent={"flex-end"}
      columnSpacing={1}
    >
      <Grid item xs={12}>
        <LocationRecords
          locations={props.formValue}
          onChangeLocations={props.setFormValue}
          readOnly={props.readOnly}
          showAddLocationButton={false}
          ref={props.instalationLocationRef}
          showAllObjectsAndLevels
          showSelectObjectLevelButton
        />
      </Grid>
      {props.showInstalationDate && (
        <Grid item xs={2}>
          <DateField
            name={"equipinstal_date_instalation"}
            label={t("form.meter_passport.equipment_date_instalation")}
            value={props.instalationDate}
            onChange={props.onChangeDate}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipinstal_date_instalation")
            }
            required
            helperText={
              props.unfillRequiredFields.includes(
                "equipinstal_date_instalation"
              ) && t("field_required")
            }
            error={props.unfillRequiredFields.includes(
              "equipinstal_date_instalation"
            )}
          />
        </Grid>
      )}
      {props.showDeinstalationDate && (
        <Grid item xs={2}>
          <DateField
            name={"equipinstal_date_deinstalation"}
            label={t("form.meter_passport.equipment_date_deinstalation")}
            value={props.deinstalationDate}
            onChange={props.onChangeDate}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipinstal_date_deinstalation")
            }
            required
            helperText={
              props.unfillRequiredFields.includes(
                "equipinstal_date_instalation"
              ) && t("field_required")
            }
            error={props.unfillRequiredFields.includes(
              "equipinstal_date_deinstalation"
            )}
          />
        </Grid>
      )}
    </Grid>
  );

  return props.inBox ? (
    <BoxWithLabel label={t("form.meter_passport.instalation_location")}>
      {formContent}
    </BoxWithLabel>
  ) : (
    formContent
  );
};

CounterInstalationLocationForm.propTypes = {
  formValue: PropTypes.object,
  setFormValue: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.array,
  instalationDate: PropTypes.string,
  deinstalationDate: PropTypes.string,
  instalationLocationRef: PropTypes.any,
  showInstalationDate: PropTypes.bool,
  showDeinstalationDate: PropTypes.bool,
  inBox: PropTypes.bool,
};

CounterInstalationLocationForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
  unfillRequiredFields: [],
  showInstalationDate: true,
  showDeinstalationDate: false,
  inBox: true,
};

export default CounterInstalationLocationForm;
