import i18n from "../../../i18n";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import DateService from "../../../services/dateService";
import SelectFieldService from "../../../services/selectFieldService";

import WarningAlertIcon from "../../../components/other/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../../components/wrapper/PrefixSuffixFieldTableWrapper";

export const EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_CONFIG = [
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_location ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.location[0]?.level_1?.object_name}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.location[0]?.level_1?.object_name
      );
    },
    label: i18n.t("table.counter_repository.measuring_place_level_1_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_2?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_2_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_3?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_3_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_4?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_4_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_5?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_5_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eios_start_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eios_start_settlement
      ),
    label: i18n.t("table.counter_repository.eios_start_settlement"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eios_end_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eios_end_settlement
      ),
    label: i18n.t("table.counter_repository.eios_end_settlement"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eios_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.eios_active),
    label: i18n.t("table.counter_repository.eios_active"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickMultipleLocationAlert, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },

  // {
  //   name: "delete",
  //   getValueCallback: (rowData, onClickEdit, onClickDelete) => (
  //     <DeleteOutlineOutlinedIcon
  //       onClick={(e) => onClickDelete(e, rowData.id)}
  //     />
  //   ),
  //   label: t("table.schedule.delete"),
  //   filterType: null,
  //   getFilterFieldConfigCallback: (filteringData) => undefined,
  // },
];
