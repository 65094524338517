import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useOfferService from "../../../services/offerService";
import useEquipmentService from "../../../services/equipmentService";

const SelectPartDialog = (props) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState();

  const { getPartsSelectListData } = useOfferService();
  const { getEquipmentSelectList } = useEquipmentService();

  const itemInfoSelectList = useAsync(() => {
    if (props.itemType === "equipment") {
      return getEquipmentSelectList(props.searchParams);
    }
    return getPartsSelectListData(props.searchParams);
  }, [props.searchParams, props.itemType]);

  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key,
    state_value_name
  ) => {
    const newFormValue = Array.isArray(value_object)
      ? value_object.map((option) =>
          typeof option === "object" ? option[value_key] : option
        )
      : value_object[value_key];
    setSelectedItem(newFormValue);
  };
  const isLoading = itemInfoSelectList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <>
        {props.itemType !== "equipment" && (
          <Grid item xs={12}>
            <AutocompleteField
              name={"part"}
              label={t("form.item_form.choice_material")}
              value={selectedItem}
              options={itemInfoSelectList.value}
              isObjectOption={true}
              optionLabelKey={"part_info"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>
        )}
        {props.itemType === "equipment" && (
          <Grid item xs={12}>
            <AutocompleteField
              name={"equipment"}
              label={t("form.item_form.choice_equipment")}
              value={selectedItem}
              options={itemInfoSelectList.value}
              isObjectOption={true}
              optionLabelKey={"equipment_info"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>
        )}
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedItem)}
          disabled={!selectedItem}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.select_item_dialog.select_item")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectPartDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
  searchParams: PropTypes.object,
  itempType: PropTypes.string,
};

SelectPartDialog.defaultProps = {
  searchParams: {},
};

export default SelectPartDialog;
