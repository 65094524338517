import { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import AgreementTable from "../../components/table/AgreementTable";
import { NAVIGATION_DRAWER_TYPE_ICO } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useIcoService from "../../services/icoService";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import useDialogWithId from "../../hooks/useDialogWithId";
import IcoAcceptDialog from "../../components/dialog/IcoAcceptDialog";
import { DRAFTS_TABLE_FIELDS_CONFIG, ICO_DOCS_TABLE_FIELDS_CONFIG, ICO_DOCS_TABLE_NAME } from "./PageTablesConfig";
import DocsFileUploadDialog from "../../components/dialog/DocsFileUploadDialog";
import { DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER } from "../../helpers/constants";
import BoxWithTypography from "../../components/box/BoxWithTypography/BoxWithTypography";
import { useTranslation } from "react-i18next";
import BaseTable from "../../components/base/BaseTable/BaseTable";
import TableService from "../../services/tableService";
import AddButton from "../../components/button/AddButton";
import DraftDialog from "../../components/dialog/DraftDialog/DraftDialog";
import useFileService from "../../services/fileService";
import { draftDownloadUrl } from "../../helpers/apiUrls";
import IcoGenerateDialog from "../../components/dialog/IcoGenerateDialog";
import IcoSignDialog from "../../components/dialog/IcoSignDialog";
import IcoArchiveDialog from "../../components/dialog/IcoArchiveDialog/IcoArchiveDialog";

const TABLE_CONFIGS = [
  { name: ICO_DOCS_TABLE_NAME, config: ICO_DOCS_TABLE_FIELDS_CONFIG },
];

export default function IcoDocumentsPage(props) {
  const { pageName } = props;
  const { t } = useTranslation();

  const [icosDataLocal, setIcosDataLocal] = useState([]);
  const [draftsLocal, setDraftsLocalLocal] = useState([]);
  const [countIcoRecord, setCountIcoRecord] = useState();
  const [selectedIcoId, setSelectedIcoId] = useState();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState(undefined);
  // const [hasEditPermission] = useCheckPermission(EDIT_AGREEMENTS_PERMISSION)
  const [hasEditPermission] = [true]
  const avaibleRestrictedAreas = [DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER]
  const [visibiltyTablesConfig, setVisibiltyTablesConfig] = useState(
    { name: ICO_DOCS_TABLE_NAME, config: ICO_DOCS_TABLE_FIELDS_CONFIG.filter((c) => c.label !== undefined) }
  )


  const {
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams();

  const [
    openAcceptDialog,
    chosenIcoId,
    onOpenAcceptDialog,
    onCloseAcceptDialog,
  ] = useDialogWithId();

  const [
    openGenerateDialog,
    chosenGenerateIcoId,
    onOpenGenerateDialog,
    onCloseGenerateDialog,
  ] = useDialogWithId();

  const [
    openIcoSignDialog,
    chosenIcoSignIcoId,
    onOpenIcoSignDialog,
    onCloseIcoSignDialog,
  ] = useDialogWithId();

  const [
    openArchiveDialog,
    chosenArchiveIcoId,
    onOpenArchiveDialog,
    onCloseArchiveDialog,
  ] = useDialogWithId();

  const [
    openFileDocsDialog,
    chosenFileDocsIcoId,
    onOpenFileDocsDialog,
    onCloseFileDocsDialog,
  ] = useDialogWithId();

  const [
    openDraftDialog,
    chosenDraftId,
    onOpenDraftDialog,
    onCloseDraftDialog,
  ] = useDialogWithId();

  const { getIcoDocsRegistryList, getIcoFilteringData, getDrafts } = useIcoService();

  const { dowloadBackendFile } = useFileService();

  const icosData = useAsync(
    () => getIcoDocsRegistryList(searchParams),
    [searchParams]
  );

  const icoFilteringData = useAsync(getIcoFilteringData);
  const draftsData = useAsync(getDrafts);

  useEffect(() => {
    if (draftsData.loading) {
      return;
    }
    setDraftsLocalLocal(draftsData.value)
  }, [draftsData.loading]);

  useEffect(() => {
    if (icosData.loading) {
      return;
    }
    setIcosDataLocal(icosData.value.results);
    setCountIcoRecord(icosData.value.count);
  }, [icosData.loading]);


  const handleClickIcoRow = useCallback((id) => {
    setSelectedIcoId(id)
  }, [])

  const onClickAccept = useCallback((e, data) => {
    e.stopPropagation();
    if (data?.allow_accepts && hasEditPermission) {
      onOpenAcceptDialog(data.id)
    }
  }, [hasEditPermission])

  const onClickGenerate = useCallback((e, data) => {
    e.stopPropagation();
    if (data?.allow_generate && hasEditPermission) {
      onOpenGenerateDialog(data.id)
    }
  }, [hasEditPermission])

  const onClickSignDoc = useCallback((e, data) => {
    e.stopPropagation();
    if (data?.allow_sign && hasEditPermission) {
      onOpenIcoSignDialog(data.id)
    }
  }, [hasEditPermission])

  const onClickArchive = useCallback((e, data) => {
    e.stopPropagation();
    if (data?.allow_archive && hasEditPermission) {
      onOpenArchiveDialog(data.id)
    }
  }, [hasEditPermission])

  const onClickUppload = useCallback((e, dataId) => {
    e.stopPropagation();
    if (hasEditPermission) {
      onOpenFileDocsDialog(dataId)
    }
  }, [hasEditPermission])


  const isAgreementTableLoading =
    icoFilteringData.loading ||
    icosDataLocal === undefined

  const getAddRowButton = useCallback(() => {
    if (hasEditPermission) {
      return (<AddButton onClick={() => onOpenDraftDialog()} />)
    }
  }, [hasEditPermission])

  const onClickEditDraft = useCallback((e, draftId) => {
    e.stopPropagation();
    onOpenDraftDialog(draftId)
  }, [])

  const handleDownloadDraft = useCallback((e, draft) => {
    e.stopPropagation();
    dowloadBackendFile(
      draftDownloadUrl(draft.id),
      draft.draft_content_name
    )
  }, [])

  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_ICO}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={pageName}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12} marginY={1}>
            <UniversalToolBarWithDialogs
              pageName={pageName}
              tableConfig={visibiltyTablesConfig}
              filteringData={icoFilteringData ? icoFilteringData : []}
              refreshTable={icosData.refetch}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showMassActionButton={false}
              showCreateButton={false}
              showOpenCloseFilterButton={false}
              showCleanfiltersButton={true}
              showMyFiltersButton={true}
              showSearchButton={true}
              extraButtonList={[]}
              extraButtonListMdGrid={[]}
              itemType={"ico"}
              style={{ marginBlock: "5px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("page.ico_documents_page.ico_agreements")}
              component={"div"}
            >
              <LoaderWrapper showLoader={isAgreementTableLoading}>
                <AgreementTable
                  data={icosDataLocal}
                  onClickRow={handleClickIcoRow}
                  countRecords={countIcoRecord}
                  resetPageNumber={resetPageNumber}
                  filteringData={icoFilteringData ? icoFilteringData : []}
                  hiddenColumns={hiddenColumnsForTables ? hiddenColumnsForTables[TABLE_CONFIGS.name] : []}
                  columnsOrders={columnsOrdersForTables ? columnsOrdersForTables[TABLE_CONFIGS.name] : []}
                  selectedAgreementId={selectedIcoId}
                  style={{ maxHeight: "50vh" }}
                  tableConfig={TABLE_CONFIGS[0].config}
                  showCleanFilterIcon={true}
                  showContextMenu={false}
                  showExportToFileButton={false}
                  showDetailsIcon={false}
                  showCheckbox={false}
                  hasPermission={hasEditPermission}
                  onClickAccept={onClickAccept}
                  onClickGenerate={onClickGenerate}
                  onClickSignDoc={onClickSignDoc}
                  onClickArchive={onClickArchive}
                  onClickUppload={onClickUppload}

                />
              </LoaderWrapper>
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("page.ico_documents_page.drafts")}
              component={"div"}
            >
              <LoaderWrapper showLoader={isAgreementTableLoading}>
                <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                  {getAddRowButton()}
                  <BaseTable
                    headers={TableService.getHeaderLables(DRAFTS_TABLE_FIELDS_CONFIG)}
                    rows={TableService.getPreparedDataForBaseTable(DRAFTS_TABLE_FIELDS_CONFIG,
                      draftsLocal,
                      [],
                      [],
                      onClickEditDraft,
                      hasEditPermission,
                      handleDownloadDraft
                    )}
                  />
                </div>
              </LoaderWrapper>
            </BoxWithTypography>
          </Grid>
        </Grid>
        {openAcceptDialog &&
          <IcoAcceptDialog
            open={openAcceptDialog}
            onClose={onCloseAcceptDialog}
            onRefetchData={icosData.refetch}
            icoId={chosenIcoId}
          />
        }
        {openGenerateDialog &&
          <IcoGenerateDialog
            open={openGenerateDialog}
            onClose={onCloseGenerateDialog}
            icoId={chosenGenerateIcoId}
            onDataRefetch={icosData.refetch}
          />
        }
        {openIcoSignDialog &&
          <IcoSignDialog
            open={openIcoSignDialog}
            onClose={onCloseIcoSignDialog}
            icoId={chosenIcoSignIcoId}
            onDataRefetch={icosData.refetch}
          />
        }
        {openArchiveDialog &&
          <IcoArchiveDialog
            open={openArchiveDialog}
            onClose={onCloseArchiveDialog}
            icoId={chosenArchiveIcoId}
            onDataRefetch={icosData.refetch}
          />
        }
        {openFileDocsDialog &&
          <DocsFileUploadDialog
            open={openFileDocsDialog}
            onClose={onCloseFileDocsDialog}
            icoId={chosenFileDocsIcoId}
            hasEditPermission={hasEditPermission}
            avaibleRestrictedAreas={avaibleRestrictedAreas}
          />
        }
        {
          openDraftDialog &&
          <DraftDialog
            open={openDraftDialog}
            onClose={onCloseDraftDialog}
            draftId={chosenDraftId}
            onDataRefetch={draftsData.refetch}
            initFormValue={{ draft_is_ico: true }}
          />
        }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
