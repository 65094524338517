import { useMemo, useState, useEffect, useRef, useCallback } from "react";

import PropTypes from "prop-types";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { useAsync } from "../../hooks/useAsync";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import TableService from "../../services/tableService";

import SelectEquipmentFilterTypeButton from "../../components/button/SelectEquipmentFIlterTypeButton";
import EquipmentTable from "../../components/table/EquipmentTable/EquipmentTable";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog";

import useDialog from "../../hooks/useDialog";

import {
  EQUIPMENT_INSTALATION_TABLE_NAME,
  EQUIPMENT_STATE_TABLE_NAME,
  EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME,
  EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME,
  EQUIPMENT_STATE_TABLE_PREFIX,
  EQUIPMENT_INSTALATION_TABLE_PREFIX,
  EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_PREFIX,
  EQUIPMENT_INSTALATION_TENANT_SET_TABLE_PREFIX,
} from "./TableConfig";

import useEquipmentService from "../../services/equipmentService";
import {
  exportEquipmentStateForMetersToFileUrl,
  exportEquipmentInstalationToFileUrl,
  exportEquipmentInstalationObjectSetToFileUrl,
  exportEquipmentInstalationTenantSetToFileUrl,
} from "../../helpers/apiUrls";

const EquipmentTableWithToolBar = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(props.tableFilterPrefix);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  } = useFilterSearchParams(searchParams);

  const [equipmentSearchParams, setEquipmentSearchParams] = useState(
    getSearchParamsByFilterPrefix(props.tableFilterPrefix)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      equipmentSearchParams,
      setEquipmentSearchParams
    );
  }, [searchParams]);

  const {
    getEquipmentStateForMeters,
    getEquipmentInstalationObjectSetForMeters,
    getEquipmentInstalationTenantSetForMeters,
    getEquipmentInstalationForMeters,
    getEquipmentForMetersFilteringData,
  } = useEquipmentService();

  const getEquipmentDataFnByTableName = (equipmentSearchParams) => {
    if (props.tableName === EQUIPMENT_STATE_TABLE_NAME)
      return getEquipmentStateForMeters(equipmentSearchParams);
    else if (props.tableName === EQUIPMENT_INSTALATION_TABLE_NAME)
      return getEquipmentInstalationForMeters(equipmentSearchParams);
    else if (props.tableName === EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME)
      return getEquipmentInstalationObjectSetForMeters(equipmentSearchParams);
    else if (props.tableName === EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME)
      return getEquipmentInstalationTenantSetForMeters(equipmentSearchParams);

    return null;
  };

  const equipmentFilteringData = useAsync(getEquipmentForMetersFilteringData);

  const equipmentData = useAsync(
    () => getEquipmentDataFnByTableName(equipmentSearchParams),
    [equipmentSearchParams, props.tableName]
  );

  const [equipmentDataLocaly, setEquipmentDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (equipmentData.loading) {
      return;
    }
    setEquipmentDataLocaly(equipmentData.value.results);
    setCountRecords(equipmentData.value.count);
  }, [equipmentData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setEquipmentDataLocaly(undefined);
    equipmentData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setEquipmentDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      props.tableConfig,
      hiddenColumnsForTables?.[props.tableName],
      columnsOrdersForTables?.[props.tableName]
    );
  }, [
    props.tableConfig,
    hiddenColumnsForTables?.[props.tableName],
    columnsOrdersForTables?.[props.tableName],
  ]);

  const exportUrl = useMemo(() => {
    if (props.tableName === EQUIPMENT_STATE_TABLE_NAME) {
      return exportEquipmentStateForMetersToFileUrl;
    }

    if (props.tableName === EQUIPMENT_INSTALATION_TABLE_NAME) {
      return exportEquipmentInstalationToFileUrl;
    }

    if (props.tableName === EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME) {
      return exportEquipmentInstalationObjectSetToFileUrl;
    }

    if (props.tableName === EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME) {
      return exportEquipmentInstalationTenantSetToFileUrl;
    }
  }, [props.tableName]);

  const handleChangeSelectedTable = (filterValue) => {
    setEquipmentDataLocaly(undefined);
    setEquipmentSearchParams({});
    setHiddenColumnsForTables(undefined);
    setColumnsOrdersForTables(undefined);
    props.setSelectedTable(filterValue);
    setPageSize(10);
    setSearchParams({
      ...clearSearchParamsByFilterPrefixes([
        EQUIPMENT_STATE_TABLE_PREFIX,
        EQUIPMENT_INSTALATION_TABLE_PREFIX,
        EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_PREFIX,
        EQUIPMENT_INSTALATION_TENANT_SET_TABLE_PREFIX,
      ]),
    });
  };

  const getExtraButtonListForToolBar = () => {
    return [
      <SelectEquipmentFilterTypeButton
        onChangeFilterValue={handleChangeSelectedTable}
        selectedFilterKey={props.tableName}
      />,
    ];
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, equipmentId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = equipmentDataLocaly.find(
        (equipment) => equipment.id === equipmentId
      ).location;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, equipmentDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const isLoading =
    equipmentDataLocaly === undefined || equipmentFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
      marginTop={1}
    >
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showMyFiltersButton={false}
          showOpenCloseFilterButton={false}
          tableConfig={props.tableConfigForUserPreferencesDialog}
          filteringData={equipmentFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={props.tableFilterPrefix}
          extraButtonList={getExtraButtonListForToolBar()}
          mdGridExtraButton={2.25}
        />
      </Grid>
      {hiddenColumnsForTables && columnsOrdersForTables && (
        <Grid item xs={12}>
          <EquipmentTable
            data={equipmentDataLocaly}
            showCheckbox={false}
            showCleanFilterIcon={false}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            filteringData={equipmentFilteringData}
            style={{ maxHeight: "75vh" }}
            tableConfig={props.tableConfig}
            hiddenColumns={hiddenColumnsForTables[props.tableName]}
            columnsOrders={columnsOrdersForTables[props.tableName]}
            onClickMultipleLocationAlert={handleOpenMultipleLocalizationDialog}
            showExportToFileButton={true}
            exportToFileSearchParams={equipmentSearchParams}
            exportToFileUrl={exportUrl}
            exportToFileHeaders={exportHeaders}
            exportToFileFileName={`${t("headers.meter_search")}.xlsx`}
            filterPrefix={props.tableFilterPrefix}
          />
        </Grid>
      )}
      {openMultipleLocalizationDialog &&
        clickedOrderMultipleLocalization.current && (
          <MultipleLocalizationDialog
            open={openMultipleLocalizationDialog}
            onClose={handleCloseMultipleLocalizationDialog}
            localizationData={clickedOrderMultipleLocalization.current}
            isLevelObjectData
            itemType={"scheme"}
          />
        )}
    </Grid>
  );
};

EquipmentTableWithToolBar.propTypes = {
  pageName: PropTypes.string,
  tableName: PropTypes.string,
  tableConfig: PropTypes.object,
  tableFilterPrefix: PropTypes.string,
  tableConfigForUserPreferencesDialog: PropTypes.array,
  setSelectedTable: PropTypes.func,
};

EquipmentTableWithToolBar.defaultProps = {};

export default EquipmentTableWithToolBar;
