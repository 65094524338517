import { useState, useEffect, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import AutocompleteField from "../../field/AutocompleteField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";

import useLocationService from "../../../services/locationService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import LocationRecords from "../../other/LocationRecords";

import ObjectParentDialog from "./ObjectParentDialog";
import useDialog from "../../../hooks/useDialog";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

import {
  isArraysEquals,
  getErrorMsg,
  isEmptyValue,
  isEmptyObject,
} from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = [
  "object_name",
  "object_code",
  "location",
  "object_shared",
];

const ObjectCreateUpdateDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const objectLevel = parseInt(props.objectLevel);

  const isDialogCreateMode = props.dialogMode === DIALOG_CREATE_MODE;
  const isDialogEditMode = props.dialogMode === DIALOG_EDIT_MODE;

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return isDialogCreateMode
      ? `${t("dialog.object.add_new_object")} ${t(
        "dialog.object.level"
      )} ${objectLevel}`
      : isDialogEditMode
        ? `${t("dialog.object.edit_object")} ${t(
          "dialog.object.level"
        )} ${objectLevel}`
        : `${t("dialog.object.object_details")} ${t(
          "dialog.object.level"
        )} ${objectLevel}`;
  }, [props.dialogMode, props.title]);

  const submitButtonLabel = useMemo(() => {
    return isDialogCreateMode ? t("create") : t("save");
  }, props.dialogMode);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(() => {
    let fieldsToSaveTemp = ["object_name", "object_code"];
    if (objectLevel !== 1) {
      fieldsToSaveTemp.push("location");
    }

    if (objectLevel === 4) {
      fieldsToSaveTemp = fieldsToSaveTemp.concat(["object_shared", "object_public", "tenant"]);

    }

    if (objectLevel === 5) {
      fieldsToSaveTemp = fieldsToSaveTemp.concat([
        "object_category",
        "object_subcategory",
        "object_area",
      ]);
    }

    return fieldsToSaveTemp;
  }, [objectLevel]);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    getObjectAdminDetailsData,
    createObject,
    updateObject,
    getObjectAdminFilteringDataForDetails,
    prepareLocationDataFromBackendForLocationRecordsComponent,
    prepareLocationDataToSend,
  } = useLocationService();

  const filteringData = useAsync(getObjectAdminFilteringDataForDetails);

  const prepareInitialDataForFormValue = (initialData) => {
    if (isEmptyValue(initialData)) return initialData;

    let location = { ...initialData.location };

    const currentLocationLevel = `level_${props.objectLevel}`;
    const currentLocationLevelData = {
      ...location[currentLocationLevel],
    };

    if (!isEmptyObject(location)) {
      location[currentLocationLevel] = null;
    }

    return {
      object_name: currentLocationLevelData?.object_name,
      object_code: currentLocationLevelData?.object_code,
      location:
        objectLevel > 1
          ? prepareLocationDataFromBackendForLocationRecordsComponent(
            isDialogEditMode ? location : null,
            false
          )
          : null,
      object_shared: initialData.object_shared,
      object_category: initialData.object_category?.id,
      object_subcategory: initialData.object_subcategory?.id,
      object_area: initialData.object_area,
      object_public: initialData.object_public,
      tenant: initialData.tenant,
    };
  };

  const [isLocationsChanged, setIsLocationChanged] = useState(false);
  const handleChangeLocation = (locationData) => {
    setIsLocationChanged(true);
    setFormValue((prev) => ({ ...prev, location: locationData }));
  };

  const handleChangeCategory = (...iputProps) => {
    setFormValue((prev) => ({ ...prev, object_subcategory: null }));
    onChangeAutocompleteFieldWithObjectOptions(...iputProps);
  };

  const handleChangeObjectShared = (e) => {
    // setFormValue((prev) => ({ ...prev, tenant: [] }));
    onChange(e);
  };

  const objectInitialData = useAsync(() => {
    if (isDialogCreateMode) {
      return Promise.resolve({});
    }

    return getObjectAdminDetailsData(props.objectId);
  }, [props.objectId, props.dialogMode]);

  useEffect(() => {
    if (objectInitialData.loading) return;

    setFormValue(prepareInitialDataForFormValue(objectInitialData.value));
  }, [objectInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = { object_level: objectLevel };
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        if (key === "location") {
          if (isLocationsChanged) {
            dataToSend["parent"] = prepareLocationDataToSend(value)[0];
          }
          continue;
        }

        if (
          key === "tenant" &&
          isDialogEditMode &&
          isArraysEquals(objectInitialData.value?.tenant, value)
        ) {
          continue;
        }

        dataToSend[key] = value;
      }
    }
    return dataToSend;
  };

  const updateObjectFn = useAsyncFn(updateObject);
  const createObjectFn = useAsyncFn(createObject);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE ? createObjectFn : updateObjectFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.objectId)
      .then((res) => {
        const successMsg =
          props.dialogMode === DIALOG_CREATE_MODE
            ? t("snackbar_alert.object_added")
            : t("snackbar_alert.object_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => {
      if (!fieldsToSave.includes(field)) return true;

      if (field === "location") {
        return (
          formValue["location"][0].filter((object) => object.id).length ===
          objectLevel - 1
        );
      }

      return !isEmptyValue(formValue[field]);
    });

  const [
    openParentObjectDialog,
    onOpenParentObjectDialog,
    onCloseParentObjectDialog,
  ] = useDialog();

  const parentObjectDialogLevel = useRef(1);
  const handleOpenParentObjectDialog = (level) => {
    parentObjectDialogLevel.current = level;
    onOpenParentObjectDialog();
  };
  const isLoading = formValue === undefined || filteringData.loading;



  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        {fieldsToSave.includes("location") && (
          <Grid
            item
            xs={props.showAddObjectLevelButton ? 11 : 12}
            marginBottom={1}
          >
            <LocationRecords
              maxAllowedLevelNumber={objectLevel - 1}
              locations={formValue.location}
              onChangeLocations={handleChangeLocation}
              showAddLocationButton={false}
              showLocationRecordTitle={false}
              displayInRows={true}
              showEmptyLevel={true}
            />
          </Grid>
        )}
        {props.showAddObjectLevelButton && objectLevel > 1 && (
          <Grid item xs={1} container marginBottom={1}>
            {Array.from(
              {
                length: formValue?.location[0].length,
              },
              (x, i) => i + 1
            ).map((idx) => (
              <Grid item xs={12} marginTop={1} key={idx}>
                <IconButtonWithTooltip
                  title={`${t("dialog.object.add_new_object")} ${t(
                    "dialog.object.level"
                  )} ${idx}`}
                  onClick={() => handleOpenParentObjectDialog(idx)}
                >
                  <AddCircleOutlineOutlined color="primary" />
                </IconButtonWithTooltip>
              </Grid>
            ))}
          </Grid>
        )}
        {fieldsToSave.includes("object_category") && (
          <>
            <Grid item xs={12} sm={6}>
              <AutocompleteField
                name="object_category"
                optionLabelKey={"objcat_code"}
                optionValueKey={"id"}
                readOnly={isReadOnly}
                value={formValue.object_category}
                options={filteringData?.value?.object_category_code}
                label={t("dialog.object.object_category_code")}
                disableClearable
                onChange={handleChangeCategory}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteField
                name="object_category"
                optionLabelKey={"objcat_name"}
                optionValueKey={"id"}
                readOnly={isReadOnly}
                value={formValue.object_category}
                options={filteringData?.value?.object_category_name}
                label={t("dialog.object.object_category_name")}
                disableClearable
                onChange={handleChangeCategory}
              />
            </Grid>
          </>
        )}
        {fieldsToSave.includes("object_subcategory") && (
          <>
            <Grid item xs={12} sm={6} marginBottom={{ sm: 1, xs: 0 }}>
              <AutocompleteField
                name="object_subcategory"
                optionLabelKey={"objcat_code"}
                optionValueKey={"id"}
                readOnly={isReadOnly || !formValue.object_category}
                value={formValue.object_subcategory}
                options={filteringData?.value?.object_subcategory_code.filter(
                  (sub) => sub.parent_id === formValue.object_category
                )}
                label={t("dialog.object.object_subcategory_code")}
                disableClearable
                onChange={onChangeAutocompleteFieldWithObjectOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6} marginBottom={1}>
              <AutocompleteField
                name="object_subcategory"
                optionLabelKey={"objcat_name"}
                optionValueKey={"id"}
                readOnly={isReadOnly || !formValue.object_category}
                value={formValue.object_subcategory}
                options={filteringData?.value?.object_subcategory_name.filter(
                  (sub) => sub.parent_id === formValue.object_category
                )}
                label={t("dialog.object.object_subcategory_name")}
                disableClearable
                onChange={onChangeAutocompleteFieldWithObjectOptions}
              />
            </Grid>
          </>
        )}

        {fieldsToSave.includes("object_shared") && (
          <Tooltip title={<h2>{t("dialog.object.if_true_object_shows_all_om_if_tenant_is_selected_also_this_r_have_access")}</h2>} arrow placement="top">
            <Grid item xs={12} sm={6}>
              <TrueFalseSelectField
                name={"object_shared"}
                label={t("dialog.object.object_shared")}
                value={formValue.object_shared}
                onChange={handleChangeObjectShared}
                addEmptyOptions={false}
                valuesAsBool
              />
            </Grid>
          </Tooltip>
        )}
        {fieldsToSave.includes("object_public") && (
            <Tooltip title={<h2>{t("dialog.object.if_true_object_shows_all_users_in_my_solvy")}</h2>} arrow placement="top">
          <Grid item xs={12} sm={6}>
              <TrueFalseSelectField
                name={"object_public"}
                label={t("dialog.object.object_public")}
                value={formValue?.object_public}
                onChange={handleChangeObjectShared}
                addEmptyOptions={false}
                valuesAsBool
              />
          </Grid>
          </Tooltip>
        )}
        {fieldsToSave.includes("tenant") && (
          <Grid item xs={12} sm={6}>

            <AutocompleteField
              name={"tenant"}
              label={t("dialog.object.tenant")}
              value={formValue.tenant}
              options={filteringData?.value?.tenant}
              multiple
              isObjectOption={true}
              optionLabelKey={"tenant_short_name"}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              addNewValue={false}
            />

          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="object_name"
            required
            value={formValue.object_name}
            label={t("dialog.object.object_name")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="object_code"
            required
            value={formValue.object_code}
            label={t("dialog.object.object_code")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        {fieldsToSave.includes("object_area") && (
          <Grid item xs={12} sm={6}>
            <FloatField
              name={"object_area"}
              label={t("dialog.object.object_area")}
              value={formValue.object_area}
              onChange={onChange}
            />
          </Grid>
        )}

        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={createObjectFn.loading || updateObjectFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {submitButtonLabel}
            </LoadingButton>
          </Grid>
        )}
        {openParentObjectDialog && (
          <ObjectParentDialog
            open={openParentObjectDialog}
            onClose={onCloseParentObjectDialog}
            onSubmitCallback={filteringData.refetch}
            dialogMode={DIALOG_CREATE_MODE}
            objectLevel={parentObjectDialogLevel.current}
            showAddObjectLevelButton={false}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="sm"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ObjectCreateUpdateDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  objectId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  objectLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showAddObjectLevelButton: PropTypes.bool,
};

ObjectCreateUpdateDialog.defaultProps = {
  open: false,
  showAddObjectLevelButton: true,
};

export default ObjectCreateUpdateDialog;
