import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import useWarehouseService from "../../../../services/warehouseService";
import { useAsync } from "../../../../hooks/useAsync";
import { getTableConfigByItemType } from "./TableConfig";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import TransactionTable from "../../../table/TransactionTable";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import TransactionDialog from "../../../dialog/TransactionDialog";
import TransactionInForm from "../../../form/TransactionInForm";
import { TRANSACTION_KIND_IN } from "../../../../helpers/constants";
import { transactionInExportDataUrl } from "../../../../helpers/apiUrls";
import { isEmptyObject } from "../../../../helpers/methods";

const WarehouseTransactionInTab = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => {
    return getTableConfigByItemType(props.itemType);
  }, [props.itemType]);

  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);

  const {
    page: transactionInPage,
    pageSize: transactionInPageSize,
    handleChangePageWithSearchParams: handleChangeTransactionInDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const { getTransactionInData } = useWarehouseService();


  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (props.itemType === "equipment") {
      newSearchParams.set('equipment__isnull', false);
      setSearchParams(newSearchParams)
    } else {
      newSearchParams.set('part__isnull', false);
    }
    setSearchParams(newSearchParams)
  }, [searchParams, props.itemType]);


  const transactionInList = useAsync(() => {
      const urlParams = new URLSearchParams(searchParams);
      const params = Object.fromEntries(urlParams.entries());
      if (!isEmptyObject(params)) {
        return getTransactionInData(searchParams);
      }
      return Promise.resolve([])
      }, [searchParams]);

  useImperativeHandle(ref, () => ({
    onRefetchData() {
      transactionInList.refetch();
    },
  }));

  return (
    <Grid container spacing={1}>
      {hasPermissionToEdit && (
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={props.onOpenTransactionDialog}
          >
            {t("tabs.warehouse_transaction_tabs.new_transaction_in")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <TransactionTable
          transactionFilteringData={props.transactionFilteringData}
          tableConfig={tableConfig}
          transactionData={transactionInList}
          page={transactionInPage}
          pageSize={transactionInPageSize}
          handleChangePageWithSearchParams={handleChangeTransactionInDataPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          exportToFileUrl={transactionInExportDataUrl}
          exportToFileSearchParams={searchParams}
        />
      </Grid>
      {props.openTransactionDialog && (
        <TransactionDialog
          open={props.openTransactionDialog}
          title={t("tabs.warehouse_transaction_tabs.new_transaction_in")}
          onClose={props.onCloseTransactionDialog}
          transactionKind={TRANSACTION_KIND_IN}
          extraRequiredFields={["transaction_price"]}
          onSubmit={props.onSubmit}
          isDataSaving={props.isSaving}
          transactionForm={<TransactionInForm itemType={props.itemType} />}
          itemType={props.itemType}
        />
      )}
    </Grid>
  );
});

WarehouseTransactionInTab.propTypes = {
  readOnly: PropTypes.bool,
  itemType: PropTypes.string,
};

WarehouseTransactionInTab.defaultProps = {
  itemType: "part",
};

export default WarehouseTransactionInTab;
