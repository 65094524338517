import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import OwnerBookkeepingBoxForm from "../../form/OwnerBookkeepingBoxForm";
import useUserService from "../../../services/userService";
import { useAsync } from "../../../hooks/useAsync";



export default function OwnerBookkeepingFormBox({ owner_bookks, onEditBookk, onAddBookk, onRemoveBookk, readOnly }) {
    const { t } = useTranslation();

      const {

        getUserSelectList ,
      } = useUserService();
      
      const userSelectList = useAsync(
        () => getUserSelectList({ 'is_active': true, 'is_external': false }));

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t(`other.owner_bookkeeping_form_box.owner_bookkeepings`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />
                {owner_bookks?.map((owner_bookk, index) => {
                    return (
                        <OwnerBookkeepingBoxForm
                            owner_bookk={owner_bookk}
                            index={index}
                            onEditBookk={onEditBookk}
                            onRemoveBookk={onRemoveBookk}
                            readOnly ={readOnly}
                            users = {userSelectList?.value ? userSelectList.value : []}
                        />
                    )
                })
                }
                <Grid item xs={12}>
                    {!readOnly &&
                    <Button
                        fullWidth
                        variant="text"
                        size="small"
                        onClick={onAddBookk}
                    >
                        {t("other.owner_bookkeeping_form_box.add_owner_bookkeeping")}
                    </Button>
                    }
                </Grid>
            </Grid>
        </BaseBox>
    );
}

OwnerBookkeepingFormBox.propTypes = {
    owner_bookks : PropTypes.array,
    onEditBookk : PropTypes.func,
    onAddBookk : PropTypes.func,
    onRemoveBookk : PropTypes.func,
    readOnly : PropTypes.bool
}


OwnerBookkeepingFormBox.defaultProps = {
    owner_bookks : [],
    readOnly : true
}
