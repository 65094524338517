import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ValidationTypography from "../../other/ValidationTypography/ValidationTypography";


const ValidationForm = (props) => {

  const { t } = useTranslation();

  return (
    <>
      {!props.isRequiredeFieldsValid &&
        <ValidationTypography
          label={t("form.validation_form.not_all_required_fields_are_completed")}
        />}

      {!props.sumSettlementRatesIsCorrectly &&
        <ValidationTypography
          label={t("form.validation_form.sum_of_settlement_rate_is_not_equal_one")}
        />}

      {props.offerCostIsEqualZero &&
        <ValidationTypography
          label={t("form.validation_form.sum_of_offer_must_be_more_than_zero")}
        />}

      {props.totalTransferAreHigherThanConditionValue &&
        <ValidationTypography
          label={t("form.validation_form.sum_off_transer_is_higher_than_condition_value")}
        />}

      {props.icoScheduleShouldBeUpdated &&
        <ValidationTypography
          label={t("form.validation_form.recalculate_schedule_again")}
        />}

      {!props.isSumEqualConditionValue &&
        <ValidationTypography
          label={t("form.validation_form.schedule_sum_is_not_equal_condition_value")}
        />}

      {!props.areIcoScheduleDatesAsc &&
        <ValidationTypography
          label={t("form.validation_form.dates_are_not_ascending")}
        />}

      {!props.isFirstInstallmentDateValid &&
        <ValidationTypography
          label={t("form.validation_form.first_installment_date_is_after_first_interest_date")}
        />}

      {!props.isFirstDateAfterActivationDateValid &&
        <ValidationTypography
          label={t("form.validation_form.first_due_date_is_before_activation_date")}
        />}

      {!props.isPartialInstallmentSumIsValid &&
        <ValidationTypography
          label={t("form.validation_form.partial_installment_sum_is_not_valid")}
        />}

      {!props.isAllocatedValueValid &&
        <ValidationTypography
          label={t("form.validation_form.allocated_value_is_grater_than_total_value")}
        />}

      {!props.areAllValuesGreaterThanZero &&
        <ValidationTypography
          label={t("form.validation_form.not_all_allocated_values_are_more_then_zero")}
        />}
      {!props.isActivationDateAfterMinInterestDate &&
        <ValidationTypography
          label={t("form.validation_form.activation_date_is_earlier_than_min_interest_rate_date")}
        />}
    </>
  );
};

ValidationForm.propTypes = {
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  offerCostIsEqualZero: PropTypes.bool,
  totalTransferAreHigherThanConditionValue: PropTypes.bool,
  icoScheduleShouldBeUpdated: PropTypes.bool,
  isSumEqualConditionValue: PropTypes.bool,
  areIcoScheduleDatesAsc: PropTypes.bool,
  isFirstInstallmentDateValid: PropTypes.bool,
  isFirstDateAfterActivationDateValid: PropTypes.bool,
  isPartialInstallmentSumIsValid: PropTypes.bool,
  isAllocatedValueValid: PropTypes.bool,
  areAllValuesGreaterThanZero: PropTypes.bool,
  isActivationDateAfterMinInterestDate: PropTypes.bool
};

ValidationForm.defaultProps = {
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  offerCostIsEqualZero: false,
  totalTransferAreHigherThanConditionValue: false,
  icoScheduleShouldBeUpdated: false,
  isSumEqualConditionValue: true,
  areIcoScheduleDatesAsc: true,
  isFirstInstallmentDateValid: true,
  isFirstDateAfterActivationDateValid: true,
  isPartialInstallmentSumIsValid: true,
  isAllocatedValueValid: true,
  areAllValuesGreaterThanZero: true,
  isActivationDateAfterMinInterestDate: true
};

export default ValidationForm;
