import { useState, useEffect, useCallback } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import { NAVIGATION_DRAWER_TYPE_OFFERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CreateItemDialog from "../../components/dialog/CreateItemDialog";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import BaseBox from "../../components/base/BaseBox/baseBox";
import PropTypes from "prop-types";
import { useForm } from "../../hooks/useForm";
import AutocompleteField from "../../components/field/AutocompleteField";
import useOfferService from "../../services/offerService";
import useItemService from "../../services/itemService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { getErrorMsg } from "../../helpers/methods";
import BoxWithTypography from "../../components/box/BoxWithTypography/BoxWithTypography";
import ItemRecord from "../../components/other/ItemRecord/ItemRecord";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SubcontractorDetailsDialog from "../../components/dialog/SubcontractorDetailsDialog/SubcontractorDetailsDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import useItemFormValidation from "../../hooks/useItemFormValidation";
import useLocationService from "../../services/locationService";
import SelectItemDialog from "../../components/dialog/SelectItemDialog";
import { MANAGE_SERVICES_PERMISSION, SERVICE_MAIN_BRANCH_NAME } from "../../helpers/constants";
import ItemForm from "../../components/form/ItemForm/ItemForm";
import useCheckPermission from "../../hooks/usePermission";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog/confirmationDialog";
import DeleteIcon from '@mui/icons-material/Delete';

export default function ServicePassportPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});
  const {
    unfillRequiredFields,
    locationRecordsRef,
    checkIfRequiredFieldsAreFill
  } = useItemFormValidation("service", formValue);

  const snackbarAlert = useSnackbarAlert();

  const { getChosenHighestLevelObject } = useLocationService()
  const [selectedServiceId, setSelectedServiceId] = useState(undefined);
  const [editMode, setEditMode] = useState(false)


  const { getServiceDetailsData,
    getServiceInfoSelectListData,
    getServiceFilteringData,
    editServiceData,
    removeService } = useOfferService();

  const {
    prepareItemDataFromBackendForItemRecordComponent,
    getEmptyItemElement,
    getItemRecordById } = useItemService()

  const [hasPermissionToManage] = useCheckPermission(MANAGE_SERVICES_PERMISSION)




  const updateServiceDataFn = useAsyncFn(editServiceData)
  const getItemRecordByIdFn = useAsyncFn(getItemRecordById)
  const removeServiceFn = useAsyncFn(removeService)

  const [items, setItems] = useState(getEmptyItemElement());


  const serviceDetailsData = useAsync(() => {
    if (selectedServiceId) {
      return getServiceDetailsData(selectedServiceId);
    }
    return Promise.resolve({});
  },
    [selectedServiceId]);



  useEffect(() => {
    if (serviceDetailsData.loading) {
      return;
    }
    if (serviceDetailsData.value) {
      setFormValue({ ...serviceDetailsData.value });
      setItems(prepareItemDataFromBackendForItemRecordComponent(serviceDetailsData.value.item))
    }
  }, [serviceDetailsData.loading]);


  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();



  const [
    openSuboctractorDialog,
    onOpenSubcontractorDialog,
    onCloseSubcotractorDialog,
  ] = useDialog();

  const [
    openSelectItemDialog,
    onOpenSelectItemDialog,
    onCloseSelectItemDialog,
  ] = useDialog();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const onChangeServiceFromList = useCallback((e, value_object, value_key, state_value_name) => {
    setSelectedServiceId(value_object[value_key])
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    setEditMode(false)
  }, [selectedServiceId])



  const getItemRecord = useCallback((selectedItemId) => {
    getItemRecordByIdFn.execute(selectedItemId)
      .then((result) => {
        setItems(prepareItemDataFromBackendForItemRecordComponent(result.item))
        onCloseSelectItemDialog()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_get_item_data")
        );
      })

  }, [items])



  const serviceSelectList = useAsync(getServiceInfoSelectListData)
  const serviceFilteringData = useAsync(getServiceFilteringData)

  const isLoading = serviceDetailsData.loading || serviceFilteringData.loading


  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
  };

  const onUpdateData = () => {

    if (checkIfRequiredFieldsAreFill()) {
      formValue['item'] = getChosenHighestLevelObject(items)
      updateServiceDataFn
        .execute(formValue.id, formValue)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.service_data_updated")
          );
          setEditMode(false)
          serviceDetailsData.refetch()
          serviceSelectList.refetch()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_service_data_updating")
          );
        })
    }
  } 

  const removeData = () => {
    removeServiceFn
      .execute(formValue.id)
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.service_removed")
        );
        setSelectedServiceId(undefined)
        setFormValue({})
        serviceDetailsData.refetch()
        serviceSelectList.refetch()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_service_data_updating")
        );
      })
}
  
  const handleCloseConfirmationDialog = (confirmRemoveService) => {
    onCloseConfirmationDialog();
    if (confirmRemoveService)
      removeData();
  };

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_OFFERS}>
      <DefaultPageWrapper titleKey={"service_passport"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={{ marginBottom: "10px" }}

        >
          <Grid item xs={12} sm={3} >
            <AutocompleteField
              name={`id`}
              label={t(`form.item_form.choice_service`)}
              value={formValue[`id`]}
              options={serviceSelectList.value}
              isObjectOption={true}
              optionLabelKey={"service_name"}
              addNewValue={false}
              onChange={onChangeServiceFromList}
              disabled={props.readOnly}
            />
          </Grid>
          <Grid item xs={12} sm={3} />
          <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
          {selectedServiceId && !editMode && hasPermissionToManage &&
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="error"
                startIcon={<DeleteIcon sx={centerVericalAlignIconStyle} />}
                onClick={onOpenConfirmationDialog}
              >
                {t("bar.warehouse_tool_bar.remove_service")}
              </Button>
              }
          </Grid>
          <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
            {selectedServiceId && !editMode && hasPermissionToManage &&
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<ModeEditOutlineIcon sx={centerVericalAlignIconStyle} />}
                onClick={() => setEditMode(true)}
              >
                {t("bar.warehouse_tool_bar.edit_service")}
              </Button>}
          </Grid>


          {hasPermissionToManage &&
            <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
                onClick={onOpenCreateItemDialog}
              >
                {t("bar.warehouse_tool_bar.add_service")}
              </Button>
            </Grid>}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={props.style}

        >
          <LoaderWrapper showLoader={isLoading} >
            {selectedServiceId ? (
              <>
                <Grid item xs={12} >
                  <ItemForm
                    itemType={'service'}
                    isPageForm={true}
                    formValue={formValue}
                    itemId={selectedServiceId}
                    readOnly={!editMode}
                    setFormValue={setFormValue}
                    onChange={onChange}
                    onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
                    partChoiceSelectData={serviceFilteringData}
                    onOpenAddNewSupplier={onOpenSubcontractorDialog}
                    unfillRequiredFields={unfillRequiredFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BoxWithTypography
                    style={{ padding: "10px" }}
                    label={t(`form.item_form.purchase_tree`)}
                    component={"div"}
                  >
                    <ItemRecord
                      ref={locationRecordsRef}
                      itemLevels={items}
                      onChangeItem={onChangeLevelItem}
                      readOnly={!editMode}
                      onClickSearchItem={onOpenSelectItemDialog}
                      defaultFirstLevelItemName={SERVICE_MAIN_BRANCH_NAME}
                    />
                  </BoxWithTypography>
                </Grid>
                <Grid item xs={12} sm={4} />
                {editMode &&
                  <Grid item xs={12} sm={4}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      loading={props.isLoading}
                      onClick={onUpdateData}
                    >
                      {t("save_changes")}
                    </LoadingButton>
                  </Grid>}

                <Grid item xs={12} sm={4} />


              </>
            ) : (
              <Grid item xs={12} >
                <BaseBox>
                  <Typography
                    textAlign="center"
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
                  >
                    {t("form.item_form.select_service_first")}
                  </Typography>
                </BaseBox>
              </Grid>
            )}
          </LoaderWrapper>
        </Grid>

        {openCreateItemDialog && (
          <CreateItemDialog
            open={openCreateItemDialog}
            onClose={onCloseCreateItemDialog}
            onRefetchData={serviceSelectList.refetch}
            itemType={'service'}
            treeIndex={2} 
          />
        )}


        {openSuboctractorDialog &&
          <SubcontractorDetailsDialog
            open={openSuboctractorDialog}
            onClose={onCloseSubcotractorDialog}
            readOnly={false}
            onRefetchData={serviceFilteringData.refetch}
          />
        }
        {openSelectItemDialog &&
          <SelectItemDialog
            open={openSelectItemDialog}
            onClose={onCloseSelectItemDialog}
            onSubmit={getItemRecord}
            treeIndex={2} 
          />
        }
        {openConfirmationDialog && (
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={() => handleCloseConfirmationDialog(false)}
            onYes={() => handleCloseConfirmationDialog(true)}
            title={t("page.service_passport_page.alert")}
            content={t("page.service_passport_page.are_you_sure_to_remove_service")}
          />
        )}

      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
ServicePassportPage.propTypes = {
  style: PropTypes.object,
};

ServicePassportPage.defaultProps = {

};


