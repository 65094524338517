import React from "react";
import PropTypes from "prop-types";
import DateService from "../../../services/dateService";
import "./OtherMessage.css";
import ContextMenu from "../../wrapper/ContextMenu";
import { isEmptyValue } from "../../../helpers/methods";


function OtherMessage(props) {
  const getMessageColor = () => {
    if (props.message?.is_log) {
      return "messageStyle loglMessageColor";
    }
    if (props.message?.message_external) {
      return "messageStyle externalMessageColor";
    }
    return "messageStyle internalMessageColor";
  };

  const splitText = "Komentarz:";
  const text = props.message?.message_content;
  let [message, comment] = text.split(splitText);

  message = isEmptyValue(comment) ? message : `${message}${splitText}`

  const messageContent = (
    <div>
      <div class="messageRow">
        <div class={getMessageColor()} style={{ width: 'fit-content' }}>
          <div class="displayName">{props.message?.sender_full_name}</div>
          <div class="messageContent">
   
        {props.message?.is_log ?
        <>
      {message}<b>{comment}</b>
      </>
      :
      <>
      {props.message?.message_content}
      </>
        }

          </div>
          <p class="messageTimeStamp">
            {DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
              props.message?.created
            )}{" "}
          </p>
        </div>
      </div>
    </div>
  );

  return props.showContextMenu ? (
    <ContextMenu
      key={props.message.id}
      showMenu={props.show}
      actions={props.contextMenuActions.map((action) => ({
        label: action.label,
        icon: action.icon,
        callback: () => action.callback(props.message.id),
      }))}
      Component={messageContent}
    />
  ) : (
    messageContent
  );
}

OtherMessage.propTypes = {
  message: PropTypes.object,
  show: PropTypes.bool,
  contextMenuActions: PropTypes.object,
  showContextMenu: PropTypes.bool,
};

OtherMessage.defaultProps = {
  message: {},
  show: false,
  contextMenuActions: {},
  showContextMenu: false,
};

export default OtherMessage;
