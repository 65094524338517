import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import PropTypes from "prop-types";
import {
  MATERIAL_MAIN_BRANCH_NAME,
  DEVICE_MAIN_BRANCH_NAME,
  COUNTER_BRANCH_NAME,
} from "../../../helpers/constants";

const TransactionOutForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <ItemRecord
          itemLevels={props.items}
          onChangeItem={props.onChangeLevelItem}
          onChangeAutocompleteFieldWithObjectOptions={
            props.onChangeAutocomplete
          }
          partValue={props.formValue["part"]}
          equipmentValue={props.formValue["equipment"]}
          onCleanPart={props.onCleanPart}
          showMaterialLabel={props.itemType === "part"}
          showEquipmentLabel={props.itemType === "equipment"}
          required={false}
          onClickSearchItem={props.onClickSearchItem}
          defaultFirstLevelItemName={
            props.itemType === "equipment"
              ? DEVICE_MAIN_BRANCH_NAME
              : MATERIAL_MAIN_BRANCH_NAME
          }
          defaultSecondLevelItemName={
            props.itemType === "equipment" ? COUNTER_BRANCH_NAME : null
          }
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name={`warehouse`}
          label={t(`form.transaction_form.warehouse`)}
          value={props.formValue[`warehouse`]}
          options={props.allowedWarehouses?.value}
          isObjectOption={true}
          optionLabelKey={"warehouse_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <FloatField
          name="transaction_amount"
          readOnly={props.readOnly}
          value={props.formValue[`transaction_amount`]}
          label={t("form.transaction_form.transaction_amount")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={1.35}>
        <FloatField
          name="stock_amount"
          disabled={true}
          value={props.formValue[`stock_amount`]}
          label={t("form.reservation_form.stock_amount")}
        />
      </Grid>
      <Grid item xs={1.35}>
        <FloatField
          name="stock_availability"
          disabled={true}
          value={props.formValue[`stock_availability`]}
          label={t("form.buffer_details_form.stock_amount")}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFieldFormControl
          disabled={true}
          value={
            props.formValue.part_unit
              ? t(`other.units.${props.formValue.part_unit}`)
              : undefined
          }
          label={t("form.transaction_form.part_unit")}
        />
      </Grid>
      <Grid item xs={1.3}>
        <FloatField
          name="transaction_price"
          disabled={true}
          value={props.formValue[`transaction_price`]}
          label={t("form.transaction_form.transaction_price")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="transaction_wz"
          onChange={props.onChange}
          value={props.formValue[`transaction_wz`]}
          label={t("form.transaction_form.transaction_wz")}
          disabled={true}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name={`transaction_order`}
          label={t(`form.transaction_form.order`)}
          value={props.formValue[`transaction_order`]}
          options={props.orders}
          isObjectOption={true}
          optionLabelKey={"order_descriptive_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldFormControl
          name="transaction_note"
          onChange={props.onChange}
          value={props.formValue[`transaction_note`]}
          label={t("form.transaction_form.transaction_note")}
          multiline
          rows={3}
          resize={"vertical"}
        />
      </Grid>
    </Grid>
  );
};

TransactionOutForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  readOnly: PropTypes.bool,
  transactionSelectData: PropTypes.array,
  poPartList: PropTypes.array,
};

TransactionOutForm.defaultProps = {
  readOnly: false,
};

export default TransactionOutForm;
