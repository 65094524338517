import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const CreateParameterForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="param_name"
          label={t("form.create_parameter_form.param_name")}
          value={props.formValue.param_name}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TrueFalseSelectField
          name="param_is_text"
          label={t("form.create_parameter_form.param_is_text")}
          value={props.formValue.param_is_text}
          valuesAsBool
          onChange={props.onChangeParamIsText}
          addEmptyOptions={false}
          required
        />
      </Grid>
      {!props.formValue.param_is_text && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name="param_unit"
            label={t("form.create_parameter_form.param_unit")}
            value={props.formValue.param_unit}
            onChange={props.onChange}
            required
          />
        </Grid>
      )}
    </Grid>
  );
};

CreateParameterForm.propTypes = {
  onChange: PropTypes.func,
  onChangeParamIsText: PropTypes.func,
  readOnly: PropTypes.bool,
};

CreateParameterForm.defaultProps = {
  readOnly: false,
};

export default CreateParameterForm;
