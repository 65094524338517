import * as React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../base/BaseTable/BaseTable";

import CheckboxField from "../../base/CheckboxField/checkboxField";
import TableService from "../../../services/tableService";

function areRowDataPropsEqual(oldProps, newProps) {
  return (
    oldProps.id === newProps.id &&
    oldProps.selected === newProps.selected &&
    oldProps.backgroundColor === newProps.backgroundColor &&
    oldProps.data.length === newProps.data.length &&
    oldProps.updated === newProps.updated &&
    oldProps.updatedInnerElementData === newProps.updatedInnerElementData &&
    oldProps.updatedSelectedInnerElement ===
      newProps.updatedSelectedInnerElement
  );
}

const CollapsibleTableDataRow = React.memo((props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandedTable = (e) => {
    e.stopPropagation();
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
  };

  const handleClickDetailsIcon = (e) => {
    e.stopPropagation();
    props.onClickDetailsIcon();
  };

  const getColapsibleElementColSpan = () => {
    let colSpan = props.data.length + 1;
    if (props.showDetailsIcon) colSpan += 1;
    if (props.showChangesCircle) colSpan += 1;
    return colSpan;
  };

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            cursor: "pointer",
            background: props.backgroundColor,
          },
        }}
        onClick={() => props.onClickRow && props.onClickRow(props.id)}
      >
        {props.showChangesCircle && (
          <StyledTableCell>
            {props.hasChanges ? (
              <CircleIcon
                color="success"
                fontSize="small"
                sx={{ marginLeft: "8px" }}
              />
            ) : (
              <CircleIcon
                fontSize="small"
                sx={{ marginLeft: "8px", color: "#999" }}
              />
            )}
          </StyledTableCell>
        )}
        {props.showCheckbox && (
          <StyledTableCell>
            <CheckboxField
              size="small"
              labelPlacement={"bottom"}
              checked={props.selected}
              onChange={() => props.onClickCheck(props.id)}
            />
          </StyledTableCell>
        )}
        {props.showDetailsIcon && (
          <StyledTableCell>
            <Tooltip title={t("open_in_new_explorer_window")}>
              <IconButton size="small" onClick={handleClickDetailsIcon}>
                <OpenInBrowserIcon />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        )}
        <StyledTableCell>
          <IconButton size="small" onClick={handleExpandedTable}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        {props.data.map((rowData, index) => (
          <StyledTableCell key={index} component="th" align="center">
            {TableService.getCellContent(rowData.value, rowData.maxTextLength)}
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={getColapsibleElementColSpan()}
          align="center"
        >
          <Collapse in={expanded} style={expanded ? { padding: "10px" } : {}}>
            {expanded &&
              React.cloneElement(props.CollapsibleElement, {
                id: props.id,
                updatedInnerElementData:
                  props.updatedInnerElementData || props.updated,
                isClosed: props.is_closed,
              })}
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}, areRowDataPropsEqual);

export default CollapsibleTableDataRow;

CollapsibleTableDataRow.propTypes = {
  id: PropTypes.string,
  updated: PropTypes.string,
  backgroundColor: PropTypes.string,
  is_closed: PropTypes.bool,
  data: PropTypes.array,
  selected: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickRow: PropTypes.func,
  CollapsibleElement: PropTypes.element,
  selectedItemId: PropTypes.string,
  style: PropTypes.object,
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  onClickDetailsIcon: PropTypes.func,
  hasChanges: PropTypes.func,
  showChangesCircle: PropTypes.func,
};

CollapsibleTableDataRow.defaultProps = {
  showCheckbox: true,
  showDetailsIcon: true,
  hasChanges: true,
  showChangesCircle: false,
};
