import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

const AccountBoxForm = ({
  index,
  account,
  onEditAccount,
  onRemoveAccount,
  readOnly }
) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8} onClick={() => onEditAccount(account?.id)}>
        <TextFieldFormControl
          value={account?.account_nr}
          label={account?.account_name}
          readOnly = {readOnly}
        />
      </Grid>

      {!readOnly &&
        <Grid item xs={0.5}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveAccount(account?.id, index)
            }}
            title={t("other.owner_account_form_box.remove_account")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

AccountBoxForm.propTypes = {
  account: PropTypes.object,
  onEditAccount : PropTypes.func,
  onRemoveAccount : PropTypes.func,
  readOnly : PropTypes.bool
};

AccountBoxForm.defaultProps = {
  readOnly : true
};

export default AccountBoxForm;
