import i18n from "../../i18n";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import SelectFieldService from "../../services/selectFieldService";
import DateService from "../../services/dateService";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

export const ADMIN_USER_TENANT_TABLE_NAME = "admin_user_tenant_table";
export const ADMIN_USER_TENANT_TABLE_FILTER_PREFIX = "user_tenant";
export const ADMIN_USER_TENANT_TABLE_CONFIG = [
  {
    name: "user__first_name",
    getValueCallback: (rowData) => rowData.user__first_name,
    label: i18n.t("table.user_tenant_table.user__first_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "user__last_name",
    getValueCallback: (rowData) => rowData.user__last_name,
    label: i18n.t("table.user_tenant_table.user__last_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "user__user_external_system",
    getValueCallback: (rowData) => rowData.user__user_external_system,
    label: i18n.t("table.user_tenant_table.user__user_external_system"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.user__user_external_system,
      isObjectOption: false,
      multiple: true,
      addNewValue: false,
    }),
    sortable: false,
  },
  {
    name: "user__user_external_identificator",
    getValueCallback: (rowData) => rowData.user__user_external_identificator,
    label: i18n.t("table.user_tenant_table.user__user_external_identificator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: true,
    sortable: true,
  },
  {
    name: "user__mail",
    getValueCallback: (rowData) => rowData.user__mail,
    label: i18n.t("table.user_tenant_table.user__mail"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "user__phone",
    getValueCallback: (rowData) => rowData.user__phone,
    label: i18n.t("table.user_tenant_table.user__phone"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "user__roles",
    getValueCallback: (rowData) => rowData.user__roles,
    label: i18n.t("table.user_tenant_table.user__roles"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.user__roles,
      isObjectOption: true,
      optionLabelKey: "role_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.user_tenant_table.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "user__object",
    getValueCallback: (rowData) => rowData.user__object,
    label: i18n.t("table.user_tenant_table.user__object"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "uste_rr_visibility",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.uste_rr_visibility),
    label: i18n.t("table.user_tenant_table.uste_rr_visibility"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "user__is_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.user__is_active),
    label: i18n.t("table.user_tenant_table.user_is_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "user__last_login",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.user__last_login
      ),
    label: i18n.t("table.user_tenant_table.last_login"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "band",
    getValueCallback: (rowData) => rowData.band,
    label: i18n.t("table.user_tenant_table.band"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.band,
      isObjectOption: true,
      optionLabelKey: "band_name",
      addNewValue: false,
      multiple: true,
    }),
    sortable: true,
  },
  {
    name: "uste_band_tenant",
    getValueCallback: (rowData) => rowData.uste_band_tenant,
    label: i18n.t("table.user_tenant_table.uste_band_tenant"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "uste_note",
    getValueCallback: (rowData) => rowData.uste_note,
    label: i18n.t("table.user_tenant_table.uste_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "responsibilities",
    getValueCallback: (rowData) => rowData.responsibilities,
    label: i18n.t("table.user_tenant_table.responsibilities"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.responsibilities,
      isObjectOption: true,
      optionLabelKey: "responsibility_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("table.user_tenant_table.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
