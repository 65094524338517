export const IS_R = "is_r";
export const IS_OM = "is_om";
export const IS_K = "is_k";
export const IS_KT = "is_kt";
export const IS_T = "is_t";
export const IS_TB = "is_tb";
export const IS_RR = "is_rr";
export const IS_G = "is_g";
export const IS_ADMIN = "is_admin";
export const IS_LEGAL = "is_legal";
export const IS_WAREHOUSE = "is_warehouse";
export const IS_WAREHOUSE_ADMIN = "is_warehouse_admin";
export const IS_POST_ADMIN = "is_post_admin";
export const IS_FINANCE = "is_finance";
export const IS_ESG = "is_esg";
export const IS_SCM = "is_scm";
export const IS_ACCOUNT = "is_account";
export const IS_POST = "is_post";
export const IS_SALE = "is_sale";
export const IS_COMMERCE = "is_commerce";
export const IS_INTERIOR = "is_interior";
export const IS_DPI = "is_dpi";
export const IS_HR = "is_hr";
export const IS_CONTROLLING = "is_controlling";
export const IS_USERS_ADMIN = "is_users_admin";
export const IS_TENANTS_ADMIN = "is_tenants_admin";
export const IS_OBJECTS_ADMIN = "is_objects_admin";
export const IS_STD_TICKETS_ADMIN = "is_std_tickets_admin";
export const IS_AUTO_TICKETS_ADMIN = "is_auto_tickets_admin";
export const IS_REPORT_SAFETY = "is_report_safety";
export const IS_REPORT_FM = "is_report_fm";
export const IS_CONTACT_BASE = "is_contact_base";
export const IS_POST_ATTACHMENT = "is_post_attachment";
export const IS_ICO_ADMIN = "is_ico_admin";
export const IS_AAD_USER = "is_aad_user";

export const ALL_PERMISSION_LIST = [
  IS_K,
  IS_KT,
  IS_OM,
  IS_R,
  IS_T,
  IS_TB,
  IS_RR,
  IS_G,
  IS_ADMIN,
  IS_LEGAL,
  IS_WAREHOUSE,
  IS_FINANCE,
  IS_ESG,
  IS_ACCOUNT,
  IS_POST,
  IS_SALE,
  IS_COMMERCE,
  IS_INTERIOR,
  IS_DPI,
  IS_HR,
  IS_CONTROLLING,
  IS_USERS_ADMIN,
  IS_TENANTS_ADMIN,
  IS_OBJECTS_ADMIN,
  IS_STD_TICKETS_ADMIN,
  IS_AUTO_TICKETS_ADMIN,
  IS_REPORT_FM,
  IS_REPORT_SAFETY,
  IS_CONTACT_BASE,
  IS_POST_ATTACHMENT,
  IS_ICO_ADMIN
];

export const ALL_PERMISSION_LIST_WITHOUT_OM = ALL_PERMISSION_LIST.filter(
  (permision) => permision !== IS_OM && permision !== IS_R
);

function hasValue(obj, key, value) {
  return obj?.hasOwnProperty(key) && obj[key] === value;
}

export default function hasUserPermission(requiredRanks = [], userData) {
  return (
    requiredRanks.length === 0 ||
    requiredRanks.some((rank) => hasValue(userData, rank, true))
  );
}

export function isUserOm(userData) {
  return (
    hasUserPermission([IS_OM, IS_R], userData) &&
    !hasUserPermission(
      ALL_PERMISSION_LIST.filter(
        (permision) => permision !== IS_OM && permision !== IS_R
      ),
      userData
    )
  );
}

export function isAADUser(userData) {
  return userData.is_aad_user === true;
}
