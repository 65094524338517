import BoxWithLabel from "../../box/BoxWithLabel";

import FileUploadList from "../../other/FileUploadList";

import { useTranslation } from "react-i18next";

import { INTERNAL_TYPE } from "../../../helpers/constants";

import useEnclosuresData from "../../../hooks/useEnclosuresData";

const MeterEnclosureForm = (props) => {
  const { t } = useTranslation();

  const {
    enclosures,
    saveEnclosures,
    onPreviewEnclosure,
    onDownloadEnclosure,
    updateEquipmentEnclosure,
    deleteEnclosureForEquipment,
  } = useEnclosuresData(props.equipmentId, undefined, "equipment");

  return (
    <BoxWithLabel
      label={t("form.meter_passport.enclosures")}
      boxStyle={{ height: "100%" }}
      // style={{ maxHeight: "300px" }}
    >
      <FileUploadList
        addEnclosureButtonProps={{ size: "mini" }}
        defaultEnclosureType={INTERNAL_TYPE}
        enclosures={enclosures}
        onPreviewEnclosure={onPreviewEnclosure}
        onDownloadEnclosure={onDownloadEnclosure}
        onUpdateEnclosure={updateEquipmentEnclosure}
        onDeleteEnclosure={deleteEnclosureForEquipment}
        onAddEnclosure={saveEnclosures}
        canRemoveEnclosures={true}
        readOnly={false}
        showExtendedAddEnclosureDialog={true}
        showSubcontractorSelect={false}
        showEnclosureType={false}
        allowCopyEnclosure={false}
        enclosureWindowStyle={{ maxHeight: "200px" }}
      />
    </BoxWithLabel>
  );
};

export default MeterEnclosureForm;
