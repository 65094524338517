import i18n from "../../../i18n";

export const PARAMS_TABLE_NAME = "Params";

const getParamValueFieldNameByItemType = (itemType) => {
  if (itemType === "equipment") {
    return "equparval_value";
  }

  return "parval_value";
};

export const getParamsTableFieldConfigByItemType = (itemType) => {
  return [
    {
      name: "param_name",
      getValueCallback: (rowData) => rowData.param_name,
      label: i18n.t("tabs.material_param_related_tables.name"),
      filterType: undefined,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    },
    {
      name: getParamValueFieldNameByItemType(itemType),
      getValueCallback: (rowData) =>
        rowData[getParamValueFieldNameByItemType(itemType)],
      label: i18n.t("tabs.material_param_related_tables.value"),
      filterType: undefined,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    },
    {
      name: "param_unit",
      getValueCallback: (rowData) => rowData.param_unit,
      label: i18n.t("tabs.material_param_related_tables.unit"),
      filterType: undefined,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    },
  ];
};
