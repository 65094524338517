import i18n from "../../../i18n";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import DateService from "../../../services/dateService";
import SelectFieldService from "../../../services/selectFieldService";

export const EQUIPMENT_INSTALATION_TABLE_CONFIG = [
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData) => rowData.location?.level_1?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_1_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) => rowData.location?.level_2?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_2_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) => rowData.location?.level_3?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_3_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) => rowData.location?.level_4?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_4_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) => rowData.location?.level_5?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_5_name"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipinstal_date_instalation",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.equipinstal_date_instalation
      ),
    label: i18n.t("table.counter_repository.equipinstal_date_instalation"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipinstal_date_deinstalation",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.equipinstal_date_deinstalation
      ),
    label: i18n.t("table.counter_repository.equipinstal_date_deinstalation"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipinstal_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equipinstal_active),
    label: i18n.t("table.counter_repository.equipinstal_active"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    headerSx: { whiteSpace: "break-spaces" },
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
