import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";
import useItemCalculationService from "../../../services/itemCalculationService";
import SelectField from "../../field/SelectField";
import { SERVICES_KWH_UNIT } from "../../../helpers/constants";


const ItemForm = (props) => {
  const { t } = useTranslation();

  const {
    onChangeCalculatedFields,
    getVatRate
  } = useItemCalculationService()

  useEffect(() => {
    if (props.formValue.id === undefined){
      let tempFormValue = props.formValue
      tempFormValue[`${props.itemType}_vat_proc`] = getVatRate()
      props.setFormValue({ ...tempFormValue })
    }
  }, [props.itemType])


  const onChangeCalculatedFieldsLocal = useCallback((e) => {
    onChangeCalculatedFields(e, props.itemType, props.formValue, props.setFormValue)
  }, [props.itemType, props.formValue, props.setFormValue]);

  const getTranslatedPartList = () => {
    let translatedUnits = []
    for (let partUnit of props.partChoiceSelectData?.value[`${props.itemType}_unit`]) {
      translatedUnits.push({ key: partUnit, name: t(`other.units.${partUnit}`) })
    }
    return translatedUnits
  }


  return (
    <Grid container spacing={1}>
      <Grid item xs={2.4 }>
        <TextFieldFormControl
          name={`${props.itemType}_name`}
          label={t(`form.item_form.name`)}
          value={props.formValue[`${props.itemType}_name`]}
          onChange={props.onChange}
          required
          disabled={props.readOnly}
          isInvalid={props.unfillRequiredFields.includes(`${props.itemType}_suplier`)}
          helperText={
            props.unfillRequiredFields.includes(`${props.itemType}_suplier`) &&
            t("field_required")}
        />
      </Grid>
      <Grid item xs={2.4}>
        <AutocompleteField
          name={`${props.itemType}_suplier`}
          label={t(`form.item_form.default_${props.itemType}_suplier`)}
          value={props.formValue[`${props.itemType}_suplier`]}
          options={props.partChoiceSelectData?.value?.[`${props.itemType}_supliers`]}
          isObjectOption={true}
          optionLabelKey={"subcontractor_short_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
          disabled={props.readOnly}
          error={props.unfillRequiredFields.includes(`${props.itemType}_suplier`)}
          helperText={
            props.unfillRequiredFields.includes(`${props.itemType}_suplier`) &&
            t("field_required")
          }
        />
      </Grid>
      <Grid item xs={2.4}>
        <AutocompleteField
          name={`account`}
          label={t(`form.item_form.account`)}
          value={props.formValue[`account`]}
          options={props.partChoiceSelectData?.value?.accounts}
          isObjectOption={true}
          optionLabelKey={"account_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
          disabled={props.readOnly}
          error={props.unfillRequiredFields.includes(`account`)}
          helperText={
            props.unfillRequiredFields.includes(`account`) &&
            t("field_required")
          }
        />
      </Grid>
      <Grid item xs={2.4}>
        <AutocompleteField
          name={`${props.itemType}_area`}
          label={t(`form.item_form.area`)}
          value={props.formValue[`${props.itemType}_area`]}
          options={props.partChoiceSelectData?.value?.ticket_areas}
          isObjectOption={true}
          optionLabelKey={"ta_area"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
          disabled={props.readOnly}
          error={props.unfillRequiredFields.includes(`${props.itemType}_unit`)}
          helperText={
            props.unfillRequiredFields.includes(`${props.itemType}_unit`) &&
            t("field_required")
          }
        />
      </Grid>

      <Grid item xs={2.4}>
        <AutocompleteField
          name={`gtu`}
          label={t(`form.item_form.gtu`)}
          value={props.formValue[`gtu`]}
          options={props.partChoiceSelectData?.value?.gtus}
          isObjectOption={true}
          optionLabelKey={"gtu_name"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={2.4}>
        <SelectField
          name={`${props.itemType}_unit`}
          label={t(`form.item_form.unit`)}
          value={props.formValue[`${props.itemType}_unit`]}
          options={getTranslatedPartList()}
          optionLabel={"name"}
          optionKey={"key"}
          addNewValue={false}
          onChange={props.onChange}
          required
          disabled={props.readOnly}
          error={props.unfillRequiredFields.includes(`${props.itemType}_unit`)}
          helperText={
            props.unfillRequiredFields.includes(`${props.itemType}_unit`) &&
            t("field_required")
          }
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={`${props.itemType}_price`}
          label={t(`form.item_form.unit_price`)}
          value={props.formValue[`${props.itemType}_price`]}
          onChange={onChangeCalculatedFieldsLocal}
          suffix={"zł"}
          disabled={props.readOnly}
          required
          decimalPlaces={props.itemType === "part" ?  2:3}
          isInvalid={props.unfillRequiredFields.includes(`${props.itemType}_price`)}
          helperText={
            props.unfillRequiredFields.includes(`${props.itemType}_price`) &&
            t("field_required")
          }
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={`${props.itemType}_vat_proc`}
          label={t(`form.item_form.vate_rate`)}
          value={props.formValue[`${props.itemType}_vat_proc`]}
          disabled={true}
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={`${props.itemType}_margin_min_pln`}
          label={t(`form.item_form.margin_unit`)}
          value={props.formValue[`${props.itemType}_margin_min_pln`]}
          onChange={onChangeCalculatedFieldsLocal}
          suffix={"zł"}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={`${props.itemType}_margin_min_proc`}
          label={t(`form.item_form.margin_unit`)}
          value={props.formValue[`${props.itemType}_margin_min_proc`]}
          onChange={onChangeCalculatedFieldsLocal}
          suffix={"%"}
          disabled={props.readOnly}
        />
      </Grid>

    </Grid>
  );
};

ItemForm.propTypes = {
  onSubmit: PropTypes.func,
  ticketId: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onOpenAddNewSupplier: PropTypes.func,
  subcontractorSelectList: PropTypes.array,
  itemType: PropTypes.oneOf(['service', 'part']),
};

ItemForm.defaultProps = {
  readOnly: false,
  subcontractorSelectList: [],
  itemType: 'service'
};

export default ItemForm;
