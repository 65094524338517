import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import { getTranslatedList } from "../../helpers/methods";

export const ICO_REGISTRY_TABLE_NAME = "ico_registry_table";

export const ICO_REGISTRY_TABLE_FIELDS_CONFIG = [
  {
    name: "ico_nr",
    getValueCallback: (rowData) => rowData.ico_nr,
    label: i18n.t("table.icos_table.agreement_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true
  },  
  {
    name: "ico_product",
    getValueCallback: (rowData) => rowData.ico_product,
    label: i18n.t("table.icos_table.product"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.products,
      isObjectOption: true,
      optionLabelKey: "icoprod_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "ico_agreement",
    getValueCallback: (rowData) => i18n.t(`form.ico_main_data_form.${rowData.ico_agreement}`),
    label: i18n.t("table.icos_table.agreement_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: getTranslatedList(filteringData?.value?.agreement_kinds,"form.ico_main_data_form."),
      isObjectOption: true,
      optionLabelKey: "name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
 
  {
    name: "ico_name",
    getValueCallback: (rowData) => rowData.ico_name,
    label: i18n.t("table.icos_table.agreement_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true
  },    
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.icos_table.currency"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.currencies,
      isObjectOption: true,
      optionLabelKey: "currency_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "lender",
    getValueCallback: (rowData) => rowData.lender,
    label: i18n.t("table.icos_table.lender"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "borrower",
    getValueCallback: (rowData) => rowData.borrower,
    label: i18n.t("table.icos_table.borrower"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "ico_date_agreement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_agreement),
    label: i18n.t("table.icos_table.agreement_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "condition_value",
    getValueCallback: (rowData) => rowData.condition_value,
    label: i18n.t("table.icos_table.condition_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
      showNumberSeparator : true
    }),
    sortable: true,
  },
  {
    name: "ico_current_capital_pln",
    getValueCallback: (rowData) => rowData.ico_current_capital_pln,
    label: i18n.t("table.icos_table.current_capital_pln"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
      showNumberSeparator : true
    }),
    sortable: true,
  },
  {
    name: "ico_current_value_interest_pln",
    getValueCallback: (rowData) => rowData.ico_current_value_interest_pln,
    label: i18n.t("table.icos_table.current_interest_pln"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
      showNumberSeparator : true
    }),
    sortable: true,
  },
  {
    name: "interest",
    getValueCallback: (rowData) => rowData.interest,
    label: i18n.t("table.icos_table.interest"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.interests,
      isObjectOption: true,
      optionLabelKey: "interest_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "condition_margin",
    getValueCallback: (rowData) => rowData.condition_margin,
    label: i18n.t("table.icos_table.margin"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_date_activation",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_activation),
    label: i18n.t("table.icos_table.date_activation"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_date_repayment",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.ico_date_repayment),
    label: i18n.t("table.icos_table.date_repayment"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ico_status",
    getValueCallback: (rowData) => rowData.ico_status,
    label: i18n.t("table.icos_table.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.statuses,
      isObjectOption: true,
      optionLabelKey: "ico_status_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },  
  {
    name: "ico_note_finance",
    getValueCallback: (rowData) => rowData.ico_note_finance,
    label: i18n.t("table.icos_table.note_finance"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }, 
  {
    name: "ico_note_legal",
    getValueCallback: (rowData) => rowData.ico_note_legal,
    label: i18n.t("table.icos_table.note_legal"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }, 
  
 
];
