import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_PAYMENT } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import AutocompleteField from "../../components/field/AutocompleteField";

import DateField from "../../components/field/DateField/DateField";
import DateService from "../../services/dateService";

import useOfferService from "../../services/offerService";
import useCorrespondenceService from "../../services/correspondenceService";
import { useAsync } from "../../hooks/useAsync";

import { useForm } from "../../hooks/useForm";

import { overflowButtonStyle } from "../../helpers/styles";

import IconButtonWithTooltip from "../../components/button/IconButtonWithTooltip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export default function GrtReportPage(props) {
  const { t } = useTranslation();

  const { getOwnersSelectList } = useCorrespondenceService();
  const ownerSelectList = useAsync(() =>
    getOwnersSelectList({ all_owners: true })
  );

  const {
    formValue,
    onChangeAutocompleteFieldWithObjectOptions,
    onChangeDateConvertedToDateTimeWithZeroHourOfDate: onChangeStartDate,
    onChangeDateConvertedToDateTimeWithEndHourOfDate: onChangeEndDate,
  } = useForm({ hours_timezone_offset: DateService.getHoursTimezoneOffset() });

  const { getGrtReport } = useOfferService();

  return (
    <NavigationDrawer
      pageName={"grt_report"}
      drawerType={NAVIGATION_DRAWER_TYPE_PAYMENT}
    >
      <DefaultPageWrapper titleKey={"grt_report"}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={3}
          marginTop={2}
        >
          <Grid container item xs={12} md={2.5} marginBottom={1}>
            <Grid item xs={1.5}>
              <IconButtonWithTooltip title={t("excluded")}>
                <PriorityHighIcon
                  sx={{ color: "var(--alert)" }}
                  fontSize="small"
                />
              </IconButtonWithTooltip>
            </Grid>
            <Grid item xs={10.5}>
              <AutocompleteField
                label={t("form.offer.owner")}
                name={"offer_owner_excluded"}
                value={formValue?.offer_owner_excluded}
                options={ownerSelectList.value || []}
                isObjectOption={true}
                optionLabelKey={"owner_short_name"}
                onChange={onChangeAutocompleteFieldWithObjectOptions}
                addNewValue={false}
                multiple
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={2.5} marginBottom={1}>
            <AutocompleteField
              label={t("form.offer.owner")}
              name={"offer_owner"}
              value={formValue?.offer_owner}
              options={ownerSelectList.value || []}
              isObjectOption={true}
              optionLabelKey={"owner_short_name"}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              addNewValue={false}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={2.5} marginBottom={1}>
            <DateField
              name={"grt_start_date_range"}
              label={t("page.grt_report.grt_start_date_range")}
              value={formValue.grt_start_date_range}
              onChange={onChangeStartDate}
              showClearIcon={true}
            />
          </Grid>
          <Grid item xs={12} md={2.5} marginBottom={1}>
            <DateField
              name={"grt_end_date_range"}
              label={t("page.grt_report.grt_end_date_range")}
              value={formValue.grt_end_date_range}
              onChange={onChangeEndDate}
              showClearIcon={true}
            />
          </Grid>
          <Grid item xs={12} md={2} marginBottom={1}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              sx={overflowButtonStyle}
              onClick={() => getGrtReport(formValue)}
            >
              {t("page.grt_report.download_report")}
            </Button>
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
