import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import PropTypes from "prop-types";
import DateField from "../../field/DateField/DateField";
import { useMemo } from "react";
import {
  MATERIAL_MAIN_BRANCH_NAME,
  DEVICE_MAIN_BRANCH_NAME,
  COUNTER_BRANCH_NAME,
} from "../../../helpers/constants";

const ReservationForm = (props) => {
  const { t } = useTranslation();

  const reservationMinDate = useMemo(() => new Date(), []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <ItemRecord
          itemLevels={props.items}
          onChangeItem={props.onChangeLevelItem}
          onChangeAutocompleteFieldWithObjectOptions={
            props.onChangeAutocomplete
          }
          partValue={props.formValue["part"]}
          equipmentValue={props.formValue["equipment"]}
          onCleanPart={props.onCleanPart}
          showMaterialLabel={props.itemType === "part"}
          showEquipmentLabel={props.itemType === "equipment"}
          required={false}
          onClickSearchItem={props.onClickSearchItem}
          defaultFirstLevelItemName={
            props.itemType === "equipment"
              ? DEVICE_MAIN_BRANCH_NAME
              : MATERIAL_MAIN_BRANCH_NAME
          }
          defaultSecondLevelItemName={
            props.itemType === "equipment" ? COUNTER_BRANCH_NAME : null
          }
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name={`warehouse`}
          label={t(`form.reservation_form.warehouse`)}
          value={props.formValue[`warehouse`]}
          options={props.allowedWarehouses?.value}
          isObjectOption={true}
          optionLabelKey={"warehouse_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteField
          name="ticket"
          label={t("other.related_ticket_record.ticket")}
          value={props.formValue.ticket}
          options={props.ticketSelectList ? props.ticketSelectList?.value : []}
          isObjectOption={true}
          optionLabelKey={"ticket_descriptive_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
        />
      </Grid>
      <Grid item xs={1.5}>
        <FloatField
          name="reservation_amount"
          readOnly={props.readOnly || props.itemType === "equipment"}
          value={props.formValue[`reservation_amount`]}
          label={t("form.reservation_form.reservation_amount")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={1.5}>
        <FloatField
          name="stock_availability"
          disabled={true}
          value={props.formValue[`stock_availability`]}
          label={t("form.reservation_form.stock_availability")}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFieldFormControl
          disabled={true}
          value={
            props.formValue.part_unit
              ? t(`other.units.${props.formValue.part_unit}`)
              : undefined
          }
          label={t("form.reservation_form.part_unit")}
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name={"reservation_closed_date"}
          value={props.formValue[`reservation_closed_date`]}
          onChange={props.onChangeDate}
          label={t("form.reservation_form.reservation_closed_date")}
          required
          minDate={reservationMinDate}
          showClearIcon={false}
        />
      </Grid>
    </Grid>
  );
};

ReservationForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  readOnly: PropTypes.bool,
  transactionSelectData: PropTypes.array,
  poPartList: PropTypes.array,
};

ReservationForm.defaultProps = {
  readOnly: false,
};

export default ReservationForm;
