import React from "react";
import PropTypes from "prop-types";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";

import DateService from "../../../services/dateService";

const MeterBasicInfoForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel label={t("form.meter_passport.basic_info")}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={2}>
          <TextFieldFormControl
            name={"equipment_nr"}
            label={t("form.meter_passport.equipment_nr")}
            value={props.formValue.equipment_nr}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_date_production"}
            label={t("form.meter_passport.equipment_date_production")}
            value={DateService.convertDatetimeFromBackendToFormatYYYYMM(
              props.formValue.equipment_date_production
            )}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldFormControl
            name={"equipment_date_legalization"}
            label={t("form.meter_passport.equipment_date_legalization")}
            value={DateService.convertDatetimeFromBackendToFormatYYYYMM(
              props.formValue.equipment_date_legalization
            )}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFormControl
            name={"equipment_responsible_user"}
            label={t("form.meter_passport.equipment_responsible_user")}
            value={props.formValue.equipment_responsible_user}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            readOnly={true}
          />
        </Grid>
      </Grid>
    </BoxWithLabel>
  );
};

MeterBasicInfoForm.propTypes = {
  formValue: PropTypes.object,
};

MeterBasicInfoForm.defaultProps = {};

export default MeterBasicInfoForm;
