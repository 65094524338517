import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useUserService from "../../../services/userService";
import IbanDetailsForm from "../../form/IbanDetailsForm";
import OwnerBookkDetailsForm from "../../form/OwnerBookkDetailsForm/OwnerBookkDetailsForm";


const OwnerBookkeepingDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({ owner: props.ownerId });

  const onCustomChangeAutoComplete  = useCallback(
      (e, value_object, value_key, state_value_name) => {
        setFormValue((prev) => ({...prev, ownboo_person_name : value_object.full_name}))
        return onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
      },
      []
    );

  const {
    getOwnerBookkDetails,
    createOwnerBookk,
    updateOwnerBookk,
    getUserSelectList ,
    getOwnerBookkFilteringData
  } = useUserService();

  const userSelectList = useAsync(
    () => getUserSelectList({ 'is_active': true, 'is_external': false }));

  const ownerBookkFilteringData = useAsync(getOwnerBookkFilteringData)

  const updateOwnerBookkFn = useAsyncFn(updateOwnerBookk)
  const createOwnerBookkFn = useAsyncFn(createOwnerBookk)

  const ownBookkDetailsData = useAsync(
    () => getOwnerBookkDetails(props.ownBookkId),
    [props.ownBookkId]
  );


  useEffect(() => {
    if (ownBookkDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(ownBookkDetailsData.value)) {
      setFormValue(ownBookkDetailsData.value);
    }
  }, [ownBookkDetailsData.loading]);



  const onSubmit = useCallback(() => {
    if (props.ownBookkId) {
      updateOwnerBookkFn
        .execute(props.ownBookkId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.owner_bookkeeping_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_owner_bookkeeping_updating")
          );
        });
    } else {
      if (props.ownerId) {
        createOwnerBookkFn
          .execute(formValue)
          .then((res) => {
            snackbarAlert.openSuccessSnackbarAlert(
              t("snackbar_alert.owner_bookkeeping_created")
            );
            props.onClose();
            props.onRefetch();
          })
          .catch((error) => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_owner_bookkeeping_creating")
            );
          });
      } else {
        props.onAddBookForNewOwner(formValue)
        props.onClose();
      }
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.ownboo_person) && !isEmptyValue(formValue.ownboo_function)



  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.ownBookkId ? t(`dialog.owner_books_details_dialog.user_details`) : t(`dialog.owner_books_details_dialog.add_user`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {ownBookkDetailsData.loading || userSelectList.loading?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <OwnerBookkDetailsForm
                ownBookData={formValue}
                onChange={onChange}
                onChangeAutocomplete={onCustomChangeAutoComplete}
                users={userSelectList?.value ? userSelectList?.value : []}
                filteringData={ownerBookkFilteringData?.value ? ownerBookkFilteringData?.value : []}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={updateOwnerBookkFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.ownBookkId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

OwnerBookkeepingDialog.propTypes = {
  ownerId: PropTypes.string,
  ownBookkId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

OwnerBookkeepingDialog.defaultProps = {
  open: false,
};

export default OwnerBookkeepingDialog;
