import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useCallback, useState, useMemo } from "react";
import { MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES, MAX_FILENAME_LENGTH_CHARS } from "../../../helpers/constants";
import useFileService from "../../../services/fileService";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoadingButton from "@mui/lab/LoadingButton";
import AddFileForm from "../../form/AddFileForm/AddFileForm";

function AddDraftDialog(props) {

    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

  
 
    const [selectedFiles, setSelectedFiles] = useState([])

    const { showFileByBlobUrl } = useFileService();


    const setNotAddedNames = (namesArr) => {
        let formattedNames = ''
        for (let name of namesArr) {
            formattedNames += '\n' + name
        }
        return t("snackbar_alert.max_allowed_file_size_or_name_is_exceeded_files_are_not_added") + formattedNames
    }

    const onChooseFile = (e) => {
        let selectedFiles = e.target.files;
        let enclosures = []
        let notAddedEnclosuresNames = []
        if (selectedFiles) {
            for (let file of selectedFiles) {
                let file_data = { file: file, draft_name: file.name, content_type: file.type }

                if (file.size < MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES && file.name.length < MAX_FILENAME_LENGTH_CHARS) {
                    file_data.blob_url = window.URL.createObjectURL(new Blob([file_data.file], { type: file_data.file.type }));
                    enclosures.push(file_data)
                } else {
                    notAddedEnclosuresNames.push(file.name)
                }
            }
            setSelectedFiles((enclosuresTemp) => {
                enclosuresTemp = [...enclosuresTemp, ...enclosures]
                return enclosuresTemp
            })
            if (notAddedEnclosuresNames.length > 0) {
                snackbarAlert.openWarningSnackbarAlert(
                    setNotAddedNames(notAddedEnclosuresNames)
                );
            }
        }
    }
    const onPreviewNewEnclosure = useCallback((enclosuerId, index) => {
        let file_data = selectedFiles[index]
        showFileByBlobUrl(file_data.blob_url)
    }, [selectedFiles])


    const onDeleteSelectedEnclosure = (index) => {
        setSelectedFiles((enclosuresTemp) => {
            enclosuresTemp = [...enclosuresTemp]
            enclosuresTemp.splice(index, 1)
            return enclosuresTemp
        })
    }

    const onSubmitButtonAction = useCallback(() => {
        props.onSaveOnMemoryFile(selectedFiles)
        props.onClose()
        setSelectedFiles([])
    }, [selectedFiles])

    const isLoadingButtonDisabled = useMemo(() => {
        return selectedFiles.length === 0;
    }, [selectedFiles]);



    return (
        <>
            <BasicDialog
                open={props.open}
                onClose={props.onClose}
                titleAlign="center"
                contentAlign="center"
                title={t("dialog.add_draft_dialog.add_draft")}
                maxWidth="sm"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={1}
                >

                    <Grid item xs={12}>
                        <AddFileForm
                            docs={selectedFiles}
                            onChooseFile={onChooseFile}
                            onDeleteEnclosure={onDeleteSelectedEnclosure}
                            onPreview={onPreviewNewEnclosure}
                            fileType={props.fileType}
                            multiple={props.multiple}
                            accept={props.accept}
                            showButtonWithFileRestriction = {selectedFiles.length < 1}
                        
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            disabled={isLoadingButtonDisabled}
                            variant="contained"
                            onClick={onSubmitButtonAction}
                            component="label"
                            loading={props.docsLoading}
                            fullWidth
                            startIcon={<FileUploadIcon />}
                        >
                            {t("dialog.add_draft_dialog.upload_file")}
                        </LoadingButton>
                    </Grid>
                </Grid>


            </BasicDialog>
        </>
    )

}

AddDraftDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDownload: PropTypes.func,
    onPreview: PropTypes.func,
    mode: PropTypes.string
};

AddDraftDialog.defaultProps = {
    open: false,
    mode: "create"
};

export default AddDraftDialog