import { useState, useEffect, useCallback } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CreateItemDialog from "../../components/dialog/CreateItemDialog";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import BaseBox from "../../components/base/BaseBox/baseBox";
import ItemExtendForm from "../../components/form/ItemExtendForm/ItemExtendForm";
import PropTypes from "prop-types";
import { useForm } from "../../hooks/useForm";
import AutocompleteField from "../../components/field/AutocompleteField";
import StockTransactionsRelatedTablesTabs from "../../components/tabs/StockTransactionsRelatedTablesTabs";
import MaterialParamRelatedTablesTabs from "../../components/tabs/MaterialParamRelatedTablesTabs";
import useCertsData from "../../hooks/useCertsData";
import useEnclosuresData from "../../hooks/useEnclosuresData";
import PartParameterDialog from "../../components/dialog/PartParameterDialog";
import useItemService from "../../services/itemService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { getErrorMsg } from "../../helpers/methods";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BoxWithTypography from "../../components/box/BoxWithTypography/BoxWithTypography";
import ItemRecord from "../../components/other/ItemRecord/ItemRecord";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import useItemFormValidation from "../../hooks/useItemFormValidation";
import useLocationService from "../../services/locationService";
import SelectItemDialog from "../../components/dialog/SelectItemDialog";
import {
  DEVICE_MAIN_BRANCH_NAME,
  COUNTER_BRANCH_NAME,
} from "../../helpers/constants";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog/confirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import useEquipmentService from "../../services/equipmentService";
import ItemEquipmentForm from "../../components/form/ItemEquipmentForm";

export default function EquipmentPassportPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});

  const {
    unfillRequiredFields,
    locationRecordsRef,
    checkIfRequiredFieldsAreFill,
  } = useItemFormValidation("equipment", formValue);

  const snackbarAlert = useSnackbarAlert();

  const { getChosenHighestLevelObject } = useLocationService();
  const [equipmentParameterLocal, setEquipmentParameterLocal] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(undefined);
  const [selectedParameterValue, setSelectedParameterValue] =
    useState(undefined);
  const [editMode, setEditMode] = useState(false);

  const {
    getEquipmentItemDetials,
    updateEquipment,
    removeEquipment,
    getEquipmentForCreateMeterFilteringData,
    getEquipmentSelectList,
    createParameterValue,
    updateParameterValue,
    removeParameterValue,
  } = useEquipmentService();

  const {
    getEquipmentParametersList,
    prepareItemDataFromBackendForItemRecordComponent,
    getEmptyItemElement,
    getItemRecordById,
  } = useItemService();

  // const [hasPermissionToManage] = useCheckPermission(MANAGE_PARTS_PERMISSION);
  const hasPermissionToManage = true;

  const createParameterValueFn = useAsyncFn(createParameterValue);
  const updateParameterValueFn = useAsyncFn(updateParameterValue);
  const removeParameterValueFn = useAsyncFn(removeParameterValue);
  const removeEquipmentFn = useAsyncFn(removeEquipment);
  const updateEquipmentFn = useAsyncFn(updateEquipment);
  const getItemRecordByIdFn = useAsyncFn(getItemRecordById);
  const [items, setItems] = useState(getEmptyItemElement());

  const {
    certs,
    updateCerts,
    saveCerts,
    hideCerts,
    onDownloadCert,
    onPreviewCert,
  } = useCertsData(selectedItemId, "equipment");

  const {
    enclosures,
    saveEnclosures,
    onPreviewEnclosure,
    onDownloadEnclosure,
    updateEquipmentEnclosure,
    deleteEnclosureForEquipment,
  } = useEnclosuresData(selectedItemId, undefined, "equipment");

  const equipmentDetailsData = useAsync(() => {
    if (selectedItemId) {
      return getEquipmentItemDetials(selectedItemId);
    }
    return Promise.resolve({});
  }, [selectedItemId]);

  const equipmentParametersData = useAsync(() => {
    if (selectedItemId) {
      return getEquipmentParametersList(selectedItemId);
    }
    return Promise.resolve([]);
  }, [selectedItemId]);

  useEffect(() => {
    if (equipmentDetailsData.loading) {
      return;
    }
    if (equipmentDetailsData.value) {
      setFormValue(equipmentDetailsData.value);
      setItems(
        prepareItemDataFromBackendForItemRecordComponent(
          equipmentDetailsData.value.item
        )
      );
    }
  }, [equipmentDetailsData.loading]);

  useEffect(() => {
    if (equipmentParametersData.loading) {
      return;
    }
    if (equipmentParametersData.value) {
      setEquipmentParameterLocal(equipmentParametersData.value);
    }
  }, [equipmentParametersData.loading]);

  const onAddParameter = (paramValuesData) => {
    if (paramValuesData.id) {
      updateParameterValueFn
        .execute(paramValuesData.id, paramValuesData)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.parameter_value_updated")
          );
          onCloseAddPartParameterDialog();
          equipmentParametersData.refetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_parameter_value_updating")
          );
        });
    } else {
      createParameterValueFn
        .execute(paramValuesData)
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.parameter_value_added")
          );
          onCloseAddPartParameterDialog();
          equipmentParametersData.refetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_parameter_value_adding")
          );
        });
    }
  };

  const onHidePramaterValue = (parameterValueId) => {
    let parameter = getParamterValueById(parameterValueId);
    removeParameterValueFn
      .execute(parameter.id)
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.parameter_value_removed")
        );
        onCloseAddPartParameterDialog();
        equipmentParametersData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_parameter_value_removing")
        );
      });
  };

  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();

  const [
    openAddPartParameterDialog,
    onOpenAddPartParameterDialog,
    onCloseAddPartParameterDialog,
  ] = useDialog();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog();

  const [
    openSelectItemDialog,
    onOpenSelectItemDialog,
    onCloseSelectItemDialog,
  ] = useDialog();

  const onChangeEquipmentFromList = useCallback(
    (e, value_object, value_key, state_value_name) => {
      setSelectedItemId(value_object[value_key]);
      onChangeAutocompleteFieldWithObjectOptions(
        e,
        value_object,
        value_key,
        state_value_name
      );
      setEditMode(false);
    },
    [selectedItemId]
  );

  const getParamterValueById = (paramValueId) => {
    return equipmentParameterLocal.find((param) => param.id === paramValueId);
  };

  const getItemRecord = useCallback(
    (selectedItemId) => {
      getItemRecordByIdFn
        .execute(selectedItemId)
        .then((result) => {
          setItems(
            prepareItemDataFromBackendForItemRecordComponent(result.item)
          );
          onCloseSelectItemDialog();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_get_item_data")
          );
        });
    },
    [items]
  );

  const onUpdatePramaterValue = (parameterValueId) => {
    setSelectedParameterValue(getParamterValueById(parameterValueId));
    onOpenAddPartParameterDialog();
  };

  const closePramaeterValuesDialog = useCallback(() => {
    setSelectedParameterValue(undefined);
    onCloseAddPartParameterDialog();
  }, [selectedParameterValue]);

  const onClickOpenParametersPartDialog = useCallback(() => {
    setSelectedParameterValue(undefined);
    onOpenAddPartParameterDialog();
  }, [selectedParameterValue]);

  const equipmentInfoSelectList = useAsync(getEquipmentSelectList);
  const equipmentFilteringData = useAsync(
    getEquipmentForCreateMeterFilteringData
  );

  const isLoading =
    equipmentDetailsData.loading || equipmentFilteringData.loading;

  const equipmentParamsContextMenuActions = [
    {
      label: t("page.equipment_passport_page.edit_parameter"),
      callback: (parameterValueId) => onUpdatePramaterValue(parameterValueId),
      icon: <EditIcon fontSize="small" />,
    },
    {
      label: t("page.equipment_passport_page.remove_parameter"),
      callback: (parameterValueId) => onHidePramaterValue(parameterValueId),
      icon: <DeleteOutlineIcon fontSize="small" />,
    },
  ];

  const onChangeLevelItem = (item, index = 0) => {
    var items_temp = [...items];
    items_temp[index] = item;
    setItems(item);
  };

  const onUpdateData = () => {
    if (checkIfRequiredFieldsAreFill()) {
      formValue["item"] = getChosenHighestLevelObject(items);
      updateEquipmentFn
        .execute(formValue.id, getPreparedFormDataToSend())
        .then((result) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.equipment_updated")
          );
          setEditMode(false);
          equipmentDetailsData.refetch();
          equipmentInfoSelectList.refetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(error.data),
            t("snackbar_alert.occurred_error_during_equipment_data_updating")
          );
        });
    }
  };

  const removeData = () => {
    removeEquipmentFn
      .execute(formValue.id)
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_removed")
        );
        setSelectedItemId(undefined);
        setFormValue({});
        equipmentDetailsData.refetch();
        equipmentInfoSelectList.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_equipment_removing")
        );
      });
  };

  const handleCloseConfirmationDialog = (confirmRemovePart) => {
    onCloseConfirmationDialog();
    if (confirmRemovePart) removeData();
  };

  const getPreparedFormDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (value !== equipmentDetailsData.value[key]) dataToSend[key] = value;
    }
    return dataToSend;
  };

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"equipment_passport"}>
        <LoaderWrapper showLoader={equipmentInfoSelectList.loading}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            columnSpacing={2}
            rowSpacing={2}
            style={{ marginBottom: "10px" }}
          >
            <Grid item xs={12} sm={2}>
              <AutocompleteField
                name={`id`}
                label={t(`form.item_form.choice_equipment`)}
                value={formValue[`id`]}
                options={equipmentInfoSelectList.value}
                isObjectOption={true}
                optionLabelKey={"equipment_info"}
                addNewValue={false}
                onChange={onChangeEquipmentFromList}
                disabled={props.readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
              {selectedItemId && !editMode && hasPermissionToManage && (
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="error"
                  startIcon={<DeleteIcon sx={centerVericalAlignIconStyle} />}
                  onClick={onOpenConfirmationDialog}
                >
                  {t("bar.warehouse_tool_bar.remove_equipment")}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={2} textAlign={{ xs: "center", sm: "right" }}>
              {selectedItemId && !editMode && hasPermissionToManage && (
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  startIcon={
                    <ModeEditOutlineIcon sx={centerVericalAlignIconStyle} />
                  }
                  onClick={() => setEditMode(true)}
                >
                  {t("bar.warehouse_tool_bar.edit_equipment")}
                </Button>
              )}
            </Grid>
            {hasPermissionToManage && (
              <Grid
                item
                xs={12}
                sm={2}
                textAlign={{ xs: "center", sm: "right" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  startIcon={
                    <ControlPointOutlinedIcon
                      sx={centerVericalAlignIconStyle}
                    />
                  }
                  onClick={onOpenCreateItemDialog}
                >
                  {t("bar.warehouse_tool_bar.add_equipment")}
                </Button>
              </Grid>
            )}
          </Grid>
        </LoaderWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={props.style}
        >
          <LoaderWrapper showLoader={isLoading}>
            {selectedItemId ? (
              <>
                <Grid item xs={12}>
                  <ItemEquipmentForm
                    formValue={formValue}
                    itemId={selectedItemId}
                    readOnly={!editMode}
                    setFormValue={setFormValue}
                    onChange={onChange}
                    onChangeAutocompleteFieldWithObjectOptions={
                      onChangeAutocompleteFieldWithObjectOptions
                    }
                    onChangeDate={onChangeDate}
                    filteringData={equipmentFilteringData}
                    unfillRequiredFields={unfillRequiredFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BoxWithTypography
                    style={{ padding: "10px" }}
                    label={t(`form.item_form.purchase_tree`)}
                    component={"div"}
                  >
                    <ItemRecord
                      ref={locationRecordsRef}
                      itemLevels={items}
                      onChangeItem={onChangeLevelItem}
                      readOnly={!editMode}
                      onClickSearchItem={onOpenSelectItemDialog}
                      defaultFirstLevelItemName={DEVICE_MAIN_BRANCH_NAME}
                      defaultSecondLevelItemName={COUNTER_BRANCH_NAME}
                    />
                  </BoxWithTypography>
                </Grid>
                <Grid item xs={12} sm={4} />
                {editMode && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        loading={props.isLoading}
                        onClick={onUpdateData}
                      >
                        {t("save_changes")}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={4} />
                  </>
                )}

                <Grid item xs={12} sm={editMode ? 8 : 4} />
                <Grid item xs={12} sm={8}>
                  <StockTransactionsRelatedTablesTabs
                    itemType={"equipment"}
                    equipmentId={selectedItemId}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MaterialParamRelatedTablesTabs
                    readOnly={false}
                    onParamtersRefetchData={equipmentParametersData.refetch}
                    onHandleAddNewParameter={onClickOpenParametersPartDialog}
                    partsParamsData={equipmentParameterLocal}
                    showParametersContextMenu={true}
                    parametersContextMenuActions={
                      equipmentParamsContextMenuActions
                    }
                    certificates={certs}
                    previewCertificate={onPreviewCert}
                    downloadCertificate={onDownloadCert}
                    updateCertificate={updateCerts}
                    deleteCertificate={hideCerts}
                    addCertificate={saveCerts}
                    partId={selectedItemId}
                    enclosures={enclosures}
                    previewEnclosure={onPreviewEnclosure}
                    downloadEnclosure={onDownloadEnclosure}
                    updateEnclosure={updateEquipmentEnclosure}
                    deleteEnclosure={deleteEnclosureForEquipment}
                    addEnclosure={saveEnclosures}
                    itemType={"equipment"}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <BaseBox>
                  <Typography
                    textAlign="center"
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{
                      margin: 0,
                      fontSize: "20px",
                      color: "var(--primary)",
                    }}
                  >
                    {t("form.item_form.select_equipment_first")}
                  </Typography>
                </BaseBox>
              </Grid>
            )}
          </LoaderWrapper>
        </Grid>

        {openCreateItemDialog && (
          <CreateItemDialog
            open={openCreateItemDialog}
            onClose={onCloseCreateItemDialog}
            onRefetchData={equipmentInfoSelectList.refetch}
            itemType={"equipment"}
            showSearchItemButton={false}
            restrictParamListForItem
            treeIndex={3.5}
          />
        )}

        {openAddPartParameterDialog && (
          <PartParameterDialog
            open={openAddPartParameterDialog}
            onClose={closePramaeterValuesDialog}
            onRefetchData={equipmentInfoSelectList.refetch}
            equipmentId={selectedItemId}
            onAddParameter={onAddParameter}
            isLoading={createParameterValueFn.loading}
            parameterValueData={selectedParameterValue}
            itemType={"equipment"}
            restrictParamListForItem
            itemId={getChosenHighestLevelObject(items)}
          />
        )}

        {openSelectItemDialog && (
          <SelectItemDialog
            open={openSelectItemDialog}
            onClose={onCloseSelectItemDialog}
            onSubmit={getItemRecord}
          />
        )}

        {openConfirmationDialog && (
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={() => handleCloseConfirmationDialog(false)}
            onYes={() => handleCloseConfirmationDialog(true)}
            title={t("page.equipment_passport_page.alert")}
            content={t(
              "page.equipment_passport_page.are_you_sure_to_remove_equipment"
            )}
          />
        )}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
EquipmentPassportPage.propTypes = {
  style: PropTypes.object,
};

EquipmentPassportPage.defaultProps = {};
