import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

const OwnerBookkeepingBoxForm = ({
  index,
  owner_bookk,
  onEditBookk,
  onRemoveBookk,
  readOnly }
) => {
  const { t } = useTranslation();


  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8} onClick={() => onEditBookk(owner_bookk?.id)}>
        <TextFieldFormControl
          value={owner_bookk?.ownboo_person_name}
          label={owner_bookk?.ownboo_function}
          readOnly = {readOnly}
        />
      </Grid>

      {!readOnly &&
        <Grid item xs={0.5}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveBookk(owner_bookk?.id, index)
            }}
            title={t("other.owner_bookkeeping_form_box.remove_owner_bookkeeping")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

OwnerBookkeepingBoxForm.propTypes = {
  owner_bookk: PropTypes.object,
  onEditBookk : PropTypes.func,
  onRemoveBookk : PropTypes.func,
  readOnly : PropTypes.bool
};

OwnerBookkeepingBoxForm.defaultProps = {
  readOnly : true
};

export default OwnerBookkeepingBoxForm;
