import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useWarehouseService from "../../../../services/warehouseService";
import useDialog from "../../../../hooks/useDialog";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import WarehouseDetailsDialog from "../../../dialog/WarehouseDetailsDialog";
import WarehouseLocationDialog from "../../../dialog/WarehouseLocationDialog/WarehouseLocationDialog";
import { ICO_PRODUCT_TABLE_CONFIG } from "./TableConfig";
import useIcoService from "../../../../services/icoService";
import IcoProductDialog from "../../../dialog/IcoProductDialog";


const IcoProductsTab = (props) => {
  const { t } = useTranslation();
  const [productsLocal, setProductsLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams] = useSearchParams();

  const { emptyArrayMemo } = useEmptyArrayMemo()

   const { getIcoProductList } = useIcoService();
 


  const [
    openProductDialog,
    productChangeId,
    onOpenProductDialog,
    onCloseProductDialog
  ] = useDialogWithId();

  

  const [openFilterDialog,
    onOpenFilterDialog,
    onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams();

  const icoProductsData = useAsync(
    () => getIcoProductList(searchParams),
    [searchParams]
  );


  useEffect(() => {
    if (icoProductsData.loading) {
       return;
    }
    setProductsLocal(icoProductsData.value.results);
    setCountRecords(icoProductsData.value.count);
  }, [icoProductsData.loading]);

  const isLoading = productsLocal === undefined || props.filteringData.loading


  const handleOpenProductDialog = useCallback(
    (e, productId) => {
      e.stopPropagation();
      onOpenProductDialog(productId);
    },
    []
  );



  const data = React.useMemo(
    () =>

      TableService.getPreparedDataForCollapsibleTable(
        ICO_PRODUCT_TABLE_CONFIG,
        productsLocal ? productsLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenProductDialog
      ),

    [productsLocal, ICO_PRODUCT_TABLE_CONFIG]
  );

  const onCleanFlitersInFilterDialog = () => {
    setProductsLocal(undefined);
    setCountRecords(undefined);
  };


  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) =>onOpenProductDialog(undefined)}
        >
          {t("tabs.ico_admin_tabs.add_product")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={ICO_PRODUCT_TABLE_CONFIG}
          showContextMenu={false}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            ICO_PRODUCT_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={ICO_PRODUCT_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openProductDialog &&
        <IcoProductDialog
          icoProductId={productChangeId}
          open={openProductDialog}
          onClose={onCloseProductDialog}
          onRefetch={icoProductsData.refetch}
        />

      }

    </Grid>
  );
};

IcoProductsTab.propTypes = {
  filteringData: PropTypes.object,
};

IcoProductsTab.defaultProps = {};

export default IcoProductsTab;
