import {TEXT_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE, AUTOCOMPLETE_FIELD_TYPE, DATE_RANGE_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";


export const CURRENCY_VALUE__TABLE_NAME  = "currency_value_table";
export const CURRENCY_VALUE_TABLE_CONFIG = [

  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.currency_table.currency_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.currencies,
      isObjectOption: true,
      optionLabelKey: "currency_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "currat_value",
    getValueCallback: (rowData) => rowData.currat_value,
    label: i18n.t("table.currency_table.currat_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
  
      decimalPlaces : 4
    }),
  },
  {
    name: "currat_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.currat_date),
    label: i18n.t("table.currency_table.currat_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },  
  {
    name: "currat_table",
    getValueCallback: (rowData) => rowData.currat_table,
    label: i18n.t("table.currency_table.nbp_table"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
];
