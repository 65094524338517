import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { Button, Grid } from "@mui/material";
import useUserService from "../../../services/userService";
import { useForm } from "../../../hooks/useForm";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import useIcoService from "../../../services/icoService";
import {ICO_SIGN_ACTION } from "../../../helpers/constants";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import DateField from "../../field/DateField/DateField";



const IcoSignDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [isSavingData, setIsSavingData] = useState(false);

  const {
    getIcoAgreementGenerateDetails,
    updateIco
  } = useIcoService();

  const updateIcoFn = useAsyncFn(updateIco)

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
  } = useForm({
    ico: props.icoId,
  })

  const requiredFields = [
    "ico_date_agreement"
  ];

  const icoGenerateData = useAsync(
    () => getIcoAgreementGenerateDetails(props.icoId),
    [props.icoId]);

  useEffect(() => {
    if (icoGenerateData.loading) {
      return;
    }
    setFormValue(icoGenerateData.value)

  }, [icoGenerateData.loading]);

  const isValid = formValue
    ? requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    ) : false;


  const { getUserSelectList } = useUserService();

  const userSelectList = useAsync(
    () => getUserSelectList(props.searchParams));

  const isLoading = userSelectList.loading 

  const onPrepareDataToSave = () => {
    let dataToSend = {};
    dataToSend.ico = formValue
    dataToSend.action = ICO_SIGN_ACTION;
    return dataToSend;
  };

  const onUpdateIco = () => {
    setIsSavingData(true);
    updateIcoFn
      .execute(props.icoId, onPrepareDataToSave())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.update_request")
        );
        setIsSavingData(false);
        props.onClose()
        props.onDataRefetch()
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_updating_request")
        );
      });
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.ico_sign_dialog.sign_agreement")}
    >
      <LoaderWrapper showLoader={isLoading || isSavingData} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >

          <Grid item xs={12}>
            <TextFieldFormControl
              name="ico_note_legal"
              multiline
              rows={3}
              resize={"vertical"}
              value={formValue?.ico_note_legal}
              label={t("dialog.ico_sign_dialog.ico_note_legal")}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <DateField
              name="ico_date_agreement"
              label={t("form.ico_main_data_form.ico_date_agreement")}
              value={formValue?.ico_date_agreement}
              onChange={onChangeDate}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onUpdateIco()}
              disabled={!isValid}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

IcoSignDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

};

IcoSignDialog.defaultProps = {
  open: false
};

export default IcoSignDialog;
