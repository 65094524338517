import { useMemo, useEffect } from "react";

import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import useUserService from "../../../services/userService";
import { useAsync } from "../../../hooks/useAsync";

import LoaderWrapper from "../../../components/wrapper/LoaderWrapper";
import AutocompleteField from "../../../components/field/AutocompleteField";

import { useForm } from "../../../hooks/useForm";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import useLocationService from "../../../services/locationService";

import LocationSearchRecords from "./LocationSearchRecords";

import { getSearchParamsObjectForFilterPrefix } from "../../../helpers/methods";

export default function RRAdminSearchBar(props) {
  const { t } = useTranslation();

  const { getTenantList, getUserSelectList } = useUserService();
  const { getObjectLevelsFilteringData } = useLocationService();

  const tenantList = useAsync(() =>
    getTenantList({ tenant_rr_visibility: true, tenant_active: true })
  );


  const userList = useAsync(() => getUserSelectList({ is_external: true , is_active : true}));

  const objectLevels = useAsync(() => getObjectLevelsFilteringData());

  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix, clearSearchParamsByFilterPrefixes } =
    useFilterSearchParams(searchParams);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm(getSearchParamsByFilterPrefix(props.filterPrefix));

  useEffect(() => {
    setFormValue(getSearchParamsByFilterPrefix(props.filterPrefix));
  }, [searchParams]);

  const getSearchParamsObjectsForFilterPrefixes = () => {
    let searchParamsObject = {};
    for (let tableFilterPrefix of props.tableFilterPrefixes) {
      searchParamsObject = {
        ...searchParamsObject,
        ...getSearchParamsObjectForFilterPrefix(formValue, tableFilterPrefix),
      };
    }
    return searchParamsObject;
  };

  const handleSearch = () => {
    setSearchParams(() => ({
      ...clearSearchParamsByFilterPrefixes([
        props.filterPrefix,
        ...props.tableFilterPrefixes,
      ]),
      // ...getObjectFromSearchParams(searchParams),
      ...getSearchParamsObjectForFilterPrefix(formValue, props.filterPrefix),
      ...getSearchParamsObjectsForFilterPrefixes(
        formValue,
        props.tableFilterPrefixes
      ),
    }));
  };
  const handleCleanFilters = () => {
    setFormValue(() => ({}));
    setSearchParams(() =>
      clearSearchParamsByFilterPrefixes([
        props.filterPrefix,
        ...props.tableFilterPrefixes,
      ])
    );
  };

  const isLoading =
    tenantList.loading || userList.loading || objectLevels.loading;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        columnSpacing={3}
        rowSpacing={2}
        marginY={1}
      >
        <Grid item xs={12} md={6}>
          <LocationSearchRecords
            formValue={formValue}
            filteringData={objectLevels.value}
            onChange={onChange}
            locationSearchMaxLevel={props.locationSearchMaxLevel}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"tenant_short_name"}
            name="tenant"
            value={formValue?.tenant}
            options={tenantList.value}
            label={t("bar.rr_admin_search_bar.tenant")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AutocompleteField
            multiple={true}
            optionValueKey={"id"}
            optionLabelKey={"full_name"}
            name="usertenant_user"
            value={formValue.usertenant_user}
            options={userList.value}
            label={t("bar.rr_admin_search_bar.office_manager")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
          />
        </Grid>
        <Grid
          container
          columnSpacing={2}
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="success"
              size="small"
              fullWidth
              onClick={handleSearch}
            >
              {t("search")}
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={handleCleanFilters}
            >
              {t("clean_filters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

RRAdminSearchBar.propTypes = {
  style: PropTypes.object,
  tableFilterPrefixes: PropTypes.array,
  locationSearchMaxLevel: PropTypes.number,
  filterPrefix: PropTypes.string,
};

RRAdminSearchBar.defaultProps = {
  locationSearchMaxLevel: 5,
  filterPrefix: "search_bar",
};
